import services from "@services/api";
import {
  CustomSpace,
  newCustomSpace,
  NewInspection,
  RoomSpace,
  Space,
} from "./types";
import { AppThunk } from "..";
import { Area, Attachment } from "../../types/common";
import { batch } from "react-redux";
import helpers from "../../services/helpers";
import { showNotification } from "../ui/uiThunk";
import l from "@locale";
import {
  NegativeInspectionTransaction,
  PositiveInspectionTransaction,
  SpaceTransaction,
  SpaceTransactionTypes,
} from "../../types/transaction";
import {
  commitInspectionTransaction,
  commitSpaceTransaction,
  removeSpace,
  setSpace,
  setSpaces,
} from "./spaceSlice";
import { addSuccess, startLoading } from "../ui/uiSlice";
import { transactionErrorMessage } from "@helpers";

export const handleSpaceTransaction =
  (actions: SpaceTransaction[]): AppThunk =>
  async (dispatch) => {
    const name = actions[0].name;
    try {
      dispatch(startLoading(name));

      await services.Transactions.create(actions);

      batch(() => {
        dispatch(commitSpaceTransaction(actions));
        dispatch(addSuccess(name));
      });
    } catch (error) {
      batch(() => {
        dispatch(
          showNotification(
            {
              type: "error",
              message: transactionErrorMessage(name),
            },
            name,
          ),
        );
      });
    }
  };

export const handleInspectionTransaction =
  ({
    transaction,
    newInspection,
  }: {
    transaction: PositiveInspectionTransaction | NegativeInspectionTransaction;
    oldSpace: Space;
    newInspection: NewInspection;
  }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(transaction.name));

      await services.Transactions.create([transaction]);
      batch(() => {
        dispatch(
          commitInspectionTransaction({
            transaction,
            newInspection,
          }),
        );
        dispatch(addSuccess(transaction.name));
      });
    } catch (error) {
      batch(() => {
        dispatch(
          showNotification(
            {
              type: "error",
              message: transactionErrorMessage(transaction.name),
            },
            transaction.name,
          ),
        );
      });
    }
  };

export const fetchSpaces = (): AppThunk => async (dispatch) => {
  try {
    const response = await services.Spaces.getAll();
    const spaces = response.data.reduce(
      (spaces, space) => {
        spaces[space.id] = space;
        return spaces;
      },
      {} as Record<Space["id"], Space>,
    );

    dispatch(setSpaces(spaces));
  } catch (error) {
    dispatch(
      showNotification({
        type: "error",
        message: l.t("actions.refresh.failed", {
          value: l.t("common.spaces").toLowerCase(),
        }),
        noOverride: true,
      }),
    );
  }
};

export const fetchSpace =
  (id: Space["id"]): AppThunk =>
  async (dispatch) => {
    try {
      const space = await services.Spaces.getById(id);
      dispatch(setSpace(space));
    } catch (error) {
      dispatch(
        showNotification({
          type: "error",
          message: l.t("actions.refresh.failed", {
            value: l.t("common.spaces").toLowerCase(),
          }),
        }),
      );
    }
  };

export const inspectRoom =
  (oldSpace: RoomSpace, newInspection: NewInspection): AppThunk =>
  async (dispatch) => {
    let transaction:
      | PositiveInspectionTransaction
      | NegativeInspectionTransaction = {
      name: SpaceTransactionTypes.FINISH_INSPECTION,
      userId: newInspection.userId,
      spaceId: oldSpace.id,
      createdAt: newInspection.createdAt,
      meta: {
        result: newInspection.result,
        remark: newInspection.remark,
      },
    };

    if (!newInspection.result) {
      let attachmentIds: Attachment["id"][] = [];
      if (
        newInspection.attachments !== undefined &&
        newInspection.attachments?.length > 0
      ) {
        try {
          const attachments = await helpers.createAttachments(
            newInspection.attachments,
          );
          attachmentIds = attachments.map((a) => a.id);
        } catch (error) {
          /* empty */
        }
      }

      transaction = {
        ...transaction,
        meta: {
          ...transaction.meta,
          tags: newInspection.tags ?? [],
          attachmentIds,
        },
      };
    }

    await dispatch(
      handleInspectionTransaction({ transaction, oldSpace, newInspection }),
    );
  };

export const createCustomSpace =
  (newSpace: newCustomSpace): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.customSpace");
    try {
      dispatch(startLoading("add:customSpace"));

      const space = await services.Spaces.createCustomSpace(newSpace);

      batch(() => {
        dispatch(setSpace(space));
        dispatch(
          showNotification({
            type: "success",
            message: l.t("actions.add.success", { value }),
          }),
        );
      });
    } catch (error) {
      batch(() => {
        dispatch(
          showNotification(
            {
              type: "error",
              message: l.t("actions.add.failed", { value }),
            },
            "add:customSpace",
          ),
        );
      });
    }
  };

export const deleteSpace =
  (id: Space["id"]): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.customSpace");

    try {
      dispatch(startLoading("delete:customSpace"));
      await services.Spaces.delete(id);

      batch(() => {
        dispatch(removeSpace(id));
        dispatch(
          showNotification(
            {
              type: "success",
              message: l.t("actions.delete.success", { value }),
            },
            "delete:customSpace",
          ),
        );
      });
    } catch (error) {
      batch(() => {
        dispatch(
          showNotification(
            {
              type: "error",
              message: l.t("actions.delete.failed", { value }),
            },
            "delete:customSpace",
          ),
        );
      });
    }
  };

export const updateCustomSpace =
  (space: Space, label: Space["label"], floor?: Space["floor"]): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.customSpace");

    try {
      dispatch(startLoading("update:customSpace"));
      await services.Spaces.update(space.id, { label, floor });
      batch(() => {
        dispatch(setSpace({ ...space, label, floor } as CustomSpace));
        dispatch(
          showNotification(
            {
              type: "success",
              message: l.t("actions.update.success", { value }),
            },
            "update:customSpace",
          ),
        );
      });
    } catch (error) {
      batch(() => {
        dispatch(
          showNotification(
            {
              type: "error",
              message: l.t("actions.update.failed", { value }),
            },
            "update:customSpace",
          ),
        );
      });
    }
  };

export const assignArea =
  (spaceIds: Space["id"][], areaId: Area["id"]): AppThunk =>
  async (dispatch) => {
    const value =
      spaceIds.length > 1 ? l.t("common.areas") : l.t("common.area");
    try {
      const response = await Promise.all(
        spaceIds.map(async (spaceId) => {
          return await services.Spaces.update(spaceId, { areaId });
        }),
      );

      batch(() => {
        response.forEach((res) => {
          dispatch(setSpace(res));
        });
        dispatch(
          showNotification({
            type: "success",
            message: l.t("actions.assign.success", { value }),
          }),
        );
      });
    } catch (error) {
      dispatch(
        showNotification({
          type: "error",
          message: l.t("actions.assign.failed", { value }),
        }),
      );
    }
  };

export const unassignArea =
  (spaceIds: Space["id"][]): AppThunk =>
  async (dispatch) => {
    const value =
      spaceIds.length > 1 ? l.t("common.areas") : l.t("common.area");

    try {
      const response = await Promise.all(
        spaceIds.map(async (spaceId) => {
          return await services.Spaces.update(spaceId, { areaId: "" });
        }),
      );

      batch(() => {
        response.forEach((res) => {
          dispatch(setSpace(res));
        });
        dispatch(
          showNotification({
            type: "success",
            message: l.t("actions.unassign.success", {
              value,
              count: spaceIds.length,
            }),
          }),
        );
      });
    } catch (error) {
      dispatch(
        showNotification({
          type: "error",
          message: l.t("actions.unassign.failed", { value }),
        }),
      );
    }
  };
