import { FC } from "react";
import theme from "@styles/theme";
import { RoomSpace } from "@store/space/types";

interface RoomRowProps {
  selectedRooms: RoomSpace;
  toggleSpace: (spaceId: RoomSpace["id"]) => void;
}

const RoomRow: FC<RoomRowProps> = ({ selectedRooms, toggleSpace }) => {
  const { label, type, id } = selectedRooms;
  const handleImageClick = () => {
    toggleSpace(id);
  };

  return (
    <li>
      {label} - {type}
      <img onClick={handleImageClick} src="/icons/icon-remove.svg" />
      <style jsx>{`
        li {
          display: flex;
          justify-content: space-between;
          color: ${theme.colors.dark};
          padding: 5px;
        }
        li:nth-child(odd) {
          background: ${theme.colors.mono200};
        }
        img {
          width: 20px;
          height: auto;
          margin: -5px;
          padding: 5px;
          cursor: pointer;
          background-color: #ffffff;
        }
      `}</style>
    </li>
  );
};
export default RoomRow;
