import * as React from "react";
import Layout from "@components/Layout";
import Tasks from "@screens/Tasks";
import withAuth from "@helpers/withAuth";

const TasksSettingsPage: React.FC = () => {
  return (
    <Layout active="settings/tasks">
      <Tasks />
    </Layout>
  );
};

export default withAuth(TasksSettingsPage);
