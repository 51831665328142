import theme from "@styles/theme";
import React, { useMemo } from "react";
import { makeSelectRoomSpaceById } from "@store/space/spaceSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { CleaningChoice, RoomSpace, RoomStates } from "@store/space/types";
import { useTranslation } from "react-i18next";

interface CleaningStatusProps {
  spaceId: RoomSpace["id"];
}

const CleaningStatus: React.FC<CleaningStatusProps> = ({ spaceId }) => {
  const { t } = useTranslation();
  const selectSpaceById = useMemo(makeSelectRoomSpaceById, [spaceId]);
  const space = useAppSelector((state) => selectSpaceById(state, spaceId));
  const { cleaningChoice, inspected, clean, state } = space.room;

  if (
    state === RoomStates.OUT_OF_INVENTORY ||
    state === RoomStates.OUT_OF_ORDER
  )
    return (
      <>
        <span className="cs">{t(`hk.roomState.${state}`)}</span>
        <style jsx>
          {`
            .cs {
              display: inline-block;
              box-sizing: border-box;
              border-radius: 4px;
              font-size: 14px;
              padding: 4px 8px;
              font-weight: 600;
              text-decoration: none;
              border: 2px solid ${theme.colors.brandBlue};
            }
          `}
        </style>
      </>
    );
  return (
    <>
      {cleaningChoice === CleaningChoice.ORANGE ? (
        <span className="cs orange-bordered">
          {clean
            ? t("hk.roomState.cleanTowels")
            : t("hk.roomState.dirtyTowels")}
        </span>
      ) : (
        <>
          {inspected && !clean && (
            <span className="cs red-bordered">
              {t("hk.roomState.dirtyAndInspected")}
            </span>
          )}
          {!inspected && clean && (
            <span className="cs orange-bordered">
              {t("hk.roomState.clean")}
            </span>
          )}
          {inspected && clean && (
            <span className="cs green-bordered">
              {t("hk.roomState.cleanAndInspected")}
            </span>
          )}
          {!inspected && !clean && (
            <span className="cs">{t("hk.roomState.dirty")}</span>
          )}
        </>
      )}
      <style jsx>
        {`
          .cs {
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            border-radius: 4px;
            font-size: 14px;
            padding: 4px 8px;
            font-weight: 600;
            text-decoration: none;
            border: 2px solid ${theme.colors.brandBlue};
          }
          /* Tablet optimizations */
          @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
            .cs {
              font-size: 12px;
            }
          }
          .service-orange {
            color: ${theme.colors.orangeLight};
            background-color: ${theme.colors.orangeDark};
          }
          .service-yellow {
            color: ${theme.colors.brandGoldDark};
            background-color: ${theme.colors.yellow};
          }

          .red-bordered {
            color: ${theme.colors.negative};
            border: 2px solid ${theme.colors.negative};
          }
          .green-bordered {
            color: ${theme.colors.positive};
            border: 2px solid ${theme.colors.positive};
          }
          .orange-bordered {
            color: ${theme.colors.accent};
            border: 2px solid ${theme.colors.accent};
          }
        `}
      </style>
    </>
  );
};

export default CleaningStatus;
