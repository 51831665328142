import { FC } from "react";
import theme from "@styles/theme";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { switchNavigationView } from "@store/ui/uiSlice";
import { useTranslation } from "react-i18next";
import { Translation } from "@locale";

interface SubSidebarLinkProps {
  active: boolean;
  href: string;
  label: keyof Translation["layout"]["menu"];
}

const SubSidebarLink: FC<SubSidebarLinkProps> = ({ active, href, label }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSideBar = () => {
    dispatch(switchNavigationView(true));
  };

  const onClick = () => {
    navigate(href);
  };

  return (
    <>
      <div onClick={onClick}>
        <a onClick={handleSideBar}>
          <li
            className={active ? "active subItem" : "subItem"}
            data-test-id="sidebar-sublink">
            {t(`layout.menu.${label}`)}
          </li>
        </a>
      </div>

      <style jsx>{`
        a {
          white-space: nowrap;
          font-size: 18px;
          color: ${theme.colors.white};
          width: 244px;
          text-decoration: none;
        }
        li {
          white-space: nowrap;
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          margin-left: -18px;
          padding: 12px 0 12px 40px;
          width: 100%;
          background: ${theme.colors.primary600};
        }
        li:hover {
          background-color: ${theme.colors.primary400};
          cursor: pointer;
        }
        li.active {
          background-color: ${theme.colors.primary400};
        }
      `}</style>
    </>
  );
};

export default SubSidebarLink;
