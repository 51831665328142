import { FC } from "react";
import { Helmet } from "react-helmet-async";
import theme from "@styles/theme";

const Head: FC = () => {
  return (
    <Helmet>
      <link
        href="//fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700"
        rel="stylesheet"
      />
      <style>{`
        html,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        main,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;
        }
        /* HTML5 display-role reset for older browsers */
        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        main,
        menu,
        nav,
        section {
          display: block;
        }
        /* HTML5 hidden-attribute fix for newer browsers */
        *[hidden] {
          display: none;
        }
        body {
          line-height: 1;
        }
        ol,
        ul {
          list-style: none;
        }
        blockquote,
        q {
          quotes: none;
        }
        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
          content: "";
          content: none;
        }
        table {
          border-collapse: collapse;
          border-spacing: 0;
        }
        p,
        div,
        li,
        td,
        th,
        span {
          font-family: "Source Sans Pro", sans-serif;
        }
        html,
        body,
        #__next {
          height: 100%;
          margin: 0;
        }

         {
          /* 
       */
        }

         {
          /* Global submit button */
        }
        .submit-bordered,
        .submit {
          display: flex;
          align-self: flex-start;
          border-radius: 4px;
          font-weight: bold;
          line-height: 20px;
          color: white;
          background-color: ${theme.colors.accent};
          cursor: pointer;
          padding: 12px 16px;
        }
        .submit-bordered {
          border: ${theme.colors.accent} solid 2px;
          background: white;
          color: ${theme.colors.accent};
        }

        strong {
          font-weight: bold;
        }
        .inactive {
          cursor: not-allowed;
          opacity: 0.3;
        }
        
        
        
        @font-face {
          font-family: "SourceSansPro-Regular";
          src: local("SourceSansPro-Regular"),
          url("/fonts/SourceSansPro-Regular.ttf") format("truetype");
          font-weight: normal;
        }

        @font-face {
          font-family: "SourceSansPro-Bold";
          src: local("SourceSansPro-Bold"),
          url("/fonts/SourceSansPro-Bold.ttf") format("truetype");
          font-weight: 900;
        }

        @font-face {
          font-family: "SourceSansPro-SemiBold";
          src: local("SourceSansPro-SemiBold"),
          url("/fonts/SourceSansPro-SemiBold.ttf") format("truetype");
          font-weight: 600;
        }

        @font-face {
          font-family: "SourceSansPro-Italic";
          src: local("SourceSansPro-Italic"),
          url("/fonts/SourceSansPro-Italic.ttf") format("truetype");
          font-weight: normal;
        }
        
        @font-face {
          font-family: "icomoon_solid";
          src: local("icomoon_solid"),
          url("/fonts/icomoon_solid.ttf") format("truetype");
          font-weight: normal;
        }
        @font-face {
          font-family: "icomoon_outline";
          src: local("icomoon_outline"),
          url("/fonts/icomoon_outline.ttf") format("truetype");
          font-weight: normal;
        }

        h1, h2, h3, h4 {
          font-family: "SourceSansPro-Bold" !important;
          letter-spacing: 0px !important;
        }

        h5, h6 {
          font-family: "SourceSansPro-Regular" !important;
          letter-spacing: 0px !important;
          font-size: 17px !important;
          line-height: 20px !important;
        }

        h1, h2 {
          font-size: 32px !important;
          line-height: 40px !important;
        }

        h3 {
          font-size: 24px !important;
          line-height: 32px !important;
        }

        a {
          color: ${theme.colors.primaryA};
          cursor: pointer;
        }

        a:hover {
          color: ${theme.colors.primary100};
        }
      `}</style>
    </Helmet>
  );
};

export default Head;
