import React, { useEffect, useState } from "react";
import SwitchDates from "@components/SwitchDates";
import { addDays, format, isFuture, subDays } from "date-fns";
import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import EndOfDayTable from "./components/EndOfDayTable";
import CreateEndOfDayRemark from "./components/CreateEndOfDayRemark";
import AuditsOverview from "./components/AuditsOverview";
import { ApprovalOverview } from "./components/ApprovalOverview";
import EndOfDayExtras from "./components/EndOfDayExtras";
import ActivityLog from "./components/ActivityLog";
import SpinnerLoader from "@components/SpinnerLoader";
import { selectCurrentRole } from "@store/user/userSelectors";
import { selectCurrentProperty } from "@store/property/propertySelector";
import { UserRoles } from "@store/user/types";
import { getLanguage, isAdmin } from "@helpers";
import {
  selectDayReportByDate,
  selectDayReportError,
  selectDayReportLoading,
} from "@store/dayReport/dayReportSelector";
import { Audit, DayReport, Total, UpdateTotals } from "@store/dayReport/types";
import { DayReportReportExtra } from "@store/reportExtra/types";
import {
  approveDayReport,
  deleteRemark,
  fetchDayReportByDate,
  fetchDayReports,
  updateDayReport,
} from "@store/dayReport/dayReportThunk";
import { fetchReportExtras } from "@store/reportExtra/reportExtraThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import theme from "@styles/theme";
import Button from "@components/base/button";
import Breadcrumb from "@components/base/breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavLabels } from "@components/Layout";
import { fetchUsers } from "@store/user/userThunk";

interface EndOfDayScreenProps {}

const EndOfDayScreen: React.FC<EndOfDayScreenProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectCurrentRole);
  const property = useAppSelector(selectCurrentProperty);
  const { permission } = property!.settings.reporting;
  const canEdit = permission.update.includes(role) || isAdmin(role);
  const canReportExtras =
    permission.reportExtras?.includes(role) || isAdmin(role);
  const dayReport = useSelector(selectDayReportByDate) as DayReport;
  const dayReportLoading = useSelector(selectDayReportLoading);
  const dayReportError = useSelector(selectDayReportError);

  const { search } = useLocation();
  let queryDate = new URLSearchParams(search).get("date") as string;

  const [extras, setExtras] = useState<DayReportReportExtra | undefined>({});
  const [remark, setRemarks] = useState<string | undefined>();
  const [totals, setTotals] = useState<UpdateTotals[]>([]);
  const [initialLoaderShown, setInitialLoaderShown] = useState<boolean>(false);

  useEffect(() => {
    setInitialLoaderShown(true);
  }, [dayReport]);

  useEffect(() => {
    if (isEmpty(queryDate) || isFuture(new Date(queryDate))) {
      navigate(`/day-reports/?date=${format(new Date(), "yyyy-MM-dd")}`);
    }
    resetEdits();
    getCurrentDayReport();
    getReportExtrasOfCurrentProperty();
  }, [queryDate]);

  const getCurrentDayReport = () => {
    dispatch(fetchDayReportByDate(queryDate));
  };

  const getReportExtrasOfCurrentProperty = () => {
    dispatch(fetchReportExtras());
  };
  const hasApproval =
    (role === UserRoles.HOTEL_MANAGER &&
      dayReport &&
      !!dayReport.approvals?.manager) ||
    (role === UserRoles.HK_MANAGER &&
      dayReport &&
      !!dayReport.approvals?.hkManager);
  const hasNoChanges =
    isEmpty(extras) && isEmpty(remark) && totals.length === 0;

  const onExtrasChange = (name: string, value: number) => {
    setExtras({
      ...extras,
      [name]: value,
    });
  };
  const onTotalChange = (value: number, type: keyof Total, id: string) => {
    const idExists = totals.findIndex((t) => t.id === id) !== -1;

    // if id exists, update the value of the type on the index of the id
    if (idExists) {
      const newTotals = totals.map((tot) => {
        if (tot.id === id) {
          return {
            ...tot,
            [type]: value,
          };
        }
        return tot;
      });
      setTotals(newTotals);
    } else {
      setTotals([
        ...totals,
        {
          id,
          [type]: value,
        },
      ]);
    }
  };

  const updateRemark = (remark: string) => {
    setRemarks(remark);
  };
  const resetEdits = () => {
    setRemarks(undefined);
    setExtras({});
    setTotals([]);
  };
  const onUpdate = async () => {
    dispatch(updateDayReport(dayReport.id, { totals, reportExtras: extras }));
    if (remark) submitRemark();
    resetEdits();
  };
  const onUpdateAndApprove = async () => {
    if (!hasApproval) {
      dispatch(approveDayReport(dayReport.id));
    }
    if (hasNoChanges) return;
    dispatch(
      updateDayReport(dayReport.id, {
        totals,
        reportExtras: extras,
      }),
    );
    if (remark) submitRemark();
    resetEdits();
  };

  const goToNext = (next: boolean) => {
    const nextDate = next
      ? addDays(new Date(queryDate), 1)
      : subDays(new Date(queryDate), 1);
    if (isFuture(new Date(nextDate))) {
      queryDate = format(new Date(), "yyyy-MM-dd");
      return;
    }
    navigate(`/day-reports/?date=${format(nextDate, "yyyy-MM-dd")}`);
  };

  const sortOnDate = (audit: Audit, audit2: Audit) => {
    const a = new Date(audit.createdAt);
    const b = new Date(audit2.createdAt);
    return a > b ? -1 : a < b ? 1 : 0;
  };

  const submitRemark = () => {
    dispatch(updateDayReport(dayReport.id, { remark }));
    setRemarks(undefined);
  };

  const removeRemark = (id: string) => {
    dispatch(deleteRemark(dayReport, id));
  };

  useEffect(() => {
    const unsavedChanges = !hasNoChanges;
    const message = t("dayreport.unsavedChanges");
    const beforeunload = (e: BeforeUnloadEvent) => {
      if (unsavedChanges) {
        e.preventDefault();
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", beforeunload);

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, [remark, totals, extras]);

  useEffect(() => {
    dispatch(fetchUsers());
    if (queryDate === undefined) {
      dispatch(
        fetchDayReports({
          filter: "unapproved",
        }),
      );
      return;
    }
    dispatch(fetchDayReportByDate(queryDate));
  }, []);

  const isFillerData = dayReport?.id.length === 0;

  return (
    <>
      <Breadcrumb labels={[NavLabels.DayReports, NavLabels.DayOverview]} />
      {(dayReportLoading && !initialLoaderShown) || !dayReport ? (
        <div css={styles.loader}>
          <SpinnerLoader />
        </div>
      ) : (
        <section data-test-id="hk-end-of-day" css={styles.section}>
          <div>
            <div css={styles.row}>
              <SwitchDates
                label={format(new Date(queryDate), "EEEE d-M-yy", {
                  locale: getLanguage(),
                })}
                goToNext={goToNext}
                showBadges={false}
                hasConstraints={false}
              />
              <ApprovalOverview
                parentStyles={styles.approvals}
                approvals={dayReport.approvals}
              />
              <div>
                {canEdit || canReportExtras ? (
                  <Button
                    onClick={onUpdate}
                    disabled={hasNoChanges}
                    kind="secondary"
                    css={styles.approveButton}>
                    {t("common.save")}
                  </Button>
                ) : null}
                {[UserRoles.HOTEL_MANAGER, UserRoles.HK_MANAGER].includes(
                  role,
                ) && (
                  <Button
                    onClick={onUpdateAndApprove}
                    disabled={hasApproval}
                    css={styles.approveButton}>
                    {canEdit || canReportExtras
                      ? t("dayreport.saveAndApprove")
                      : t("dayreport.approve")}
                  </Button>
                )}
              </div>
            </div>
            {dayReportError ? (
              <p css={styles.LoadingError}>{t("common.noData")}</p>
            ) : (
              <>
                <EndOfDayTable
                  dayReport={dayReport}
                  onTotalChange={onTotalChange}
                />
                {canReportExtras && (
                  <EndOfDayExtras
                    dayReportExtras={dayReport?.reportExtras}
                    onChange={onExtrasChange}
                  />
                )}
                <div css={styles.container}>
                  <CreateEndOfDayRemark
                    remark={remark}
                    setRemark={updateRemark}
                    submitRemark={submitRemark}
                    disabled={isFillerData}
                  />
                  <AuditsOverview
                    audits={[...dayReport.audits].sort(sortOnDate)}
                    removeRemark={removeRemark}
                  />
                </div>
                <ActivityLog audits={[...dayReport.audits].sort(sortOnDate)} />
              </>
            )}
          </div>
        </section>
      )}
    </>
  );
};

const styles = {
  section: css({
    overflow: "scroll",
    padding: theme.sizing.scale1600,
    backgroundColor: theme.colors.backgroundPrimary,
    paddingBottom: "90px",
  }),
  row: css({
    display: "flex",
    alignSelf: "stretch",
    justifyContent: "space-between",
    marginBottom: theme.sizing.scale800,
  }),
  container: css({
    marginTop: theme.sizing.scale800,
    marginBottom: theme.sizing.scale1600,
  }),
  approvals: css({
    alignSelf: "center",
  }),
  approveButton: css({
    marginLeft: "6px",
  }),
  LoadingError: css({
    fontWeight: 500,
  }),
  loader: css({
    width: "100vw",
    height: "100vh",
  }),
};

export default EndOfDayScreen;
