import React, { useMemo } from "react";
import theme from "@styles/theme";
import { makeSelectRoomSpaceById } from "@store/space/spaceSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { RoomSpace, SpaceType } from "@store/space/types";
import { css } from "@emotion/react";

interface RoomStatusIndicatorProps {
  spaceId: RoomSpace["id"];
}

const RoomStatusIndicator: React.FC<RoomStatusIndicatorProps> = ({
  spaceId,
}) => {
  const selectSpaceById = useMemo(makeSelectRoomSpaceById, [spaceId]);
  const space = useAppSelector((state) => selectSpaceById(state, spaceId));

  let statusColor = space?.room?.vacant
    ? theme.colors.positive
    : space?.room?.staying
      ? theme.colors.primaryA
      : theme.colors.negative;

  statusColor =
    space?.type !== SpaceType.ROOM ? theme.colors.mono200 : statusColor;

  return (
    <div
      css={css({
        position: "absolute",
        top: "1px",
        left: "0px",
        bottom: "1px",
        right: "0px",
        backgroundColor: statusColor,
      })}
    />
  );
};

export default RoomStatusIndicator;
