import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { selectAllAreas } from "@store/area/areaSelectors";
import { selectRoomFilters } from "@store/ui/uiSelectors";
import { RoomSpaceFilterTypes } from "@store/space/types";
import { Area } from "../../../types/common";
import { setRoomFilter } from "@store/ui/uiSlice";
import SegmentControl from "@components/base/segment-control";
import { useEffect } from "react";
import { fetchAreas } from "@store/area/areaThunk";
import React from "react";

const ALL_AREAS = "all";

const AreaOptions: React.FC = () => {
  const dispatch = useAppDispatch();
  const areas = useAppSelector(({ area }) => selectAllAreas(area));
  const activeFilters = useAppSelector(selectRoomFilters);
  const selectedAreaId = activeFilters[RoomSpaceFilterTypes.AREA_ID];

  const setSelectedArea = (label: Area["label"]) => {
    const value = areas.find((area) => area.label === label)?.id;
    dispatch(
      setRoomFilter({
        filterType: RoomSpaceFilterTypes.AREA_ID,
        value: value,
      }),
    );
  };

  const selectedIdToLabel = () => {
    return areas.find((area) => area.id === selectedAreaId)?.label;
  };

  useEffect(() => {
    dispatch(fetchAreas());
  }, [dispatch]);

  return (
    <SegmentControl
      values={areas.map((a) => a.label).concat([ALL_AREAS])}
      selectedValue={selectedIdToLabel() ?? ALL_AREAS}
      onPress={setSelectedArea}
    />
  );
};

export default AreaOptions;
