import { FC, useState } from "react";
import SelectOptions from "@components/SelectOptions";
import NewIssueContainer from "./NewIssueContainer";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { selectPaginationMeta } from "@store/issue/issueSelectors";
import useAppSelector from "@hooks/useAppSelector";
import Pagination from "@components/base/pagination";
import { IssueState, IssueType } from "@store/issue/types";
import SearchInput from "@components/base/search-input";
import { overrideButtonStyles } from "@components/base/button/overrides";
import Button from "@components/base/button";
import Modal from "@components/base/modal";
import { useTranslation } from "react-i18next";
import useAppDispatch from "@hooks/useAppDispatch";
import { exportIssuesByPage } from "@store/issue/issueThunk";

interface IssueTopRowProps {
  search: string;
  onSearchChange: (text: string) => void;
  state: IssueState | undefined;
  onStateChange: (filter: string) => void;
  onPaginationChange: (page: number) => void;
  type?: IssueType;
}

const IssueTopRow: FC<IssueTopRowProps> = ({
  search,
  onSearchChange,
  state,
  onStateChange,
  onPaginationChange,
  type = IssueType.TECHNICAL,
}) => {
  const { t } = useTranslation();
  const [showTaskForm, setShowTaskForm] = useState(false);
  const PaginationMeta = useAppSelector(selectPaginationMeta);
  const dispatch = useAppDispatch();

  const onClose = () => {
    setShowTaskForm(false);
  };

  const onShowTaskForm = () => {
    setShowTaskForm(true);
  };

  const onExportTasks = () => {
    dispatch(
      exportIssuesByPage({
        state,
        filter: search,
        type,
      }),
    );
  };

  return (
    <>
      {type === IssueType.TECHNICAL && (
        <div style={styles.containerEnd}>
          <a onClick={onExportTasks}>{t("issue.export")}</a>
        </div>
      )}
      <div css={[styles.container, styles.row]}>
        <div>
          {PaginationMeta.pages > 1 && (
            <Pagination
              totalPages={PaginationMeta.pages}
              onPaginationChange={onPaginationChange}
            />
          )}
        </div>
        <div data-test-id="td-search" css={styles.row}>
          <div data-test-id="status-row" css={[styles.row, styles.spacing]}>
            <span>{t("issue.states.title")}</span>
            <SelectOptions
              width={124}
              value={String(state)}
              onChange={onStateChange}
              optionsWithLabel={{
                [IssueState.OPEN]: t(`issue.states.${IssueState.OPEN}`),
                [IssueState.IN_PROGRESS]: t(
                  `issue.states.${IssueState.IN_PROGRESS}`,
                ),
                [IssueState.ON_HOLD]: t(`issue.states.${IssueState.ON_HOLD}`),
                [IssueState.ORDERED]: t(`issue.states.${IssueState.ORDERED}`),
                [IssueState.DONE]: t(`issue.states.${IssueState.DONE}`),
                undefined: t("issue.states.all"),
              }}
              style={{
                marginLeft: theme.sizing.scale400,
              }}
              selectorStyle={{ border: `1px solid ${theme.colors.mono500}` }}
            />
          </div>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            placeholder={t("search.taskPersonRoom")}
            containerStyle={{ width: "275px" }}
          />
          <Button
            onClick={onShowTaskForm}
            overrides={overrideButtonStyles({
              BaseButton: { marginLeft: theme.sizing.scale800 },
            })}>
            {t("issue.addNewTask")}
          </Button>
        </div>
      </div>
      {showTaskForm && (
        <Modal
          title={t("issue.newTask")}
          setShowModal={setShowTaskForm}
          isOpen={showTaskForm}>
          <NewIssueContainer onClose={onClose} type={type} />
        </Modal>
      )}
    </>
  );
};

const styles = {
  container: css({
    justifyContent: "space-between",
    paddingTop: theme.sizing.scale800,
    paddingBottom: theme.sizing.scale1200,
  }),
  row: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }),

  spacing: css({
    marginRight: theme.sizing.scale800,
  }),
  containerEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
};

export default IssueTopRow;
