import { selectCurrentProperty } from "@store/property/propertySelector";
import { FC, ReactNode, useEffect, useRef } from "react";
import useAppSelector from "@hooks/useAppSelector";
import { selectCurrentUserId } from "@store/user/userSelectors";
import { Property } from "@store/property/types";
import { fetchSpace } from "@store/space/spaceThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import { fetchProperties } from "@store/property/propertyThunk";
import { removeSpace } from "@store/space/spaceSlice";
import { fetchTask } from "@store/task/taskThunk";
import { removeTask } from "@store/task/taskSlice";
import { removeIssue } from "@store/issue/issueSlice";
import { fetchDayReport } from "@store/dayReport/dayReportThunk";
import { fetchIssue } from "@store/issue/issueThunk";
import { API_ROOT } from "@services/api";
interface PropertyProviderProps {
  children: ReactNode;
}

const PUBLIC_API = API_ROOT.replace(/http.?:\/\//, "");

const PropertyProvider: FC<PropertyProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const selectedProperty = useAppSelector(selectCurrentProperty);
  const userPropertyIds = useAppSelector(
    ({ user }) => user.current?.properties,
  );
  const currentUserId = useAppSelector(selectCurrentUserId);
  const subscribedProperty = useRef<Property | undefined>();
  const ws = useRef<WebSocket | null>(null);

  const code = selectedProperty?.code;

  const messageHandler = (event: MessageEvent) => {
    const payload = JSON.parse(event.data);
    // Ignore ping messages and other types with no relevant updates
    if (!payload.message || !payload.message.entity) return;

    const { entity, id, initiator_id, action } = payload.message as {
      entity: "day_report" | "issue" | "space" | "task";
      action: "create" | "update" | "destroy";
      id: string;
      initiator_id?: string; // $platform-$userId
    };

    let actionToDispatch = null;
    const fetch = action !== "destroy";
    // Ignore self-initiated broadcasts
    if (initiator_id === `web-${currentUserId}`) return;

    switch (entity) {
      case "day_report":
        // We don't destroy day reports
        if (fetch) actionToDispatch = fetchDayReport(id);
        break;
      case "issue":
        actionToDispatch = fetch ? fetchIssue(id) : removeIssue(id);
        break;
      case "task":
        actionToDispatch = fetch ? fetchTask(id) : removeTask(id);
        break;
      case "space":
        actionToDispatch = fetch ? fetchSpace(id) : removeSpace(id);
        break;
    }
    if (actionToDispatch !== null) dispatch(actionToDispatch);
  };

  const subscribeToProperty = (selectedProperty: Property) => {
    if (subscribedProperty.current) {
      ws.current?.send(
        JSON.stringify({
          command: "unsubscribe",
          identifier: JSON.stringify(subscribedProperty.current?.stream),
        }),
      );
    }
    ws.current?.send(
      JSON.stringify({
        command: "subscribe",
        identifier: JSON.stringify(selectedProperty.stream),
      }),
    );
  };

  const startWebSocket = (selectedProperty: Property) => {
    console.log("+++ connecting to socket");

    if (!ws.current) {
      ws.current = new WebSocket(`wss://${PUBLIC_API}/stream`);
      ws.current.onopen = () => {
        ws.current?.send(
          JSON.stringify({
            command: "subscribe",
            identifier: JSON.stringify(selectedProperty.stream),
          }),
        );
      };
      ws.current.onmessage = messageHandler;
      return;
    }
    subscribeToProperty(selectedProperty);
  };

  useEffect(() => {
    if (!code || !selectedProperty) return;
    startWebSocket(selectedProperty);
    subscribedProperty.current = selectedProperty;
  }, [code]);

  useEffect(() => {
    if (!userPropertyIds?.length) return;

    dispatch(fetchProperties());
  }, [userPropertyIds]);

  useEffect(() => {
    return () => {
      ws.current?.close();
    };
  }, []);

  return <>{children}</>;
};

export default PropertyProvider;
