import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import BaseSearchInput from "@components/base/search-input";
import { setRoomFilter } from "@store/ui/uiSlice";
import { RoomSpaceFilterTypes } from "@store/space/types";
import { useTranslation } from "react-i18next";

const SearchInput = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const dispatch = useDispatch();

  useEffect(() => {
    debounceSearch(search);
  }, [search]);

  const debounceSearch = useCallback(
    debounce((search) => {
      if (search.length == 0) search = undefined;
      dispatch(
        setRoomFilter({
          filterType: RoomSpaceFilterTypes.SEARCH_TEXT,
          value: search,
        }),
      );
    }, 200),
    [],
  );

  return (
    <BaseSearchInput
      onChange={setSearch}
      value={search}
      placeholder={t("search.roomOrHouseKeeper")}
      containerStyle={{ width: "275px" }}
    />
  );
};
export default SearchInput;
