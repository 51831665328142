import { FC } from "react";
import Layout from "@components/Layout";
import HkOverviewScreen from "@screens/HkOverview";
import withAuth from "@helpers/withAuth";

const HkOverviewContainer: FC = () => {
  return (
    <Layout active="overview">
      <HkOverviewScreen />
    </Layout>
  );
};
export default withAuth(HkOverviewContainer);
