import { Overrides } from "baseui/overrides";
import { isFunction } from "lodash";
import { Theme } from "baseui/theme";
import { StyleObject } from "styletron-standard";

export type OverrideStyles<T> = Partial<
  Record<
    keyof T,
    | StyleObject
    | ((props: { $theme: Theme } & React.PropsWithChildren<any>) => StyleObject)
  >
>;

export const overrideStyles = <T>({
  overrides,
  styles,
}: {
  overrides?: any;
  styles?: OverrideStyles<T>;
}): T => {
  if (styles === undefined) return overrides;

  const styleEntries = Object.entries(styles).reduce(
    (result: Overrides<any>, [key, value]) => {
      const getCurrentStyle = (props: any) => {
        if (!overrides[key]) return undefined;
        return isFunction(overrides[key].style)
          ? overrides[key].style(props)
          : overrides[key].style;
      };

      const addToStyle = (props: any) => {
        return isFunction(value) ? value(props) : value;
      };

      result[key] = {
        style: (props: any) => ({
          ...getCurrentStyle(props),
          ...addToStyle(props),
        }),
      };
      return result;
    },

    {}
  );

  return {
    ...overrides,
    ...styleEntries,
  };
};
