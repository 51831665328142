import {
  createSelector,
  Dictionary,
  EntityId,
  Selector,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import { isCustomSpace, isRoomSpace } from "../../helpers";
import { User } from "../user/types";
import { spaceSelectors } from "./spaceSlice";
import { isToday } from "date-fns";
import {
  Assignee,
  AssigneeCounts,
  CleaningChoice,
  CustomSpace,
  CustomSpaceTableSort,
  RoomSpace,
  RoomSpaceDropdownFilters,
  RoomSpaceFilters,
  RoomSpaceFilterTypes,
  RoomSpaceSortingKeys,
  RoomSpaceTableSort,
  RoomStatesOrder,
  Space,
  SpacesSortingKeys,
} from "./types";

const selectSelf = (state: RootState) => state.space;

export const {
  selectById: selectSpaceById,
  selectEntities: selectSpaceEntities,
  selectAll: selectAllSpaces,
} = spaceSelectors;

export const selectAllRoomSpaces: Selector<RootState, RoomSpace[]> =
  createSelector(selectSelf, ({ entities, ids }) => {
    const roomSpaces: RoomSpace[] = [];
    ids.forEach((id) => {
      const space = entities[id];
      if (isRoomSpace(space)) roomSpaces.push(space);
    });
    return roomSpaces;
  });

export const selectRoomSpaceEntities: Selector<
  RootState,
  Dictionary<RoomSpace>
> = createSelector(selectSelf, ({ entities, ids }) => {
  const roomSpaces: Dictionary<RoomSpace> = {};
  ids.forEach((id) => {
    const space = entities[id];
    if (!isRoomSpace(space)) return;
    roomSpaces[space.id] = space;
  });

  return roomSpaces;
});

export const selectAllCustomSpacesIds: Selector<
  RootState,
  CustomSpace["id"][]
> = createSelector(selectSelf, ({ entities, ids }) => {
  const CustomSpaceIds: CustomSpace["id"][] = [];
  ids.forEach((id) => {
    const space = entities[id];
    if (isCustomSpace(space)) CustomSpaceIds.push(id);
  });
  return CustomSpaceIds;
});
export const selectAllCustomSpaces: Selector<RootState, CustomSpace[]> =
  createSelector(selectSelf, ({ entities, ids }) => {
    const customSpaces: CustomSpace[] = [];
    ids.forEach((id) => {
      const space = entities[id];
      if (isCustomSpace(space)) customSpaces.push(space);
    });
    return customSpaces;
  });

export const selectAllRoomTypes: Selector<
  RootState,
  RoomSpace["room"]["code"][]
> = createSelector(selectSelf, ({ entities, ids }) => {
  const roomTypes = new Set<RoomSpace["room"]["code"]>();

  ids.forEach((id: Space["id"]) => {
    const space = entities[id];
    if (!isRoomSpace(space)) return;
    roomTypes.add(space.room.code);
  });
  return Array.from(roomTypes);
});

export const selectActiveTaskIds: Selector<RootState, EntityId[]> =
  createSelector(selectSelf, ({ ids, entities }) => {
    const taskIds = new Set<string | number>();

    ids.forEach((id) => {
      const space = entities[id];

      if (!isRoomSpace(space) || space.room.tasks === undefined) return;

      space.room.tasks.forEach((task) => {
        taskIds.add(task.id);
      });
    });
    return Array.from(taskIds);
  });

const filterRoomSpacesWithFilters = (
  filters: RoomSpaceFilters,
  members: Dictionary<User>,
  space: RoomSpace,
) => {
  // Not a room
  // The possible given filters
  const areaId = filters[RoomSpaceFilterTypes.AREA_ID];
  const roomCode = filters[RoomSpaceFilterTypes.ROOM_CODE];
  const searchText =
    filters[RoomSpaceFilterTypes.SEARCH_TEXT]?.toLocaleLowerCase();

  if (!hasRoomCode(roomCode, space)) return false;
  if (areaId !== undefined && space.areaId !== areaId) return false;

  // Finally filter on search text
  if (searchText !== undefined) {
    if (
      space.label.includes(searchText) ||
      space.room.users.some((a) =>
        members[a.id]?.profile?.name.toLocaleLowerCase().includes(searchText),
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  return true;
};

const hasRoomCode = (
  roomCodes: RoomSpaceFilters["room_code"],
  space: RoomSpace,
) => {
  if (!roomCodes?.length) return true;

  return roomCodes?.some((roomCode) => space.room?.code === roomCode);
};

const hasDropdownFilters = (filters: RoomSpaceFilters) => {
  let hasFilters = false;

  RoomSpaceDropdownFilters.forEach((filter) => {
    if (filters[filter] !== undefined) hasFilters = true;
  });

  return hasFilters;
};

const hasExtras = (space: RoomSpace) => {
  const guest = space.room.guest;
  const currentExtras = guest.current?.services.length;
  const arrivingExtras = guest.next?.services.length;

  return currentExtras || arrivingExtras;
};

const filterRoomSpacesWithDropdownFilters = (
  filters: RoomSpaceFilters,
  space: RoomSpace,
) => {
  let matchesFilter = false;

  if (!hasDropdownFilters(filters)) return true; // Return when there are no filters selected

  Object.keys(filters).forEach((filter) => {
    switch (filter) {
      case RoomSpaceFilterTypes.GREEN_CHOICE:
        matchesFilter ||= space.room.cleaningChoice === CleaningChoice.GREEN;
        break;
      case RoomSpaceFilterTypes.ORANGE_CHOICE:
        matchesFilter ||= space.room.cleaningChoice === CleaningChoice.ORANGE;
        break;
      case RoomSpaceFilterTypes.NON_GREEN_SKIP:
        matchesFilter ||=
          space.room.cleaningChoice === CleaningChoice.NON_GREEN_SKIP;
        break;
      case RoomSpaceFilterTypes.ARRIVALS:
        matchesFilter ||= space.room.arriving;
        break;
      case RoomSpaceFilterTypes.DEPARTURES:
        matchesFilter ||= space.room.departing;
        break;
      case RoomSpaceFilterTypes.STAYOVERS:
        matchesFilter ||= space.room.staying;
        break;
      case RoomSpaceFilterTypes.OCCUPIED_TODAY:
        matchesFilter ||=
          !space.room.vacant &&
          !!space.room.guest.current &&
          isToday(new Date(space.room.guest.current.arrival));
        break;
      case RoomSpaceFilterTypes.ROOM_CLEAN:
        matchesFilter ||= space.room.clean;
        break;
      case RoomSpaceFilterTypes.ROOM_DIRTY:
        matchesFilter ||= !space.room.clean;
        break;
      case RoomSpaceFilterTypes.WITH_EXTRAS:
        matchesFilter ||= !!hasExtras(space);
        break;
      case RoomSpaceFilterTypes.WITH_TASKS:
        matchesFilter ||= !!space.room.tasks!.length;
        break;
    }
  });
  return matchesFilter;
};
export const selectRoomSpaceIdsByFilters: Selector<
  RootState,
  RoomSpace["id"][]
> = createSelector(
  (state: RootState) => state.ui.roomFilters,
  (state: RootState) => state.space.ids,
  (state: RootState) => state.user.entities,
  (state: RootState) => state.space.entities,
  (roomFilters, ids, members, spaces) => {
    const roomSpaceIds: RoomSpace["id"][] = [];
    ids.forEach((id) => {
      const space = spaces[id];
      if (!isRoomSpace(space)) return;
      if (!filterRoomSpacesWithFilters(roomFilters, members, space)) return;
      if (!filterRoomSpacesWithDropdownFilters(roomFilters, space)) return;
      roomSpaceIds.push(id);
    });
    return roomSpaceIds;
  },
);

export const selectCustomSpaceIdsBySorting: Selector<
  RootState,
  { sorting: CustomSpaceTableSort; ids: CustomSpace["id"][] }
> = createSelector(
  selectSelf,
  selectAllCustomSpacesIds,
  (state: RootState) => state.ui.customSpaceSorting,
  ({ entities }, ids, sorting = { key: SpacesSortingKeys.NAME, asc: true }) => {
    if (!sorting.key) return { sorting, ids };
    const getSortMethod = () => {
      switch (sorting.key) {
        case "name":
          return (a: CustomSpace, b: CustomSpace) =>
            a.label.trim().toLowerCase() < b.label.trim().toLowerCase()
              ? -1
              : 1;
        case "floor":
          return (a: CustomSpace, b: CustomSpace) => {
            return Number(a.floor) > Number(b.floor) ? -1 : 1;
          };
        default:
          return () => 0;
      }
    };

    const sortMethod = getSortMethod();

    ids.sort((a: EntityId, b: EntityId) => {
      const aSpace = entities[a] as CustomSpace;
      const bSpace = entities[b] as CustomSpace;
      const [x, y] = sorting.asc ? [aSpace, bSpace] : [bSpace, aSpace];
      return sortMethod(x, y);
    });
    return { sorting, ids };
  },
);

export const selectRoomSpaceIdsBySorting: Selector<
  RootState,
  { sorting: RoomSpaceTableSort; ids: RoomSpace["id"][] }
> = createSelector(
  selectSelf,
  selectRoomSpaceIdsByFilters,
  (state: RootState) => state.ui.roomSorting,
  (
    { entities },
    ids,
    sorting = { key: RoomSpaceSortingKeys.ROOM, asc: true },
  ) => {
    if (!sorting.key) return { sorting, ids };

    const getSortMethod = () => {
      switch (sorting.key) {
        case "action":
          return (x: RoomSpace) =>
            x.room.cleaningChoice === CleaningChoice.GREEN ||
            x.room.cleaningChoice === CleaningChoice.ORANGE ||
            x.room.cleaningChoice === CleaningChoice.NON_GREEN_SKIP
              ? -1
              : 1;
        case "room":
          return (x: RoomSpace, y: RoomSpace) =>
            Number(x.label) > Number(y.label) ? 1 : -1;
        case "roomStatus":
          return (x: RoomSpace) =>
            x.room.guest.current && x.room.guest.current.services.length > 0
              ? -1
              : 1;
        case "arrivals":
          return (x: RoomSpace) =>
            x.room.guest.next && x.room.guest.next.services.length > 0 ? -1 : 1;
        case "cleaningStatus":
          return (x: RoomSpace, y: RoomSpace) =>
            RoomStatesOrder.indexOf(x.room.state) >
            RoomStatesOrder.indexOf(y.room.state)
              ? 1
              : -1;
        case "tasks":
          return (x: RoomSpace, y: RoomSpace) =>
            x.room.tasks && x.room.tasks.length > y.room.tasks!.length ? -1 : 1;
        default:
          return () => 0;
      }
    };

    const sortMethod = getSortMethod();

    ids.sort((a: EntityId, b: EntityId) => {
      const roomSpaceA = entities[a] as RoomSpace;
      const roomSpaceB = entities[b] as RoomSpace;

      const [x, y] = sorting.asc
        ? [roomSpaceA, roomSpaceB]
        : [roomSpaceB, roomSpaceA];

      return sortMethod(x, y);
    });

    return { sorting, ids };
  },
);

export const selectVacantBySpaceId: Selector<
  RootState,
  { leavers: number; stayers: number },
  [EntityId[]]
> = createSelector(
  selectSelf,
  (_: any, ids: Space["id"][]) => ids,
  ({ entities }, ids) => {
    const roomStatus: { leavers: number; stayers: number } = {
      leavers: 0,
      stayers: 0,
    };

    ids.forEach((id) => {
      const space = entities[id];

      if (!isRoomSpace(space)) return;
      if (space.room.departing) roomStatus.leavers += 1;
      if (space.room.staying) roomStatus.stayers += 1;
      return;
    });

    return roomStatus;
  },
);

export const selectAssigneeCounts: Selector<RootState, AssigneeCounts> =
  createSelector(selectSelf, ({ entities, ids }) => {
    const assignees: AssigneeCounts = {};

    ids.forEach((id) => {
      const space = entities[id];
      if (!isRoomSpace(space)) return;
      space.room.users.forEach((assignee) => {
        if (assignees[assignee.id]) {
          assignees[assignee.id] += 1;
        } else {
          assignees[assignee.id] = 1;
        }
      });
    });

    return assignees;
  });

export const selectCurrentSpaceId: Selector<RootState, EntityId | undefined> =
  createSelector(selectSelf, (data) => data.selectedId);

export const selectRoomSpacesByIds: Selector<
  RootState,
  RoomSpace[],
  [Space["id"][]]
> = createSelector(
  selectSelf,
  (_: any, ids: Space["id"][]) => ids,
  ({ entities }, ids) => {
    const spaces: RoomSpace[] = [];
    ids.forEach((id) => {
      const space = entities[id];
      if (space === undefined || !isRoomSpace(space)) return;
      spaces.push(space);
    });
    return spaces;
  },
);

export const selectAssigneeBySpaceId: Selector<
  RootState,
  Assignee | undefined,
  [Space["id"]]
> = createSelector(
  (state: RootState) => state.space.entities,
  (_: any, spaceId: Space["id"]) => spaceId,
  (spaces, spaceId) => {
    const space = spaces[spaceId];
    if (!isRoomSpace(space)) return undefined;
    if (space.room.users.length == 0) return undefined;
    return space.room.users[0];
  },
);

export const makeSelectRoomSpaceById: () => Selector<
  RootState,
  RoomSpace,
  [EntityId]
> = () =>
  createSelector(
    selectSelf,
    (_: RootState, spaceId: EntityId) => spaceId,
    ({ entities }, spaceId: EntityId) => {
      return entities[spaceId] as RoomSpace;
    },
  );
export const makeSelectCustomSpaceById: () => Selector<
  RootState,
  CustomSpace,
  [EntityId]
> = () =>
  createSelector(
    selectSelf,
    (_: RootState, spaceId: EntityId) => spaceId,
    ({ entities }, spaceId: EntityId) => {
      return entities[spaceId] as CustomSpace;
    },
  );

export const selectCustomSpaceById: Selector<
  RootState,
  CustomSpace | undefined,
  [EntityId]
> = createSelector(
  selectSelf,
  (_: any, id: CustomSpace["id"]) => id,
  ({ entities }, id: CustomSpace["id"]) => {
    const space = entities[id] as CustomSpace;
    if (!isCustomSpace(space)) return undefined;
    return space;
  },
);
