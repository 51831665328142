import { FC } from "react";
import NameRow from "./NameRow";
import FloorRow from "./FloorRow";
import ActionRow from "./ActionRow";
import { EntityId } from "@reduxjs/toolkit";

interface TableRowProps {
  customId: EntityId;
  handleContentChange: (value: EntityId) => void;
  handleContentDelete: (value: EntityId) => void;
}

const TableRow: FC<TableRowProps> = ({
  customId,
  handleContentChange,
  handleContentDelete,
}) => {
  return [
    <NameRow customId={customId} />,
    <FloorRow customId={customId} />,
    <ActionRow
      customId={customId}
      handleChange={handleContentChange}
      handleDelete={handleContentDelete}
    />,
  ];
};

export default TableRow;
