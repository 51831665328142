import { FC } from "react";
import theme from "@styles/theme";
import { selectTaskById } from "@store/task/taskSelectors";
import { useMemo } from "react";
import { batch } from "react-redux";
import { Space } from "@store/space/types";
import { Task } from "../../../types/common";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import { SpaceTransactionTypes } from "@store/transaction/types";
import { handleSpaceTransaction } from "@store/space/spaceThunk";
import { useTranslation } from "react-i18next";

const selectById = () => selectTaskById;

interface TaskRowProps {
  spaceId: Space["id"];
  taskId: Task["id"];
}

const TaskRow: FC<TaskRowProps> = ({ spaceId, taskId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectTask = useMemo(selectById, [taskId]);
  const task = useAppSelector((state) => selectTask(state.task, taskId));

  const assignTask = (
    taskId: Task["id"],
    spaceId: Space["id"],
    assign: boolean,
  ) => {
    return {
      name: assign
        ? SpaceTransactionTypes.ASSIGN_SPACE
        : SpaceTransactionTypes.UNASSIGN_SPACE,
      spaceId,
      taskId,
      createdAt: new Date().toISOString(),
    };
  };

  const onRemove = () => {
    batch(() => {
      dispatch(handleSpaceTransaction([assignTask(taskId, spaceId, false)]));
    });
  };

  if (task === undefined) return null;
  const { label, description } = task;

  return (
    <div className="container" key={taskId}>
      <div className="text">
        <h5>{label}</h5>
        <p>
          {description &&
            description?.length > 0 &&
            t("hk.description", { description })}
        </p>
      </div>
      <img onClick={onRemove} src="/icons/icon-remove.svg" />
      <style jsx>{`
        .container {
          text-align: left;
          display: flex;
          flex-direction: row;
          flex: 1;
          justify-content: space-between;
        }
        .text {
          padding: 4px 8px;
        }
        .container:nth-child(odd) > .text {
          background: ${theme.colors.mono200};
        }
        p {
          margin-top: 4px;
        }
        h5 {
          font-weight: bold;
        }
        img {
          width: 20px;
          margin-left: 8px;
          height: auto;
          background-color: #ffffff;
          z-index: 2;
        }
        img:hover {
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default TaskRow;
