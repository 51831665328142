import React, { useMemo } from "react";
import { css } from "@emotion/react";
import useAppSelector from "@hooks/useAppSelector";
import { makeSelectRoomSpaceById } from "@store/space/spaceSelectors";
import { RoomSpace } from "@store/space/types";
import { getSpaceStatus } from "@helpers";

interface RoomProps {
  spaceId: RoomSpace["id"];
}

const Room: React.FC<RoomProps> = ({ spaceId }) => {
  const selectSpaceById = useMemo(makeSelectRoomSpaceById, [spaceId]);
  const space = useAppSelector((state) => selectSpaceById(state, spaceId));
  const {
    room: { code },
    label,
  } = space;

  return (
    <div
      css={css({
        borderLeft: `4px solid ${getSpaceStatus(space)}`,
        padding: "4px",
      })}>
      <span css={styles.label}>{label}</span>
      <span>{` - ${code}`}</span>
    </div>
  );
};

const styles = {
  label: css({
    fontWeight: "bold",
  }),
};
export default React.memo(Room);
