import { css } from "@emotion/react";
import { FC, useEffect, useState } from "react";
import TotalDifferenceTable from "./TotalDifferenceTable";
import useAppSelector from "@hooks/useAppSelector";
import { selectCurrentProperty } from "@store/property/propertySelector";
import { selectCurrentRole } from "@store/user/userSelectors";
import { DayReport, RateTypeTotal, Total } from "@store/dayReport/types";
import { overrideTableStyles } from "@components/base/table/overrides";
import theme from "@styles/theme";
import Input from "@components/base/input";
import { forceToNumberString } from "@helpers";
import Table from "@components/base/table";
import { useTranslation } from "react-i18next";

interface EndOfDayTableProps {
  dayReport: DayReport;
  onTotalChange: (value: number, type: keyof Total, id: string) => void;
}

const EndOfDayTable: FC<EndOfDayTableProps> = ({
  dayReport,
  onTotalChange,
}) => {
  const { t } = useTranslation();
  const role = useAppSelector(selectCurrentRole);
  const property = useAppSelector(selectCurrentProperty);
  const { permission } = property!.settings.reporting;
  const canEdit = permission.update.includes(role);
  const { totals, perRate } = dayReport;
  const [sortedPerRate, setSortedPerRate] = useState<RateTypeTotal[]>([]);

  useEffect(() => {
    setSortedPerRate(
      Object.values(perRate).sort((a, b) => (a.rateName < b.rateName ? -1 : 1)),
    );
  }, [dayReport]);

  const totalsPerRate = sortedPerRate.reduce(
    (acc, cur) => {
      return {
        cleaned: acc.cleaned + cur.cleaned,
        green: acc.green + cur.green,
        orange: acc.orange + cur.orange,
        nonGreenSkip: acc.nonGreenSkip + cur.nonGreenSkip,
        doNotDisturb: acc.doNotDisturb + cur.doNotDisturb,
        carriedOver: acc.carriedOver + cur.carriedOver,
      };
    },
    {
      cleaned: 0,
      green: 0,
      orange: 0,
      nonGreenSkip: 0,
      doNotDisturb: 0,
      carriedOver: 0,
    },
  );

  const onChangeText = (value: string, type: keyof Total, rateId: string) => {
    let parsedValue = Number(forceToNumberString(value));
    if (isNaN(parsedValue)) parsedValue = 0;

    onTotalChange(parsedValue, type, rateId);

    const indexOfRate = sortedPerRate.findIndex((rate) => rate.id === rateId);

    setSortedPerRate(
      sortedPerRate.map((rate, index) =>
        index === indexOfRate ? { ...rate, [type]: parsedValue } : rate,
      ),
    );
  };

  const editView = (item: RateTypeTotal) => {
    const setNumberChange = (value: string, type: keyof Total) => {
      onChangeText(value, type, item.id);
    };
    const containerWidth = { width: "4.5rem" };
    return [
      <Input
        key="cleaned"
        value={item.cleaned}
        onChangeText={(text) => setNumberChange(text, "cleaned")}
        containerStyle={containerWidth}
      />,
      <Input
        key="green"
        value={item.green}
        onChangeText={(text) => setNumberChange(text, "green")}
        containerStyle={containerWidth}
      />,
      <Input
        key="orange"
        value={item.orange}
        onChangeText={(text) => setNumberChange(text, "orange")}
        containerStyle={containerWidth}
      />,
      <Input
        key="nonGreenSkip"
        value={item.nonGreenSkip}
        onChangeText={(text) => setNumberChange(text, "nonGreenSkip")}
        containerStyle={containerWidth}
      />,
      <Input
        key="doNotDisturb"
        value={item.doNotDisturb}
        onChangeText={(text) => setNumberChange(text, "doNotDisturb")}
        containerStyle={containerWidth}
      />,
      <Input
        key="carriedOver"
        value={item.carriedOver}
        onChangeText={(text) => setNumberChange(text, "carriedOver")}
        containerStyle={containerWidth}
      />,
    ];
  };
  const spectatorView = (item: RateTypeTotal) => {
    return [
      item.cleaned,
      item.green,
      item.orange,
      item.nonGreenSkip,
      item.doNotDisturb,
      item.carriedOver,
    ];
  };
  const generateRows = () => {
    const rateTypeRows =
      Object.values(sortedPerRate).map((item) => {
        return [
          item.rateName,
          canEdit ? editView(item) : spectatorView(item),
        ].flat();
      }) ?? [];
    rateTypeRows.push([
      t("common.total"),
      totalsPerRate.cleaned,
      totalsPerRate.green,
      totalsPerRate.orange,
      totalsPerRate.nonGreenSkip,
      totalsPerRate.doNotDisturb,
      totalsPerRate.carriedOver,
    ]);
    return rateTypeRows;
  };

  return (
    <>
      <Table
        css={styles.statisticsTable}
        columns={[
          null,
          t("dayreport.clean"),
          t("dayreport.green.title"),
          t("dayreport.orange.title"),
          t("dayreport.nonGreenSkip.title"),
          t("dayreport.doNotDisturb.title"),
          t("dayreport.carriedOver.title"),
        ]}
        data={generateRows()}
        emptyMessage={t("dayreport.noData")}
        overrides={overrideTableStyles({
          TableBodyRow: {
            height: "58px",
            ":last-of-type": {
              borderTop: `2px solid ${theme.colors.mono500}`,
            },
          },
          TableBodyCell: {
            ":first-child": {
              fontWeight: 700,
              fontSize: "16px",
            },
          },
        })}
      />
      <TotalDifferenceTable
        totals={{
          ...totals,
          ...totalsPerRate,
        }}
        isDayTotal={dayReport.statistics == undefined}
      />
    </>
  );
};

const styles = {
  statisticsTable: css({
    marginBottom: "32px",
    whiteSpace: "nowrap",
    width: "max(875px, 45vw)",
    overflowX: "hidden",
  }),
  totalsTable: css({
    marginTop: "32px",
  }),
};

export default EndOfDayTable;
