import React from "react";
import {
  Breadcrumbs as BaseBreadcrumbs,
  BreadcrumbsProps as BaseBreadcrumbsProps,
} from "baseui/breadcrumbs";
import { breadcrumbOverrides } from "@components/base/breadcrumb/overrides";
import { useTranslation } from "react-i18next";
import { Translation } from "@locale";

export interface BreadcrumbProps extends BaseBreadcrumbsProps {
  labels: (keyof Translation["layout"]["menu"])[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  const { t } = useTranslation();
  return (
    <BaseBreadcrumbs
      {...props}
      overrides={{ ...props.overrides, ...breadcrumbOverrides }}>
      {props.labels.map((label) => (
        <span key={label}>{t(`layout.menu.${label}`)}</span>
      ))}
    </BaseBreadcrumbs>
  );
};

export default Breadcrumb;
