import React, { useState } from "react";
import { Input } from "baseui/input";
import { ParagraphMedium, ParagraphSmall } from "baseui/typography";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { signInUser } from "@store/user/userThunk";
import Button from "@components/base/button";
import { overrideButtonStyles } from "@components/base/button/overrides";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "@hooks/useAppDispatch";
import { API_ROOT } from "@services/api";
import { useTranslation } from "react-i18next";

interface LoginScreenProps {}

interface FormValues {
  email?: string;
  password?: string;
}

const LoginScreen: React.FC<LoginScreenProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [form, setForm] = useState<FormValues>({
    email: "",
    password: "",
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") return;
    onLogin();
  };

  const onLogin = async () => {
    setIsLoading(true);
    setError("");
    try {
      await dispatch(signInUser(form));
      navigate("/");
      return;
    } catch (error) {
      setError(t("login.loginError"));
    }
    setIsLoading(false);
  };

  return (
    <div css={styles.wrapper} data-test-id="login-screen">
      <div>
        <Input
          data-test-id="login-email"
          value={form.email}
          type="email"
          name="email"
          placeholder={t("login.self")}
          onChange={onChange}
          overrides={{
            Root: {
              style: {
                marginBottom: "16px",
              },
            },
          }}
        />
        <Input
          data-test-id="login-password"
          value={form.password}
          type="password"
          name="password"
          placeholder={t("login.password")}
          onChange={onChange}
          onKeyPress={onKeyPress}
          overrides={{
            Root: {
              style: {
                marginBottom: "16px",
              },
            },
          }}
        />
        <Button
          data-test-id="login-submit"
          onClick={onLogin}
          isLoading={isLoading}
          disabled={isLoading}
          size="large"
          overrides={overrideButtonStyles({
            BaseButton: { width: "240px" },
          })}>
          {t("layout.menu.app-login")}
        </Button>
        <ParagraphMedium>
          <a
            data-test-id="forgot-password"
            href={`${API_ROOT}/hktd/passwords/new`}
            style={{ color: theme.colors.linkText }}>
            {t("login.forgotPassword")}
          </a>
        </ParagraphMedium>

        {!!error && (
          <ParagraphSmall color={theme.colors.negative}>{error}</ParagraphSmall>
        )}
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    height: "100vh",
    backgroundColor: theme.colors.backgroundPrimary,
    padding: theme.sizing.scale1800, // 48px
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  }),
};

export default LoginScreen;
