import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Area } from "../../types/common";

export interface AreaState extends EntityState<Area> {
  selectedId?: EntityId;
}

export const areaAdapter = createEntityAdapter<Area>();

const initialState: AreaState = {
  ...areaAdapter.getInitialState(),
  selectedId: undefined,
};

const areaSlice = createSlice({
  name: "areas",
  initialState,
  reducers: {
    addArea: areaAdapter.addOne,
    setAreas(state, { payload }: PayloadAction<Area[]>) {
      areaAdapter.setAll(state, payload);
    },
    setSelectedArea(state, { payload }: PayloadAction<Area["id"] | undefined>) {
      state.selectedId = payload;
    },
    removeArea: areaAdapter.removeOne,
    upsertArea: areaAdapter.upsertOne,
  },
});

export const { addArea, setAreas, removeArea, upsertArea, setSelectedArea } =
  areaSlice.actions;

export default areaSlice;
