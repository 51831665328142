import React from "react";
import withAuth from "@helpers/withAuth";
import Layout from "@components/Layout";
import RateTypeFormScreen from "@screens/RateTypeForm";

const RateTypeFormPage: React.FC = () => {
  return (
    <Layout active="settings/day-reports/rate-type-form">
      <RateTypeFormScreen />
    </Layout>
  );
};

export default withAuth(RateTypeFormPage);
