import { FC, CSSProperties } from "react";
import theme from "@styles/theme";
import { css } from "@emotion/react";
import { Input } from "baseui/input";

export interface InputProps {
  onChangeText?: (value: string) => void;
  name?: string;
  label?: string;
  value?: string | number;
  placeholder?: string;
  containerStyle?: CSSProperties;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  autoFocus?: boolean;
}

const InputField: FC<InputProps> = ({
  onChangeText,
  name,
  label,
  value,
  placeholder,
  containerStyle,
  disabled,
  required,
  helperText,
  autoFocus = false,
}) => {
  return (
    <div css={styles.wrapper}>
      {label && (
        <label
          css={[
            containerStyle?.marginTop && {
              marginTop: containerStyle?.marginTop,
            },
            { marginBottom: "2px" },
          ]}>
          {label}
        </label>
      )}
      <Input
        autoFocus={autoFocus}
        data-baseweb="input-field"
        name={name ?? ""}
        onChange={(value) =>
          onChangeText && onChangeText(value.currentTarget.value)
        }
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        overrides={{
          Root: {
            style: () => ({
              backgroundColor: "none",
              border: "none",
              outline: "none",
              boxShadow: "none",
              right: containerStyle?.right && `${containerStyle?.right}`,
            }),
          },
          InputContainer: {
            style: () => ({
              backgroundColor: "none",
              border: "none",
              outline: "none",
              boxShadow: "none",
              width: containerStyle?.width && `${containerStyle?.width}`,
            }),
          },
          Input: {
            style: ({ $theme }) => ({
              backgroundColor: containerStyle?.backgroundColor
                ? `${containerStyle?.backgroundColor}`
                : `${$theme.colors.white}`,
              boxShadow: "none",
              border: containerStyle?.border
                ? `${containerStyle?.border}`
                : `1px solid ${$theme.colors.contentInverseSecondary} !important`,
              paddingLeft: "12px",
              marginRight:
                containerStyle?.marginRight && `${containerStyle?.marginRight}`,
              marginBottom:
                containerStyle?.marginBottom &&
                `${containerStyle?.marginBottom}`,
              margin: containerStyle?.margin && `${containerStyle?.margin}`,
            }),
          },
        }}
      />
      {helperText && <span css={styles.helper}>{helperText}</span>}
    </div>
  );
};

const styles = {
  label: css({
    paddingBottom: "10px",
  }),
  wrapper: css({
    display: "flex",
    flexDirection: "column",
  }),
  helper: css({
    fontSize: ".8rem",
    color: theme.colors.mono600,
  }),
};
export default InputField;
