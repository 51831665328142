import {
  addIssue,
  commitAttachments,
  commitIssueTransaction,
  deleteAttachment,
  removeIssue,
  setIssue,
  setIssues,
  setPaginationMeta,
} from "./issueSlice";
import {
  Issue,
  IssueType,
  TransactionPayload,
  UpdateIssueParams,
} from "./types";
import l from "@locale";
import { v4 as uuidv4 } from "uuid";
import { AppThunk } from "..";
import api from "../../services/api";
import { showNotification } from "../ui/uiThunk";
import { batch } from "react-redux";
import { createAttachments } from "@services/helpers";
import { Attachment } from "../../types/common";
import {
  AttachmentsTransaction,
  AttachmentsTransactionTypes,
  IssueTransaction,
  IssueTransactionTypes,
} from "../../types/transaction";
import { transactionErrorMessage, transactionSuccessMessage } from "@helpers";
import { startLoading } from "../ui/uiSlice";
import { createKeyValueObject, exportToCsv } from "../helpers";
import { ExportParams, PaginationParams } from "@services/types";
import i18next from "@locale";

export const getIssuesByPage =
  ({
    page,
    state,
    filter,
    type = IssueType.TECHNICAL,
  }: PaginationParams): AppThunk =>
  async (dispatch) => {
    try {
      const response = await api.Issues.getPage({
        page,
        state,
        filter,
        type,
      });
      const issues = createKeyValueObject(response.data);

      dispatch(setIssues(issues));
      dispatch(setPaginationMeta(response.meta));
    } catch (error) {
      dispatch(
        showNotification({
          type: "error",
          message: l.t("actions.refresh.failed", {
            value: l.t("common.issues").toLowerCase(),
          }),
          noOverride: true,
        }),
      );
    }
  };
export const exportIssuesByPage =
  ({ state, filter, type = IssueType.TECHNICAL }: ExportParams): AppThunk =>
  async (dispatch) => {
    try {
      const response = await api.Issues.getCsv({
        state,
        filter,
        type,
      });
      exportToCsv(response, i18next.t("common.issues"));
    } catch (error) {
      dispatch(
        showNotification({
          type: "error",
          message: l.t("actions.export.failed", {
            value: l.t("common.issues").toLowerCase(),
          }),
          noOverride: true,
        }),
      );
    }
  };

export const fetchIssue =
  (id: Issue["id"]): AppThunk =>
  async (dispatch) => {
    try {
      const response = await api.Issues.getById(id);
      dispatch(setIssue(response));
    } catch (error) {
      dispatch(
        showNotification({
          type: "error",
          message: l.t("actions.refresh.failed", {
            value: l.t("common.issues").toLowerCase(),
          }),
        }),
      );
    }
  };

export const addOrDeleteAttachments =
  (issue: Issue, attachments: Partial<Attachment>[] = []): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(AttachmentsTransactionTypes.ADD_ATTACHMENT));

      const existingAttachments = issue?.attachments ?? [];

      const newAttachments =
        existingAttachments?.length == 0
          ? attachments
          : attachments.filter(
              (attach) => attach.id === undefined && attach.url,
            );
      if (newAttachments.length > 0) {
        const newUploadedAttachments = await createAttachments(newAttachments);
        dispatch(
          addIssueAttachments({
            issueId: issue.id,
            attachments: newUploadedAttachments,
          }),
        );
      }

      const removedAttachments: Attachment[] = [];

      existingAttachments.forEach((attach) => {
        if (!attachments.includes(attach)) removedAttachments.push(attach);
      });

      batch(() => {
        removedAttachments.forEach(async (attachment) => {
          await api.Attachment.delete(attachment.id);
          dispatch(deleteAttachment({ attachment, issueId: issue.id }));
        });
      });
    } catch (error) {
      batch(() => {
        dispatch(
          showNotification(
            {
              type: "error",
              message: transactionErrorMessage(
                AttachmentsTransactionTypes.ADD_ATTACHMENT,
              ),
            },
            AttachmentsTransactionTypes.ADD_ATTACHMENT,
          ),
        );
      });
    }
  };

export const updateIssue =
  (
    oldIssue: Issue,
    updatedParams?: UpdateIssueParams,
    updatedAttachments: Partial<Attachment>[] = [],
  ): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.issue");
    try {
      dispatch(startLoading("update:issue"));
      if (updatedParams) {
        await api.Issues.update(oldIssue.id, updatedParams);
        dispatch(setIssue({ ...oldIssue, ...updatedParams }));
      }

      batch(() => {
        dispatch(addOrDeleteAttachments(oldIssue, updatedAttachments));
        dispatch(
          showNotification(
            {
              type: "success",
              message: l.t("actions.update.success", { value }),
            },
            "update:issue",
          ),
        );
      });
    } catch (error) {
      batch(() => {
        dispatch(
          showNotification(
            {
              type: "error",
              message: l.t("actions.update.failed", { value }),
            },
            "update:issue",
          ),
        );
      });
    }
  };

export const createIssue =
  (
    issue: Omit<Issue, "id">,
    attachments: Partial<Attachment>[] = [],
  ): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.issue");

    const issueId = uuidv4();
    const newIssue = { ...issue, id: issueId };

    try {
      dispatch(startLoading(IssueTransactionTypes.CREATE));
      await api.Transactions.create([
        {
          issueId: newIssue.id,
          name: IssueTransactionTypes.CREATE,
          spaceId: issue.spaceId,
          createdAt: new Date().toISOString(),

          meta: {
            priority: newIssue.priority,
            label: newIssue.label,
            reportedBy: issue.reportedBy,
            description: newIssue.description,
            type: newIssue.typeOf,
          },
        } as IssueTransaction,
      ]);

      batch(() => {
        dispatch(addIssue(newIssue));
        attachments?.length > 0 &&
          dispatch(addOrDeleteAttachments(newIssue, attachments));

        dispatch(
          showNotification(
            {
              type: "success",
              message: l.t("actions.add.success", { value }),
            },
            IssueTransactionTypes.CREATE,
          ),
        );
      });
    } catch (error) {
      batch(() => {
        dispatch(
          showNotification(
            {
              type: "error",
              message: l.t("actions.add.failed", { value }),
            },
            IssueTransactionTypes.CREATE,
          ),
        );
      });
    }
  };

export const handleIssueTransaction =
  (actions: TransactionPayload[]): AppThunk =>
  async (dispatch) => {
    const transactionName = actions[0].transaction.name;

    try {
      dispatch(startLoading(transactionName));
      await api.Transactions.create(
        actions.map((action) => action.transaction),
      );
      batch(() => {
        dispatch(commitIssueTransaction(actions));
        dispatch(
          showNotification(
            {
              type: "success",
              message: transactionSuccessMessage(transactionName),
            },
            transactionName,
          ),
        );
      });
    } catch (error) {
      dispatch(
        showNotification(
          {
            type: "error",
            message: transactionErrorMessage(transactionName),
          },
          transactionName,
        ),
      );
    }
  };

export const addIssueAttachments =
  ({
    issueId,
    attachments,
  }: {
    issueId: Issue["id"];
    attachments: Attachment[];
  }): AppThunk =>
  async (dispatch) => {
    const transaction = {
      issueId,
      name: AttachmentsTransactionTypes.ADD_ATTACHMENT,
      meta: {
        attachmentIds: attachments.map((a) => a.id),
      },
    } as AttachmentsTransaction;

    try {
      dispatch(startLoading(transaction.name));
      await api.Transactions.create([transaction]);
      dispatch(commitAttachments({ issueId, attachments }));
    } catch (error) {
      batch(() => {
        dispatch(
          showNotification(
            {
              type: "error",
              message: transactionErrorMessage(transaction.name),
            },
            transaction.name,
          ),
        );
      });
    }
  };

export const deleteIssue =
  (issueId: Issue["id"]): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.issue");

    try {
      dispatch(startLoading("delete:issue"));

      await api.Issues.delete(issueId);
      batch(() => {
        dispatch(removeIssue(issueId));
        dispatch(
          showNotification(
            {
              type: "success",
              message: l.t("actions.delete.success", { value }),
            },
            "delete:issue",
          ),
        );
      });
    } catch (error) {
      batch(() => {
        dispatch(
          showNotification(
            {
              type: "error",
              message: l.t("actions.delete.failed", { value }),
            },
            "delete:issue",
          ),
        );
      });
    }
  };
