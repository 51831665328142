import { FC } from "react";
import {
  MdCleaningServices,
  MdEditCalendar,
  MdOutlineListAlt,
} from "react-icons/md";
import {
  AiFillQuestionCircle,
  AiFillSetting,
  AiFillHome,
} from "react-icons/ai";
import { BiQrScan } from "react-icons/bi";
import { RiHotelBedFill } from "react-icons/ri";
import { GoTools } from "react-icons/go";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { NavLabels, SubNavLabels } from ".";

interface getIconProps {
  label: string;
  active: boolean;
}

const getIcon: FC<getIconProps> = ({ label, active }) => {
  const icons: {
    [key: string]: JSX.Element;
  } = {
    [NavLabels.Dashboard]: <AiFillHome css={style(active).icon} />,
    [NavLabels.Overview]: <RiHotelBedFill css={style(active).icon} />,
    [NavLabels.AppLogin]: <BiQrScan css={style(active).icon} />,
    [NavLabels.DayReports]: <MdEditCalendar css={style(active).icon} />,
    [NavLabels.TdOverview]: <GoTools css={style(active).icon} />,
    [NavLabels.GsOverview]: <MdCleaningServices css={style(active).icon} />,
    [SubNavLabels.Settings]: <AiFillSetting css={style(active).icon} />,
    [NavLabels.ActionsLog]: <MdOutlineListAlt css={style(active).icon} />,
  };
  return (
    <>{icons[label] ?? <AiFillQuestionCircle css={style(active).icon} />}</>
  );
};
const style = (active: boolean) => ({
  icon: css({
    minWidth: "25px",
    height: "25px",
    color: active ? theme.colors.accent : theme.colors.white,
    marginRight: "16px",
  }),
});

export default getIcon;
