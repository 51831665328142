import { FC, ReactNode } from "react";
import useAppSelector from "@hooks/useAppSelector";
import { selectMembers } from "@store/user/userSelectors";
import useAppDispatch from "@hooks/useAppDispatch";
import { Issue, IssueType } from "@store/issue/types";
import { User } from "@store/user/types";
import { IssueTransactionTypes } from "@store/transaction/types";
import { handleIssueTransaction } from "@store/issue/issueThunk";
import AssignMember from "@components/AssignMember";
interface AssignIssueMemberProps {
  children: ReactNode;
  memberId?: User["id"];
  issue: Issue;
  assigns: Record<string, number>;
  type: IssueType;
}

const AssignIssueMember: FC<AssignIssueMemberProps> = ({
  memberId,
  issue,
  assigns,
  children,
  type,
}) => {
  const dispatch = useAppDispatch();
  const members = useAppSelector((state) => selectMembers(state, type));

  const onSetMember = ({
    userId,
    assign,
  }: {
    userId: User["id"];
    assign: boolean;
  }) => {
    const transactions = [];
    transactions.push({
      transaction: {
        name: assign
          ? IssueTransactionTypes.ASSIGN
          : IssueTransactionTypes.UNASSIGN,
        userId,
        issueId: issue.id,
        createdAt: new Date().toISOString(),
      },
      prevIssue: issue,
    });
    dispatch(handleIssueTransaction(transactions));
  };

  return (
    <AssignMember
      memberId={memberId}
      assigns={assigns}
      onSetMember={onSetMember}
      members={members}>
      {children}
    </AssignMember>
  );
};

export default AssignIssueMember;
