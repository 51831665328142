import { Dictionary, EntityId } from "@reduxjs/toolkit";
import { Area, Attachment, Task } from "../../types/common";
import { User } from "../user/types";

export enum CleaningChoice {
  DEFAULT = "clean",
  GREEN = "skip_cleaning",
  ORANGE = "fresh_towels",
  NON_GREEN_SKIP = "skip_cleaning_no_green",
}

export enum SpaceType {
  ROOM = "room",
  CUSTOM = "custom",
}

export enum RoomStates {
  CLEAN_INSPECTED = "clean_inspected",
  CLEAN = "clean",
  DIRTY = "dirty",
  DIRTY_INSPECTED = "dirty_inspected",
  OUT_OF_ORDER = "out_of_order",
  OUT_OF_INVENTORY = "out_of_inventory",
}

export const RoomStatesOrder = [
  RoomStates.CLEAN_INSPECTED,
  RoomStates.CLEAN,
  RoomStates.DIRTY,
  RoomStates.DIRTY_INSPECTED,
  RoomStates.OUT_OF_ORDER,
  RoomStates.OUT_OF_INVENTORY,
];

export enum RoomSpaceFilterTypes {
  ROOM_CODE = "room_code",
  AREA_ID = "area_id",
  GREEN_CHOICE = "green_choice",
  ORANGE_CHOICE = "orange_choice",
  NON_GREEN_SKIP = "non_green_skip",
  ARRIVALS = "arrivals",
  STAYOVERS = "stayovers",
  DEPARTURES = "departures",
  WITH_EXTRAS = "with_extras",
  WITH_TASKS = "with_tasks",
  SEARCH_TEXT = "search_text",
  OCCUPIED_TODAY = "occupied_today",
  ROOM_DIRTY = "room_dirty",
  ROOM_CLEAN = "room_clean",
  RESET = "reset",
}

export type RoomSpaceFilters = {
  [RoomSpaceFilterTypes.ROOM_CODE]?: string[];
  [RoomSpaceFilterTypes.AREA_ID]?: Area["id"];
  [RoomSpaceFilterTypes.GREEN_CHOICE]?: boolean;
  [RoomSpaceFilterTypes.ORANGE_CHOICE]?: boolean;
  [RoomSpaceFilterTypes.NON_GREEN_SKIP]?: boolean;
  [RoomSpaceFilterTypes.ARRIVALS]?: boolean;
  [RoomSpaceFilterTypes.STAYOVERS]?: boolean;
  [RoomSpaceFilterTypes.DEPARTURES]?: boolean;
  [RoomSpaceFilterTypes.WITH_EXTRAS]?: boolean;
  [RoomSpaceFilterTypes.WITH_TASKS]?: boolean;
  [RoomSpaceFilterTypes.SEARCH_TEXT]?: string;
  [RoomSpaceFilterTypes.OCCUPIED_TODAY]?: string;
  [RoomSpaceFilterTypes.ROOM_DIRTY]?: string;
  [RoomSpaceFilterTypes.ROOM_CLEAN]?: string;
  [RoomSpaceFilterTypes.RESET]?: string;
};

export const RoomSpaceDropdownFilters = [
  RoomSpaceFilterTypes.GREEN_CHOICE,
  RoomSpaceFilterTypes.ORANGE_CHOICE,
  RoomSpaceFilterTypes.NON_GREEN_SKIP,
  RoomSpaceFilterTypes.ARRIVALS,
  RoomSpaceFilterTypes.DEPARTURES,
  RoomSpaceFilterTypes.STAYOVERS,
  RoomSpaceFilterTypes.WITH_EXTRAS,
  RoomSpaceFilterTypes.OCCUPIED_TODAY,
  RoomSpaceFilterTypes.ROOM_CLEAN,
  RoomSpaceFilterTypes.ROOM_DIRTY,
  RoomSpaceFilterTypes.WITH_TASKS,
];

export enum RoomSpaceSortingKeys {
  ACTION = "action",
  ROOM = "room",
  ROOM_STATUS = "roomStatus",
  ARRIVALS = "arrivals",
  CLEANING_STATUS = "cleaningStatus",
  TASKS = "tasks",
}

export type RoomSpaceTableSort = {
  key: RoomSpaceSortingKeys;
  asc: boolean;
};

export enum SpacesSortingKeys {
  NAME = "name",
  FLOOR = "floor",
}

export type CustomSpaceTableSort = {
  key: SpacesSortingKeys;
  asc: boolean;
};

export interface Spaces extends Dictionary<Space> {}

export interface SpaceService {
  code: string;
  label: string;
  quantity: number;
}

export interface Assignee {
  id: User["id"];
  active: boolean;
}

export interface AssigneeCounts {
  [id: string]: number;
}

export interface Assignees {
  [id: string]: Assignee;
}

export interface GuestInfo {
  previous?: {
    departure: Date;
  };
  current?: {
    arrival: Date;
    departure: Date;
    services: SpaceService[];
    duration: {
      day: number;
      total: number;
    };
  };
  next?: {
    arrival: Date;
    departure: Date;
    services: SpaceService[];
  };
}

export interface NewInspection {
  result: boolean;
  remark?: string;
  tags?: string[];
  attachments?: Attachment[];
  userId: User["id"];
  createdAt: string;
}

export interface Inspection {
  remark: string;
  attachments?: Attachment[];
  userId: User["id"];
  createdAt: string;
  result: boolean;
  tags?: string[];
}

export interface Room {
  clean: boolean;
  cleaningChoice: CleaningChoice;
  code: string;
  doNotDisturb: boolean;
  freshTowels: boolean;
  guest: GuestInfo;
  inspected: boolean;
  lastInspection?: Inspection;
  staying: boolean;
  arriving: boolean;
  departing: boolean;
  suite: boolean;
  tasks?: { id: Task["id"] }[];
  users: Assignee[];
  vacant: boolean;
  state: RoomStates;
}

export interface BaseSpace {
  id: EntityId;
  label: string;
  type: SpaceType;
  floor: number;
  areaId: Area["id"];
}

export interface RoomSpaceStats {
  staying: number;
  arrival: number;
  departure: number;
}

export interface CustomSpace extends Omit<BaseSpace, "areaId" | "floor"> {
  type: SpaceType.CUSTOM;
  floor?: number;
}

export type newCustomSpace = Omit<CustomSpace, "id" | "areaId" | "type">;

export interface RoomSpace extends BaseSpace {
  type: SpaceType.ROOM;
  room: Room;
}

export type Space = RoomSpace | CustomSpace;

export type UpdateSpaceParams = Partial<
  Pick<BaseSpace, "areaId" | "label" | "floor">
>;
