import theme from "@styles/theme";
import { useState, useEffect, ChangeEvent, FC } from "react";

interface ResizableTextAreaProps {
  maxRows: number;
  value: string;
  label: string;
  onChange: (target: ChangeEvent<HTMLTextAreaElement>) => void;
}

const ResizableTextArea: FC<ResizableTextAreaProps> = ({
  maxRows,
  value,
  label,
  onChange,
}) => {
  const [rows, setRows] = useState(1);

  useEffect(() => {
    if (!value) return;
    const lines = value.split("\n").length;
    if (lines >= maxRows && rows == maxRows) return;
    if (lines >= maxRows && rows < maxRows) {
      setRows(maxRows);
      return;
    }
    setRows(lines + 1);
  }, [maxRows, rows, value]);

  return (
    <>
      <p className="header">{label}</p>
      <textarea
        rows={rows}
        className="remarks"
        value={value}
        onChange={onChange}
      />
      <style jsx>
        {`
          textarea {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 20px;
            outline: none;
            border: none;
            border-bottom: solid 1px ${theme.colors.lightGray};
            margin-bottom: 20px;
            resize: none;
          }
          p.header {
            font-family: Source Sans Pro;
            font-style: normal;
            text-align: start;
            font-weight: 600;
            font-size: 14px;
            color: #000;
          }
        `}
      </style>
    </>
  );
};

export default ResizableTextArea;
