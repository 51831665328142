import React, { useRef } from "react";
import Dropzone from "./Dropzone.web";
import FileDialog from "./FileDialog";
import Preview from "./Preview";
import Resizer from "react-image-file-resizer";
import { Issue } from "@store/issue/types";
import { Attachment } from "../../../types/common";
import Button from "@components/base/button";
import {
  generateButtonColors,
  overrideButtonStyles,
} from "@components/base/button/overrides";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

interface FileDropProps {
  issue: Omit<Issue, "id">;
  setIssue: (issue: Omit<Issue, "id">) => void;
}

const FileDrop: React.FC<FileDropProps> = ({ issue, setIssue }) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const attachments = issue.attachments;

  const onDrag = (e: React.DragEvent<HTMLImageElement>) => {
    e.preventDefault();
  };

  const onDrop = (e: React.DragEvent<HTMLImageElement>) => {
    e.preventDefault();
    onAddImages(e.dataTransfer.files);
  };

  const onClick = () => {
    inputRef.current?.click();
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onAddImages(event.target.files);
  };

  const resizeFile = async (file: File) =>
    new Promise<string>((resolve) => {
      Resizer.imageFileResizer(
        file,
        700,
        600,
        "JPEG",
        50,
        0,
        (newFile) => {
          // TODO Correct typing
          resolve(URL.createObjectURL(newFile as Blob));
        },
        "file",
      );
    });

  const onAddImages = async (fileList: FileList | null) => {
    if (fileList === null) return;

    const urls = await Promise.all(
      Array.from(fileList).map(async (file) => ({
        url: await resizeFile(file),
      })),
    );
    if (!urls?.length) return;

    setIssue({
      ...issue,
      attachments: [...attachments, ...urls],
    });
  };

  const onRemoveImage = (attachment: Partial<Attachment>) => {
    const filtered = attachments!.filter((x) => x.url !== attachment.url);

    setIssue({
      ...issue,
      attachments: filtered,
    });
  };

  return (
    <>
      <FileDialog ref={inputRef} onInputChange={onInputChange} />
      {!attachments?.length ? (
        <Dropzone onClick={onClick} onDrag={onDrag} onDrop={onDrop} />
      ) : (
        <div>
          <Button
            onClick={onClick}
            type="button"
            kind="secondary"
            overrides={overrideButtonStyles({
              BaseButton: ({ $theme, $disabled }) => ({
                marginBottom: "32px",
                width: "100%",
                ...generateButtonColors({
                  $disabled,
                  color: $theme.colors.info,
                }),
              }),
            })}>
            {t("issue.addImage")}
          </Button>
          <div
            css={css({
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            })}>
            {attachments.map((attachment, index) => {
              return (
                <Preview
                  key={index}
                  attachment={attachment}
                  onRemoveImage={onRemoveImage}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default FileDrop;
