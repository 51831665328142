import {
  removeUser,
  resetStore,
  setCurrentUser,
  setSelectedPropertyId,
  setSelectedRole,
  setSelectedUser,
  setUser,
  setUsers,
} from "./userSlice";
import { AppThunk } from "..";
import helpers, { Auth } from "@services/helpers";
import { batch } from "react-redux";
import { CreateUserParams, UpdateUserParams, User } from "./types";
import api from "@services/api";
import { showNotification } from "../ui/uiThunk";
import l from "@locale";
import { Property } from "../property/types";
import { setRequiredDataState } from "../ui/uiSlice";
import { setProperties } from "../property/propertySlice";
import { Honeybadger } from "@honeybadger-io/react";

export const signInUser =
  ({
    email,
    password,
    userId,
  }: {
    email?: string;
    password?: string;
    userId?: User["id"];
  }): AppThunk =>
  async (dispatch) => {
    let signedIn = false;
    if (email !== undefined && password !== undefined) {
      signedIn = await api.Auth.signInWithEmail(email, password);
    } else if (userId !== undefined) {
      signedIn = await api.Auth.signInWithQr(userId);
    }
    if (!signedIn) {
      throw Error("invalid_auth");
    }
    const auth = helpers.Auth.get();
    if (!auth) return;

    dispatch(fetchRequiredData());
  };

export const fetchRequiredData = (): AppThunk => async (dispatch) => {
  try {
    const auth = helpers.Auth.get();
    if (!auth) return;
    dispatch(setRequiredDataState("loading"));
    const user = await api.Users.getById(auth.currentUserId);
    Honeybadger.setContext({ user_id: String(auth.currentUserId) });

    await batch(async () => {
      dispatch(setSelectedPropertyId(auth.property));
      dispatch(setSelectedRole(auth.role));
      dispatch(setCurrentUser(user));
      dispatch(setUser(user));
    });

    await dispatch(setProperties((await api.Property.getAll()).data));

    dispatch(setRequiredDataState("loaded"));
  } catch (e) {
    console.log("error", e);
    dispatch(setRequiredDataState("error"));
  }
};

export const signOut =
  (showError = false): AppThunk =>
  async (dispatch) => {
    try {
      Auth.reset();
      if (!showError) return;
      dispatch(
        showNotification({
          type: "error",
          message: l.t("user.sessionExpired"),
        }),
      );
      Honeybadger.clear();
    } catch (error) {
      /* empty */
    } finally {
      dispatch(resetStore());
      dispatch(setRequiredDataState("void"));
    }
  };

export const fetchUsers = (): AppThunk => async (dispatch) => {
  try {
    const response = await api.Users.getAll();
    const users = response.data.reduce(
      (allUsers, user) => {
        allUsers[user.id] = user;
        return allUsers;
      },
      {} as Record<User["id"], User>,
    );
    dispatch(setUsers(users));
  } catch (error) {
    /* empty */
  }
};

export const switchProperty =
  (propertyId: Property["id"]): AppThunk =>
  async (dispatch) => {
    let value = l.t("common.property");

    try {
      await api.Property.switch(propertyId);
      const auth = helpers.Auth.get();
      if (auth === null) throw new Error("error");

      batch(async () => {
        dispatch(setSelectedPropertyId(auth.property));
        dispatch(setSelectedRole(auth.role));
        dispatch(
          showNotification({
            message: l.t("actions.switch.success", { value }),
            type: "success",
          }),
        );
      });
      window.location.reload();
    } catch (error) {
      dispatch(
        showNotification({
          message: l.t("actions.switch.failed", { value }),
          type: "error",
        }),
      );
    }
  };

export const createUser =
  (user: CreateUserParams): AppThunk =>
  async (dispatch) => {
    const value = l.t("user.self");
    try {
      const response = await api.Users.create(user);
      batch(() => {
        dispatch(
          showNotification({
            message: l.t("actions.add.success", { value }),
            type: "success",
          }),
        );
        dispatch(setUser(response));
        dispatch(setSelectedUser(response.id));
      });
    } catch (error) {
      dispatch(
        showNotification({
          title: l.t("actions.add.failed", { value }),
          message: l.t("actions.add.userFailed"),
          type: "error",
        }),
      );
    }
  };

export const deleteUser =
  (userId: User["id"]): AppThunk =>
  async (dispatch) => {
    const value = l.t("user.self");

    try {
      await api.Users.delete(userId);
      batch(() => {
        dispatch(
          showNotification({
            message: l.t("actions.delete.success", { value }),
            type: "success",
          }),
        );
        dispatch(removeUser(userId));
      });
    } catch (error) {
      dispatch(
        showNotification({
          message: l.t("actions.delete.failed", { value }),
          type: "error",
        }),
      );
    }
  };

export const updateUser =
  (updatedParams: UpdateUserParams, userId: User["id"]): AppThunk =>
  async (dispatch) => {
    const value = l.t("user.self");
    try {
      const response = await api.Users.update(userId, updatedParams);
      batch(() => {
        dispatch(setUser(response));
        dispatch(
          showNotification({
            message: l.t("actions.update.success", { value }),
            type: "success",
          }),
        );
      });
    } catch (error) {
      showNotification({
        message: l.t("actions.update.failed", { value }),
        type: "error",
      });
    }
  };
