import React from "react";
import { Notification } from "baseui/notification";
import theme from "@styles/theme";
import usePmsSyncStateNotification from "@hooks/usePmsSyncStateNotification";
interface pmsSyncStateNotificationProps {}

const PmsSyncStateNotification: React.FC<
  pmsSyncStateNotificationProps
> = () => {
  const hcsNotification = usePmsSyncStateNotification();
  if (!hcsNotification) return null;

  const { type, content } = hcsNotification;

  return (
    <Notification
      kind={type}
      overrides={{
        Body: {
          style: {
            width: "auto",
            borderRadius: 0,
            margin: 0,
            ...(type === "warning" ? Object(theme.colors.black) : {}),
          },
        },
      }}>
      {content}
    </Notification>
  );
};

export default PmsSyncStateNotification;
