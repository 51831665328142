import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import Assignee from "@components/Assignee";
import { debounce } from "lodash";
import useAppSelector from "@hooks/useAppSelector";
import {
  selectAllTransactions,
  selectTransactionMeta,
} from "@store/transaction/transactionSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import { selectSpaceEntities } from "@store/space/spaceSelectors";
import { fetchTransactionsByPage } from "@store/transaction/transactionThunk";
import {
  AttachmentsTransactionTypes,
  Transactions,
} from "@store/transaction/types";
import Table from "@components/base/table";
import SearchInput from "@components/base/search-input";
import Pagination from "@components/base/pagination";
import Breadcrumb from "@components/base/breadcrumb";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";
import { NavLabels } from "@components/Layout";
import { fetchSpaces } from "@store/space/spaceThunk";
import { fetchUsers } from "@store/user/userThunk";

const ActionsLogScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const actions = useAppSelector(({ transaction }) => {
    return selectAllTransactions(transaction);
  });
  const language = localStorage.getItem("language") ?? "nl";
  const pagination = useAppSelector(selectTransactionMeta);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const spaces = useAppSelector(({ space }) => selectSpaceEntities(space));
  const [filteredActions, setFilteredActions] = useState<Transactions>([]);
  const dateFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const delayedQuery = useRef(
    debounce((page: number, query: string) => getActions(page, query), 750),
  ).current;

  const getActions = async (page: number, filter = search) => {
    setLoading(true);
    try {
      dispatch(fetchTransactionsByPage(page, filter));
    } catch (error) {
      /* empty */
    }
    setLoading(false);
  };

  const handleSearchChange = (text: string) => {
    const trimText = text.trim();
    setSearch(trimText);
    delayedQuery(1, trimText);
  };

  useEffect(() => {
    getActions(1);
    dispatch(fetchSpaces());
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    setFilteredActions(
      actions.sort((a1, a2) =>
        (a2.createdAt ?? "").localeCompare(a1.createdAt ?? ""),
      ),
    );
  }, [actions]);

  return (
    <>
      <Breadcrumb labels={[NavLabels.ActionsLog]} />
      <section css={styles.section}>
        <div css={styles.actionBar}>
          <div>
            {pagination.pages > 1 && (
              <Pagination
                totalPages={pagination.pages}
                onPaginationChange={getActions}
              />
            )}
          </div>
          <SearchInput
            onChange={handleSearchChange}
            value={search}
            placeholder={t("search.rooms")}
            containerStyle={{ width: "220px" }}
          />
        </div>
        <Table
          columns={[
            t("common.room"),
            t("user.self"),
            t("common.action"),
            t("common.date"),
          ]}
          data={filteredActions.map((action) => {
            if (action.name === AttachmentsTransactionTypes.ADD_ATTACHMENT)
              return [];
            const { spaceId, authorId, name, createdAt } = action;
            const label = spaceId && spaces[spaceId]?.label;
            return [
              label ?? t("common.notApplicable"),
              <Assignee key={authorId} memberId={authorId} hover={true} />,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore - name is a multi layer nested translation object
              t(`actionLog.actions.${name.replaceAll(":", ".")}`),
              new Date(createdAt).toLocaleDateString(
                language,
                dateFormatOptions as Intl.DateTimeFormatOptions,
              ),
            ];
          })}
          emptyMessage={t("search.noResults", {
            object: t("common.room").toLowerCase(),
          })}
          isLoading={loading}
          loadingMessage={t("common.loading")}
        />
      </section>
    </>
  );
};

const styles = {
  section: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: theme.sizing.scale1600,
    overflow: "scroll",
  }),
  actionBar: css({
    display: "flex",
    marginBottom: theme.sizing.scale800,
    alignItems: "flex-end",
    justifyContent: "space-between",
  }),
};

export default ActionsLogScreen;
