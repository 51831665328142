import { FC } from "react";
import CustomRooms from "@screens/CustomRooms";
import Layout from "@components/Layout";
import withAuth from "@helpers/withAuth";

const CustomRoomsPage: FC = () => {
  return (
    <Layout active="settings/custom-rooms">
      <CustomRooms />
    </Layout>
  );
};
export default withAuth(CustomRoomsPage);
