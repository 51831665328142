import React from "react";
import withAuth from "@helpers/withAuth";
import Layout from "@components/Layout";
import Team from "@screens/Team";

const TeamPage: React.FC = () => {
  return (
    <Layout active="settings/team">
      <Team />
    </Layout>
  );
};

export default withAuth(TeamPage);
