import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Property } from "./types";

export const propertyAdapter = createEntityAdapter<Property>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const propertySlice = createSlice({
  name: "property",
  initialState: propertyAdapter.getInitialState(),
  reducers: {
    setProperties: propertyAdapter.setAll,
    setProperty: propertyAdapter.upsertOne,
  },
});

export const { setProperties, setProperty } = propertySlice.actions;

export default propertySlice;
