export const createKeyValueObject = <T extends { id: string | number }>(
  arr: T[],
): { [key: string | number]: T } =>
  arr.reduce(
    (spaces, space) => {
      spaces[space.id] = space;
      return spaces;
    },
    {} as { [key: string | number]: T },
  );

export const exportToCsv = (data: BlobPart, filename: string) => {
  const url = window.URL.createObjectURL(
    new Blob([data], {
      type: "text/csv",
    }),
  );
  const link = document.createElement("a");
  link.href = url;
  link.download = `${filename}.csv`;
  document.body.appendChild(link);
  link.click();
};
