import React from "react";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { fetchRequiredData } from "@store/user/userThunk";
import Button from "@components/base/button";
import { overrideButtonStyles } from "@components/base/button/overrides";
import useAppDispatch from "@hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FetchingRequiredDataErrorScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const retry = () => {
    dispatch(fetchRequiredData());
  };

  const logout = () => {
    localStorage.removeItem("tokens");
    navigate("/login");
  };

  return (
    <div css={styles.wrapper}>
      <p css={styles.text}>{t("actions.fetch.failed")}</p>
      <Button
        onClick={retry}
        size="large"
        overrides={overrideButtonStyles({
          BaseButton: { width: "240px" },
        })}>
        {t("common.tryAgain")}
      </Button>
      <Button
        onClick={logout}
        size="large"
        overrides={overrideButtonStyles({
          BaseButton: { width: "240px", marginTop: "16px" },
        })}>
        {t("common.logout")}
      </Button>
    </div>
  );
};

const styles = {
  wrapper: css({
    height: "100vh",
    backgroundColor: theme.colors.backgroundPrimary,
    padding: theme.sizing.scale1800, // 48px
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  }),
  text: css({
    lineHeight: "1.3",
    marginBottom: "8px",
  }),
};

export default FetchingRequiredDataErrorScreen;
