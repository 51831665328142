import withAuth from "../helpers/withAuth";
import Layout from "../components/Layout";
import IssueOverview from "../screens/IssueOverview";
import { IssueType } from "@store/issue/types";
import { FC } from "react";

const GSOverviewContainer: FC = () => {
  return (
    <Layout active="gs-overview">
      <IssueOverview type={IssueType.GENERAL} />
    </Layout>
  );
};

export default withAuth(GSOverviewContainer);
