import React from "react";
import ActionsLogScreen from "@screens/ActionsLog";
import Layout from "@components/Layout";
import withAuth from "@helpers/withAuth";

const ActionsLogPage: React.FC = () => {
  return (
    <Layout active="actions-log">
      <ActionsLogScreen />
    </Layout>
  );
};

export default withAuth(ActionsLogPage);
