import React, { useState } from "react";
import PopUpWrapper from "@components/PopUpWrapper";
import { Suggestion } from "@components/SuggestionsInput";
import ActiveTaskRooms from "./ActiveTaskRooms";
import SelectTask from "./SelectTask";
import theme from "@styles/theme";
import { SpaceTransactionTypes } from "@store/transaction/types";
import { selectRoomToggles } from "@store/ui/uiSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { selectRoomSpacesByIds } from "@store/space/spaceSelectors";
import { selectAllTaskIds } from "@store/task/taskSelectors";
import { handleSpaceTransaction } from "@store/space/spaceThunk";
import { hidePopUp, resetRoomToggles, setRoomToggle } from "@store/ui/uiSlice";
import { Task } from "../../../types/common";
import { RoomSpace } from "@store/space/types";
import useAppDispatch from "@hooks/useAppDispatch";
import { useTranslation } from "react-i18next";

interface AssignTaskFormProps {}

export const assignTask = (
  taskId: Task["id"],
  spaceId: string,
  assign: boolean,
) => {
  return {
    name: assign
      ? SpaceTransactionTypes.ASSIGN_SPACE
      : SpaceTransactionTypes.UNASSIGN_SPACE,
    spaceId,
    taskId,
    createdAt: new Date().toISOString(),
  };
};

const AssignTaskForm: React.FC<AssignTaskFormProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const roomToggles = useAppSelector(selectRoomToggles);
  const selectedRoomIds = Object.keys(roomToggles);
  const taskEntities = useAppSelector(({ task }) => task.entities);
  const selectedSpaces = useAppSelector((state) =>
    selectRoomSpacesByIds(state, selectedRoomIds),
  );
  const taskIds = useAppSelector(({ task }) => selectAllTaskIds(task));

  const assignTasks = (tasks: Task[]) => {
    const spaceTransaction = selectedRoomIds.flatMap((spaceId) =>
      tasks.map((task) => assignTask(task.id, spaceId, true)),
    );
    dispatch(handleSpaceTransaction(spaceTransaction));
    dispatch(resetRoomToggles());
  };

  const allSuggestions = taskIds.reduce((suggestions: Suggestion[], taskId) => {
    const task = taskEntities[taskId];
    if (task === undefined) return suggestions;

    suggestions.push({
      label: task.label,
      key: task.id as string,
    });

    return suggestions;
  }, []);

  const [selectedTasks, setSelectedTasks] = useState<{ [key: string]: Task }>(
    {},
  );
  const [suggestions, setSuggestions] = useState<Suggestion[]>(allSuggestions);
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [isValid, setIsValid] = useState(false);

  const removeSpace = (id: RoomSpace["id"]) => {
    dispatch(setRoomToggle(id));
  };

  const editTasks = (id: Task["id"]) => {
    let newSelectedTasks = selectedTasks;

    if (newSelectedTasks && newSelectedTasks[id]) {
      delete newSelectedTasks[id];
    } else {
      const task = taskEntities[id];
      if (!newSelectedTasks) newSelectedTasks = {};
      if (task !== undefined) newSelectedTasks[id] = task;
    }
    const generatedSuggestions = allSuggestions.filter(
      (suggestion) => !selectedTasks[suggestion.key],
    );

    setSuggestions(generatedSuggestions);
    setSelectedTasks(newSelectedTasks);
  };

  const updateTask = (task: Task) => {
    const newSelectedTasks = selectedTasks;
    newSelectedTasks[task.id] = task;
    setSelectedTasks(newSelectedTasks);
    setSelectedTask(newSelectedTasks[task.id]);
  };

  const setTaskById = (id: Task["id"] | null) => {
    if (id === null) {
      setSelectedTask(undefined);
      return;
    }
    if (selectedTasks[id]) {
      setSelectedTask(selectedTasks[id]);
      return;
    }
    setSelectedTask(taskEntities[id]);
  };

  const onAssign = () => {
    assignTasks(Object.values(selectedTasks));
    dispatch(hidePopUp());
  };

  return (
    <PopUpWrapper>
      <div className="assignTaskContainer">
        <h3>{t("tasks.assignTask")}</h3>
        <SelectTask
          tasks={selectedTasks}
          suggestions={suggestions}
          editTasks={editTasks}
          updateTask={updateTask}
          selectedTask={selectedTask}
          setSelectedTask={setTaskById}
          setIsValid={setIsValid}
        />

        <ActiveTaskRooms
          spaces={selectedSpaces}
          showRooms={false}
          removeSpace={removeSpace}
        />

        <button
          className={isValid ? "" : "inactive"}
          type="submit"
          disabled={!isValid}
          onClick={onAssign}>
          {t("tasks.assign")}
        </button>
      </div>
      <style jsx>
        {`
          .assignTaskContainer {
            display: flex;
            flex-direction: column;
            width: 310px;
          }
          label {
            font-weight: 600;
            font-family: Source Sans Pro;
            font-size: 13px;
            display: block;
          }

          input,
          textarea {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 20px;
            width: 229px;
            outline: none;
            border: none;
            border-bottom: solid 1px ${theme.colors.lightGray};
            resize: none;
          }
          .details {
            width: 100%;
            margin: 0px;
          }
          .roomsContainer {
            overflow-y: scroll;
          }

          button {
            align-self: flex-end;
            color: #ffffff;
            background: #ff8100;
            font-weight: bold;
            margin-top: 20px;
            font-size: 16px;
            line-height: 20px;
            border-radius: 4px;
            padding: 11px 22px;
            border: none;
            outline: none;
            cursor: pointer;
          }

          .inactive {
            cursor: not-allowed;
            opacity: 0.2;
          }
          button.addTask {
            margin-top: 40px;
          }
        `}
      </style>
    </PopUpWrapper>
  );
};

export default AssignTaskForm;
