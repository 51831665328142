import { createSelector, Selector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { areaAdapter } from "./areaSlice";
import { Area } from "../../types/common";

const selectSelf = (state: RootState) => state.area;

export const selectCurrentArea: Selector<RootState, Area | undefined> =
  createSelector(selectSelf, ({ entities, selectedId }) => {
    if (selectedId) return entities[selectedId];
    return undefined;
  });

export const { selectEntities: selectAreaEntities, selectAll: selectAllAreas } =
  areaAdapter.getSelectors();
