import { StatefulPopover } from "baseui/popover";
import { ParagraphSmall } from "baseui/typography";
import React from "react";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { DayReport } from "@store/dayReport/types";
import { useTranslation } from "react-i18next";

interface AuditsProps {
  report: DayReport;
}

const Audits: React.FC<AuditsProps> = ({ report }) => {
  const { t } = useTranslation();
  const { audits } = report;
  const auditsWithRemarks = audits.filter((audit) => audit.remark);

  if (!auditsWithRemarks.length) return null;
  return (
    <div css={styles.container}>
      <StatefulPopover
        showArrow
        placement="bottomRight"
        triggerType="hover"
        content={
          <div css={styles.commentsContainer}>
            <div>
              <ParagraphSmall css={{ marginBottom: theme.sizing.scale400 }}>
                <strong>{t("common.remark")}</strong>
              </ParagraphSmall>
              <ul>
                {auditsWithRemarks.map((audit, index) => {
                  return (
                    <li css={styles.remarks} key={index}>
                      <ParagraphSmall>{audit.remark}</ParagraphSmall>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        }
        accessibilityType="menu"
        overrides={{
          Arrow: { style: { backgroundColor: "white" } },
        }}>
        <ParagraphSmall
          color="white"
          css={{
            padding: `${theme.sizing.scale100} ${theme.sizing.scale600}`,
            backgroundColor: theme.colors.primaryA,
            cursor: "pointer",
            borderRadius: theme.borders.radius400,
            textAlign: "center",
          }}>
          {auditsWithRemarks.length}
        </ParagraphSmall>
      </StatefulPopover>
    </div>
  );
};

const styles = {
  container: css({
    display: "flex",
    flex: 0,
  }),
  commentsContainer: css({
    padding: theme.sizing.scale800,
    backgroundColor: theme.colors.white,
    borderRadius: theme.borders.radius200,
  }),
  remarks: css({
    padding: theme.sizing.scale400,
    "&:nth-child(odd)": {
      backgroundColor: theme.colors.mono400,
    },
  }),
};

export default Audits;
