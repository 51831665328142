import { format } from "date-fns";
import Tooltip from "@components/Tooltip";
import { UserRoles } from "@store/user/types";
import { Audit } from "@store/dayReport/types";
import { FC } from "react";
import useAppSelector from "@hooks/useAppSelector";
import { selectUserById } from "@store/user/userSelectors";
import { getLanguage, getMostImportantRole } from "@helpers";
import { BiSolidTrashAlt } from "react-icons/bi";

type RoleNames = Partial<Record<UserRoles, string>>;
const roleNames: RoleNames = {
  [UserRoles.ADMIN]: "Admin",
  [UserRoles.HOTEL_MANAGER]: "Front office",
  [UserRoles.HK_MANAGER]: "Housekeeping supervisor",
};

interface AuditRowProps {
  audit: Audit;
  isFromCurrentUser?: boolean;
  removeRemark: (id: string) => void;
}

const AuditRow: FC<AuditRowProps> = ({
  audit: { id, createdAt, remark, userId },
  isFromCurrentUser,
  removeRemark,
}) => {
  const member = useAppSelector(({ user }) => selectUserById(user, userId));
  if (!member) return null;

  const roleType = getMostImportantRole(member.roles);
  const role = roleType && roleNames[roleType];
  const name = member?.profile?.name;

  const remove = () => {
    removeRemark(id);
  };

  return (
    <div className="remarkContainer">
      <div className="column initials">
        {role}
        <div className="tooltipWrapper">
          {name && (
            <Tooltip>
              <p className="name">{name}</p>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="column">
        <p className="capitalize">
          {format(new Date(createdAt), "EEEE d-M-yy, HH:mm", {
            locale: getLanguage(),
          })}
        </p>
      </div>
      <div className="row comment-row">
        <div className="column">
          <p className="comment">{remark}</p>
        </div>
        {isFromCurrentUser && (
          <div className="column">
            <BiSolidTrashAlt onClick={remove} style={{ cursor: "pointer" }} />
          </div>
        )}
      </div>
      <style jsx>{`
        .container {
          display: flex;
          flex: 1;
          background-color: white;
          border-top: 1px solid #e6e6e6;
          border-right: 1px solid #e6e6e6;
          margin-bottom: 8px;
          flex-direction: column;
        }
        .initials {
          cursor: pointer;
          text-align: center;
          vertical-align: center;
          min-width: 36px;
        }
        .initials:hover > div.tooltipWrapper {
          display: flex !important;
        }

        .tooltipWrapper {
          display: none;
          top: 10px;
          position: relative;
        }
        .name {
          width: 120px;
          padding: 20px;
        }

        .remarkContainer,
        .row {
          display: flex;
          flex: 1;
        }

        .comment-row {
          justify-content: space-between;
        }

        .remarkContainer {
          width: 100%;
          align-self: center;
          border-bottom: 1px solid #e6e6e6;
        }

        .mutatedSpaces {
          font-weight: 600;
          margin-bottom: 4px;
        }
        .column {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 16px;
          border-left: 1px solid #e6e6e6;
          min-width: 200px;
        }

        .capitalize {
          text-transform: capitalize;
          min-width: 200px;
          weight: 600;
        }
        .comment {
          word-wrap: break-word;
          max-width: 400px;
        }
        .delete {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          background-color: red;
          align-self: flex-end;
        }
        .empty {
          padding: 16px;
          width: 100%;
          vertical-align: center;
          text-align: center;
          border-left: 1px solid #e6e6e6;
        }
      `}</style>
    </div>
  );
};

export default AuditRow;
