import { differenceInCalendarDays, isToday } from "date-fns";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import Tooltip from "@components/Tooltip";
import theme from "@styles/theme";
import {
  CleaningChoice,
  Room,
  RoomSpace,
  SpaceService,
} from "@store/space/types";
import { makeSelectRoomSpaceById } from "@store/space/spaceSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { useTranslation } from "react-i18next";

interface RoomStatusProps {
  spaceId: RoomSpace["id"];
  showArrivals?: boolean;
}

const RoomStatus: React.FC<RoomStatusProps> = ({ spaceId, showArrivals }) => {
  const { t } = useTranslation();
  const selectSpaceById = useMemo(makeSelectRoomSpaceById, [spaceId]);
  const space = useAppSelector((state) => selectSpaceById(state, spaceId));

  const {
    guest: { current, next },
  } = space.room;
  let services: SpaceService[] = [];
  if (!!current && !showArrivals) services = current.services;
  if (!!next && showArrivals) services = next.services;

  const amountOfServices = services.length;
  const firstExtra = services[0];

  return (
    <>
      <div className="status">
        <Status
          room={space.room}
          duration={current?.duration}
          showArrivals={showArrivals}
        />
        {amountOfServices === 1 && (
          <span className="extra-service extra-service-yellow">
            {firstExtra.label}
          </span>
        )}
        {amountOfServices > 1 && (
          <div className="extra-services">
            <span className="extra-service extra-service-yellow">
              {t("hk.amountExtra", { amount: amountOfServices })}
            </span>
            <div className="hover">
              <Tooltip color="#E2F5FE">
                <h4
                  style={{
                    marginLeft: "10px",
                    fontWeight: 600,
                    color: theme.colors.brandBlue,
                    minWidth: "125px",
                  }}>
                  {t("extra.self")}
                </h4>
                {services.map((s) => (
                  <span className="extra-service extra-service-blue">
                    - {s.label}
                  </span>
                ))}
              </Tooltip>
            </div>
          </div>
        )}
      </div>

      <style global jsx>{`
        .status {
          display: flex;
          flex-wrap: wrap;
        }
        .extra-service {
          display: inline-block;
          font-weight: normal;
          background-color: ${theme.colors.gray};
          box-sizing: border-box;
          white-space: normal;
          border-radius: 4px;
          font-size: 14px;
          padding: 4px 8px;
          margin-left: 4px;
        }
        .extra-service-yellow {
          color: ${theme.colors.brandGoldDark};
          background-color: ${theme.colors.yellow};
        }
        .extra-service-blue {
          color: ${theme.colors.brandBlue};
          background-color: ${theme.colors.primary300};
          padding-left: ${8}px;
        }
        .hover {
          position: relative;
          display: none;
        }
        .extra-services {
          cursor: pointer;
        }
        .extra-services:hover > .hover {
          display: block;
        }

        .rs {
          display: inline-block;
          font-weight: normal;
          background-color: ${theme.colors.gray};
          box-sizing: border-box;
          white-space: normal;
          border-radius: 4px;
          font-size: 14px;
          padding: 4px 8px;
        }
        .rs:not(:first-child) {
          margin-left: 4px;
        }
        /* Tablet optimizations */
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
          .rs {
            font-size: 12px;
          }
        }
      `}</style>
    </>
  );
};

export default RoomStatus;

interface StatusProps {
  room: Room;
  duration?: { total: number; day: number };
  showArrivals?: boolean;
}

const Status: React.FC<StatusProps> = ({ room, duration, showArrivals }) => {
  const { t } = useTranslation();
  const { vacant, staying, cleaningChoice, doNotDisturb } = room;
  const departureDate = room.guest?.previous?.departure;

  if (showArrivals && !isEmpty(room.guest?.next))
    return <span className="rs">aankomst</span>;

  if (
    !showArrivals &&
    !vacant &&
    !!room.guest.current &&
    isToday(new Date(room.guest.current.arrival))
  )
    return <span className="rs">{t("hk.roomState.extra.occupiedToday")}</span>;

  if (!showArrivals && !isEmpty(room.guest?.current)) {
    return (
      <>
        {vacant && duration?.total === 0 && (
          <span className="rs">{t("hk.roomState.extra.vacantToday")}</span>
        )}
        {!vacant && !staying && (
          <span className="rs">{t("hk.roomState.extra.leaving")}</span>
        )}
        {vacant && duration !== undefined && duration?.total != 0 && (
          <span className="rs">
            {t("hk.roomState.extra.vacant", { duraction: duration.total })}
          </span>
        )}
        {staying && duration?.total !== undefined && (
          <span className="rs">
            {t("hk.roomState.extra.staying", {
              current: duration.day,
              total: duration.total,
            })}
          </span>
        )}
        {cleaningChoice === CleaningChoice.ORANGE && (
          <span className="extra-service extra-service-yellow">
            {t("hk.roomState.extra.orange")}
          </span>
        )}
        {cleaningChoice === CleaningChoice.NON_GREEN_SKIP && (
          <span className="rs">{t("hk.roomState.extra.nonGreenSkip")}</span>
        )}
        {cleaningChoice === CleaningChoice.GREEN && (
          <span className="rs">{t("hk.roomState.extra.green")}</span>
        )}
        {doNotDisturb && (
          <span className="extra-service extra-service-yellow">
            {t("hk.roomState.extra.doNotDisturb")}
          </span>
        )}
      </>
    );
  }

  if (!showArrivals && isEmpty(room.guest?.current) && !!departureDate) {
    const vacantDays = differenceInCalendarDays(
      new Date(),
      new Date(departureDate),
    );
    return (
      <span className="rs">
        {t("hk.roomState.extra.vacant", { count: vacantDays })}
      </span>
    );
  }

  return null;
};
