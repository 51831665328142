import React, { useEffect } from "react";
import FileDrop from "./FileDrop";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { EntityId } from "@reduxjs/toolkit";
import { LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import { OptionsT } from "baseui/select";
import { Issue, IssueType } from "@store/issue/types";
import { selectSpaceById } from "@store/space/spaceSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { selectUserById } from "@store/user/userSelectors";
import Select from "@components/base/select";
import Input from "@components/base/input";
import TextareaInput from "@components/base/text-area";
import Checkbox from "@components/base/checkbox";
import { overrideButtonStyles } from "@components/base/button/overrides";
import Button from "@components/base/button";
import { useTranslation } from "react-i18next";
import Table from "@components/base/table";
import { getFormattedActiveIssues } from "@store/issue/issueHelpers";

interface NewIssueScreenProps {
  sections: { title: string; data: { value: EntityId; label: string }[] }[];
  issue: Omit<Issue, "id">;
  setIssue: (issue: Omit<Issue, "id">) => void;
  onSubmit: () => void;
  canSubmit: boolean;
  isInEditMode: boolean;
  type: IssueType;
}

const NewIssueScreen: React.FC<NewIssueScreenProps> = ({
  sections,
  issue,
  setIssue,
  onSubmit,
  canSubmit,
  isInEditMode,
  type,
}) => {
  const { t } = useTranslation();
  const { spaceId, label, description, priority, reportedBy, authorId } = issue;
  const [activeIssues, setActiveIssues] = React.useState<string[][]>([]);

  const spaceLabel = useAppSelector((state) =>
    selectSpaceById(state.space, spaceId),
  );
  const user = useAppSelector(({ user }) =>
    selectUserById(user, authorId as EntityId),
  );

  const onTogglePriority = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIssue({ ...issue, priority: event.target.checked });
  };

  const options: OptionsT | undefined = sections.reduce(
    (prev, curr) => ({ ...prev, [curr.title]: curr.data }),
    {} as OptionsT,
  );

  useEffect(() => {
    if (isInEditMode || !spaceId) return;

    const fetchActiveIsues = async () => {
      const issues = await getFormattedActiveIssues(spaceId, type);
      setActiveIssues(issues);
    };

    fetchActiveIsues();
  }, [spaceId, isInEditMode]);
  return (
    <form data-test-id="new-issue-screen" css={styles.container}>
      <div css={styles.row}>
        <div
          css={[
            styles.leftContainer,
            { borderRightColor: theme.colors.contentInverseSecondary },
          ]}>
          <div data-test-id="location-section" css={styles.locationSection}>
            <label css={{ marginBottom: "2px", display: "block" }}>
              {t("common.property")}(*)
            </label>
            <Select
              props={{
                placeholder: t("search.location"),
              }}
              options={options}
              value={
                spaceLabel === undefined
                  ? undefined
                  : [{ id: spaceId, label: spaceLabel?.label }]
              }
              onChange={({ value }) => {
                if (value[0]) setIssue({ ...issue, spaceId: value[0].value });
                else setIssue({ ...issue, spaceId: "" });
              }}
              overrides={{
                ControlContainer: {
                  style: {
                    backgroundColor: theme.colors.white,
                  },
                },
                Popover: {
                  props: {
                    placement: "bottom",
                    overrides: {
                      Body: {
                        style: {
                          maxHeight: "475px",
                          zIndex: 99999,
                        },
                      },
                    },
                  },
                },
                Dropdown: {
                  style: {
                    backgroundColor: theme.colors.white,
                    height: "475px",
                  },
                },
                DropdownListItem: {
                  style: {
                    backgroundColor: theme.colors.white,
                    borderBottom: `1px solid ${theme.colors.contentInverseSecondary}`,
                    margin: "0 10px",
                    height: "35px",
                    display: "flex",
                    alignItems: "center",
                  },
                },
                StatefulMenu: {
                  props: {
                    overrides: {
                      OptgroupHeader: {
                        style: {
                          backgroundColor: theme.colors.mono200,
                          fontSize: "16px",
                          fontWeight: 600,
                          paddingLeft: "20px",
                          height: "24px",
                          display: "flex",
                          alignItems: "center",
                        },
                      },
                    },
                  },
                },
              }}
            />
          </div>
          {!isInEditMode && !!location && activeIssues.length > 0 && (
            <>
              <label>{t("issue.newIssueModal.createdIssuesTitle")}:</label>
              <Table
                overrides={{
                  Root: {
                    style: {
                      marginBottom: theme.sizing.scale800,
                      maxHeight: "175px",
                      maxWidth: "575px",
                    },
                  },
                  TableBodyCell: {
                    style: {
                      paddingTop: 0,
                      paddingBottom: 0,
                      whiteSpace: "nowrap",
                      overflow: "auto",
                    },
                  },
                  TableEmptyMessage: {
                    style: {
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                  },
                  TableHeadCell: {
                    style: {
                      backgroundColor: theme.colors.mono200,
                      fontWeight: "bold",
                      fontSize: "11px",
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                  },
                }}
                columns={[
                  t("issue.newIssueModal.columns.date"),
                  t("issue.newIssueModal.columns.task"),
                  t("issue.newIssueModal.columns.status"),
                ]}
                data={activeIssues}
              />
            </>
          )}
          <div data-test-id="new-issue-task">
            <Input
              label={`${t("common.issue")}(*)`}
              value={label}
              placeholder={t("issue.typeTaskName")}
              onChangeText={(text) => setIssue({ ...issue, label: text })}
              containerStyle={{
                marginBottom: theme.sizing.scale800,
                width: "100%",
              }}
            />
          </div>
          <Input
            label={t("issue.createdBy")}
            value={user?.profile.name}
            containerStyle={{
              marginBottom: theme.sizing.scale800,
              width: "100%",
            }}
            disabled={true}
          />
          <div data-test-id="new-issue-reporter">
            <Input
              label={`${t("issue.reportedBy")}(*)`}
              value={reportedBy}
              placeholder={t("issue.typeReporterName")}
              onChangeText={(text) => setIssue({ ...issue, reportedBy: text })}
              containerStyle={{
                marginBottom: theme.sizing.scale800,
                width: "100%",
              }}
            />
          </div>
          <div data-test-id="new-issue-remark">
            <TextareaInput
              label={`${t("common.remark")}(${t("common.optional")})`}
              value={description ?? ""}
              placeholder={t("issue.addRemark")}
              onChangeText={(description) =>
                setIssue({
                  ...issue,
                  description,
                })
              }
            />
          </div>
        </div>

        <div css={styles.rightContainer}>
          <FileDrop issue={issue} setIssue={setIssue} />
          <div>
            <Checkbox
              checked={priority}
              checkmarkType={STYLE_TYPE.toggle_round}
              labelPlacement={LABEL_PLACEMENT.right}
              onChange={onTogglePriority}
              overrides={{
                Root: {
                  style: {
                    marginBottom: "24px",
                  },
                },
              }}>
              {t("issue.priority")}
            </Checkbox>
            <Button
              onClick={onSubmit}
              disabled={!canSubmit}
              overrides={overrideButtonStyles({
                BaseButton: {
                  width: "100%",
                  alignSelf: "flex-end",
                },
              })}>
              {isInEditMode ? t("issue.change") : t("issue.create")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const styles = {
  container: css({
    width: "55vw",
    backgroundColor: theme.colors.white,
    boxSizing: "border-box",
    textAlign: "left",
    justifyContent: "space-between",
  }),
  buttonContainer: css({
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.sizing.scale800,
    "& button": {
      fontFamily: "SourceSansPro-Bold",
    },
  }),
  header: css({
    marginTop: theme.sizing.scale1200,
    marginBottom: theme.sizing.scale1600,
  }),
  row: css({
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.sizing.scale400,
  }),
  leftContainer: css({
    flex: 1,
    paddingRight: theme.sizing.scale800,
    borderRightWidth: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  }),
  rightContainer: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    marginTop: 20,
    paddingLeft: theme.sizing.scale800,
  }),
  checkbox: css({
    width: 24,
    heigth: 24,
    marginRight: theme.sizing.scale400,
  }),
  locationSection: css({
    marginBottom: theme.sizing.scale800,
  }),
};

export default NewIssueScreen;
