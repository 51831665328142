import { FC } from "react";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

interface SwitchDatesProps {
  label: string;
  goToNext: (next: boolean) => void;
  prevCount?: number;
  nextCount?: number;
  hasConstraints?: boolean;
  showBadges?: boolean;
  canSwitch?: boolean;
  labelWidth?: number;
}
const SwitchDates: FC<SwitchDatesProps> = ({
  label,
  goToNext,
  prevCount = 0,
  nextCount = 0,
  hasConstraints,
  showBadges = true,
  canSwitch = true,
}) => {
  const showPreviousArrow =
    canSwitch && (hasConstraints ? prevCount > 0 : true);
  const showNextArrow = canSwitch && (hasConstraints ? nextCount > 0 : true);

  return (
    <>
      <div css={styles.container}>
        {showPreviousArrow && (
          <div css={styles.arrow} onClick={() => goToNext(false)}>
            <FiChevronLeft css={styles.chevron} />
            {showBadges && (
              <div css={styles.badge}>
                <p css={[styles.text, styles.badgeAmount]}>{prevCount}</p>
              </div>
            )}
          </div>
        )}

        <p css={[styles.text, styles.label]}>{label}</p>

        {showNextArrow && (
          <div css={styles.arrow} onClick={() => goToNext(true)}>
            {showBadges && (
              <div css={styles.badge}>
                <p css={[styles.text, styles.badgeAmount]}>{nextCount}</p>
              </div>
            )}

            <FiChevronRight css={styles.chevron} />
          </div>
        )}
      </div>
    </>
  );
};

const styles = {
  container: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${theme.colors.mono200}`,
    minWidth: 450,
    borderRadius: theme.borders.radius200,
    backgroundColor: theme.colors.white,
  }),
  text: css({
    padding: `${theme.sizing.scale400} ${theme.sizing.scale800}`,
    borderLeft: `1px solid ${theme.colors.mono200}`,
    borderRight: `1px solid ${theme.colors.mono200}`,
    textTransform: "capitalize",
  }),
  label: css({
    textAlign: "center",
    width: "100%",
  }),
  arrow: css({
    display: "flex",
    padding: `0 ${theme.sizing.scale200}`,
    alignItems: "center",
    maxWidth: "40px",
    cursor: "pointer",
  }),
  chevron: css({
    padding: `0 ${theme.sizing.scale100}`,
  }),
  badge: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.borders.radius300,
    width: 16,
    height: 16,
    backgroundColor: theme.colors.accent,
    color: theme.colors.white,
  }),
  badgeAmount: css({
    fontSize: "12px",
  }),
};

export default SwitchDates;
