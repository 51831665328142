import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sort from "@components/Sort";
import Actions from "./Actions";
import { selectAllAreas, selectAreaEntities } from "@store/area/areaSelectors";
import { selectAllRoomSpaces } from "@store/space/spaceSelectors";
import Table from "@components/base/table";
import { fetchAreas } from "@store/area/areaThunk";
import { RoomSpace } from "@store/space/types";
import useAppDispatch from "@hooks/useAppDispatch";
import { useTranslation } from "react-i18next";
import useAppSelector from "@hooks/useAppSelector";
import { selectCurrentRole } from "@store/user/userSelectors";
import { isFrontofficeManager } from "@helpers";

interface SpaceByArea {
  rooms: RoomSpace[];
  assignedRooms: string;
  numberOfRooms: number;
  label: string;
}

interface SpacesByArea {
  [key: string]: SpaceByArea;
}

const AreasTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const areas = useSelector(({ area }) => selectAllAreas(area));
  const areaEntities = useSelector(({ area }) => selectAreaEntities(area));
  const spaces = useSelector(selectAllRoomSpaces);
  const role = useAppSelector(selectCurrentRole);
  const canEdit = isFrontofficeManager(role);

  const [sortSections, setSortSections] = useState<{
    key: string;
    asc: boolean;
  }>({
    key: "label",
    asc: true,
  });
  const [spacesByArea, setSpacesByArea] = useState<SpacesByArea>({});

  const onSortChange = (key: string) => {
    if (sortSections.key === key) {
      setSortSections({ ...sortSections, asc: !sortSections.asc });
      return;
    }
    setSortSections({ key: key, asc: true });
  };

  const getSpacesByArea = () => {
    let spacesByArea: SpacesByArea = {};

    areas.forEach((area) => {
      const spacesAssignedToArea = spaces.filter(
        (space) => space.areaId === area.id,
      );

      const assignedRooms = spacesAssignedToArea
        .map((space) => space.label)
        .join(", ");

      const numberOfRooms = spacesAssignedToArea.length;
      spacesByArea = {
        ...spacesByArea,
        [area.id]: {
          rooms: spacesAssignedToArea,
          assignedRooms,
          numberOfRooms,
          label: area.label,
        },
      };
    });
    return spacesByArea;
  };

  const onSortSections = (
    spacesInSections: SpacesByArea,
    key: keyof SpacesByArea,
  ): SpacesByArea => {
    return (
      spacesInSections &&
      Object.keys(spacesInSections)
        .sort((a, b) => {
          const spacesA = sortSections.asc
            ? spacesInSections[a]
            : spacesInSections[b];
          const spacesB = sortSections.asc
            ? spacesInSections[b]
            : spacesInSections[a];

          switch (key) {
            case "label":
              return spacesA.label.localeCompare(spacesB.label);
            case "numberOfRooms":
              return spacesA.numberOfRooms - spacesB.numberOfRooms;
            case "assignedRooms":
              if (spacesA.assignedRooms === spacesB.assignedRooms) return 0;
              return spacesA.assignedRooms < spacesB.assignedRooms ? -1 : 1;
            default:
              return 0;
          }
        })
        .reduce((result: { [key: string]: SpaceByArea }, key) => {
          result[key] = spacesInSections[key];
          return result;
        }, {})
    );
  };

  useEffect(() => {
    dispatch(fetchAreas());
  }, []);

  useEffect(() => {
    const spacesByArea = getSpacesByArea();
    const sorted = onSortSections(
      spacesByArea,
      sortSections.key as keyof SpacesByArea,
    );
    setSpacesByArea(sorted);
  }, [areas, sortSections, spaces]);

  return (
    <Table
      data-test-id="areas-table"
      columns={[
        <Sort id="label" sort={sortSections} onChange={onSortChange}>
          {t("area.name")}
        </Sort>,
        <Sort id="numberOfRooms" sort={sortSections} onChange={onSortChange}>
          {t("area.numberOfRooms")}
        </Sort>,
        <Sort id="assignedRooms" sort={sortSections} onChange={onSortChange}>
          {t("area.assignedRooms")}
        </Sort>,
        canEdit && <></>,
      ].filter(Boolean)}
      data={Object.entries(spacesByArea).map(([id, value]) => {
        const { numberOfRooms, assignedRooms } = value;
        const area = areaEntities[id];

        return [
          area?.label,
          numberOfRooms?.toString() ?? "",
          assignedRooms ? assignedRooms : t("area.noRoomsAssigned"),
          canEdit && area && <Actions area={area} />,
        ];
      })}
      emptyMessage={t("area.emptyMessage")}
    />
  );
};

export default AreasTable;
