import React from "react";
import { Select, Value, Option, OptionsT } from "baseui/select";

interface SelectUIProps {
  placeholder?: string;
  onClose?: () => void;
  labelKey?: string;
  valueKey?: string;
  clearable?: boolean;
  searchable?: boolean;
  backspaceRemoves?: boolean;
  type?: "select" | "search" | undefined;
  getOptionLabel?: ({ option }: { option?: Option | undefined }) => JSX.Element;
  maxDropdownHeight?: string;
  autoFocus?: boolean;
  size?: "compact" | "mini" | "large" | "default";
}

interface SelectProps {
  options: { id: string | number; label: string }[] | Option[] | OptionsT;
  value: Value | undefined;
  onChange: (params: any) => void;
  props?: SelectUIProps;
  placeholder?: string;
  containerStyle?: React.CSSProperties;
  overrides?: object;
}

const Selector: React.FC<SelectProps> = ({
  options,
  value,
  onChange,
  props,
  placeholder,
  containerStyle,
  overrides,
}) => {
  return (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      {...props}
      overrides={
        overrides
          ? overrides
          : {
              ControlContainer: {
                style: ({ $theme }) => ({
                  width: containerStyle?.width && `${containerStyle?.width}`,
                  borderRadius:
                    containerStyle?.borderRadius &&
                    `${containerStyle?.borderRadius}`,
                  padding:
                    containerStyle?.padding && `${containerStyle?.padding}`,
                  fontWeight:
                    containerStyle?.fontWeight &&
                    `${containerStyle?.fontWeight}`,
                  backgroundColor: $theme.colors.white,
                  border: `1px solid ${$theme.colors.contentInverseSecondary}`,
                }),
              },
              Dropdown: {
                style: ({ $theme }) => ({
                  backgroundColor: $theme.colors.white,
                }),
              },
            }
      }
    />
  );
};

export default Selector;
