import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Task } from "../../types/common";

export interface TaskState extends EntityState<Task> {
  selectedId?: EntityId;
}

export const taskAdapter = createEntityAdapter<Task>();

const initialState: TaskState = {
  ...taskAdapter.getInitialState(),
  selectedId: undefined,
};

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    addTask: taskAdapter.addOne,
    setTasks: taskAdapter.setAll,
    removeTask: taskAdapter.removeOne,
    setTask: taskAdapter.upsertOne,
    setSelectedTask(state, { payload }: PayloadAction<Task["id"] | undefined>) {
      state.selectedId = payload;
    },
  },
});

export const { addTask, setTasks, removeTask, setTask, setSelectedTask } =
  taskSlice.actions;

export default taskSlice;
