import { css } from "@emotion/react";
import { FC, ReactNode, useEffect, useState } from "react";
import { ImCross } from "react-icons/im";
import { ReportExtra } from "@store/reportExtra/types";
import {
  createReportExtra,
  fetchReportExtras,
} from "@store/reportExtra/reportExtraThunk";
import Input from "@components/base/input";
import Button from "@components/base/button";
import Breadcrumb from "@components/base/breadcrumb";
import Table from "@components/base/table";
import theme from "@styles/theme";
import useAppDispatch from "@hooks/useAppDispatch";
import { useTranslation } from "react-i18next";
import { NavLabels, SubNavLabels } from "@components/Layout";

interface taskScreenProps {
  tasks: ReportExtra[];
  taskToDelete: (id: ReportExtra | null) => void;
  deleteTaskModal: (open: boolean) => void;
}

const AdditionalTasksScreen: FC<taskScreenProps> = ({
  tasks,
  taskToDelete,
  deleteTaskModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [addNewTask, setAddNewTask] = useState<boolean>(false);

  const onSubmitTasks = (e: any) => {
    e.preventDefault();
    const taskName: string = e.target[0].value;
    if (taskName === "") return;
    dispatch(createReportExtra({ label: taskName }));
    setAddNewTask(false);
  };

  const newTask = (
    <form css={styles.newTaskContainer} onSubmit={onSubmitTasks}>
      <Input autoFocus placeholder={t("extra.description")} />
      <div css={styles.buttonWrapper}>
        <a onClick={() => setAddNewTask(false)}>{t("common.cancel")}</a>
        <Button type="submit">{t("common.add")}</Button>
      </div>
    </form>
  );

  const onDeleteTask = (currentTarget: EventTarget & HTMLAnchorElement) => {
    const id = currentTarget.dataset.reportId;
    const task = tasks.find((task) => task.id === id);
    if (task) {
      taskToDelete(task);
      deleteTaskModal(true);
    }
  };
  const generateRows = () => {
    const rows: ReactNode[][] = [];
    tasks &&
      tasks.length > 0 &&
      tasks.forEach((task) => {
        if (task.discarded) return;
        rows.push([
          <div css={styles.taskContainer}>
            <span>{task.label}</span>
            <a
              css={styles.deleteButton}
              data-report-id={task.id}
              onClick={({ currentTarget }) => onDeleteTask(currentTarget)}>
              <ImCross />
            </a>
          </div>,
        ]);
      });
    if (addNewTask) {
      rows.push([newTask]);
    }
    return rows;
  };

  useEffect(() => {
    dispatch(fetchReportExtras());
  }, [dispatch]);

  return (
    <>
      <Breadcrumb
        labels={[
          SubNavLabels.Settings,
          NavLabels.DayReportSettings,
          NavLabels.ExtraTasks,
        ]}
      />
      <div css={styles.container}>
        <header css={styles.row}>
          <h2 css={styles.label}>{t("extra.hourAndTask")}</h2>
          <Button disabled={addNewTask} onClick={() => setAddNewTask(true)}>
            {t("extra.newTask")}
          </Button>
        </header>
        <section css={styles.section}>
          <Table
            css={styles.table}
            columns={[t("extra.typeName")]}
            data={generateRows()}
            emptyMessage={t("extra.noExtra")}
          />
        </section>
      </div>
    </>
  );
};
const styles = {
  container: css({
    overflow: "scroll",
    padding: theme.sizing.scale1600,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    paddingBottom: "90px",
  }),
  row: css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
  }),
  label: css({
    marginRight: "auto",
  }),
  section: css({
    paddingTop: theme.sizing.scale1600,
  }),
  table: css({
    width: "clamp(600px, 850px, 50%)",
  }),
  buttonWrapper: css({
    marginLeft: "auto",
    a: {
      marginRight: "8px",
    },
  }),
  newTaskContainer: css({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "48px",
  }),
  taskContainer: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 4px",
  }),
  deleteButton: css({
    color: theme.colors.negative,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      color: theme.colors.negative400,
    },
  }),
};
export default AdditionalTasksScreen;
