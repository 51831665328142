import React from "react";
import { css } from "@emotion/react";
import { Input } from "baseui/input";
import { BiSearchAlt2 } from "react-icons/bi";
import theme from "@styles/theme";

export interface SearchInputProps {
  onChange: (value: string) => void;
  value: string;
  placeholder: string;
  label?: string;
  containerStyle?: React.CSSProperties;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  value,
  placeholder,
  label,
  containerStyle,
}) => {
  return (
    <>
      {label && <label css={styles.label}>{label}</label>}
      <Input
        data-baseweb="search-input"
        startEnhancer={<BiSearchAlt2 />}
        onChange={(event) => onChange(event.currentTarget.value)}
        value={value}
        placeholder={placeholder}
        overrides={{
          Root: {
            style: () => ({
              backgroundColor: theme.colors.white,
              border: `1px solid ${theme.colors.contentInverseSecondary}`,
              outline: "none",
              boxShadow: "none",
              justifyContent: `${containerStyle?.justifyContent}`,
              width: `${containerStyle?.width}`,
              borderRadius: "4px",
              padding: "1px",
            }),
          },
          StartEnhancer: {
            style: () => ({
              backgroundColor: theme.colors.white,
              border: "none",
              outline: "none",
              boxShadow: "none",
            }),
          },
          InputContainer: {
            style: () => ({
              border: "none",
              outline: "none",
              boxShadow: "none",
              width: `${containerStyle?.width}`,
            }),
          },
          Input: {
            style: () => ({
              paddingLeft: "0",
              backgroundColor: theme.colors.white,
              boxShadow: "none",
              border: "none",
              width: `${containerStyle?.width}`,
            }),
          },
        }}
      />
    </>
  );
};

const styles = {
  label: css({
    display: "block",
    marginBottom: "2px",
  }),
};

export default SearchInput;
