import { FC, useEffect } from "react";
import Head from "@components/Head";
import { Provider } from "react-redux";
import store from "@store";
import { BaseProvider } from "baseui";
import theme from "@styles/theme";
import { Provider as StyletronProvider } from "styletron-react";
import PropertyProvider from "@helpers/PropertyProvider";
import { Client as Styletron } from "styletron-engine-atomic";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import IndexPage from "@pages";
import LoginPage from "@pages/login";
import HkOverviewContainer from "@pages/overview";
import AppLoginContainer from "@pages/app-login";
import ActionsLogPage from "@pages/actions-log";
import TDOverviewContainer from "@pages/td-overview";
import GSOverviewContainer from "@pages/gs-overview";
import DayReportsPage from "@pages/day-reports";
import DayReportDetailPage from "@pages/day-reports/week";
import AdditionalTasksPage from "@pages/settings/day-reports/extra-tasks";
import RateTypeFormPage from "@pages/settings/day-reports/rate-type-form";
import RateTypesPage from "@pages/settings/day-reports/rate-types";
import DayReportSettingsPage from "@pages/settings/day-reports";
import AreasContainer from "@pages/settings/areas";
import CustomRoomsPage from "@pages/settings/custom-rooms";
import TasksSettingsPage from "@pages/settings/tasks";
import TeamPage from "@pages/settings/team";
import { HelmetProvider } from "react-helmet-async";
import "@locale";
import i18next from "@locale";

const styletronEngine = new Styletron();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<IndexPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="overview" element={<HkOverviewContainer />} />
      <Route path="actions-log" element={<ActionsLogPage />} />
      <Route path="app-login" element={<AppLoginContainer />} />
      <Route path="td-overview" element={<TDOverviewContainer />} />
      <Route path="gs-overview" element={<GSOverviewContainer />} />
      <Route path="day-reports">
        <Route index element={<DayReportsPage />} />
        <Route path="week" element={<DayReportDetailPage />} />
      </Route>
      <Route path="settings">
        <Route path="day-reports">
          <Route index element={<DayReportSettingsPage />} />
          <Route path="extra-tasks" element={<AdditionalTasksPage />} />
          <Route path="rate-type-form" element={<RateTypeFormPage />} />
          <Route path="rate-types" element={<RateTypesPage />} />
        </Route>
        <Route path="areas" element={<AreasContainer />} />
        <Route path="custom-rooms" element={<CustomRoomsPage />} />
        <Route path="tasks" element={<TasksSettingsPage />} />
        <Route path="team" element={<TeamPage />} />
      </Route>
    </Route>,
  ),
);

const App: FC = () => {
  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem("language") || "nl");
  }, []);

  return (
    <HelmetProvider>
      <Provider store={store}>
        <PropertyProvider>
          <StyletronProvider value={styletronEngine}>
            <BaseProvider theme={theme}>
              <Head />
              <RouterProvider router={router} />
            </BaseProvider>
          </StyletronProvider>
        </PropertyProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
