import React, { useState } from "react";
import { css } from "@emotion/react";
import SelectOptions from "@components/SelectOptions";
import AssignForm from "./AssignForm";
import { Space } from "@store/space/types";
import Button from "@components/base/button";
import {
  generateButtonColors,
  overrideButtonStyles,
} from "@components/base/button/overrides";
import Modal from "@components/base/modal";
import SearchInput from "@components/base/search-input";
import Checkbox from "@components/base/checkbox";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";

interface AssignFiltersProps {
  assignedSpaceIsSelected: boolean;
  onRemoveFromSection: () => void;
  selectedSpacesCount: number;
  setToggleAll: (toggleAll: boolean) => void;
  selectedSpaces: { [key: string]: number };
  setSelectedSpaces: (selectedSpaces: { [key: string]: number }) => void;
  toggleSpace: (spaceId: Space["id"]) => void;
  search: string;
  setSearch: (search: string) => void;
  roomTypeOptions: string[];
  roomTypeFilter: string;
  setRoomTypeFilter: (roomTypeFilter: string) => void;
  showAssignedRooms: boolean;
  toggleAssignedRooms: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AssignFilters: React.FC<AssignFiltersProps> = ({
  assignedSpaceIsSelected,
  onRemoveFromSection,
  setToggleAll,
  selectedSpaces,
  setSelectedSpaces,
  toggleSpace,
  selectedSpacesCount,
  search,
  setSearch,
  roomTypeOptions,
  roomTypeFilter,
  setRoomTypeFilter,
  showAssignedRooms,
  toggleAssignedRooms,
}) => {
  const { t } = useTranslation();
  const [showWarning, setShowWarning] = useState(false);
  const [showAssignForm, setShowAssignForm] = useState(false);

  const showRemoveWarning = () => {
    setShowWarning(true);
  };

  const toggleAssignForm = () => {
    setShowAssignForm(!showAssignForm);
  };

  return (
    <>
      <div data-test-id="assign-filters" css={[styles.row, styles.actionRow]}>
        <div css={styles.alignRight}>
          <Button
            onClick={showRemoveWarning}
            disabled={!assignedSpaceIsSelected}
            kind="secondary"
            overrides={overrideButtonStyles({
              BaseButton: ({ $theme, $disabled }) => ({
                marginRight: $theme.sizing.scale800,
                ":hover": {
                  backgroundColor: "transparant",
                  color: $theme.colors.negative100,
                  borderColor: $theme.colors.negative100,
                },
                ...generateButtonColors({
                  $disabled,
                  color: $theme.colors.negative,
                }),
              }),
            })}>
            {t("area.delete.action")}
          </Button>
          <Modal
            title={t("common.youSure")}
            buttonText={t("area.delete.action")}
            buttonAction={onRemoveFromSection}
            onClose={() => setShowWarning(true)}
            isOpen={showWarning}
            setShowModal={setShowWarning}>
            {t("area.delete.description")}
          </Modal>
          <div css={styles.popUp}>
            <Button
              onClick={toggleAssignForm}
              disabled={selectedSpacesCount === 0}>
              {t("area.assignSection", { count: selectedSpacesCount })}
            </Button>
            {showAssignForm && (
              <AssignForm
                setShowAssignForm={setShowAssignForm}
                setToggleAll={setToggleAll}
                selectedSpaces={selectedSpaces}
                setSelectedSpaces={setSelectedSpaces}
                toggleSpace={toggleSpace}
                selectedSpacesCount={selectedSpacesCount}
              />
            )}
          </div>
        </div>
      </div>
      <div data-test-id="secties-search" css={[styles.row, styles.filterRow]}>
        <SearchInput
          value={search}
          onChange={setSearch}
          placeholder={t("search.rooms")}
        />
        <div data-test-id="kamertype" css={styles.alignRight}>
          <label css={styles.label}>{t("common.roomType")}:</label>
          <SelectOptions
            options={roomTypeOptions}
            value={roomTypeFilter}
            width={120}
            onChange={setRoomTypeFilter}
          />

          <label css={styles.label}>{t("area.assignedRooms")}:</label>
          <Checkbox
            checked={showAssignedRooms}
            onChange={toggleAssignedRooms}
            checkmarkType="toggle"
          />
        </div>
      </div>
    </>
  );
};

const styles = {
  row: css({
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "11px",
    marginTop: "-1px",
    position: "sticky",
    background: theme.colors.backgroundPrimary,
  }),
  actionRow: css({
    paddingTop: theme.sizing.scale1600,
    marginTop: "10px",
    justifyContent: "flex-end",
    top: "-35px",
    zIndex: 11,
  }),
  filterRow: css({
    paddingTop: theme.sizing.scale100,
    paddingBottom: theme.sizing.scale800,
    paddingRight: "20px",
    top: "56px",
    zIndex: 10,
  }),
  alignRight: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }),
  label: css({
    whiteSpace: "nowrap",
    margin: " 0 10px 0 40px",
    lineHeight: "33px",
  }),
  popUp: css({
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "20px",
  }),
};

export default AssignFilters;
