import React from "react";
import withAuth from "@helpers/withAuth";
import Layout from "@components/Layout";
import EndOfDaySettingsScreen from "@screens/EndOfDaySettings";

const DayReportSettingsPage: React.FC = () => {
  return (
    <Layout active="settings/day-reports">
      <EndOfDaySettingsScreen />
    </Layout>
  );
};

export default withAuth(DayReportSettingsPage);
