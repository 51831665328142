import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AssignFilters from "./AssignFilters";
import Sort from "@components/Sort";
import useAppSelector from "@hooks/useAppSelector";
import { selectAreaEntities } from "@store/area/areaSelectors";
import {
  selectAllRoomTypes,
  selectRoomSpaceEntities,
} from "@store/space/spaceSelectors";
import { RoomSpace, Space } from "@store/space/types";
import { unassignArea } from "@store/space/spaceThunk";
import Checkbox from "@components/base/checkbox";
import Table from "@components/base/table";
import { overrideTableStyles } from "@components/base/table/overrides";
import useAppDispatch from "@hooks/useAppDispatch";
import { useTranslation } from "react-i18next";

interface AssignTableProps {}

const AssignTable: React.FC<AssignTableProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const areaEntities = useAppSelector(({ area }) => selectAreaEntities(area));
  const spaces = useSelector(selectRoomSpaceEntities);
  const roomTypes = useSelector(selectAllRoomTypes);
  const [search, setSearch] = useState("");
  const [toggleAll, setToggleAll] = useState(false);
  const [selectedSpaces, setSelectedSpaces] = useState<{
    [key: string]: number;
  }>({});
  const [roomTypeOptions, setRoomTypeOptions] = useState<string[]>([]);
  const [roomTypeFilter, setRoomTypeFilter] = useState<string>("All");
  const [showAssignedRooms, setShowAssignedRooms] = useState(true);
  const [sortRooms, setSortRooms] = useState<{ key: string; asc: boolean }>({
    key: "room",
    asc: true,
  });
  const [filteredSpaces, setFilteredSpaces] = useState<Space["id"][]>([]);

  const selectedSpacesCount: number = Object.keys(selectedSpaces).length;
  const assignedSpaceIsSelected: boolean = Object.keys(selectedSpaces).some(
    (selectedSpace) => {
      if (spaces !== undefined) return spaces[selectedSpace]?.areaId;
    },
  );

  const toggleSpace = (spaceId: RoomSpace["id"]) => {
    const select = { ...selectedSpaces };
    if (selectedSpaces[spaceId]) {
      delete select[spaceId];
    } else {
      select[spaceId] = 1;
    }
    setSelectedSpaces(select);
  };

  const toggleAssignedRooms = (event: ChangeEvent<HTMLInputElement>) => {
    setShowAssignedRooms(event.target.checked);
  };

  const onRemoveFromSection = () => {
    dispatch(unassignArea(Object.keys(selectedSpaces)));
    setToggleAll(false);
    setSelectedSpaces({});
  };

  const toggleAllSpaces = () => {
    if (!spaces || filteredSpaces.length === 0) return;
    if (!toggleAll) {
      const addToSelection = filteredSpaces.reduce(
        (result: { [key: string]: number }, key) => {
          result[key] = 1;
          return result;
        },
        {},
      );
      setSelectedSpaces({ ...selectedSpaces, ...addToSelection });
    } else {
      const strippedSelection = { ...selectedSpaces };
      filteredSpaces.forEach((space) => {
        delete strippedSelection[space];
      });
      setSelectedSpaces(strippedSelection);
    }
    setToggleAll(!toggleAll);
  };

  const getRoomTypeOptions = () => {
    const roomTypeArray: string[] = roomTypes;
    roomTypeArray.sort().unshift("All");
    setRoomTypeOptions(roomTypeArray);
  };

  const onSortRooms = (key: string) => {
    if (sortRooms.key === key) {
      setSortRooms({ ...sortRooms, asc: !sortRooms.asc });
      return;
    }
    setSortRooms({ key, asc: true });
  };

  const sortSpaces = (key: string): string[] => {
    return Object.keys(spaces).sort((a: Space["id"], b: Space["id"]) => {
      const spacesA = sortRooms.asc ? spaces[a]! : spaces[b]!;
      const spacesB = sortRooms.asc ? spaces[b]! : spaces[a]!;

      switch (key) {
        case "room":
          return spacesA.label.localeCompare(spacesB.label);
        case "type":
          return spacesA.room.code.localeCompare(spacesB.room.code);
        case "section":
          return (
            areaEntities[spacesA.areaId]?.label.localeCompare(
              areaEntities[spacesB.areaId]?.label ?? "",
            ) ?? 0
          );
        default:
          return 0;
      }
    });
  };

  const filterSpaces = (
    rooms: string[],
    searchInput: string,
    roomTypeFilter: string,
  ): Space["id"][] => {
    return rooms.filter((room) => {
      const space = spaces[room]!;

      const search = searchInput ? space.label.includes(searchInput) : true;
      const roomType =
        roomTypeFilter !== "All" ? space.room.code === roomTypeFilter : true;
      const showAssigned =
        (space.areaId !== "" || space.areaId !== undefined) &&
        showAssignedRooms;

      return search && roomType && showAssigned;
    });
  };

  const generateRows = () => {
    return filteredSpaces.map((spaceId) => {
      const { label, room, areaId } = spaces[spaceId] ?? {};
      const areaLabel = areaId && areaEntities[areaId]?.label;

      const _toggleSpace = () => {
        toggleSpace(spaceId);
      };

      return [
        <Checkbox
          checked={selectedSpaces[spaceId] === 1}
          onChange={_toggleSpace}
        />,
        label,
        room?.code ?? "",
        areaLabel ?? t("area.toAssign"),
      ];
    });
  };

  useEffect(() => {
    if (!spaces) return;
    getRoomTypeOptions();
  }, [spaces]);

  useEffect(() => {
    if (!spaces) return;
    const sorted = sortSpaces(sortRooms.key);
    const filtered = filterSpaces(sorted, search, roomTypeFilter);
    setFilteredSpaces(filtered);
  }, [spaces, sortRooms, search, roomTypeFilter, showAssignedRooms]);

  return (
    <>
      <AssignFilters
        assignedSpaceIsSelected={assignedSpaceIsSelected}
        onRemoveFromSection={onRemoveFromSection}
        setToggleAll={setToggleAll}
        selectedSpaces={selectedSpaces}
        setSelectedSpaces={setSelectedSpaces}
        toggleSpace={toggleSpace}
        selectedSpacesCount={selectedSpacesCount}
        search={search}
        setSearch={setSearch}
        roomTypeOptions={roomTypeOptions}
        roomTypeFilter={roomTypeFilter}
        setRoomTypeFilter={setRoomTypeFilter}
        showAssignedRooms={showAssignedRooms}
        toggleAssignedRooms={toggleAssignedRooms}
      />
      <Table
        columns={[
          <Checkbox checked={toggleAll} onChange={toggleAllSpaces} />,
          <Sort id="room" sort={sortRooms} onChange={onSortRooms}>
            {t("common.space")}
          </Sort>,
          <Sort id="type" sort={sortRooms} onChange={onSortRooms}>
            {t("common.roomType")}
          </Sort>,
          <Sort id="section" sort={sortRooms} onChange={onSortRooms}>
            {t("common.area")}
          </Sort>,
        ]}
        data-test-id="assign-table"
        data={generateRows()}
        emptyMessage={t("search.notFound", {
          search: t("common.space").toLowerCase(),
        })}
        overrides={overrideTableStyles({
          Root: {
            overflow: "visible",
          },
          TableHead: {
            position: "sticky",
            top: "114px",
          },
          TableBodyCell: {
            ":first-child": {
              width: "0px",
              alignSelf: "center",
            },
          },
        })}
      />
    </>
  );
};

export default AssignTable;
