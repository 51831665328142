import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RateType } from "./types";

export interface RateTypeState extends EntityState<RateType> {
  selectedId?: EntityId;
}

export const rateTypeAdapter = createEntityAdapter<RateType>();

const initialState: RateTypeState = {
  ...rateTypeAdapter.getInitialState(),
  selectedId: undefined,
};

const rateTypeSlice = createSlice({
  name: "rateType",
  initialState,
  reducers: {
    setRateTypes: rateTypeAdapter.setAll,
    setSelectedRateType(
      state,
      { payload }: PayloadAction<RateType["id"] | undefined>,
    ) {
      state.selectedId = payload;
    },
  },
});

export const { setRateTypes, setSelectedRateType } = rateTypeSlice.actions;

export default rateTypeSlice;
