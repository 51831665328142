import React, { useEffect, useState } from "react";
import { batch } from "react-redux";
import TasksTable from "./components/TasksTable";
import EditTask from "./components/EditTask";
import NewTaskForm from "./components/NewTaskForm";
import { css } from "@emotion/react";
import { selectCurrentTask } from "@store/task/taskSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { setSelectedTask } from "@store/task/taskSlice";
import { hidePopUp, showPopUp } from "@store/ui/uiSlice";
import { deleteTask, fetchTasks } from "@store/task/taskThunk";
import Breadcrumb from "@components/base/breadcrumb";
import Input from "@components/base/input";
import Button from "@components/base/button";
import theme from "@styles/theme";
import Modal from "@components/base/modal";
import { overrideButtonStyles } from "@components/base/button/overrides";
import useAppDispatch from "@hooks/useAppDispatch";
import { NavLabels, SubNavLabels } from "@components/Layout";
import { useTranslation } from "react-i18next";

const TasksScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDeleteWindow, setShowDeleteWindow] = useState(false);
  const popUp = useAppSelector(({ ui }) => ui.web.popUp);
  const task = useAppSelector(selectCurrentTask);

  const onShowForm = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    batch(() => {
      dispatch(setSelectedTask(undefined));
      dispatch(
        showPopUp({
          type: "tasks/taskForm",
          pos: { x: event.nativeEvent.pageX, y: event.nativeEvent.pageY },
        }),
      );
    });
  };

  const onShowDeleteWindow = () => {
    dispatch(hidePopUp());
    setShowDeleteWindow(true);
  };

  const onDelete = () => {
    if (task === undefined) return;
    dispatch(deleteTask(task.id));
    setShowDeleteWindow(false);
  };

  useEffect(() => {
    dispatch(fetchTasks());
  }, []);

  return (
    <>
      <Breadcrumb labels={[SubNavLabels.Settings, NavLabels.TaskSettings]} />
      <section data-test-id="taken-beheren" css={styles.section}>
        <div css={styles.actionbar}>
          <h4>{t("tasks.all")}</h4>
          <div data-test-id="taken-search" css={styles.actions}>
            <Input
              autoFocus
              placeholder={t("search.task")}
              value={search}
              onChangeText={(text) => setSearch(text)}
            />
            <Button
              onClick={onShowForm}
              overrides={overrideButtonStyles({
                BaseButton: {
                  marginLeft: theme.sizing.scale800,
                  whiteSpace: "nowrap",
                },
              })}>
              {t("issue.addNewTask")}
            </Button>
          </div>
        </div>

        <TasksTable search={search} />

        {popUp?.type === "tasks/editTask" && (
          <EditTask onShowDeleteWindow={onShowDeleteWindow} />
        )}
        {popUp?.type === "tasks/taskForm" && (
          <NewTaskForm initialValues={task} />
        )}
        <Modal
          title={t("issue.delete.title")}
          buttonText={t("common.delete")}
          buttonAction={onDelete}
          isOpen={showDeleteWindow}
          setShowModal={setShowDeleteWindow}>
          {t("issue.delete.action", { label: task?.label })}
        </Modal>
      </section>
    </>
  );
};

const styles = {
  section: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: theme.sizing.scale1600,
    overflow: "scroll",
  }),
  actionbar: css({
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.sizing.scale800,
  }),
  actions: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }),
};

export default TasksScreen;
