import { Pagination as BUPagination } from "baseui/pagination";
import { FC, useState } from "react";
import theme from "@styles/theme";
import Button from "../button";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

interface PaginationProps {
  totalPages: number;
  onPaginationChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  totalPages,
  onPaginationChange,
}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    onPaginationChange(page);
  };

  return (
    <BUPagination
      currentPage={currentPage}
      numPages={totalPages}
      onPageChange={({ nextPage }) => handlePageChange(nextPage)}
      overrides={{
        NextButton: {
          component: ({ onClick }: any) => (
            <Button
              onClick={onClick}
              css={[
                {
                  lineHeight: "0.75rem",
                },
                currentPage !== totalPages ? styles.active : styles.inactive,
              ]}>
              Volgende
            </Button>
          ),
        },
        PrevButton: {
          component: ({ onClick }: any) => (
            <Button
              onClick={onClick}
              css={[
                {
                  lineHeight: "0.75rem",
                },
                currentPage > 1 ? styles.active : styles.inactive,
              ]}>
              {t("common.back")}
            </Button>
          ),
        },
        DropdownContainer: {
          style: () => ({
            ":nth-child(n)": {
              border: "none",
              outline: "none",
            },
          }),
        },
        Select: {
          props: {
            overrides: {
              Root: {
                style: () => ({
                  border: "none",
                  outline: "none",
                }),
              },
              ControlContainer: {
                style: () => ({
                  borderRadius: "4px",
                  backgroundColor: theme.colors.mono200,
                  border: "none",
                  minWidth: "4rem",
                  color: theme.colors.mono1000,
                }),
              },
              DropdownListItem: {
                style: () => ({
                  borderBottom: `1px solid ${theme.colors.mono400}`,
                }),
              },
            },
          },
        },
        MaxLabel: {
          style: () => ({
            color: theme.colors.mono1000,
          }),
        },
      }}
    />
  );
};

const styles = {
  active: css({
    border: `1px solid ${theme.colors.buttonPrimaryFill}`,
    backgroundColor: theme.colors.backgroundPrimary,
    color: theme.colors.buttonPrimaryFill,
    ":hover": {
      border: `1px solid ${theme.colors.buttonPrimaryFill}`,
      backgroundColor: theme.colors.buttonPrimaryGhostHover,
    },
  }),
  inactive: css({
    border: `1px solid ${theme.colors.mono600}`,
    backgroundColor: theme.colors.buttonDisabledFill,
    color: theme.colors.mono600,
    ":hover": {
      border: `1px solid ${theme.colors.mono600}`,
      backgroundColor: theme.colors.buttonDisabledFill,
    },
    cursor: "not-allowed",
  }),
};

export default Pagination;
