import { CheckboxOverrides } from "baseui/checkbox";

export const checkboxOverrides: CheckboxOverrides = {
  Checkmark: {
    style: ({ $checked, $theme }) => ({
      borderTopStyle: "solid",
      borderBottomStyle: "solid",
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
      borderTopWidth: "2px",
      borderRightWidth: "2px",
      borderBottomWidth: "2px",
      borderLeftWidth: "2px",
      borderBottomColor: $checked
        ? $theme.colors.positive
        : $theme.colors.mono600,
      borderTopColor: $checked ? $theme.colors.positive : $theme.colors.mono600,
      borderLeftColor: $checked
        ? $theme.colors.positive
        : $theme.colors.mono600,
      borderRightColor: $checked
        ? $theme.colors.positive
        : $theme.colors.mono600,
      borderTopRightRadius: $theme.borders.radius200,
      borderTopLeftRadius: $theme.borders.radius200,
      borderBottomRightRadius: $theme.borders.radius200,
      borderBottomLeftRadius: $theme.borders.radius200,
      backgroundColor: $checked ? $theme.colors.positive : null,
    }),
  },
  ToggleTrack: {
    style: ({ $checked, $theme }) => ({
      width: "40px",
      backgroundColor: $checked
        ? $theme.colors.positive
        : $theme.colors.mono600,
    }),
  },
  Toggle: {
    style: ({ $theme }) => ({
      backgroundColor: $theme.colors.white,
      boxShadow: `0px 2px 8px ${$theme.colors.mono600}`,
    }),
  },
};
