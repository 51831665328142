import { makeSelectRoomSpaceById } from "@store/space/spaceSelectors";
import { CleaningChoice, RoomSpace } from "@store/space/types";
import { StatefulPopover } from "baseui/popover";
import { ParagraphSmall } from "baseui/typography";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@store";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

interface ActionProps {
  spaceId: RoomSpace["id"];
}

const Action: React.FC<ActionProps> = ({ spaceId }) => {
  const { t } = useTranslation();
  const selectSpaceById = useMemo(makeSelectRoomSpaceById, [spaceId]);
  const space = useSelector((state: RootState) =>
    selectSpaceById(state, spaceId),
  );
  const { cleaningChoice, inspected, lastInspection } = space.room;

  const isGreenChoice =
    cleaningChoice === CleaningChoice.GREEN ||
    cleaningChoice === CleaningChoice.ORANGE ||
    cleaningChoice === CleaningChoice.NON_GREEN_SKIP;

  const containsRemarks = inspected && lastInspection?.remark;

  return (
    <StatefulPopover
      showArrow
      placement="bottomRight"
      triggerType="hover"
      content={
        containsRemarks &&
        lastInspection !== undefined && (
          <div css={css({ padding: "16px", backgroundColor: "white" })}>
            <ParagraphSmall>
              <strong>{t("common.remark")}: </strong>
              <br />
              {lastInspection?.remark}
            </ParagraphSmall>
          </div>
        )
      }
      accessibilityType="menu"
      overrides={{
        Arrow: { style: { backgroundColor: "white" } },
      }}>
      <div>
        {(isGreenChoice || containsRemarks) && (
          <img
            src={
              isGreenChoice ? "/icons/do-not-disturb.svg" : "/icons/remarks.svg"
            }
          />
        )}
      </div>
    </StatefulPopover>
  );
};

export default Action;
