import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { User, UserRoles, Users } from "./types";
import { Property } from "../property/types";

export interface UserState extends EntityState<User> {
  current?: User;
  selectedRole: UserRoles;
  selectedPropertyId?: Property["id"];
  selectedId?: User["id"];
}

const userAdapter = createEntityAdapter<User>();

const initialState: UserState = {
  current: undefined,
  selectedRole: UserRoles.NONE,
  selectedPropertyId: undefined,
  selectedId: undefined,
  ...userAdapter.getInitialState(),
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers(state, { payload }: PayloadAction<Users>) {
      userAdapter.setAll(state, payload);
    },
    setUser(state, { payload }: PayloadAction<User>) {
      if (state.current?.id === payload.id) state.current = payload;
      userAdapter.upsertOne(state, payload);
    },
    setCurrentUser(state, { payload }: PayloadAction<User>) {
      state.current = payload;
    },
    setSelectedRole(state, { payload }: PayloadAction<UserRoles>) {
      state.selectedRole = payload;
    },
    setSelectedPropertyId(state, { payload }: PayloadAction<Property["id"]>) {
      state.selectedPropertyId = payload;
    },
    setSelectedUser(state, { payload }: PayloadAction<User["id"]>) {
      state.selectedId = payload;
    },
    resetStore: () => initialState,
    removeUser(state, { payload }: PayloadAction<User["id"]>) {
      userAdapter.removeOne(state, payload);
    },
  },
});

export const {
  setUser,
  setUsers,
  setCurrentUser,
  setSelectedPropertyId,
  setSelectedRole,
  setSelectedUser,
  removeUser,
  resetStore,
} = userSlice.actions;

export const userSelectors = userAdapter.getSelectors();

export default userSlice;
