import React, { useState } from "react";
import NewIssueContainer from "./NewIssueContainer";
import IssueDetails from "./IssueDetails";
import { selectCurrentIssue } from "@store/issue/issueSelectors";
import { selectSelectedUserId } from "@store/user/userSelectors";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import { deleteIssue, handleIssueTransaction } from "@store/issue/issueThunk";
import {
  IssueTransaction,
  IssueTransactionTypes,
} from "@store/transaction/types";
import { IssueActionTypes, IssueType } from "@store/issue/types";
import Modal from "@components/base/modal";
import TextareaInput from "@components/base/text-area";
import { useTranslation } from "react-i18next";

interface IssueActionsProps {
  actionType: IssueActionTypes;
  setActionType: (actionType: IssueActionTypes) => void;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  type: IssueType;
}

const IssueActions: React.FC<IssueActionsProps> = ({
  actionType,
  setActionType,
  showModal,
  setShowModal,
  type,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [remark, setRemark] = useState("");
  const issue = useAppSelector(selectCurrentIssue);
  const userId = useAppSelector(selectSelectedUserId);

  const onClose = () => {
    setShowModal(false);
    setActionType(false);
  };

  const onEditPressed = () => {
    setActionType("edit");
  };

  const deleteTask = async () => {
    if (!issue) return;
    dispatch(deleteIssue(issue.id));
    setShowModal(false);
  };

  const setTaskToInactive = async () => {
    if (!issue) return;
    const { id, spaceId } = issue;
    const transaction: IssueTransaction = {
      issueId: id,
      name: IssueTransactionTypes.WAIT_WORK,
      spaceId,
      createdAt: new Date().toDateString(),
      userId,
      meta: { remark },
    };
    dispatch(handleIssueTransaction([{ transaction, prevIssue: issue }]));
    setShowModal(false);
    setRemark("");
  };

  if (!issue) return null;
  if (actionType === "remove") {
    return (
      <Modal
        title={t("issue.delete.title")}
        buttonText={t("issue.delete.action")}
        buttonAction={deleteTask}
        isOpen={showModal}
        setShowModal={onClose}>
        {t("issue.delete.description", { label: issue.label })}
      </Modal>
    );
  }
  if (actionType === "setToInactive") {
    return (
      <Modal
        title={t("issue.inactive.title")}
        buttonText={t("issue.inactive.title")}
        buttonAction={setTaskToInactive}
        isOpen={showModal}
        setShowModal={onClose}>
        {t("issue.inactive.action")}
        <TextareaInput
          value={remark}
          onChangeText={(text: string) => setRemark(text)}
          placeholder={t("issue.inactive.placeholder")}
          label={t("issue.inactive.label")}
          containerStyle={{ paddingTop: "15px" }}
        />
      </Modal>
    );
  }
  if (actionType === "edit") {
    return (
      <Modal title={t("issue.edit")} setShowModal={onClose} isOpen={showModal}>
        <NewIssueContainer
          onClose={onClose}
          selectedIssueId={issue.id}
          type={type}
        />
      </Modal>
    );
  }
  if (actionType === "view") {
    return (
      <Modal title={t("issue.view")} setShowModal={onClose} isOpen={showModal}>
        <IssueDetails onEditPressed={onEditPressed} issue={issue} />
      </Modal>
    );
  }
  return null;
};

export default IssueActions;
