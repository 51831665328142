import React, { useEffect, useState } from "react";
import SwitchDates from "../../components/SwitchDates";
import { addWeeks, endOfWeek, format, startOfWeek } from "date-fns";
import WeekOverviewTable from "./components/WeekOverviewTable";
import NotActive from "./components/NotActive";
import { getLanguage, isAdmin, isFrontofficeManager } from "@helpers";
import { selectCurrentRole } from "@store/user/userSelectors";
import { selectCurrentProperty } from "@store/property/propertySelector";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import {
  deliverWeekReport,
  exportDayReportsToCSV,
  fetchDayReports,
} from "@store/dayReport/dayReportThunk";
import Button from "@components/base/button";
import Modal from "@components/base/modal";
import Breadcrumb from "@components/base/breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLabels } from "@components/Layout";
import { useTranslation } from "react-i18next";

const WeekOverviewScreen: React.FC = () => {
  const { t } = useTranslation();
  const locale = getLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectCurrentRole);
  const property = useAppSelector(selectCurrentProperty);
  const [showModal, setShowModal] = useState(false);
  const [modelInfo, setModalInfo] = useState<{
    title: string;
    content: string[];
    description: string;
    type: string;
    button: string;
  }>({
    title: "",
    content: [],
    description: "",
    type: "",
    button: "",
  });
  const [selectedWeek, setSelectedWeek] = useState<Date>(new Date());

  const { active, email, permission } = property!.settings.reporting;
  const start = startOfWeek(selectedWeek, { locale });
  const end = endOfWeek(selectedWeek, { locale });
  const startDate = format(start, "d LLLL yyyy", {
    locale,
  });
  const endDate = format(end, "d LLLL yyyy", { locale });
  const weekLabel = format(
    selectedWeek,
    `'${t("dayreport.week")}' w '(${startDate} - ${endDate})'`,
    {
      locale,
    },
  );

  const emailNotSet = email?.length === 0;
  const canSend = permission?.send?.includes(role) || isAdmin(role);
  const goToNext = (next: boolean) => {
    const nextDate = addWeeks(selectedWeek, next ? 1 : -1);
    navigate(`/day-reports/week?date=${format(nextDate, "yyyy-MM-dd")}`);
  };
  const showConfirm = async (type: "send" | "download") => {
    if (type === "send") {
      if (email.length === 0) goToSettings();
      setShowModal(true);
      setModalInfo({
        title: t("dayreport.send.title"),
        description: t("dayreport.send.description"),
        content: email,
        button: t("dayreport.send.button"),
        type: "send",
      });
    }
  };

  const goToSettings = () => {
    navigate("/settings/day-reports");
  };

  const sendWeekReport = () => {
    dispatch(deliverWeekReport(format(selectedWeek, "yyyy-MM-dd")));
    setShowModal(false);
  };

  const { search } = useLocation();
  const date = new URLSearchParams(search).get("date");

  useEffect(() => {
    if (!date && !selectedWeek) return;
    if (!date) {
      dispatch(fetchDayReports({ start: format(selectedWeek, "yyyy-MM-dd") }));
      return;
    }
    dispatch(fetchDayReports({ start: date as string }));
    setSelectedWeek(new Date(date as string));
  }, [date, property?.code]);

  const exportWeekOverview = () => {
    dispatch(exportDayReportsToCSV({ start: format(startDate, "yyyy-MM-dd") }));
  };

  return (
    <>
      <Breadcrumb labels={[NavLabels.DayReports]} />
      <section data-test-id="hk-weekoverzicht">
        {(!active || (emailNotSet && canSend)) && (
          <NotActive
            buttonText={
              !active ? t("dayreport.manage") : t("dayreport.setupEmail")
            }
            info={!active ? t("dayreport.enableEmail") : t("dayreport.noEmail")}
            onClick={goToSettings}
          />
        )}
        <div className="row">
          <div className="controls">
            <SwitchDates
              label={weekLabel}
              goToNext={goToNext}
              showBadges={false}
              hasConstraints={false}
            />
          </div>
          {isFrontofficeManager(role) && (
            <div className="button-wrapper">
              {canSend && (
                <div className="row">
                  <Button
                    onClick={() => showConfirm("send")}
                    kind="primary"
                    disabled={!active && !!email?.length}>
                    {t("dayreport.send.self")}
                  </Button>
                  <Button
                    onClick={exportWeekOverview}
                    kind="secondary"
                    disabled={!active}>
                    {t("dayreport.send.export")}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>

        <WeekOverviewTable />
        <Modal
          title={modelInfo.title}
          buttonText={modelInfo.button}
          // TODO: when download functionality is implemented, set undefined as downloadFunction
          buttonAction={modelInfo.type === "send" ? sendWeekReport : undefined}
          isOpen={showModal}
          setShowModal={setShowModal}>
          <p>
            {modelInfo.description}
            {modelInfo.content.map((item) => (
              <li key={`modelInfo_${item}`}>{item}</li>
            ))}
          </p>
        </Modal>
      </section>
      <style jsx>
        {`
          section {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 16px 32px;
            overflow: scroll;
          }
          .row,
          .controls {
            flex-direction: row;
            display: flex;
            width: 100%;
            align-items: center;
            gap: 8px;
          }
          .controls {
            margin: 16px 0;
          }
          li {
            text-align: left;
            padding-left: 16px;
          }
          .modal-title {
            font-size: 24px;
            max-width: 80%;
            padding: 16px 0 8px 0;
            text-align: left;
            font-weight: 600;
          }
          .button-wrapper {
            display: flex;
            flex-direction: row;
            gap: 16px;
          }
          .warning {
            margin-top: 16px;
            font-weight: 600;
          }
          p {
            text-align: left;
          }
          .hk-weekoverzicht-totals {
            width: 100%;
          }
          .hk-weekoverzicht-totals-title {
            padding-bottom: 16px;
          }
          .hk-weekoverzicht-totals > p {
            padding-bottom: 8px;
          }
        `}
      </style>
    </>
  );
};
export default WeekOverviewScreen;
