import theme from "@styles/theme";
import React, { FC, useState, useRef } from "react";

export interface Suggestion {
  key: string;
  label: string;
}

interface SuggestionRowProps {
  suggestion: Suggestion;
  idx: number;
  highlight: boolean;
  onClick: (suggestion: Suggestion) => void;
  handleDropDownHover: (index: number) => void;
}

const SuggestionRow: FC<SuggestionRowProps> = ({
  suggestion,
  idx,
  highlight,
  onClick,
  handleDropDownHover,
}) => {
  let wrapperClass = idx == 0 ? "wrap" : "wrap bordered";
  if (highlight) wrapperClass += " highlight";

  return (
    <li
      className={wrapperClass}
      onClick={() => onClick(suggestion)}
      onMouseEnter={() => handleDropDownHover(idx)}>
      <span className="name">{suggestion.label}</span>
      <style jsx>{`
        li {
          font-family: Source Sans Pro;
          list-style: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding-left: 10px;
          height: 54px;
        }
        li:hover,
        .highlight {
          background: rgba(73, 143, 167, 0.6);
        }
        .bordered {
          border-top: 1px solid #a2a2a2;
        }
        .initials {
          margin: 0 10px;
          display: flex;
          font-weight: 600;
          background: #f7f7f7;
          border-radius: 17px;
          width: 34px;
          height: 34px;
          justify-content: center;
          align-items: center;
        }
        li > span.name {
          font-size: 14px;
          color: ${theme.colors.dark};
          font-weight: 600;
        }
      `}</style>
    </li>
  );
};

interface SuggestionsInputProps {
  suggestions: Suggestion[];
  onSetSuggestion: (suggestion: Suggestion["key"]) => void;
  placeholder: string;
  label: string;
  setInput: (input: string) => void;
  input: string;
}

const SuggestionsInput: FC<SuggestionsInputProps> = ({
  suggestions,
  onSetSuggestion,
  placeholder,
  label,
  setInput,
  input = "",
}) => {
  const [highlight, setHighlight] = useState(-1);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionScroll = useRef<HTMLUListElement>(null);

  const updateInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!showSuggestions) setShowSuggestions(true);
    setInput(evt.target.value);
  };

  const normalize = function (str: string) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };
  const onClick = (suggestion: Suggestion) => {
    onSetSuggestion(suggestion.key);
    setInput(suggestion.label);
    setShowSuggestions(false);
  };
  const search = normalize(input);
  const results: Suggestion[] = [];
  suggestions.forEach((s: Suggestion) => {
    if (!normalize(s.label).includes(search)) return;
    results.push(s);
  });

  const handleDropDownKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!showSuggestions) return;

    const { key } = event;
    const DOWN_KEY = "ArrowDown";
    const UP_KEY = "ArrowUp";
    const ESC_KEY = "Escape";
    const ENTER = "Enter";

    if (key == ESC_KEY) {
      setShowSuggestions(false);
      return;
    }
    if (key !== DOWN_KEY && key !== UP_KEY && key !== ENTER) return;
    event.preventDefault();

    let updateHighlight = highlight;
    if (key === DOWN_KEY) updateHighlight += 1;
    if (key === UP_KEY) updateHighlight -= 1;
    updateHighlight =
      updateHighlight < 0
        ? suggestions.length - 1
        : updateHighlight > suggestions.length - 1
          ? 0
          : updateHighlight;
    setHighlight(updateHighlight);

    suggestionScroll.current?.scrollTo(
      0,
      Math.max(updateHighlight - 7, 0) * 54,
    );

    if (key === ENTER) {
      if (highlight == -1) return;
      const suggestion = results[highlight];
      onSetSuggestion(suggestion.key);
      setInput(suggestion.label);
      setShowSuggestions(false);
    }
  };

  return (
    <div>
      <div className="wrapper">
        <p className="header">{label}</p>
        <div className="row">
          <input
            onFocus={() => setShowSuggestions(true)}
            type="text"
            value={input}
            placeholder={placeholder}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
              handleDropDownKey(event)
            }
            onChange={updateInput}
          />
        </div>
        <ul
          hidden={!showSuggestions}
          className="suggest"
          ref={suggestionScroll}
          onMouseEnter={() => setHighlight(-1)}>
          {results.map((s: Suggestion, i: number) => {
            return (
              <SuggestionRow
                handleDropDownHover={setHighlight}
                suggestion={s}
                idx={i}
                key={s.key}
                onClick={onClick}
                highlight={highlight == i}
              />
            );
          })}
        </ul>
      </div>
      <style jsx>{`
        .wrapper {
          position: relative;
          height: 87px;
          box-sizing: border-box;
          z-index: 1001;
        }

        p.header {
          font-family: Source Sans Pro;
          font-style: normal;
          text-align: start;
          font-weight: 600;
          font-size: 14px;
          color: #000;
        }

        input {
          padding: 0;
          border: none;
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          width: 280px;
          background-color: transparent;
          border-bottom: 1px solid #999999;
          margin-bottom: 2px;
        }
        .suggest {
          width: 280px;
          max-height: 440px;
          padding-left: 0;
          margin-top: 0;
          overflow-y: scroll;
          background: #ffffff;
          border: 1px solid #a2a2a2;
          border-radius: 4px;
          box-sizing: border-box;
        }
        .suggest:empty {
          border: none;
        }
        .row {
          display: flex;
          flex-direction: row;
        }
        .fa-times-circle {
          margin-left: -15px;
          color: #c4c4c4;
          cursor: pointer;
        }
        .fa-times {
          position: absolute;
          top: 5px;
          right: 10px;
          color: ${theme.colors.dark};
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default SuggestionsInput;
