import { NavLabels, SubNavLabels } from "@components/Layout";
import { IssueState } from "@store/issue/types";
import { RoomStates } from "@store/space/types";
import { UserRoles } from "@store/user/types";

export default {
  common: {
    all: "Alle",
    notApplicable: "n.v.t.",
    cancel: "Annuleren",
    delete: "Verwijderen",
    submit: "Bevestigen",
    save: "Opslaan",
    saveChanges: "Wijzigingen opslaan",
    add: "Toevoegen",
    change: "Wijzigen",
    back: "Terug",
    filter: "Filter",
    logout: "Uitloggen",
    loading: "Aan het laden...",
    space: "Kamer",
    spaces: "Kamers",
    issue: "Taak",
    issues: "Taken",
    photos: "Foto's",
    area: "Sectie",
    areas: "Secties",
    property: "Locatie",
    roomType: "Kamertype",
    customSpace: "Aangepaste kamer",
    transactions: "Gegevens",
    room: "Ruimte",
    rooms: "Ruimtes",
    action: "Actie",
    date: "Datum",
    export: "Exporteer {{value}}",
    total: "Totaal",
    name: "Naam",
    giveName: "Voer een naam in",
    floor: "Verdieping",
    youSure: "Weet je het zeker?",
    unknown: "Onbekend",
    noData: "Geen data beschikbaar",
    tryAgain: "Probeer opnieuw",
    remark: "Opmerkingen",
    resetFilter: "Verwijder alle filters",
    general: "Algemeen",
    optional: "optioneel",
    view: "Bekijken",
    priority: "Prioriteit",
    tasks: "Periodieke taken",
  },
  counts: {
    room_one: "{{count}} Kamer",
    room_other: "{{count}} Kamers",
  },
  user: {
    sessionExpired: "Sessie verlopen, log opnieuw in.",
    self: "Gebruiker",
    role: "Rol",
    switch: "Wissel {{value}}",
  },
  layout: {
    refresh: "Vernieuwen",
    refreshMessage: 'Druk op "Vernieuwen" voor de meest actuele data.',
    fold: "Inklappen",
    menu: {
      [NavLabels.Dashboard]: "Dashboard",
      [NavLabels.Overview]: "Kamers",
      [NavLabels.AppLogin]: "Inloggen",
      [NavLabels.DayReports]: "Weekoverzicht",
      [NavLabels.TdOverview]: "Technische Dienst",
      [NavLabels.GsOverview]: "Algemene Dienst",
      [NavLabels.TeamSettings]: "Team beheren",
      [NavLabels.AreaSettings]: "Secties beheren",
      [NavLabels.CustomRoomSettings]: "Ruimtes beheren",
      [NavLabels.TaskSettings]: "Taken beheren",
      [NavLabels.DayReportSettings]: "Dagafsluiting beheren",
      [NavLabels.ActionsLog]: "Kamer Acties",
      [SubNavLabels.Settings]: "Instellingen",
      [SubNavLabels.None]: "Geen",
      [NavLabels.DayOverview]: "Dagoverzicht",
      [NavLabels.ExtraTasks]: "Extra Taken",
      [NavLabels.TypeGroups]: "Tarieftype groepen",
    },
  },
  dayreport: {
    week: "Week",
    editWarning: "Er zijn handmatig wijzigingen toegepast",
    send: {
      title: "Weet je het zeker dat je het weekoverzicht wilt versturen?",
      description: "Dit weekoverzicht wordt verstuurd naar:",
      button: "Ja, verstuur deze week",
      self: "Versturen",
      export: "Exporteer weekoverzicht",
    },
    manage: "Dagafsluiting beheren",
    endOfDay: "Dagafsluiting",
    weekOverview: "Weekoverzicht",
    extra: "Extra Taken",
    deletedExtra:
      "Deze extra is verwijderd. Je kan de waarde nog wel inzien maar niet meer wijzigen.",
    addRemark: "Opmerking toevoegen",
    writeRemark: "Schrijf hier uw opmerking",
    noRemarks: "Er zijn nog geen opmerkingen toegevoegd.",
    noChanges: "Er zijn geen wijzigingen gedaan.",
    dayView: "Dagoverzicht",
    saveAndApprove: "Opslaan & Akkoord",
    approve: "Akkoord",
    approval: "Goedkeuring",
    unsavedChanges: "De aangepaste cijfers zijn nog niet opgeslagen.",
    difference: "Schoonmaakverschil",
    popover: {
      green: "Green choice <strong>{{number}}</strong>",
      orange: "Orange choice <strong>{{number}}</strong>",
      nonGreenSkip: "Geen schoonmaak <strong>{{number}}</strong>",
      doNotDisturb: "Niet storen <strong>{{number}}</strong>",
    },
    sold: {
      title: "Verkocht",
      description:
        "alle kamers die we vanuit PMS doorkrijgen als verkocht dit wordt elke nacht om 03:00 opgehaald van de vorige dag.",
    },
    carriedOverFromYesterday: {
      title: "Doorgezet (van gisteren)",
      description:
        "alle kamers die vanuit gisteren nog niet schoongemaakt zijn.",
    },
    dayuse: {
      title: "Daggebruik",
      description:
        "alle kamers die we vanuit PMS doorkrijgen als dayuse dit wordt elke nacht om 03:00 opgehaald van de vorige dag.",
    },
    carriedOver: {
      title: "Doorschuiven (vandaag)",
      description:
        "kamers die nog vuil zijn maar gisteren (en dus niet vandaag) al vertrokken zijn.",
    },
    totalDescription:
      "de verkochte, daggebruik en doorgeschoven kamers opgeteld.",
    green: {
      title: "Green choice",
      description:
        "kamers waarbij de gast (via de mail) of receptie (via ServiceV4) heeft aangegeven dat er geen schoonmaak nodig is (green choice).",
    },
    orange: {
      title: "Orange choice",
      description:
        "kamers waarbij de gast (via de mail) of receptie (via ServiceV4) heeft aangegeven dat er enkel handdoeken of tasje (orange choice) benodigd is + de kamers die via de HKTD app op ‘tasje opgehangen’ zijn gezet.",
    },
    nonGreenSkip: {
      title: "Geen schoonmaak",
      description:
        "Kamers waarbij de gast zonder green choice ervoor heeft gekozen de schoonmaak over te slaan.",
    },
    doNotDisturb: {
      title: "Niet storen",
      description: "de kamers die via de HKTD app op ‘niet storen’ zijn gezet.",
    },
    cleaned: {
      title: "Schoon te maken",
      description:
        "dit zijn alle verkochte, daggebruik en doorgeschoven kamers opgeteld waar de uitzonderingen (green, orange, niet storen) vanaf zijn gehaald.",
    },
    totalCleaned: {
      title: "Totaal schoongemaakt",
      description:
        "dit zijn de kamers die via de HKTD app op schoon zijn gezet.",
    },
    note: {
      title: "Let op",
      description:
        "bepaalde cijfers kunnen handmatig aangepast worden door de HKS of FOM, de aangepaste cijfers worden dan meegenomen in de berekening.",
    },
    calculate: "Hoe wordt dit berekend",
    noData:
      "Nog geen gegevens beschikbaar. Deze gegevens worden op een later moment opgehaald.",
    noShow: "No show",
    clean: "Schoongemaakt",
    exceptions: "Uitzonderingen",
    oldValue: "Oude waarde",
    new_Value: "Nieuwe waarde",
    whoMay: {
      send: "Wie mag het weekoverzicht versturen?",
      edit: "Wie mag cijfers aanpassen?",
      create: "Wie mag extra uren schrijven?",
      receive: "Naar welke e-mailadressen wordt het weekoverzicht verstuurd?",
    },
    setupEmail: "E-mailadressen instellen",
    enableEmail:
      "Om optimaal gebruik te kunnen maken van het weekoverzicht (en de dagafsluiting) moet deze module worden aangezet.",
    noEmail:
      "Er is geen e-mail toegevoegd, het weekoverzicht kan niet verstuurd worden.",
    rateTypeGroup: "Tarieftype groepen wijzigen",
    editRateType: "Klik hier om de groepen te wijzigen of in te zien",
    settings: {
      reportPermission: {
        fom: "Front office",
        hk: "Housekeeping Supervisor",
        gs: "Algemene Dienst Supervisor",
        fomHk: "Front office & Housekeeping Supervisor",
        fomGs: "Front office & Algemene Dienst Supervisor",
        hkGs: "Housekeeping & Algemene Dienst Supervisor",
        fomHkGs: "Front office, Housekeeping & Algemene Dienst Supervisor",
      },
    },
  },
  rateType: {
    create: "Nieuwe tarieftype groep",
    update: "Wijzig tarieftype groep",
    delete: "Verwijder groep",
    groupName: "Groepsnaam",
    groupDescription: "Voer een groepsnaam in",
    defaultGroup:
      "Het is niet mogelijk om deze groep te verwijderen. Je kunt de kamertypes wel onder een andere groep indelen",
    roomType: "Kamertypes",
    belongsTo: "Behoort tot groep",
    deleteDescription:
      "De verwijderde kamertypes worden teruggezet onder de groep 'Kamers' of 'Suites'.",
    deleteConfirmation:
      "Je staat op het punt om de groep {{name}} te verwijderen. \n Deze actie kan niet ongedaan gemaakt worden.",
    groups: "Tarieftype groepen",
    group: "Groep",
    groupedRatesInfo: "Aanpassingen gaan pas in vanaf volgende week maandag.",
    countTypes: "Aantal toegevoegde kamertypes",
  },
  hkStatistics: {
    tableContent: {
      arrivals: "Aankomsten",
      atStart: "Begin",
      carriedOver: "Doorgezet",
      carriedOverFromYesterday: "Gisteren doorgezet",
      currently: "Huidig",
      departures: "Vertrekkers",
      dirtyVacant: "Alle vrij/vuile kamers",
      notAvailable: "Statistieken zijn (nog) niet beschikbaar",
      roomsCleaned: "Kamers schoongemaakt",
      stayovers: "Blijvers",
      rateTypesCleaned: "Suites schoongemaakt",
    },
    emptyMessage: "De statistieken konden helaas niet worden opgehaald.",
    atStart: "Begin",
    currently: "Huidig",
  },
  pmsNotification: {
    content: {
      bad: "De kamer statussen zijn niet up-to-date door een connectie probleem met het PMS.",
      poor: "De kamer statussen zijn mogelijk niet up-to-date door een connectie probleem met het PMS.",
    },
    heading: {
      bad: "PMS langere tijd niet bereikbaar",
      poor: "PMS niet bereikbaar",
    },
    specific: {
      amadeus:
        "Vraag aan de receptie om een supportcase aan te maken bij Amadeus (hospitality.support@amadeus.com) met als onderwerp: 'Hybrid Cloud Service is down for Hotel {{propertyName}}'",
    },
  },
  actions: {
    export: {
      failed: "{{value}} kon niet geëxporteerd worden.",
    },
    assign: {
      failed: "{{value}} is niet toegewezen.",
      success: "{{value}} is succesvol toegewezen.",
    },
    unassign: {
      failed: "{{value}} niet toewijzen is mislukt.",
      success: "{{value}} zijn niet meer toegewezen.",
      success_plural: "{{value}} is niet meer toegewezen.",
    },
    cleaning: {
      start: {
        failed: "Beginnen met schoonmaken niet gelukt",
        success: "Kamer wordt schoongemaakt",
      },
      stop: {
        failed: "Stoppen met schoonmaken is niet gelukt",
        success: "Kamer is succesvol van schoon gezet",
      },
      finish: {
        failed: "Kan kamer niet op schoon zetten",
        success: "Kamer is succesvol schoongemaakt",
      },
    },
    inspectionFinish: {
      failed: "Kamer inspecteren faalt",
      success: "Kamer is succesvol geïnspecteerd",
    },
    issueUpdate: {
      failed: "Taak status updaten is mislukt",
      success: "Status van de taak is geüpdatet",
    },
    add: {
      failed: "{{value}} niet toegevoegd.",
      userFailed:
        "Let op: het is niet mogelijk om hetzelfde e-mailadres voor meerdere gebruikers / hotels te gebruiken.",
      success: "{{value}} is succesvol toegevoegd",
      success_plural: "{{value}} is succesvol toegevoegd",
    },
    update: {
      failed: "{{value}} niet geüpdatet.",
      success: "{{value}} is succesvol geüpdatet",
      success_plural: "{{value}} zijn succesvol geüpdatet",
    },
    delete: {
      failed: "{{value}} niet verwijderd.",
      success: "{{value}} is succesvol verwijderd",
      success_plural: "{{value}} zijn succesvol verwijderd",
    },
    approved: {
      failed: "{{value}} kon niet goedgekeurd worden.",
      success: "{{}} is goedgekeurd.",
    },
    send: {
      failed: "{{value}} kon niet verzonden worden.",
      success: "{{value} is succesvol verstuurd.",
    },
    refresh: {
      failed: "{{value}} kon niet vernieuwd worden!",
      success: "{{value}} gegevens vernieuwd!",
    },
    switch: {
      failed: "{{value}} niet gewisseld.",
      languageFailed: "Kon notificatie taal niet wisselen.",
      success: "{{value}} gewisseld!",
    },
    qrCode: {
      failed: "Het exporteren van de QR-codes is mislukt.",
      success: "De QR-codes zijn succesvol gedownload.",
    },
    default: {
      failed: "Mislukt!",
      success: "{{value}} is succesvol geüpdatet",
    },
    fetch: {
      failed:
        "De informatie kan momenteel niet opgehaald worden.\n  Controleer je internet en probeer het opnieuw.\n\n Mocht het probleem aanhouden, probeer dan opnieuw in te loggen.",
    },
  },
  hk: {
    roomState: {
      [RoomStates.OUT_OF_INVENTORY]: "Uit voorraad",
      [RoomStates.OUT_OF_ORDER]: "Buiten gebruik",
      cleanTowels: "handoeken opgehangen",
      dirtyTowels: "handoeken ophangen",
      dirtyAndInspected: "vuil & geïnspecteerd",
      cleanAndInspected: "schoon & geïnspecteerd",
      clean: "schoon",
      dirty: "vuil",
      extra: {
        vacantToday: "sinds vandaag vrij",
        leaving: "vertrekker",
        occupiedToday: "bezet sinds vandaag",
        staying: "blijver | {{current}}/{{total}}",
        vacant_zero: "vrij sinds vandaag",
        vacant_one: "vrij | {{count}} dag",
        vacant_other: "vrij | {{count}} dagen",
        orange: "orange choice",
        green: "geen schoonmaak (green choice)",
        nonGreenSkip: "geen schoonmaak",
        doNotDisturb: "niet storen",
      },
    },
    description: "Beschrijving: {{description}}",
    leavers: "Vertrekkers",
    stayers: "Blijvers",
    amountExtra: "Extra's ({{amount}})",
    filters: {
      roomState: "Kamerstatus (+extra's)",
      arrivals: "Aankomsten (+ extras)",
      cleaningState: "Schoonmaak status",
      tasks: "Taken",
      user: "Pers.",
      roomsWithExtra: "Kamers met extra's",
      roomsWithTasks: "Kamers met taken",
      occupiedToday: "Bezet sinds vandaag",
      rooms: {
        dirty: "Vuile kamers",
        clean: "Schone kamers",
      },
    },
    selectedRooms: "Geselecteerde kamers: {{count}}",
    assignRoom: "Kamers toewijzen",
    showQrCode: "Zet uw muis in dit vlak voor de QR-code",
  },
  search: {
    title: "Zoeken",
    rooms: "Zoek een kamer",
    location: "Zoek een ruimte",
    noResults: "Geen {{object}} gevonden.",
    name: "Zoek op naam",
    employee: "Zoek een medewerker",
    task: "Zoek een taak",
    floor: "Selecteer een verdieping",
    role: "Selecteer een rol",
    function: "Selecteer een functie",
    taskPersonRoom: "Zoek een taak, persoon of ruimte",
    roomOrHouseKeeper: "Zoek op kamer of housekeeper",
    notFound:
      "Er zijn helaas geen {{search}} gevonden voor de huidige zoekopdacht en/of filters.",
    rateType: "Zoek een kamertype",
  },
  tasks: {
    delete: {
      title: "Verwijder {{value}}",
      description:
        "Weet je zeker dat je '{{value}}' wilt verwijderen? Het verwijderen kan niet ongedaan gemaakt worden. Verwijderen is vanaf de eerstvolgende werkdag van kracht.",
    },
    number: "Taak {{task}}",
    add: "+ voeg nog een taak toe",
    assign: "Wijs toe",
    assignTask: "Wijs taak toe",
    assignTasks: "Taken toewijzen",
    unassignTasks: "Taken verwijderen",
    unassignUser: "Medewerkers verwijderen",
    all: "Alle periodieke taken",
    empty: "Er zijn nog geen periodieke taken aangemaakt.",
    labelPlaceholder: "Schoonmaken",
    edit: "Wijzig taak",
    remove: "Verwijder taak",
  },
  actionLog: {
    actions: {
      space: {
        clean: {
          start: "schoonmaak gestart",
          stop: "schoonmaak gestopt",
          finish: "schoonmaak afgerond",
          skip: "schoonmaak overgeslagen",
        },
        assign: {
          add: "kamer toegewezen",
          remove: "kamer verwijderd",
        },
        inspect: {
          finish: "inspectie afgerond",
        },
      },
      issue: {
        work: {
          start: "taak gestart",
          stop: "taak gestopt",
          finish: "taak opgelost",
          wait: "taak in bestelling",
        },
        report: {
          create: "nieuwe taak aangemeld",
          attach: "foto toegevoegd",
        },
        assign: {
          add: "taak toegewezen aan service medewerker",
          remove: "taak verwijderd van service medewerker",
        },
      },
    },
  },
  area: {
    name: "Sectienaam",
    numberOfRooms: "Aantal kamers",
    assignedRooms: "Toegewezen kamers",
    noRoomsAssigned: "Nog geen kamers toegewezen",
    emptyMessage:
      "Er zijn nog geen secties aangemaakt. Druk op de knop hierboven om een sectie aan te maken.",
    delete: {
      action: "Verwijderen uit de sectie",
      description:
        "Je staat op het punt om toegewezen kamers uit een sectie te verwijderen.\nDeze actie kan niet ongedaan gemaakt worden.",
      area: "Je staat op het punt om de sectie '{{item}}' te verwijderen. \nDeze actie kan niet ongedaan gemaakt worden.",
    },
    assignSection_zero: "Toewijzen aan sectie",
    assignSection: "Toewijzen aan sectie ({{count}})",
    toAssign: "Wijs een sectie toe",
    select: "Selecteer een sectie",
    count: "Aantal kamers: {{count}}",
    remove: "Sectie verwijderen",
    update: "Sectie wijzigen",
    add: "Sectie toevoegen",
    charLeft_other: "{{count}} karakters over",
    charLeft_one: "{{count}} karakter over",
    charLeft_zero: "Geen karakters over",
  },
  customroom: {
    create: {
      title: "Openbare ruimte aanmaken",
      submit: "Ruimte aanmaken",
      add: "Ruimte toevoegen",
      nameRequired: "De openbare ruimte moet een naam hebben",
      groupRequired: "Groepsnaam is verplicht",
      nameInUse: "Deze naam is al in gebruik.",
    },
    changeTitle: "Kamer aanpassen",
    floor: "Verdieping {{floor}}",
    tooltip: "Tip: een korte titel werkt het beste!",
  },
  extra: {
    self: "Extra's",
    description: "Beschrijving van extra",
    hourAndTask: "Extra taken & uren",
    newTask: "Nieuwe extra",
    typeName: "Type naam",
    noExtra: "Er zijn nog geen extra's aangemaakt.",
    newTaskAction: "Klik hier om de taken te wijzigen of in te zien",
  },
  login: {
    self: "E-mailadres",
    password: "Wachtwoord",
    alreadyadded: "Het e-mailadres {{email}} is al toegevoegd.",
    invalid: "Het e-mailadres {{email}} is ongeldig.",
    enterNew: "Voer een e-mailadres in",
    loginError: "Verkeerd email adres of wachtwoord",
    forgotPassword: "Wachtwoord vergeten?",
  },
  issue: {
    typeTaskName: "Voer een taaknaam in",
    typeReporterName: "Voer een naam in",
    createdBy: "Aangemaakt door",
    reportedBy: "Geconstateerd door",
    createdAt: "Aangemaakt op",
    addRemark: "Voer een opmerking voor de taak in",
    priority: "Deze taak heeft prioriteit",
    change: "Wijzig taak",
    create: "Taak aanmaken",
    addNewTask: "Nieuwe taak aanmaken",
    newTask: "Nieuwe taak",
    states: {
      title: "Statussen",
      [IssueState.OPEN]: "Open",
      [IssueState.IN_PROGRESS]: "Actief",
      [IssueState.ON_HOLD]: "Inactief",
      [IssueState.ORDERED]: "In bestelling",
      [IssueState.DONE]: "Voltooid",
      all: "Alle",
    },
    createDate: "Aanmaakdatum",
    taskName: "Taaknaam",
    status: "Status",
    assignee: "Toegewezen",
    changeLog: "Wijzigingslog",
    changeDetails: "Details wijzigen",
    delete: {
      title: "Taak verwijderen",
      action: "Verwijder taak",
      description: "Je staat op het punt om de taak {{label}} te verwijderen.",
    },
    inactive: {
      title: "Inactief stellen",
      action: "Waarom moet deze taak inactief gesteld worden?",
      placeholder: "Voer hier een reden in",
      label: "Reden",
    },
    edit: "Taak wijzigen",
    view: "Taak detail",
    addImage: "Nog een foto toevoegen",
    export: "Exporteer takenlijst",
    newIssueModal: {
      createdIssuesTitle: "Andere taken op deze locatie",
      columns: {
        date: "Datum",
        task: "Taak",
        status: "Status",
      },
    },
  },
  segment: {
    Groepen: "Groepen",
    ["Alle kamertypes"]: "Alle kamertypes",
    all: "Alle",
  },
  team: {
    addMember: "Teamlid toevoegen",
    detail: "Details",
    removeMember: "Teamlid verwijderen",
    inputName: "Naam van het teamlid",
    inputEmail: "E-mailadres van het teamlid",
    inputEmailInfo:
      "Bij het aanmaken van een nieuw account wordt er een mail verstuurd naar het ingevuld e-mailadres",
    deleteConfirmation:
      "Je staat op het punt om {{value}} te verwijderen. \nDeze actie kan niet ongedaan gemaakt worden.",
  },
  roleNames: {
    [UserRoles.ADMIN]: "Admin",
    [UserRoles.HOTEL_MANAGER]: "Front office",
    [UserRoles.HK_MANAGER]: "Supervisor",
    [UserRoles.HK_MEMBER]: "Housekeeper",
    [UserRoles.TD_MANAGER]: "TD manager",
    [UserRoles.TD_MEMBER]: "TD medewerker",
    [UserRoles.TD_CREATOR]: "TD beheerder",
    [UserRoles.GS_MANAGER]: "AD manager",
    [UserRoles.GS_MEMBER]: "AD medewerker",
    [UserRoles.NONE]: "Geen",
  },
};
