import { useEffect } from "react";
import { fetchProperty } from "@store/property/propertyThunk";
import { useSelector } from "react-redux";
import { selectCurrentProperty } from "@/store/property/propertySelector";
import l from "@/locale";
import useAppDispatch from "@hooks/useAppDispatch";

const POLL_INTERVAL_SECONDS = 30;

export type PmsSyncStateNotification = {
  type: "warning" | "negative";
  heading: string;
  content: string;
};

const usePmsSyncStateNotification = ():
  | PmsSyncStateNotification
  | undefined => {
  const dispatch = useAppDispatch();
  const currentProperty = useSelector(selectCurrentProperty);

  useEffect(() => {
    if (!currentProperty) return;
    const intervalCallback = () => {
      dispatch(fetchProperty(currentProperty.id));
    };
    const interval = setInterval(
      intervalCallback,
      POLL_INTERVAL_SECONDS * 1000,
    );

    return () => clearInterval(interval);
  }, [currentProperty]);

  if (!currentProperty) return;
  const { pmsProvider, pmsSyncState } = currentProperty;
  const showNotification = pmsSyncState !== "good";

  if (!showNotification) return;

  let content = l.t(`pmsNotification.content.${pmsSyncState}`);
  if (pmsProvider == "amadeus")
    content += " " + l.t("pmsNotification.specific.amadeus");

  return {
    type: pmsSyncState === "bad" ? "negative" : "warning",
    heading: l.t(`pmsNotification.heading.${pmsSyncState}`),
    content: content,
  };
};

export default usePmsSyncStateNotification;
