import { AppThunk } from "..";
import services from "@services/api";
import { showNotification } from "../ui/uiThunk";
import { setTransactionMeta, setTransactions } from "./transactionSlice";
import l from "@/locale";

export const fetchTransactionsByPage =
  (page: number, spaceLabel?: string): AppThunk =>
  async (dispatch) => {
    try {
      const { data, meta } = await services.Transactions.getByPage({
        page,
        spaceLabel,
      });

      dispatch(setTransactions(data));
      dispatch(setTransactionMeta(meta));
    } catch (error) {
      dispatch(
        showNotification({
          type: "error",
          message: l.t("actions.refresh.failed", {
            value: l.t("common.transactions").toLowerCase(),
          }),
          noOverride: true,
        }),
      );
    }
  };
