import React from "react";
import { css } from "@emotion/react";
import { Textarea } from "baseui/textarea";

export interface TextareaInputProps {
  onChangeText: (value: string) => void;
  value: string;
  placeholder: string;
  containerStyle?: React.CSSProperties;
  useFullHeight?: boolean;
  label?: string;
  disabled?: boolean;
}

const TextareaInput: React.FC<TextareaInputProps> = (props) => {
  return (
    <div css={props.label && styles.wrapper}>
      {props.label && (
        <label
          css={{
            paddingTop: props.containerStyle?.paddingTop,
            marginBottom: "2px",
          }}>
          {props.label}
        </label>
      )}
      <Textarea
        disabled={props.disabled}
        onChange={(event) => props.onChangeText(event.currentTarget.value)}
        value={props.value}
        placeholder={props.placeholder}
        overrides={{
          InputContainer: {
            style: () => ({
              backgroundColor: "#fff",
              border: "none",
              outline: "none",
              boxShadow: "none",
              display: props?.containerStyle?.display,
              flexDirection: props?.containerStyle?.flexDirection,
            }),
          },
          Input: {
            style: () => ({
              height: "160px",
              padding: "10px",
            }),
          },
        }}
      />
    </div>
  );
};

const styles = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
  }),
};

export default TextareaInput;
