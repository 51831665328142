import React, { useState } from "react";
import RoomRow from "@components/RoomRow";
import theme from "@styles/theme";
import { RoomSpace } from "@store/space/types";
import { useTranslation } from "react-i18next";

interface ActiveTaskRoomsProps {
  spaces: RoomSpace[];
  removeSpace: (id: RoomSpace["id"]) => void;
  showRooms?: boolean;
}

const ActiveTaskRooms: React.FC<ActiveTaskRoomsProps> = ({
  spaces = [],
  removeSpace,
  showRooms = false,
}) => {
  const { t } = useTranslation();
  const count = spaces.length;
  const [setRoomToggles, setsetRoomToggles] = useState(showRooms);
  return (
    <div className="container">
      <div className="row">
        <h5>Aantal kamers ({count})</h5>
        <img
          onClick={() => {
            setsetRoomToggles(!setRoomToggles);
          }}
          src={`/icons/chevron-${setRoomToggles ? "up" : "down"}.svg`}
        />
      </div>

      {setRoomToggles && (
        <ul>
          {spaces.map((space) => (
            <RoomRow
              key={space.id}
              selectedRooms={space}
              toggleSpace={removeSpace}
            />
          ))}
        </ul>
      )}
      {setRoomToggles && (
        <div className="row">
          <p>{t("common.total")}</p>
          <h5 className="rooms">{t("counts.room", { count })}</h5>
        </div>
      )}
      <style jsx>
        {`
          .container {
            width: 100%;
          }
          ul {
            padding-inline-start: 0px;
            max-height: 240px;
            width: 100%;
            margin-bottom: 0px;
            overflow-y: scroll;
          }
          h5 {
            text-align: center;
            color: ${theme.colors.dark};
            margin: 0px;
          }
          .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
          p {
            margin-left: 8px;
          }
          .rooms {
            margin-right: 30px;
          }
          img {
            margin-top: 5px;
          }
        `}
      </style>
    </div>
  );
};

export default ActiveTaskRooms;
