import { BreadcrumbsOverrides } from "baseui/breadcrumbs";
import theme from "@styles/theme";
import { Theme } from "baseui";

export const breadcrumbOverrides: BreadcrumbsOverrides = {
  Root: {
    style: ({ $theme }) => ({
      backgroundColor: theme.colors.primary400,
      padding: $theme.sizing.scale1200,
    }),
  },
  ListItem: {
    style: ({ $theme }) => ({
      color: $theme.colors.white,
    }),
  },
  Icon: {
    props: {
      overrides: {
        Svg: {
          style: ({ $theme }: { $theme: Theme }) => ({
            color: $theme.colors.white,
            width: "30px",
            height: "24px",
          }),
        },
      },
    },
  },
};
