import React, { useEffect } from "react";
import RoomsTable from "./components/RoomsTable";
import AssignTaskForm from "./components/AssignTaskForm";
import AssignOptions from "./components/AssignOptions";
import FilterBar from "./components/FilterBar";
import { selectPopup } from "@store/ui/uiSelectors";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import { fetchTasks } from "@store/task/taskThunk";
import { fetchSpaces } from "@store/space/spaceThunk";
import { fetchUsers } from "@store/user/userThunk";
import { resetRoomToggles } from "@store/ui/uiSlice";
import Breadcrumb from "@components/base/breadcrumb";
import { NavLabels } from "@components/Layout";
import { selectCurrentRole } from "@store/user/userSelectors";
import { isSupervisor } from "@helpers";

interface OverviewScreenProps {}

const OverviewScreen: React.FC<OverviewScreenProps> = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectCurrentRole);

  const popUp = useAppSelector(selectPopup);
  useEffect(() => {
    dispatch(fetchSpaces());
    dispatch(fetchUsers());
    if (isSupervisor(role)) dispatch(fetchTasks());

    return () => {
      dispatch(resetRoomToggles());
    };
  }, [dispatch, role]);

  return (
    <>
      <Breadcrumb labels={[NavLabels.Overview]} />
      <section>
        <FilterBar />
        <RoomsTable />
        {popUp?.type === "overview/assignTask" && <AssignTaskForm />}
        {popUp?.type === "overview/assignOptions" && <AssignOptions />}
        <style jsx>{`
          section {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 32px;
            overflow: scroll;
          }

          label {
            margin-right: 10px;
          }

          .active-tasks {
            display: flex;
            flex-direction: column;
            width: 240px;
          }
        `}</style>
      </section>
    </>
  );
};

export default OverviewScreen;
