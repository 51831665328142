import React, { ComponentProps } from "react";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";

interface PopOverProps {
  content: React.ReactNode;
  actionElement: React.ReactNode;
  placement: ComponentProps<typeof StatefulPopover>["placement"];
}

const PopOver: React.FC<PopOverProps> = ({
  content,
  actionElement,
  placement,
}) => {
  return (
    <StatefulPopover
      content={content}
      autoFocus
      triggerType={TRIGGER_TYPE.hover}
      placement={placement}>
      {actionElement}
    </StatefulPopover>
  );
};

export default PopOver;
