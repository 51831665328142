import React from "react";
import AssignHkMember from "./AssignHkMember";
import { ChevronDown } from "baseui/icon";
import theme from "@styles/theme";
import { selectRoomToggles } from "@store/ui/uiSelectors";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { showPopUp } from "@store/ui/uiSlice";
import Button from "@components/base/button/index";
import { overrideButtonStyles } from "@components/base/button/overrides";
import { t } from "i18next";

interface ActionButtonProps {}

const ActionButtons: React.FC<ActionButtonProps> = () => {
  const dispatch = useAppDispatch();
  const selectedRoomIds = Object.keys(useAppSelector(selectRoomToggles));
  const active = selectedRoomIds.length > 0;

  const onAssignOptionsClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (!active) return;

    dispatch(
      showPopUp({
        type: "overview/assignOptions",
        pos: { x: event.clientX, y: event.clientY },
      }),
    );
  };

  return (
    <>
      <h5 data-test-id="selected-rooms">
        {t("hk.selectedRooms", { count: selectedRoomIds.length })}
      </h5>
      <div style={{ display: "flex", marginLeft: theme.sizing.scale400 }}>
        <AssignHkMember spaceIds={selectedRoomIds}>
          <Button disabled={!active}>{t("hk.assignRoom")}</Button>
        </AssignHkMember>
        <Button
          onClick={onAssignOptionsClick}
          disabled={!active}
          overrides={overrideButtonStyles({
            BaseButton: {
              paddingLeft: theme.sizing.scale200,
              paddingRight: theme.sizing.scale200,
              paddingBottom: 0,
              paddingTop: 0,
            },
          })}>
          <ChevronDown size={32} />
        </Button>
      </div>
    </>
  );
};

export default ActionButtons;
