import { FC } from "react";
import theme from "@styles/theme";
import { Notification as NotificationProps } from "@/store/ui/types";

const Notification: FC<NotificationProps> = ({
  message,
  title,
  type = "success",
}) => {
  return (
    <div>
      <h4>{title}</h4>
      <p>{message}</p>
      <style jsx>{`
        div {
          position: absolute;
          top: 66px;
          right: 30px;
          background: ${type === "success"
            ? theme.colors.positive300
            : theme.colors.negative300};
          border-left: 4px solid
            ${type === "success"
              ? theme.colors.positive100
              : theme.colors.positive100};
          border-radius: 4px;
          padding: 10px 20px;
          width: 360px;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
        }
        h4 {
          font-family: Source Sans Pro;
          font-weight: 600;
          font-size: 16px;
          color: ${type === "success"
            ? theme.colors.positive100
            : theme.colors.positive100};
        }
        p {
          font-family: Source Sans Pro;
          font-style: normal;
          font-size: 16px;
          line-height: 25px;
          color: ${type === "success"
            ? theme.colors.positive100
            : theme.colors.positive100};
        }
        strong {
          font-weight: 600;
        }
      `}</style>
    </div>
  );
};

export default Notification;
