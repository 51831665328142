import React from "react";
import { format } from "date-fns";
import { useEffect } from "react";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { Statistics } from "@store/dayReport/types";
import { selectCurrentProperty } from "@store/property/propertySelector";
import useAppSelector from "@hooks/useAppSelector";
import { selectCurrentDayReport } from "@store/dayReport/dayReportSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import {
  capitalizeFirstLetter,
  getLanguage,
  isSupervisor,
  isTechnicalManager,
} from "@helpers";
import { fetchDayReport } from "@store/dayReport/dayReportThunk";
import Table from "@components/base/table";
import Breadcrumb from "@components/base/breadcrumb";
import { useTranslation } from "react-i18next";
import { NavLabels } from "@components/Layout";
import { selectCurrentRole } from "@store/user/userSelectors";
import { useNavigate } from "react-router-dom";

const relevantStats: Array<keyof Statistics> = [
  "departures",
  "carriedOver",
  "carriedOverFromYesterday",
  "dirtyVacant",
  "stayovers",
  "arrivals",
];

const StatsScreen: React.FC = () => {
  const { t } = useTranslation();
  const currentProperty = useAppSelector(selectCurrentProperty);
  const dayReport = useAppSelector(selectCurrentDayReport);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectCurrentRole);
  const generateRows = () => {
    const stats = relevantStats.map((key) => {
      return [
        t(`hkStatistics.tableContent.${key}`),
        // as is not preferred, but the type can't be else it's added here
        (dayReport?.statistics?.atStart?.[key] || 0) as string | number,
        (dayReport?.statistics?.current?.[key] || 0) as string | number,
      ];
    });

    const { rateTypesCleaned } = dayReport?.statistics?.current ?? {};
    const rateTypeStats = rateTypesCleaned
      ? Object.entries(rateTypesCleaned).map(([key, value]) => {
          return [capitalizeFirstLetter(key), "-", value];
        })
      : [];

    return [...stats, ...rateTypeStats];
  };
  useEffect(() => {
    if (currentProperty?.lastDayReport && isSupervisor(role))
      dispatch(fetchDayReport(currentProperty.lastDayReport.id));
  }, [currentProperty, role]);

  useEffect(() => {
    if (!isSupervisor(role)) {
      navigate(isTechnicalManager(role) ? "/td-overview" : "/gs-overview");
    }
  }, [role]);

  const data = generateRows();

  return (
    <>
      <Breadcrumb labels={[NavLabels.Dashboard]} />
      <section css={styles.section}>
        <Table
          columns={[
            format(
              new Date(!!dayReport ? dayReport.date : new Date()),
              "iiii d MMMM",
              {
                locale: getLanguage(),
              },
            ),
            t("hkStatistics.atStart"),
            t("hkStatistics.currently"),
          ]}
          data={data}
          emptyMessage={t("hkStatistics.emptyMessage")}
          overrides={{
            Table: {
              props: {
                "data-test-id": "dashboard-report-table",
              },
            },
          }}
        />
      </section>
    </>
  );
};

const styles = {
  section: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: theme.sizing.scale1600,
  }),
};

export default StatsScreen;
