import React, { useState } from "react";
import PopUpWrapper from "@components/PopUpWrapper";
import { Task } from "../../../types/common";
import { CreateTaskParams } from "@store/task/types";
import Button from "@components/base/button";
import Input from "@components/base/input";
import { hidePopUp } from "@store/ui/uiSlice";
import { createTask } from "@store/task/taskThunk";
import { updateTask } from "@store/task/taskThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import { useTranslation } from "react-i18next";

interface NewTaskFormProps {
  initialValues?: Task;
}

const NewTaskForm: React.FC<NewTaskFormProps> = ({ initialValues }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [task, setTask] = useState<CreateTaskParams | Task>(
    initialValues
      ? initialValues
      : { label: "", description: "", reportedBy: "" },
  );

  const hasChanges = initialValues?.label !== task.label;

  const onInputChange = (text: string) => {
    setTask({
      ...task,
      label: text,
    });
  };

  const onSubmit = () => {
    if (initialValues === undefined) {
      dispatch(createTask(task));
    } else {
      dispatch(updateTask(initialValues.id, task));
    }
    dispatch(hidePopUp());
  };

  return (
    <PopUpWrapper>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}>
        <Input
          autoFocus
          label={t("common.issue")}
          value={task.label}
          onChangeText={(text) => onInputChange(text)}
          placeholder={t("tasks.labelPlaceholder")}
          containerStyle={{ marginBottom: "20px" }}
        />
        <Button
          css={{ width: "fit-content", alignSelf: "flex-end" }}
          onClick={onSubmit}
          disabled={!task.label || !hasChanges}>
          {initialValues ? t("common.save") : t("common.add")}
        </Button>
      </div>
    </PopUpWrapper>
  );
};

export default NewTaskForm;
