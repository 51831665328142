import { FC, useEffect } from "react";
import theme from "@styles/theme";
import { QRCodeCanvas } from "qrcode.react";
import { User, UserRoles } from "@store/user/types";
import { useTranslation } from "react-i18next";

interface QrListProps {
  teamMember: User;
  getQrRefs: (isLast: boolean) => void;
  isLast: boolean;
}

const QrList: FC<QrListProps> = ({
  teamMember: {
    profile: { initials, name },
    id,
  },
  getQrRefs,
  isLast,
}: QrListProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    getQrRefs(isLast);
  }, []);

  return (
    <li data-test-id="worker-qr">
      <div className="initials">
        <span>{initials}</span>
      </div>
      <div className="name">{name}</div>
      <div className="code">{t(`roleNames.${UserRoles.HK_MEMBER}`)}</div>
      <div className="box-qr">
        <span className="instructions">{t("hk.showQrCode")}</span>
        <div className="qr">
          <QRCodeCanvas id={`${id}`} size={225} value={`${id}`} />
        </div>
      </div>
      <style jsx>{`
        li {
          width: 225px;
          padding: 20px;
          background-color: #ffffff;
          text-align: center;
          border-radius: 4px;
          box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.2);
          margin: 30px 10px 0 10px;
        }
        .initials {
          width: 68px;
          height: 68px;
          border-radius: 68px;
          background-color: ${theme.colors.primary};
          text-align: center;
          margin: 0 auto;
        }
        .initials > span {
          font-weight: 800;
          font-size: 32px;
          color: white;
          line-height: 68px;
        }
        .name {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 30px;
        }
        .code {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 26px;
        }
        .box-qr {
          width: 225px;
          height: 225px;
          line-height: 225px;
          margin-top: 10px;
          background-color: #edebe8;
        }
        .box-qr .instructions {
          display: inline-block;
          vertical-align: middle;
          line-height: 18px;
        }
        .box-qr .qr,
        .box-qr:hover .instructions {
          display: none;
        }
        .box-qr:hover .qr {
          display: block;
          cursor: none;
        }
      `}</style>
    </li>
  );
};

export default QrList;
