import { useEffect, useState } from "react";
import { MemberForm, MemberRow } from "./components";
import { useSelector } from "react-redux";
import { Value } from "baseui/select";
import theme from "@styles/theme";
import { css } from "@emotion/react";
import { User, UserRoles } from "@store/user/types";
import {
  selectEditableRoles,
  selectEditableUsers,
  selectSelectedUser,
} from "@store/user/userSelectors";
import useAppDispatch from "@hooks/useAppDispatch";
import { setSelectedUser } from "@store/user/userSlice";
import { getMostImportantRole } from "@helpers";
import { fetchUsers } from "@store/user/userThunk";
import Breadcrumb from "@components/base/breadcrumb";
import Button from "@components/base/button";
import { overrideButtonStyles } from "@components/base/button/overrides";
import SearchInput from "@components/base/search-input";
import Select from "@components/base/select";
import { NavLabels, SubNavLabels } from "@components/Layout";
import { useTranslation } from "react-i18next";

interface TeamScreenProps {}

const TeamScreen: React.FC<TeamScreenProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const editableRoles = useSelector(selectEditableRoles);
  const members = useSelector(selectEditableUsers);
  const selectedUser = useSelector(selectSelectedUser);

  const [search, setSearch] = useState("");
  const [selectedRole, setSelectedRole] = useState<Value>([
    { label: t("common.all"), value: "all" },
  ]);
  const [filteredMembers, setFilteredMembers] = useState<User[]>([]);

  const selectedUserId = selectedUser?.id;

  const roleOptions =
    editableRoles
      ?.filter(
        (role) => ![UserRoles.TD_CREATOR, UserRoles.ADMIN].includes(role),
      )
      .map((role) => ({
        label: t(`roleNames.${role}`),
        value: role,
      })) ?? [];

  const onAddMember = () => {
    dispatch(setSelectedUser(""));
  };

  const sortMembers = (members: User[]) => {
    return members.sort((a: User, b: User) => {
      return a.profile.name?.localeCompare(b.profile.name);
    });
  };

  const filterMembers = (
    memberList: User[],
    search: string,
    selectedRole: UserRoles | "all",
  ) => {
    return memberList.filter((member) => {
      // If e-mail contains @valkdigital.nl remove it from the list
      if (member.email.includes("@valkdigital.nl")) return false;

      const searchMatch =
        search && member.profile.name
          ? member.profile.name
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          : true;

      const roleMatch =
        selectedRole !== "all"
          ? getMostImportantRole(member.roles) === selectedRole
          : true;

      return searchMatch && roleMatch;
    });
  };

  useEffect(() => {
    if (members === undefined || members.length === 0) return;
    const sortedMemberList = sortMembers(members);
    const filteredMemberList = filterMembers(
      sortedMemberList,
      search,
      selectedRole[0]?.value,
    );
    setFilteredMembers(filteredMemberList);
  }, [search, selectedRole, members]);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <>
      <Breadcrumb labels={[SubNavLabels.Settings, NavLabels.TeamSettings]} />
      <section data-test-id="team-beheren" css={styles.section}>
        <div css={styles.members}>
          <div css={styles.filterContainer}>
            <Button
              onClick={onAddMember}
              overrides={overrideButtonStyles({
                BaseButton: { marginBottom: "16px" },
              })}>
              {t("team.addMember")}
            </Button>
            <SearchInput
              value={search}
              onChange={setSearch}
              placeholder={t("search.name")}
              containerStyle={{ width: "100%" }}
            />
          </div>

          <div css={styles.filterRow}>
            <Select
              value={selectedRole}
              options={[
                { label: t("common.all"), value: "all" },
                ...roleOptions,
              ]}
              onChange={(params) => setSelectedRole(params.value)}
              props={{
                labelKey: "label",
                valueKey: "value",
                clearable: false,
                searchable: false,
              }}
            />
          </div>

          <div data-test-id="memberslist" css={styles.memberList}>
            {filteredMembers.map((member) => (
              <MemberRow
                member={member}
                key={member.id}
                active={selectedUserId === member.id}
              />
            ))}
          </div>
        </div>

        <MemberForm roleOptions={roleOptions} />
      </section>
    </>
  );
};

const styles = {
  filterContainer: css({
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.sizing.scale800,
  }),
  section: css({
    display: "flex",
    flex: 1,
    flexDirection: "row",
    overflow: "hidden",
  }),
  members: css({
    display: "flex",
    flexDirection: "column",
    padding: theme.sizing.scale1200,
    width: "280px",
    backgroundColor: theme.colors.white,
  }),
  filterRow: css({
    display: "flex",
    height: "auto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    color: theme.colors.primaryB,
    paddingBottom: theme.sizing.scale800,
    borderBottom: `2px solid ${theme.colors.mono300}`,
    justifyContent: "space-between",
  }),
  memberList: css({
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    overflowX: "hidden",
    paddingBottom: `${theme.sizing.scale1600} * 2`,
    paddingTop: theme.sizing.scale800,
  }),
};

export default TeamScreen;
