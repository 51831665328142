import { FC, useEffect, useState } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { EntityId } from "@reduxjs/toolkit";
import { DayReportReportExtra } from "@store/reportExtra/types";
import { selectAllReportExtras } from "@store/reportExtra/reportExtraSelector";
import useAppSelector from "@hooks/useAppSelector";
import { forceToNumberString } from "@helpers";
import Input from "@components/base/input";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";

interface ExtraHourProps {
  onChange: (name: string, value: number) => void;
  dayReportExtras: DayReportReportExtra | undefined;
}

const EndOfDayExtras: FC<ExtraHourProps> = ({ onChange, dayReportExtras }) => {
  const { t } = useTranslation();
  const allReportExtras = useAppSelector(selectAllReportExtras);
  const [sortedExtras, setSortedExtras] = useState<{ [key: string]: string }>(
    {},
  );

  useEffect(() => {
    const allExtras = new Set<EntityId>();
    if (dayReportExtras)
      Object.keys(dayReportExtras).forEach((extra) =>
        allExtras.add(String(extra)),
      );
    allReportExtras.forEach((extra) => allExtras.add(extra.id));

    setSortedExtras(
      Object.fromEntries(
        Array.from(allExtras).map((extraId) => [
          extraId,
          (dayReportExtras?.[extraId] ?? 0).toString(),
        ]),
      ),
    );
  }, [dayReportExtras]);

  const changeValue = (taskId: string, value: string) => {
    setSortedExtras((prev) => ({
      ...prev,
      [taskId]: forceToNumberString(value, 2),
    }));
    if (!isNaN(Number(value))) onChange(taskId, Number(value));
  };

  const formatName = (name: string | undefined) =>
    name ? name.charAt(0).toUpperCase() + name.slice(1) : "";

  const isArchived = (extraId: EntityId | undefined) =>
    allReportExtras.find(({ id }) => id === extraId)?.discarded;

  return (
    <>
      <div className="container">
        <div className="inner">
          <h2>{t("dayreport.extra")}</h2>
          <table>
            <tbody>
              {Object.keys(sortedExtras).map((taskId, index) => {
                const task = allReportExtras.find(({ id }) => id == taskId);
                return (
                  <tr key={index}>
                    <td>
                      <label>{formatName(task?.label)}</label>
                    </td>
                    <td>
                      {isArchived(taskId) ? (
                        <>
                          <label className="alginLabel">
                            {sortedExtras[taskId]}
                          </label>
                          <StatefulPopover
                            content={() => (
                              <section className="popover">
                                <div className="popover-item">
                                  <p>{t("dayreport.deletedExtra")}</p>
                                </div>
                              </section>
                            )}
                            triggerType={TRIGGER_TYPE.hover}
                            placement="bottomLeft">
                            <span>
                              <HiOutlineInformationCircle />
                            </span>
                          </StatefulPopover>
                        </>
                      ) : (
                        <Input
                          value={sortedExtras[taskId]}
                          onChangeText={(changedValue) =>
                            changeValue(taskId, changedValue)
                          }
                          containerStyle={{
                            width: "5rem",
                          }}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <style jsx>{`
        .container {
          background-color: ${theme.colors.white};
          border: 1px solid ${theme.colors.mono200};
          width: max(875px, 45vw);
        }
        .inner {
          padding: ${theme.sizing.scale800};
        }
        .row {
          display: flex;
          flex-direction: row;
          margin-bottom: ${theme.sizing.scale800};
          align-items: center;
          width: 100%;
        }
        .link {
          margin-left: auto;
        }
        table {
          margin-top: ${theme.sizing.scale400};
          width: 100%;
          border: 1px solid rgba(0, 0, 0, 0.12);
          padding: ${theme.sizing.scale800};
        }
        table tr:nth-child(even) {
          background-color: ${theme.colors.mono200};
        }
        table tr:nth-child(odd) {
          background-color: ${theme.colors.white};
        }
        table tr td:first-child {
          width: 80%;
          line-height: 50px;
          padding-left: ${theme.sizing.scale800};
          font-weight: 600;
          /* border-right: 1.5px solid ${theme.colors
            .contentInverseSecondary}; */
        }
        table tr td:last-child {
          padding-left: ${theme.sizing.scale400};
        }
        .alginLabel {
          margin-left: 8%;
          padding-right: ${theme.sizing.scale400};
        }
        .popover-item {
          padding: ${theme.sizing.scale400};
          max-width: 250px;
          word-wrap: break-word;
        }
      `}</style>
    </>
  );
};

export default EndOfDayExtras;
