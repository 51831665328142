import { Translation } from "./index";
import { RoomStates } from "@store/space/types";
import { NavLabels, SubNavLabels } from "@components/Layout";
import { IssueState } from "@store/issue/types";
import { UserRoles } from "@store/user/types";

const de: Translation = {
  common: {
    all: "Alle",
    notApplicable: "n/v",
    cancel: "Abbrechen",
    delete: "Löschen",
    submit: "Bestätigen",
    back: "Zurück",
    filter: "Filter",
    logout: "Abmelden",
    loading: "Laden...",
    space: "Zimmer",
    spaces: "Zimmer",
    issue: "Aufgabe",
    issues: "Aufgaben",
    photos: "Fotos",
    area: "Sektion",
    areas: "Sektionen",
    property: "Standort",
    roomType: "Zimmertyp",
    customSpace: "Benutzerdefiniertes Zimmer",
    transactions: "Daten",
    room: "Raum",
    action: "Aktion",
    date: "Datum",
    export: "Exportieren {{value}}",
    save: "Speichern",
    total: "Gesamt",
    change: "Ändern",
    name: "Name",
    giveName: "Geben Sie einen Namen ein",
    floor: "Stockwerk",
    youSure: "Sind Sie sicher?",
    unknown: "Unbekannt",
    noData: "Keine Daten verfügbar",
    add: "Hinzufügen",
    tryAgain: "Erneut versuchen",
    remark: "Bemerkung",
    resetFilter: "Filter zurücksetzen",
    general: "Allgemein",
    rooms: "Zimmer",
    optional: "Optional",
    view: "Anzeigen",
    priority: "Priorität",
    tasks: "Regelmäßige Aufgaben",
    saveChanges: "Änderungen speichern",
  },
  layout: {
    refresh: "Aktualisieren",
    refreshMessage: 'Drücken Sie auf "Aktualisieren" für die neuesten Daten.',
    fold: "Einklappen",
    menu: {
      [NavLabels.Dashboard]: "Dashboard",
      [NavLabels.Overview]: "Zimmer",
      [NavLabels.AppLogin]: "Einloggen",
      [NavLabels.DayReports]: "Wochenübersicht",
      [NavLabels.TdOverview]: "Technischer Dienst",
      [NavLabels.GsOverview]: "Allgemeiner Dienst",
      [NavLabels.TeamSettings]: "Team verwalten",
      [NavLabels.AreaSettings]: "Sektionen verwalten",
      [NavLabels.CustomRoomSettings]: "Räume verwalten",
      [NavLabels.TaskSettings]: "Aufgaben verwalten",
      [NavLabels.DayReportSettings]: "Tagesabschluss verwalten",
      [NavLabels.ActionsLog]: "Zimmeraktionen",
      [SubNavLabels.Settings]: "Einstellungen",
      [SubNavLabels.None]: "Keine",
      [NavLabels.DayOverview]: "Tagesübersicht",
      [NavLabels.ExtraTasks]: "Zusätzliche Aufgaben",
      [NavLabels.TypeGroups]: "Gruppentarif",
    },
  },
  dayreport: {
    endOfDay: "Tagesabschluss",
    weekOverview: "Wochenübersicht",
    extra: "Zusätzliche Aufgabenn",
    deletedExtra:
      "Dieses Extra wurde gelöscht. Sie können den Wert noch einsehen, aber nicht mehr ändern.",
    addRemark: "Bemerkung hinzufügen",
    writeRemark: "Schreiben Sie eine Bemerkung",
    noRemarks: "Keine Bemerkungen",
    noChanges: "Keine Änderungen",
    dayView: "Tagesansicht",
    saveAndApprove: "Speichern und genehmigen",
    approve: "Genehmigen",
    approval: "Genehmigung",
    unsavedChanges:
      "Sie haben ungespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?",
    difference: "Unterschied",
    sold: {
      title: "Verkauf",
      description:
        "Alle Zimmer, die wir von PMS als verkauft erhalten, werden jeden Abend um 03:00 Uhr vom Vortag gesammelt.",
    },
    carriedOverFromYesterday: {
      title: "Von gestern übertragen",
      description:
        "Alle Zimmer, die wir von PMS als verkauft erhalten, werden jeden Abend um 03:00 Uhr vom Vortag gesammelt.",
    },
    dayuse: {
      title: "Tagesnutzung",
      description: "Alle Zimmer, die nicht vom Vortag gereinigt wurden.",
    },
    carriedOver: {
      title: "Übertragen",
      description:
        "Zimmer, die gestern noch schmutzig waren, aber heute nicht mehr.",
    },
    totalDescription:
      "Die verkauften, Tagesnutzungs- und übertragenen Zimmer zusammengezählt.",
    green: {
      title: "Grün Auswahl",
      description:
        "Zimmer, in denen der Gast (per E-Mail) oder die Rezeption (über ServiceV4) angegeben hat, dass keine Reinigung erforderlich ist (grüne Auswahl).",
    },
    orange: {
      title: "Orange Auswahl",
      description:
        "Zimmer, in denen der Gast (per E-Mail) oder die Rezeption (über ServiceV4) angegeben hat, dass nur Handtücher oder eine Tüte (orangefarbene Auswahl) benötigt werden + die Zimmer, die über die HKTD-App auf 'Tasche aufgehängt' gesetzt wurden.",
    },
    nonGreenSkip: {
      title: "Reinigung übersprungen",
      description:
        "Zimmer, in denen der Gast ohne grüne Auswahl wählt, das Zimmer nicht zu reinigen.",
    },
    doNotDisturb: {
      title: "Nicht stören",
      description:
        "Zimmer, die über die HKTD-App auf 'Nicht stören' gesetzt wurden.",
    },
    cleaned: {
      title: "Zu säubern",
      description:
        "Alle verkauften, Tagesnutzungs- und übertragenen Zimmer zusammengezählt, bei denen die Ausnahmen (grün, orange, nicht stören) entfernt wurden.",
    },
    totalCleaned: {
      title: "Gesamt gereinigt",
      description:
        "Alle Zimmer, die über die HKTD-App auf 'Zu säubern' gesetzt wurden.",
    },
    note: {
      title: "Notiz",
      description:
        "Bestimmte Zahlen können manuell vom HKS oder FOM angepasst werden, die angepassten Zahlen werden dann in die Berechnung einbezogen.",
    },
    calculate: "Wie wird dies berechnet",
    noData:
      "Keine Daten verfügbar. Diese Daten werden zu einem späteren Zeitpunkt abgerufen.",
    noShow: "Nichterscheinen",
    clean: "Gereinigt",
    oldValue: "Alter Wert",
    new_Value: "Neuer Wert",
    whoMay: {
      send: "Wer darf den Tagesabschluss senden",
      edit: "Wer darf den Tagesabschluss bearbeiten",
      create: "Wer darf Extras erstellen",
      receive: "Wer darf den Tagesabschluss per E-Mail erhalten",
    },
    setupEmail: "E-Mail-Setup",
    rateTypeGroup: "Rate Type Group bearbeiten",
    editRateType: "Bearbeiten Sie die Rate Type Group",
    week: "Woche",
    editWarning: "Manuelle Änderungen wurden vorgenommen",
    send: {
      title: "Sind Sie sicher, dass Sie den Wochenrückblick versenden wollen?",
      description: "Dieser Wochenbericht wird an folgende Adresse gesandt:",
      button: "Ja, diese Woche versenden",
      self: "Senden Sie",
      export: "Exportübersicht",
    },
    manage: "Tagesabschluss verwalten",
    popover: {
      green: "Grüne Wahl <strong>{{number}}</strong>",
      orange: "orange Wahl <strong>{{number}}</strong>",
      nonGreenSkip: "Reinigung übersprungen <strong>{{number}}</strong>",
      doNotDisturb: "Nicht stören <strong>{{number}}</strong>",
    },
    exceptions: "Ausnahmen",
    enableEmail: "E-Mail aktivieren",
    noEmail:
      "Es wurde keine E-Mail-Adresse hinzugefügt, der Tagesabschluss kann nicht gesendet werden.",
    settings: {
      reportPermission: {
        fom: "Rezeption",
        fomHk: "Rezeption & Hauswirtschaft Supervisor",
        hk: "Hauswirtschaft Supervisor",
        gs: "Algemene Dienst Supervisor",
        hkGs: "Hauswirtschaft & Allgemeine Dienste Supervisor",
        fomGs: "Rezeption & Allgemeine Dienste Supervisor",
        fomHkGs: "Rezeption, Hauswirtschaft & Allgemeine Dienste Supervisor",
      },
    },
  },
  hkStatistics: {
    tableContent: {
      arrivals: "Ankünfte",
      atStart: "Start",
      carriedOver: "Übertragen",
      carriedOverFromYesterday: "Gestern durchgestellt",
      currently: "Aktuell",
      departures: "Abgänge",
      dirtyVacant: "Alle freien/verschmutzten Zimmer",
      notAvailable: "Statistiken sind (noch) nicht verfügbar",
      roomsCleaned: "Zimmer gereinigt",
      stayovers: "Aufenthalte",
      rateTypesCleaned: "Suiten gereinigt",
    },
    emptyMessage: "Die Statistiken konnten leider nicht abgerufen werden.",
    atStart: "Start",
    currently: "Aktuell",
  },
  pmsNotification: {
    content: {
      bad: "Die Zimmerstatus sind aufgrund eines Verbindungsproblems mit PMS nicht aktuell.",
      poor: "Der Zimmerstatus ist aufgrund eines Verbindungsproblems mit PMS möglicherweise nicht aktuell.",
    },
    heading: {
      bad: "Keine Verbindung zu PMS für längere Zeit möglich",
      poor: "Keine Verbindung zu PMS möglich",
    },
    specific: {
      amadeus:
        "Bitten Sie die Rezeption, einen fall bei Amadeus (hospitality.support@amadeus.com) mit dem Betreff 'Hybrid Cloud Service is down for Hotel {{propertyName}}'",
    },
  },
  actions: {
    export: {
      failed: "{{value}} konnte nicht exportiert werden.",
    },
    assign: {
      failed: "{{value}} ist nicht zugewiesen.",
      success: "{{value}} zugewiesen.",
    },
    unassign: {
      failed: "{{value}} nicht zuzuweisen ist fehlgeschlagen.",
      success: "{{value}} erfolgreich nicht zugewiesen.",
      success_plural: "{{value}} erfolgreich nicht zugewiesen.",
    },
    cleaning: {
      start: {
        failed: "Reinigung starten fehlgeschlagen",
        success: "Reinigung gestartet",
      },

      stop: {
        failed: "Reinigung stoppen fehlgeschlagen",
        success: "Reinigung gestoppt",
      },
      finish: {
        failed: "Kann Zimmer nicht auf sauber setzen",
        success: "Zimmer auf sauber setzen erfolgreich",
      },
    },
    inspectionFinish: {
      failed: "Zimmer inspizieren fehlgeschlagen",
      success: "Zimmer inspiziert",
    },
    issueUpdate: {
      failed: "Aufgabenstatusaktualisierung fehlgeschlagen",
      success: "Aufgabenstatusaktualisierung erfolgreich",
    },
    add: {
      failed: "{{value}} nicht hinzugefügt.",
      success: "{{value}} erfolgreich hinzugefügt.",
      success_plural: "{{value}} erfolgreich hinzugefügt.",
      userFailed:
        "Hinweis: Es ist nicht möglich, dieselbe E-Mail-Adresse für mehrere Benutzer/Hotels zu verwenden.",
    },
    default: {
      failed: "Fehlgeschlagen!",
      success: "{{value}} erfolgreich aktualisiert",
    },
    update: {
      failed: "{{value}} nicht aktualisiert.",
      success: "{{value}} erfolgreich aktualisiert",
      success_plural: "{{value}} erfolgreich aktualisiert",
    },
    delete: {
      failed: "{{value}} nicht gelöscht.",
      success: "{{value}} erfolgreich gelöscht",
      success_plural: "{{value}} erfolgreich gelöscht",
    },
    approved: {
      failed: "{{value}} konnte nicht genehmigt werden.",
      success: "{{value}} genehmigt.",
    },
    send: {
      failed: "{{value}} konnte nicht gesendet werden.",
      success: "{{value}} erfolgreich gesendet.",
    },
    refresh: {
      failed: "Auffrischen {{value}} fehlgeschlagen",
      success: "{{value}} erfolgreich aufgefrischt",
    },
    switch: {
      failed: "{{value}} nicht gewechselt.",
      languageFailed:
        "Benachrichtigungssprache konnte nicht gewechselt werden.",
      success: "{{value}} gewechselt!",
    },
    qrCode: {
      failed: "Das Exportieren der QR-Codes ist fehlgeschlagen.",
      success: "Die QR-Codes wurden erfolgreich heruntergeladen.",
    },
    fetch: {
      failed:
        "Die Informationen können derzeit nicht abgerufen werden.\n  Überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.\n\n Wenn das Problem weiterhin besteht, versuchen Sie sich erneut anzumelden.",
    },
  },
  hk: {
    description: "Beschreibung: {{description}}",
    roomState: {
      [RoomStates.OUT_OF_INVENTORY]: "Nicht verfügbar",
      [RoomStates.OUT_OF_ORDER]: "Außer Betrieb",
      cleanTowels: "Handtücher gereinigt",
      dirtyTowels: "Handtücher schmutzig",
      dirtyAndInspected: "Schmutzig und inspiziert",
      cleanAndInspected: "Gereinigt und inspiziert",
      clean: "Gereinigt",
      dirty: "Schmutzig",
      extra: {
        vacantToday: "seit heute frei",
        leaving: "Abgänger",
        occupiedToday: "seit heute besetzt",
        staying: "Aufenthalt | {{current}}/{{total}}",
        vacant_zero: "seit heute frei",
        vacant_one: "frei | {{count}} tag",
        vacant_other: "frei | {{count}} tage",
        orange: "orange Wahl",
        green: "keine reinigung (grüne Wahl)",
        nonGreenSkip: "keine reinigung",
        doNotDisturb: "nicht stören",
      },
    },
    leavers: "Abreisen",
    stayers: "Aufenthalte",
    amountExtra: "{{amount}} Extra Aufgaben",
    filters: {
      roomState: "Raumzustand (+extra)",
      arrivals: "Ankünfte (+extra)",
      cleaningState: "Reinigungszustand",
      tasks: "Aufgaben",
      user: "Benutzer",
      roomsWithExtra: "Zimmer mit Extras",
      roomsWithTasks: "Zimmer mit Aufgaben",
      occupiedToday: "Seit heute besetzt",
      rooms: {
        clean: "Gereinigt Zimmer",
        dirty: "Schmutziges Zimmer",
      },
    },
    selectedRooms: "Zimmer ausgewählt: {{count}} ",
    assignRoom: "Zimmer zuweisen",
    showQrCode:
      "Fahren Sie mit der Maus über diesen Bereich, um den QR-Code anzuzeigen",
  },
  user: {
    sessionExpired:
      "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
    self: "Benutzer",
    role: "Rolle",
    switch: "Wechseln {{value}}",
  },
  tasks: {
    delete: {
      title: "Löschen {{value}}",
      description:
        "Sind Sie sicher, dass Sie {{value}} löschen wollen? Die Löschung kann nicht rückgängig gemacht werden. Die Löschung wird am nächsten Arbeitstag wirksam.",
    },
    number: "Aufgabe {{task}}",
    add: "+ eine weitere Aufgabe hinzufügen",
    assign: "zuweisen",
    assignTask: "Aufgabe zuweisen",
    assignTasks: "Aufgaben zuweisen",
    unassignTasks: "Aufgaben löschen",
    unassignUser: "Mitarbeiter löschen",
    all: "Alle periodischen Aufgaben",
    empty: "Es sind noch keine periodischen Aufgaben erstellt worden.",
    labelPlaceholder: "Reinigung",
    edit: "Aufgabe ändern",
    remove: "Aufgabe löschen",
  },
  actionLog: {
    actions: {
      space: {
        clean: {
          start: "Reinigung gestartet",
          stop: "Reinigung gestoppt",
          finish: "Reinigung abgeschlossen",
          skip: "Reinigung übersprungen",
        },
        assign: {
          add: "Zimmer zugewiesen",
          remove: "Zimmer nicht zugewiesen",
        },
        inspect: {
          finish: "Inspektion abgeschlossen",
        },
      },
      issue: {
        work: {
          start: "Aufgabe gestartet",
          stop: "Aufgabe gestoppt",
          finish: "Aufgabe gelöst",
          wait: "Aufgabe in Bestellung",
        },
        report: {
          create: "Neue Aufgabe gemeldet",
          attach: "Anhang hinzugefügt",
        },
        assign: {
          add: "Aufgabe zugewiesen",
          remove: "Aufgabe nicht zugewiesen",
        },
      },
    },
  },
  search: {
    title: "Suchen",
    rooms: "Suche ein Zimmer",
    noResults: "Keine {{object}} gefunden für diese Suche.",
    name: "Suche nach Name",
    employee: "Suche einen Mitarbeiter",
    notFound:
      "Es wurden leider keine {{search}} für die aktuelle Suche und/oder Filter gefunden.",
    floor: "Wählen Sie ein Stockwerk",
    role: "Wählen Sie eine Rolle",
    task: "Suche einen Aufgabe",
    roomOrHouseKeeper: "Suche ein Zimmer oder Reiniger",
    location: "Suche nach Standort",
    taskPersonRoom: "Suche eine Aufgabe, Person oder Raum",
    rateType: "Suche nach Zimmertyp",
    function: "Wählen Sie eine Funktion",
  },
  area: {
    name: "Sektion Name",
    numberOfRooms: "Anzahl Zimmer",
    assignedRooms: "Zugewiesene Zimmer",
    noRoomsAssigned: "Keine Zimmer zugewiesen",
    emptyMessage:
      "Es wurden noch keine Abschnitte erstellt. Drücken Sie die Schaltfläche oben, um einen Abschnitt zu erstellen.",
    delete: {
      action: "Aus Abschnitt entfernen",
      description:
        "Sie sind im Begriff, zugewiesene Räume aus einem Bereich zu entfernen,\n diese Aktion kann nicht rückgängig gemacht werden.",
      area: "Sie sind dabei, den Bereich '{{count}}' zu entfernen. \nDiese Aktion kann nicht rückgängig gemacht werden.",
    },
    assignSection_zero: "Zu Abschnitt zuweisen",
    assignSection: "Zu Abschnitt zuweisen ({{count}})",
    select: "Wählen Sie einen Abschnitt",
    count: "Anzahl Zimmer: {{count}}",
    toAssign: "Abschnitt zuweisen",
    remove: "Abschnitt entfernen",
    update: "Abschnitt aktualisieren",
    add: "Abschnitt hinzufügen",
    charLeft_one: "{{count}} Zeichen übrig",
    charLeft_other: " {{count}} Zeichen übrig",
    charLeft_zero: "Keine Zeichen übrig",
  },
  counts: {
    room_one: "{{count}} Zimmer",
    room_other: "{{count}} Zimmer",
  },
  customroom: {
    create: {
      title: "Öffentlichen Raum erstellen",
      submit: "Raum erstellen",
      add: "Raum hinzufügen",
      nameRequired: "Der öffentliche Raum muss einen Namen haben",
      nameInUse: "Dieser Name wird bereits verwendet.",
      groupRequired: "Gruppenname ist erforderlich",
    },
    changeTitle: "Raum ändern",
    floor: "Stockwerk {{floor}}",
    tooltip: "Tipp: Ein kurzer Titel funktioniert am besten!",
  },
  extra: {
    description: "Beschreibung des Extras",
    hourAndTask: "Zusätzliche Aufgaben und Stunden",
    newTask: "Neue Aufgabe",
    typeName: "Geben Sie den Namen ein",
    noExtra: "Es wurden noch keine zusätzlichen Aufgaben hinzugefügt.",
    newTaskAction: "Klicken Sie hier, um Extras anzuzeigen oder zu bearbeiten",
    self: "Extras",
  },
  login: {
    alreadyadded: "Die E-Mail-Adresse {{email}} wurde bereits hinzugefügt.",
    invalid: "Die E-Mail-Adresse {{email}} ist ungültig.",
    enterNew: "Geben Sie eine neue E-Mail-Adresse ein",
    self: "E-Mail Adresse",
    password: "Passwort",
    loginError: "Falsche E-Mail-Adresse oder Passwort",
    forgotPassword: "Passwort vergessen?",
  },
  issue: {
    typeTaskName: "Geben Sie den Namen der Aufgabe ein",
    typeReporterName: "Geben Sie den Namen des Melders ein",
    createdBy: "Erstellt von",
    reportedBy: "Gemeldet von",
    createdAt: "Erstellt am",
    addRemark: "Geben Sie einen Kommentar zu der Aufgabe ein",
    priority: "Diese Aufgabe hat Priorität",
    change: "Aufgabe ändern",
    create: "Aufgabe erstellen",
    addNewTask: "Neue Aufgabe hinzufügen",
    newTask: "Neue Aufgabe",
    states: {
      title: "Zustände",
      [IssueState.OPEN]: "Offen",
      [IssueState.IN_PROGRESS]: "In Bearbeitung",
      [IssueState.ON_HOLD]: "In Wartestellung",
      [IssueState.ORDERED]: "Bestellt",
      [IssueState.DONE]: "Erledigt",
      all: "Alle",
    },
    createDate: "Erstellungsdatum",
    taskName: "Aufgabenname",
    status: "Zustand",
    assignee: "Zugewiesen",
    changeLog: "Änderungsprotokoll",
    changeDetails: "Änderungsdetails",
    delete: {
      title: "Aufgabe löschen",
      action: "Aufgabe löschen",
      description: "Sie sind dabei, die Aufgabe {{label}} zu löschen.",
    },
    inactive: {
      title: "Deaktivieren",
      action: "Warum sollte diese Aufgabe inaktiv sein?",
      placeholder: "Geben Sie einen Grund ein",
      label: "Grund",
    },
    edit: "Aufgabe ändern",
    view: "Sprache Detail",
    addImage: "Ein Bild einfügen",
    export: "Exportieren",
    newIssueModal: {
      createdIssuesTitle: "Andere Aufgaben für diesen Raum",
      columns: {
        date: "Datum",
        task: "Aufgabe",
        status: "Status",
      },
    },
  },
  segment: {
    ["Groepen"]: "Gruppen",
    ["Alle kamertypes"]: "Alle Zimmerarten",
    all: "Alle",
  },
  rateType: {
    create: "Neue Tarifgruppe",
    update: "Tarifgruppe aktualisieren",
    delete: "Tarifgruppe löschen",
    groupName: "Gruppenname",
    groupDescription: "Gruppenbeschreibung",
    defaultGroup:
      "Es ist nicht möglich, diese Gruppe zu entfernen. Sie können die Raumtypen jedoch einer anderen Gruppe zuordnen.",
    roomType: "Zimmertyp",
    belongsTo: "Gehört zu",
    deleteDescription:
      "Die gelöschten Zimmerarten werden in die Gruppe 'Zimmer' oder 'Suiten' zurückgesetzt.",
    deleteConfirmation:
      "Sie sind dabei, die Gruppe {{name}} zu löschen. \nDiese Aktion kann nicht rückgängig gemacht werden.",
    groups: "Tarifgruppen",
    group: "Gruppe",
    groupedRatesInfo: "Anpassungen gehen erst ab dem nächsten Montag in Kraft.",
    countTypes: "Anzahl der hinzugefügten Zimmertypen",
  },
  team: {
    addMember: "Mitglied hinzufügen",
    detail: "Einzelheiten",
    removeMember: "Teammitglied löschen",
    inputName: "Name des Teammitglieds",
    inputEmail: "E-Mail Adresse des Teammitglieds",
    inputEmailInfo:
      "Beim Anlegen eines neuen Kontos wird eine E-Mail an die angegebene E-Mail-Adresse gesendet.",
    deleteConfirmation:
      "Sie sind dabei, {{value}} zu entfernen. \nDiese Aktion kann nicht rückgängig gemacht werden.",
  },
  roleNames: {
    [UserRoles.ADMIN]: "Verwalter",
    [UserRoles.HOTEL_MANAGER]: "Rezeption",
    [UserRoles.HK_MANAGER]: "Hauswirtschaft Supervisor",
    [UserRoles.HK_MEMBER]: "Hauswirtschaft",
    [UserRoles.TD_MANAGER]: "Technischer Dienst Supervisor",
    [UserRoles.TD_MEMBER]: "Technischer Dienst",
    [UserRoles.TD_CREATOR]: "Technischer Ersteller",
    [UserRoles.GS_MANAGER]: "Allgemeiner Dienst",
    [UserRoles.GS_MEMBER]: "Allgemeiner Dienst Supervisor",
    [UserRoles.NONE]: "Keine",
  },
};

export default de;
