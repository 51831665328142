import React from "react";
import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
} from "baseui/checkbox";
import { checkboxOverrides } from "./overrides";

export interface CheckboxProps extends BaseCheckboxProps {}

const Index: React.FC<CheckboxProps> = (props) => {
  return (
    <BaseCheckbox
      {...props}
      overrides={{ ...props.overrides, ...checkboxOverrides }}
    />
  );
};

export default Index;
