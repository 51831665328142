import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Form } from "@helpers";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import {
  selectAllRoomCodes,
  selectRateTypeById,
} from "@store/rateType/rateTypeSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { UpdateRateTypeParams } from "@store/rateType/types";
import {
  createRateType,
  deleteRateType,
  updateRateType,
} from "@store/rateType/rateTypeThunk";
import { overrideButtonStyles } from "@components/base/button/overrides";
import Button from "@components/base/button";
import Input from "@components/base/input";
import Table from "@components/base/table";
import Checkbox from "@components/base/checkbox";
import { overrideTableStyles } from "@components/base/table/overrides";
import Modal from "@components/base/modal";
import Notification from "@components/base/notification";
import useAppDispatch from "@hooks/useAppDispatch";
import Breadcrumb from "@components/base/breadcrumb";
import { useNavigate } from "react-router-dom";
import l from "@locale";
import { NavLabels, SubNavLabels } from "@components/Layout";
import { useTranslation } from "react-i18next";
export enum DefaultRateTypes {
  ROOMS = "Kamers",
  SUITES = "Suites",
}

const RateTypeSchema = yup.object().shape({
  name: yup.string().required(l.t("customroom.create.groupRequired")),
  roomCodes: yup.array().required().min(1),
});

interface RateTypeFormProps {}

const RateTypeForm: React.FC<RateTypeFormProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const roomCodes = useAppSelector(selectAllRoomCodes);
  const selectedRateTypeId = useAppSelector(
    ({ rateType }) => rateType.selectedId,
  );
  const rateType = useAppSelector(({ rateType }) => {
    if (selectedRateTypeId) {
      return selectRateTypeById(rateType, selectedRateTypeId);
    }
  });
  const dispatch = useAppDispatch();
  const [form, setForm] = useState<UpdateRateTypeParams>({
    name: rateType?.name ?? "",
    roomCodes: rateType?.roomCodes ?? [],
  });
  const [isValid, setIsValid] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const codes = roomCodes.map((item) => item.roomCode);
  const allRoomCodesSelected = isEqual(form.roomCodes, codes);
  const isInEditMode = !!selectedRateTypeId;
  const isDefault = (Object.values(DefaultRateTypes) as string[]).includes(
    rateType?.name ?? "",
  );

  const onNameChange = (name: string) => {
    setForm({ ...form, name });
  };

  const setSelectedRoomCodes = (roomCodes: string[]) => {
    setForm({ ...form, roomCodes });
  };

  const toggleAll = () => {
    if (isDefault) return;

    setForm({ ...form, roomCodes: allRoomCodesSelected ? [] : codes });
  };

  const onRemoveClick = () => {
    setShowRemoveModal(true);
  };

  const onRemove = () => {
    if (!selectedRateTypeId) return;
    try {
      dispatch(deleteRateType(selectedRateTypeId));
      navigate(-1);
    } catch (error) {}
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onSave = async () => {
    try {
      if (isInEditMode) {
        if (!selectedRateTypeId) return;
        dispatch(updateRateType(selectedRateTypeId, form));
      } else {
        dispatch(createRateType(form));
      }
      navigate(-1);
    } catch (error) {}
  };

  const validateForm = async () => {
    const errors = await Form.validate(RateTypeSchema, form);
    let isValid = !errors;
    if (isInEditMode) {
      isValid =
        !isEqual(
          { name: rateType?.name, roomCode: rateType?.roomCodes },
          { name: form.name, roomCodes: form.roomCodes },
        ) && !errors;
    }
    setIsValid(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [form]);

  return (
    <>
      <Breadcrumb
        labels={[
          SubNavLabels.Settings,
          NavLabels.DayReportSettings,
          NavLabels.TypeGroups,
        ]}
      />
      <section data-test-id="ratetype-groups" css={styles.section}>
        <div css={styles.row}>
          <h3>{isInEditMode ? t("rateType.update") : t("rateType.create")}</h3>

          {isInEditMode && !isDefault && (
            <Button
              onClick={onRemoveClick}
              kind="secondary"
              css={styles.button}
              overrides={overrideButtonStyles({
                BaseButton: {
                  color: theme.colors.negative,
                },
              })}>
              {t("rateType.delete")}
            </Button>
          )}
        </div>

        <Input
          autoFocus
          label={t("rateType.groupName")}
          value={form.name}
          onChangeText={(text) => onNameChange(text)}
          containerStyle={{ margin: "20px 0", width: "200px" }}
          disabled={isDefault}
          placeholder={t("rateType.groupDescription")}
        />
        {isDefault && (
          <Notification
            fullWidth={true}
            kind="info"
            content={t("rateType.defaultGroup")}
          />
        )}
        <Table
          columns={[
            <Checkbox
              disabled={isDefault}
              checked={allRoomCodesSelected}
              onChange={toggleAll}
            />,
            t("rateType.roomType"),
            t("rateType.belongsTo"),
          ]}
          data={roomCodes.map((item) => {
            const isActive = form.roomCodes?.some(
              (roomCode) => item.roomCode === roomCode,
            );

            const isDisabled =
              isDefault && rateType?.roomCodes.includes(item.roomCode);

            const toggleSelectedRoomCode = () => {
              if (isDisabled) return;
              if (isActive) {
                setSelectedRoomCodes(
                  form.roomCodes?.filter(
                    (roomCode) => roomCode !== item.roomCode,
                  ),
                );
                return;
              }
              setSelectedRoomCodes([...form.roomCodes, item.roomCode]);
            };

            return [
              <Checkbox
                disabled={isDisabled}
                checked={isActive}
                onChange={toggleSelectedRoomCode}
              />,
              item.roomCode,
              item.rateType.name,
            ];
          })}
          overrides={overrideTableStyles({
            TableBodyCell: {
              ":first-child": {
                width: "0px",
                alignSelf: "center",
              },
            },
          })}
        />

        <div css={[styles.row, styles.bottomRow]}>
          <a data-test-id="ratetype-btn-cancel" onClick={onCancel}>
            {t("common.cancel")}
          </a>
          <Button
            onClick={onSave}
            disabled={!isValid}
            overrides={overrideButtonStyles({
              BaseButton: ({ $theme }) => ({
                marginLeft: $theme.sizing.scale800,
              }),
            })}>
            {isInEditMode ? t("common.change") : t("common.save")}
          </Button>
        </div>

        <p data-test-id="ratetype-text" css={styles.paragraph}>
          {t("rateType.deleteDescription")}
        </p>
      </section>
      <Modal
        title={t("common.youSure")}
        buttonText={t("common.delete")}
        buttonAction={onRemove}
        isOpen={showRemoveModal}
        setShowModal={setShowRemoveModal}>
        <p>{t("rateType.deleteConfirmation", { name: form.name })}</p>
      </Modal>
    </>
  );
};

const styles = {
  section: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: theme.sizing.scale1600,
    overflow: "scroll",
  }),
  row: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  bottomRow: css({
    marginTop: theme.sizing.scale800,
    justifyContent: "flex-start",
  }),
  paragraph: css({
    margin: `${theme.sizing.scale800} 0`,
  }),
  button: css({
    border: `1px solid ${theme.colors.negative}`,
  }),
};

export default RateTypeForm;
