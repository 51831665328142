import * as React from "react";
import Layout from "@components/Layout";
import withAuth from "@helpers/withAuth";
import AreasScreen from "@screens/Areas";

const AreasContainer: React.FC = () => {
  return (
    <Layout active="settings/areas">
      <AreasScreen />
    </Layout>
  );
};
export default withAuth(AreasContainer);
