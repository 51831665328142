import React from "react";
import { css } from "@emotion/react";

interface DropzoneProps {
  onClick: () => void;
  onDrag: (event: React.DragEvent<HTMLImageElement>) => void;
  onDrop: (event: React.DragEvent<HTMLImageElement>) => void;
}

const Dropzone: React.FC<DropzoneProps> = ({ onClick, onDrag, onDrop }) => {
  return (
    <div onClick={onClick}>
      <img
        src="/icons/droparea.svg"
        css={styles.dropzone}
        draggable={true}
        onDragOver={onDrag}
        onDragLeave={onDrag}
        onDragEnter={onDrag}
        onDrop={onDrop}
      />
    </div>
  );
};

const styles = {
  dropzone: css({
    width: "100%",
    height: "100%",
  }),
};

export default Dropzone;
