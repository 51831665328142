import { batch } from "react-redux";
import { AppThunk } from "..";
import services from "../../services/api";
import {
  DayReport,
  DayReportQueryParams,
  DayReportsMeta,
  UpdateDayReportParams,
} from "./types";
import l from "@locale";
import { showNotification } from "../ui/uiThunk";
import {
  setDayReport,
  setDayReports,
  setError,
  setLoading,
  setWeek,
} from "./dayReportSlice";
import { exportToCsv } from "@store/helpers";
import i18next from "@locale";

export const fetchDayReport =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await services.DayReports.getById(id);
      dispatch(setDayReport(response));
    } catch (error) {
      dispatch(setError(true));
    }
    dispatch(setLoading(false));
  };

export const fetchDayReportByDate =
  (date: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await services.DayReports.getByDate(date);
      dispatch(setDayReport(response));
    } catch (error) {
      dispatch(setError(true));
    }
    dispatch(setLoading(false));
  };

export const fetchDayReports =
  (params: DayReportQueryParams): AppThunk =>
  async (dispatch) => {
    try {
      let response = await services.DayReports.getPage({
        ...params,
        page: 1,
      });

      const dayReports = response.data;
      while (response.meta.next !== null) {
        response = await services.DayReports.getPage({
          ...params,
          page: response.meta.next,
        });
        dayReports.push(...response.data);
      }
      const meta: DayReportsMeta = {
        perRate: response.meta.perRate,
        totals: response.meta.totals,
      };

      if (params.start) {
        dispatch(setWeek({ data: dayReports, meta }));
        return;
      }

      dispatch(setDayReports({ data: dayReports, meta }));
    } catch (error) {}
  };

export const exportDayReportsToCSV =
  (params: DayReportQueryParams): AppThunk =>
  async () => {
    try {
      const response = await services.DayReports.getCsv({
        ...params,
      });

      exportToCsv(
        response,
        `${i18next.t("dayreport.weekOverview")}-${params.start}`,
      );
    } catch (error) {}
  };

export const updateDayReport =
  (id: DayReport["id"], dayReport: UpdateDayReportParams): AppThunk =>
  async (dispatch) => {
    const value = l.t("dayreport.endOfDay");
    try {
      const response = await services.DayReports.update(id, dayReport);
      batch(() => {
        dispatch(
          showNotification({
            message: l.t("actions.update.success", { value }),
            type: "success",
          }),
        );
        dispatch(setDayReport(response));
      });
    } catch (error) {
      dispatch(
        showNotification({
          message: l.t("actions.update.failed", { value }),
          type: "error",
        }),
      );
    }
  };

export const deleteRemark =
  (dayReport: DayReport, auditId: string): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.remark");
    try {
      await services.DayReports.deleteRemark(dayReport.id, auditId);

      const audits = dayReport.audits.filter(
        (storedAudit) => storedAudit.id !== auditId,
      );

      batch(() => {
        dispatch(
          showNotification({
            message: l.t("actions.delete.success", { value }),
            type: "success",
          }),
        );
        dispatch(setDayReport({ ...dayReport, audits }));
      });
    } catch (error) {
      dispatch(
        showNotification({
          message: l.t("actions.delete.failed", { value }),
          type: "error",
        }),
      );
    }
  };

export const approveDayReport =
  (id: DayReport["id"]): AppThunk =>
  async (dispatch) => {
    const value = l.t("dayreport.endOfDay");
    try {
      const response = await services.DayReports.approve(id);
      batch(() => {
        dispatch(setDayReport(response));
        dispatch(
          showNotification({
            message: l.t("actions.approved.success", { value }),
            type: "success",
          }),
        );
      });
    } catch (error) {
      dispatch(
        showNotification({
          message: l.t("actions.approved.failed", { value }),
          type: "error",
        }),
      );
    }
  };

export const deliverWeekReport = (date: string) => async () => {
  const value = l.t("dayreport.weekOverview");
  try {
    await services.DayReports.deliver(date);
    showNotification({
      message: l.t("actions.send.success", { value }),
      type: "success",
    });
  } catch (error) {
    showNotification({
      message: l.t("actions.send.failed", { value }),
      type: "error",
    });
  }
};
