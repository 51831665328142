import React from "react";
import theme from "@styles/theme";
import ActionButtons from "./ActionButtons";
import SearchInput from "./SearchInput";
import AreaOptions from "./AreaOptions";
import { StayLeaveTable } from "./StayLeaveTable";
import { FilterDropdown } from "./FilterDropdown";
import { selectRoomSpaceIdsByFilters } from "@store/space/spaceSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { selectCurrentRole } from "@store/user/userSelectors";
import { isSupervisor } from "@helpers";

const FilterBar: React.FC = () => {
  const filteredSpaceIds = useAppSelector(selectRoomSpaceIdsByFilters);
  const currentRole = useAppSelector(selectCurrentRole);
  const canEdit = isSupervisor(currentRole);

  return (
    <div className="wrapper">
      <div data-test-id="filterRow" className="filterRow">
        <AreaOptions />

        {canEdit && (
          <div className="buttons">
            <ActionButtons />
          </div>
        )}
      </div>
      <div data-test-id="filterBar" className="filterBar">
        <div className="filterRow">
          <FilterDropdown />
          <SearchInput />
        </div>
        <StayLeaveTable ids={filteredSpaceIds} />
      </div>
      <style jsx>{`
        .filterBar {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-top: 10px;
          min-height: 50px;
          margin-bottom: 20px;
        }
        .floorOrSection {
          flex: 1;
        }

        label {
          margin-right: 10px;
        }
        .tasksFilter {
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-left: 20px;
        }
        .assign-table {
          top: 75px;
          position: absolute;
        }

        .tasksFilter > div.hide,
        div.hover {
          position: absolute;
          z-index: 3;
          border: 1px solid ${theme.colors.mono600};
          box-sizing: border-box;
          margin-top: 150px;
          margin-left: 110px;
          display: none;
          box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
          background: ${theme.colors.white};
          border: 1px solid ${theme.colors.mono600};
        }
        .hover:after,
        .hover:before {
          bottom: 100%;
          left: 20px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        .hover:after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: ${theme.colors.white};
          border-width: 10px;
          margin-left: -10px;
        }
        .hover:before {
          border-color: rgba(162, 162, 162, 0);
          border-bottom-color: ${theme.colors.mono600};
          border-width: 11px;
          margin-left: -11px;
        }
        .tasksFilter:hover > div.hover {
          display: block !important;
        }
        .header {
          font-weight: bold;
        }
        .noTasks {
          background-color: ${theme.colors.white};
          max-width: 200px;
          padding: 10px 20px;
        }

        .buttons {
          display: flex;
          align-items: center;
          flex-direction: row;
        }
        .filterRow {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        /* Tablet optimizations */
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
          label {
            font-size: 12px;
          }
        }
      `}</style>
    </div>
  );
};

export default FilterBar;
