import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ReportExtra } from "./types";

export const reportExtraAdapter = createEntityAdapter<ReportExtra>();

export interface ReportExtraState extends EntityState<ReportExtra> {
  selectedId?: EntityId;
}

const initialState: ReportExtraState = {
  ...reportExtraAdapter.getInitialState(),
  selectedId: undefined,
};

const reportExtraSlice = createSlice({
  name: "reportExtra",
  initialState,
  reducers: {
    setReportExtras: reportExtraAdapter.setAll,
    setSelectedReportExtra(
      state,
      { payload }: PayloadAction<ReportExtra["id"] | undefined>,
    ) {
      state.selectedId = payload;
    },
  },
});

export const { setReportExtras } = reportExtraSlice.actions;

export default reportExtraSlice;
