import { FC } from "react";
import { css, SerializedStyles } from "@emotion/react";
import { GoCheck } from "react-icons/go";
import { ImHourGlass } from "react-icons/im";
import { Approvals } from "@store/dayReport/types";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";

type ApprovalOverviewProps = {
  parentStyles: SerializedStyles;
  approvals: Approvals;
};

const ApprovalOverview: FC<ApprovalOverviewProps> = ({
  parentStyles,
  approvals,
}) => {
  const { t } = useTranslation();
  return (
    <div css={parentStyles}>
      <p>
        <strong>{t("dayreport.approval")}</strong>:
        <span css={styles.approvalLabel}>HKS</span>
        {!!approvals.hkManager ? (
          <GoCheck size={20} fill={theme.colors.green} />
        ) : (
          <ImHourGlass size={16} />
        )}
        <span css={styles.approvalLabel}>FOM</span>
        {!!approvals.manager ? (
          <GoCheck size={20} fill={theme.colors.green} />
        ) : (
          <ImHourGlass size={16} />
        )}
      </p>
    </div>
  );
};

const styles = {
  approvalLabel: css({
    display: "inline-block",
    margin: "0 4px",
  }),
};

export { ApprovalOverview };
