import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IssueFilters } from "../issue/types";
import {
  CustomSpaceTableSort,
  RoomSpaceFilters,
  RoomSpaceFilterTypes,
  RoomSpaceSortingKeys,
  RoomSpaceTableSort,
  SpacesSortingKeys,
} from "../space/types";
import {
  ErrorTypes,
  IssueFilterPayload,
  LoadingTypes,
  Notification,
  PopUp,
  RequiredDataState,
  RoomFilterPayload,
  SuccessTypes,
  ToggleMap,
} from "./types";

export interface UIState {
  notification?: Notification;
  errors: ErrorTypes[];
  loading: LoadingTypes[];
  success: LoadingTypes[];
  web: {
    popUp?: PopUp;
  };
  roomToggles: ToggleMap;
  issueFilters: IssueFilters;
  roomFilters: RoomSpaceFilters;
  roomSorting: RoomSpaceTableSort;
  customSpaceSorting: CustomSpaceTableSort;
  sidebarView: boolean;
  requiredDataState?: RequiredDataState;
}

const initialState: UIState = {
  notification: undefined,
  errors: [],
  loading: [],
  success: [],
  web: { popUp: undefined },
  roomToggles: {},
  issueFilters: {},
  roomFilters: {
    room_code: [],
  },
  roomSorting: {
    key: RoomSpaceSortingKeys.ROOM,
    asc: true,
  },
  customSpaceSorting: {
    key: SpacesSortingKeys.NAME,
    asc: true,
  },
  sidebarView: true,
  requiredDataState: undefined,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    addError(state, { payload }: PayloadAction<ErrorTypes>) {
      const errorIndex = state.errors.indexOf(payload);
      if (errorIndex === -1) {
        state.errors = [...state.errors, payload];
      }

      // Stop loading when error
      const loadingIndex = state.loading.indexOf(payload);
      if (loadingIndex > -1) {
        state.loading.splice(loadingIndex, 1);
      }
    },
    removeError(state, { payload }: PayloadAction<ErrorTypes>) {
      const errorIndex = state.errors.indexOf(payload);
      if (errorIndex > -1) {
        state.errors.splice(errorIndex, 1);
      }
    },

    addSuccess(state, { payload }: PayloadAction<SuccessTypes>) {
      const successIndex = state.success.indexOf(payload);
      if (successIndex === -1) {
        state.success = [...state.success, payload];
      }
      // stop loading on success
      const loadingIndex = state.loading.indexOf(payload);
      if (loadingIndex > -1) {
        state.loading.splice(loadingIndex, 1);
      }
    },
    removeSuccess(state, { payload }: PayloadAction<SuccessTypes>) {
      const successIndex = state.success.indexOf(payload);
      if (successIndex > -1) {
        state.success.splice(successIndex, 1);
      }
    },
    startLoading(state, { payload }: PayloadAction<LoadingTypes>) {
      // Reset error when loading a transaction
      const errorIndex = state.errors.indexOf(payload);
      if (errorIndex > -1) {
        state.errors.splice(errorIndex, 1);
      }

      const successIndex = state.errors.indexOf(payload);
      if (successIndex > -1) {
        state.success.splice(successIndex, 1);
      }

      const loadingIndex = state.loading.indexOf(payload);
      if (loadingIndex === -1) {
        state.loading = [...state.loading, payload];
      }
    },
    switchNavigationView(state, { payload }: PayloadAction<boolean>) {
      state.sidebarView = payload;
    },
    setNotification(
      state,
      { payload }: PayloadAction<Notification | undefined>,
    ) {
      state.notification = payload;
    },
    showPopUp(state, { payload }: PayloadAction<PopUp>) {
      state.web.popUp = payload;
    },
    hidePopUp(state) {
      state.web.popUp = undefined;
    },
    setRoomToggle(state, { payload }) {
      if (state.roomToggles[payload]) {
        delete state.roomToggles[payload];
      } else {
        state.roomToggles[payload] = 1;
      }
    },
    setRoomToggles(state, { payload }) {
      state.roomToggles = payload;
    },
    resetRoomToggles(state) {
      state.roomToggles = {};
    },
    setIssueFilter(
      state,
      { payload: { filterType, value } }: PayloadAction<IssueFilterPayload>,
    ) {
      if (value === undefined) {
        delete state.issueFilters[filterType];
      } else {
        //@ts-ignore
        state.issueFilters[filterType] = value;
      }
    },
    setRoomFilter(
      state,
      { payload: { filterType, value } }: PayloadAction<RoomFilterPayload>,
    ) {
      if (filterType === RoomSpaceFilterTypes.RESET) {
        state.roomFilters = initialState.roomFilters;
      } else if (!value) {
        delete state.roomFilters[filterType];
      } else if (filterType === RoomSpaceFilterTypes.ROOM_CODE) {
        const index =
          state.roomFilters[RoomSpaceFilterTypes.ROOM_CODE]?.indexOf(
            value.toString(),
          ) ?? -1;

        if (index > -1) {
          state.roomFilters[RoomSpaceFilterTypes.ROOM_CODE]?.splice(index, 1);
          return;
        }

        state.roomFilters[RoomSpaceFilterTypes.ROOM_CODE]?.push(
          value.toString(),
        );
      } else {
        // You shouldn't use @ts-ignore, it already was here and is hard to fix due to wrong initial setup.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        state.roomFilters[filterType] = value;
      }
    },
    setRoomSorting(state, { payload: { key } }) {
      let currentAsc = true;

      if (state.roomSorting.key === key) {
        currentAsc = !state.roomSorting.asc;
      }

      state.roomSorting = { key, asc: currentAsc };
    },
    setCustomSpaceSorting(state, { payload: { key } }) {
      let currentAsc = true;

      if (state.customSpaceSorting.key === key) {
        currentAsc = !state.customSpaceSorting.asc;
      }

      state.customSpaceSorting = { key, asc: currentAsc };
    },
    setRequiredDataState(state, { payload }: PayloadAction<RequiredDataState>) {
      state.requiredDataState = payload;
    },
  },
});

export const {
  setNotification,
  switchNavigationView,
  showPopUp,
  hidePopUp,
  addError,
  startLoading,
  addSuccess,
  removeError,
  removeSuccess,
  setRoomToggle,
  setRoomToggles,
  resetRoomToggles,
  setIssueFilter,
  setRoomFilter,
  setRoomSorting,
  setCustomSpaceSorting,
  setRequiredDataState,
} = uiSlice.actions;

export default uiSlice;
