import CleaningStatus from "./CleaningStatus";
import RoomStatus from "./RoomStatus";
import Room from "./Room";
import Action from "./Action";
import PeriodicTasks from "./PeriodicTasks";
import AssignHkMember from "./AssignHkMember";
import RoomCheckbox from "./RoomCheckbox";
import { RoomSpace } from "@store/space/types";

interface TableRowProps {
  spaceId: RoomSpace["id"];
  canEdit?: boolean;
}

// Defined as any since https://github.com/DefinitelyTyped/DefinitelyTyped/issues/41808
const TableRow: (props: TableRowProps) => JSX.Element[] = ({
  spaceId,
  canEdit,
}) => {
  return [
    canEdit && <RoomCheckbox spaceId={spaceId} />,
    canEdit && <Action spaceId={spaceId} />,
    <Room spaceId={spaceId} />,
    <RoomStatus spaceId={spaceId} />,
    <RoomStatus spaceId={spaceId} showArrivals />,
    <CleaningStatus spaceId={spaceId} />,
    canEdit && <PeriodicTasks spaceId={spaceId} />,
    canEdit && <AssignHkMember spaceIds={[spaceId]} single />,
  ].filter(Boolean) as JSX.Element[];
};

export default TableRow;
