import { FC, ReactNode } from "react";

interface SortProps {
  children: ReactNode;
  id: string;
  sort?: {
    key: string;
    asc: boolean;
  };
  onChange: (sortKey: string) => void;
}

const Sort: FC<SortProps> = ({ children, id, sort, onChange }) => {
  let imagePath: string;
  if (id === sort?.key) {
    if (sort?.asc) {
      imagePath = "/icons/sort-asc.svg";
    } else {
      imagePath = "/icons/sort-desc.svg";
    }
  } else {
    imagePath = "/icons/sort-inactive.svg";
  }

  const onClick = () => {
    onChange(id);
  };

  return (
    <div onClick={onClick} className="container">
      <span className="sort">
        <img src={imagePath} /> {children}
      </span>
      <style jsx>{`
        div.container {
          cursor: pointer;
        }
        span.sort > img {
          margin-right: 4px;
        }
      `}</style>
    </div>
  );
};

export default Sort;
