import React, { ReactNode } from "react";
import AssignMember from "@components/AssignMember";
import Assignee from "@components/Assignee";
import { RoomSpace, Space } from "@store/space/types";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import {
  selectAssigneeBySpaceId,
  selectAssigneeCounts,
  selectSpaceEntities,
} from "@store/space/spaceSelectors";
import { selectHKMembers } from "@store/user/userSelectors";
import {
  SpaceTransaction,
  SpaceTransactionTypes,
} from "@store/transaction/types";
import { User } from "@store/user/types";
import { handleSpaceTransaction } from "@store/space/spaceThunk";
import { resetRoomToggles } from "@store/ui/uiSlice";

interface AssignHkMemberProps {
  children?: ReactNode;
  spaceIds: Space["id"][];
  single?: boolean;
}

const AssignHkMember: React.FC<AssignHkMemberProps> = ({
  spaceIds,
  children,
  single,
}) => {
  const dispatch = useAppDispatch();

  const spaceEntities = useAppSelector(({ space }) =>
    selectSpaceEntities(space),
  );
  const assigneeCounts = useAppSelector(selectAssigneeCounts);
  const members = useAppSelector(selectHKMembers);

  let assignee;
  if (single && spaceIds.length == 1) {
    assignee = useAppSelector((state) =>
      selectAssigneeBySpaceId(state, spaceIds[0]),
    );
  }

  const assignRoom = (
    userId: User["id"],
    spaceId: Space["id"],
    assign: boolean,
  ) => {
    return {
      name: assign
        ? SpaceTransactionTypes.ASSIGN_SPACE
        : SpaceTransactionTypes.UNASSIGN_SPACE,
      spaceId,
      userId,
      createdAt: new Date().toISOString(),
    };
  };

  const onSetMember = ({
    userId,
    assign,
  }: {
    userId: User["id"];
    assign: boolean;
  }) => {
    const spaceTransaction: SpaceTransaction[] = [];

    if (assign) {
      spaceIds.forEach((spaceId) => {
        const space = spaceEntities[spaceId] as RoomSpace;
        space.room.users.forEach((u) => {
          spaceTransaction.push(assignRoom(u.id, spaceId, false));
        });
      });
    }
    spaceIds.forEach((spaceId) => {
      spaceTransaction.push(assignRoom(userId, spaceId, assign));
    });
    dispatch(handleSpaceTransaction(spaceTransaction));
    dispatch(resetRoomToggles());
  };

  return (
    <AssignMember
      memberId={assignee?.id}
      assigns={assigneeCounts}
      onSetMember={onSetMember}
      members={members}>
      {children ? (
        children
      ) : (
        <Assignee memberId={assignee?.id} isActive={assignee?.active} />
      )}
    </AssignMember>
  );
};

export default AssignHkMember;
