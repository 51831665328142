import React from "react";
import theme from "@styles/theme";
import Button from "@components/base/button";

interface NotActiveProps {
  info: string;
  buttonText: string;
  onClick: () => void;
}

const NotActive: React.FC<NotActiveProps> = ({ onClick, buttonText, info }) => {
  return (
    <>
      <div className="message-container">
        <div className="row">
          <p>{info}</p>
          <Button onClick={onClick}>{buttonText}</Button>
        </div>
      </div>
      <style jsx>{`
        .message-container {
          display: flex;
          background-color: ${theme.colors.yellow};
          width: 100%;
        }
        .row {
          display: flex;
          justify-content: space-between;
          padding: 16px 16px;
          align-items: center;
          width: 100%;
        }
        .message-container > p {
          max-width: 90%;
        }
      `}</style>
    </>
  );
};

export default NotActive;
