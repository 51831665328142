import { FC } from "react";
import PopUpWrapper from "@components/PopUpWrapper";
import { assignTask } from "./AssignTaskForm";
import theme from "@styles/theme";
import { selectRoomSpaceEntities } from "@store/space/spaceSelectors";
import useAppDispatch from "@hooks/useAppDispatch";
import { selectAllTaskIds } from "@store/task/taskSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { selectRoomToggles } from "@store/ui/uiSelectors";
import {
  SpaceTransaction,
  SpaceTransactionTypes,
} from "@store/transaction/types";
import { handleSpaceTransaction } from "@store/space/spaceThunk";
import { hidePopUp, resetRoomToggles, showPopUp } from "@store/ui/uiSlice";
import { Space } from "@store/space/types";
import { User } from "@store/user/types";
import { useTranslation } from "react-i18next";

enum ACTION_OPTIONS {
  unassignTasks = "unassign-tasks",
  assignTasks = "assign-tasks",
  unassignRooms = "unassign-rooms",
}

interface ActionButtonOptions {
  [key: string]: {
    label: string;
    active?: boolean;
    type: "assign" | "unassign";
  };
}

const AssignOptions: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const spaces = useAppSelector(selectRoomSpaceEntities);
  const taskIds = useAppSelector(({ task }) => selectAllTaskIds(task));
  const uiPos = useAppSelector(({ ui }) => ui.web.popUp?.pos);
  const roomToggles = useAppSelector(selectRoomToggles);
  const selectedRoomIds = Object.keys(roomToggles);

  const options: ActionButtonOptions = {
    [ACTION_OPTIONS.assignTasks]: {
      label: t("tasks.assignTasks"),
      type: "assign",
      active: taskIds.length > 0,
    },
    [ACTION_OPTIONS.unassignTasks]: {
      label: t("tasks.unassignTasks"),
      type: "unassign",
      active: selectedRoomIds.some((k) => {
        const tasks = spaces[k]?.room?.tasks;
        if (tasks === undefined) return false;
        return tasks.length > 0;
      }),
    },
    [ACTION_OPTIONS.unassignRooms]: {
      label: t("tasks.unassignUser"),
      type: "unassign",
      active: selectedRoomIds.some((k) => {
        const users = spaces[k]?.room?.users;
        if (users == undefined) return false;
        return users.length > 0;
      }),
    },
  };

  const unassignTasks = () => {
    const transactions: SpaceTransaction[] = [];
    selectedRoomIds.forEach((spaceId) => {
      const space = spaces[spaceId];
      space?.room?.tasks?.forEach((task) =>
        transactions.push(assignTask(task.id, spaceId, false)),
      );
    });

    dispatch(handleSpaceTransaction(transactions));
    dispatch(resetRoomToggles());
  };

  const assignRoom = (
    userId: User["id"],
    spaceId: Space["id"],
    assign: boolean,
  ) => {
    return {
      name: assign
        ? SpaceTransactionTypes.ASSIGN_SPACE
        : SpaceTransactionTypes.UNASSIGN_SPACE,
      spaceId,
      userId,
      createdAt: new Date().toISOString(),
    };
  };

  const unassignRooms = () => {
    const transactions: SpaceTransaction[] = [];
    selectedRoomIds.forEach((spaceId) => {
      const space = spaces[spaceId];
      space?.room?.users.forEach((assignee) =>
        transactions.push(assignRoom(assignee.id, spaceId, false)),
      );
    });

    dispatch(handleSpaceTransaction(transactions));
    dispatch(resetRoomToggles());
  };

  const onOptionSelected = (key: string) => {
    const selectedOption = options[key];
    switch (key as ACTION_OPTIONS) {
      case ACTION_OPTIONS.assignTasks: {
        if (!selectedOption.active) return;
        dispatch(
          showPopUp({
            type: "overview/assignTask",
            pos: uiPos!,
          }),
        );
        break;
      }
      case ACTION_OPTIONS.unassignTasks: {
        if (!selectedOption.active) return;
        unassignTasks();
        dispatch(hidePopUp());
        break;
      }
      case ACTION_OPTIONS.unassignRooms: {
        if (!selectedOption.active) return;
        unassignRooms();
        dispatch(hidePopUp());
        break;
      }
      default:
        break;
    }
  };

  return (
    <PopUpWrapper>
      <div className="container">
        <ul className="assignOptions">
          {Object.keys(options)
            .filter((key) => options[key].type == "assign")
            .map((k) => {
              const option = options[k];

              return (
                <li
                  className={`${option.active ? "" : "inactive"}`}
                  key={k}
                  onClick={() => {
                    onOptionSelected(k);
                  }}>
                  {option.label}
                </li>
              );
            })}
        </ul>
        <ul>
          {Object.keys(options)
            .filter((key) => options[key].type == "unassign")
            .map((k) => {
              const option = options[k];

              return (
                <li
                  className={`${option.active ? "" : "inactive"} unassign`}
                  key={k}
                  onClick={() => {
                    onOptionSelected(k);
                  }}>
                  {option.label}
                </li>
              );
            })}
        </ul>
      </div>
      <style jsx>{`
        div.container {
          width: 184px;
        }
        ul.assignOptions {
          padding-top: 15px;
          border-bottom: 2px solid ${theme.colors.mono200};
          margin-bottom: 16px;
        }
        li {
          padding-bottom: 16px;
          font-weight: 600;
          cursor: pointer;
        }
        li.inactive {
          cursor: default;
          opacity: 0.2;
        }
        li.unassign {
          color: ${theme.colors.negative};
        }
      `}</style>
    </PopUpWrapper>
  );
};

export default AssignOptions;
