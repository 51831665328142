import { FC, ReactNode, useEffect, useLayoutEffect } from "react";
import classNames from "classnames";
import { SubNavLabels } from ".";
import theme from "@styles/theme";
import getIcon from "./SidebarIcon";
import { css } from "@emotion/react";
import { selectSidebarView } from "@store/ui/uiSelectors";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import { switchNavigationView } from "@store/ui/uiSlice";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useTranslation } from "react-i18next";

interface SubLinksContainerProps {
  children: ReactNode;
  label: SubNavLabels;
  active: boolean;
  subNavigation: SubNavLabels;
  setSubNavigation: (subNav: SubNavLabels) => void;
}

const SubLinksContainer: FC<SubLinksContainerProps> = ({
  children,
  label,
  active,
  subNavigation,
  setSubNavigation,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const visible = useAppSelector(selectSidebarView);
  const showSubLinks = label === subNavigation;

  useEffect(() => {
    if (!visible) return;
    setSubNavigation(SubNavLabels.None);
  }, [visible]);

  const toggleSubNavigation = () => {
    setSubNavigation(subNavigation === label ? SubNavLabels.None : label);
    dispatch(switchNavigationView(showSubLinks));
  };

  useLayoutEffect(() => {
    if (active) setSubNavigation(label);
  }, []);

  return (
    <div>
      <div
        className={`row ${classNames({ active })}`}
        onClick={toggleSubNavigation}
        css={style.subMenuItem}>
        <li data-test-id="sidebar-link">
          {getIcon({ label, active })}
          <a
            className={` ${classNames({ active })} ${
              visible && !showSubLinks ? "smallView" : ""
            }`}>
            {t(`layout.menu.${label}`)}
          </a>
        </li>
        {showSubLinks ? (
          <FiChevronUp css={style.chevron} />
        ) : (
          <FiChevronDown css={style.chevron} />
        )}
      </div>
      {showSubLinks && <ul>{children}</ul>}
      <style jsx>{`
        div {
          margin: 0;
          padding: 0;
        }
        .row {
          display: flex;
          width: 225px;
          justify-content: space-between;
          cursor: pointer;
          overflow: hidden;
        }
        li {
          display: flex;
          align-items: center;
          align-self: flex-end;
          user-select: none;
          ransition: transition 200ms ease-in-out 50ms;
        }
        .row.active .fa-chevron-up,
        .row.active .fa-chevron-down,
        .row.active > li > a {
          color: #ffa500;
        }
        li > a {
          white-space: nowrap;
          color: ${theme.colors.white};
          line-height: 1;
          text-decoration: none;
          display: block;
          width: 100%;
          height: 100%;
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          line-height: 1;
          opacity: 1;
          transition: opacity 200ms ease-in-out 50ms;
        }
        .smallView {
          opacity: 1;
          transition: opacity 200ms ease-in-out 50ms;
        }
        li .smallView {
          opacity: 0;
        }
        ul {
          margin-top: 12px;
        }
        .fa-chevron-up,
        .fa-chevron-down {
          font-size: 16px;
          margin: 6px 20px;
          color: ${theme.colors.white};
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};
const style = {
  subMenuItem: css({
    ":hover li svg": {
      fill: theme.colors.accent,
    },
    ":hover li > a": {
      color: `${theme.colors.accent} !important`,
    },
    ":hover .fa-chevron-up": {
      color: theme.colors.accent,
    },
    ":hover .fa-chevron-down": {
      color: theme.colors.accent,
    },
  }),
  chevron: css({
    stroke: theme.colors.white,
    height: 24,
    width: 24,
    marginRight: 16,
  }),
};

export default SubLinksContainer;
