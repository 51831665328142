import {
  AllTransactionNames,
  AttachmentsTransactionTypes,
  IssueTransactionTypes,
  SpaceTransactionTypes,
} from "../types/transaction";
import { CustomSpace, RoomSpace, Space, SpaceType } from "../store/space/types";
import l from "@locale";
import * as Yup from "yup";
import theme from "@styles/theme";
import { UserRoles } from "@store/user/types";
import { DayReportTotal } from "@store/dayReport/types";
import { de, enUS, nl } from "date-fns/locale";

export const capitalizeFirstLetter = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const isRoomSpace = (space?: Space): space is RoomSpace =>
  space?.type === SpaceType.ROOM;
export const isCustomSpace = (space?: Space): space is CustomSpace =>
  space?.type === SpaceType.CUSTOM;

export const getMostImportantRole = (roles: UserRoles[]): UserRoles => {
  if (roles.length === 1) return roles[0];

  const mostImportantRole = [
    UserRoles.ADMIN,
    UserRoles.HOTEL_MANAGER,
    UserRoles.HK_MANAGER,
    UserRoles.TD_MANAGER,
    UserRoles.GS_MANAGER,
    UserRoles.HK_MEMBER,
    UserRoles.TD_MEMBER,
    UserRoles.GS_MEMBER,
    UserRoles.TD_CREATOR,
  ].find((role) => {
    return roles.includes(role);
  });
  return mostImportantRole || roles[0];
};

export const getEditableRoles = (role: UserRoles): UserRoles[] => {
  switch (role) {
    case UserRoles.ADMIN:
    case UserRoles.HOTEL_MANAGER:
      return Object.values(UserRoles);
    case UserRoles.HK_MANAGER:
      return [UserRoles.HK_MEMBER, UserRoles.HK_MANAGER];
    case UserRoles.TD_MANAGER:
      // Also add td_creater when supported on app
      return [UserRoles.TD_MEMBER, UserRoles.TD_MANAGER];
    case UserRoles.GS_MANAGER:
      return [UserRoles.GS_MEMBER, UserRoles.GS_MANAGER];
    default:
      return [];
  }
};

export const transactionErrorMessage = (name: AllTransactionNames): string => {
  switch (name) {
    case SpaceTransactionTypes.ASSIGN_SPACE:
      return l.t("actions.assign.failed", {
        value: l.t("common.space").toLowerCase(),
      });
    case SpaceTransactionTypes.UNASSIGN_SPACE:
      return l.t("actions.unassign.failed", {
        value: l.t("common.space").toLowerCase(),
      });
    case SpaceTransactionTypes.START_CLEANING:
      return l.t("actions.cleaning.start.failed");
    case SpaceTransactionTypes.STOP_CLEANING:
      return l.t("actions.cleaning.stop.failed");
    case SpaceTransactionTypes.FINISH_CLEANING:
      return l.t("actions.cleaning.finish.failed");
    case SpaceTransactionTypes.FINISH_INSPECTION:
      return l.t("actions.inspectionFinish.failed");
    case IssueTransactionTypes.START_WORK:
    case IssueTransactionTypes.FINISH_WORK:
    case IssueTransactionTypes.STOP_WORK:
      return l.t("actions.issueUpdate.failed");
    case IssueTransactionTypes.ASSIGN:
      return l.t("actions.assign.failed", {
        value: l.t("common.issue").toLowerCase(),
      });
    case AttachmentsTransactionTypes.ADD_ATTACHMENT:
      return l.t("actions.add.failed", {
        value: l.t("common.photos").toLowerCase(),
      });
    default:
      return l.t("actions.default.failed");
  }
};

export const transactionSuccessMessage = (
  name: AllTransactionNames,
): string => {
  switch (name) {
    case SpaceTransactionTypes.ASSIGN_SPACE:
      return l.t("actions.assign.success", {
        value: l.t("common.space").toLowerCase(),
      });
    case SpaceTransactionTypes.UNASSIGN_SPACE:
      return l.t("actions.unassign.success", {
        value: l.t("common.space").toLowerCase(),
      });
    case SpaceTransactionTypes.START_CLEANING:
      return l.t("actions.cleaning.start.success");
    case SpaceTransactionTypes.STOP_CLEANING:
      return l.t("actions.cleaning.stop.success");
    case SpaceTransactionTypes.FINISH_CLEANING:
      return l.t("actions.cleaning.finish.success");
    case SpaceTransactionTypes.FINISH_INSPECTION:
      return l.t("actions.inspectionFinish.success");
    case IssueTransactionTypes.START_WORK:
    case IssueTransactionTypes.FINISH_WORK:
    case IssueTransactionTypes.STOP_WORK:
      return l.t("actions.issueUpdate.success");
    case IssueTransactionTypes.ASSIGN:
      return l.t("actions.assign.success", {
        value: l.t("common.issue").toLowerCase(),
      });
    case AttachmentsTransactionTypes.ADD_ATTACHMENT:
      return l.t("actions.add.success", {
        value: l.t("common.photos").toLowerCase(),
      });
    default:
      return l.t("actions.default.success", {
        value: l.t("common.issue"),
      });
  }
};

export const Form = {
  async validate(
    schema: Yup.AnySchema,
    values: any,
  ): Promise<Record<string, string> | null> {
    try {
      await schema.validate(values, { abortEarly: false });
      return null;
    } catch (err: any) {
      const errors: any = {};
      err.inner.forEach((err: Yup.ValidationError) => {
        const currentKey = err.path;
        if (!currentKey) return;
        errors[currentKey] = err.message;
      });
      return errors;
    }
  },
};

export const getSpaceStatus = (space: RoomSpace) => {
  if (!space?.room || space?.type !== SpaceType.ROOM)
    return theme.colors.mono200;

  const room = space.room;
  if (room.vacant) return theme.colors.positive;
  if (room.staying) return theme.colors.primaryA;
  return theme.colors.negative;
};

export const forceToNumberString = (value: string, decimals = 0) => {
  let strippedValue = value.replace(/[^0-9.]/g, "");

  if (decimals === 0) return strippedValue.split(".")[0];

  if (strippedValue.startsWith("0")) strippedValue = strippedValue.substring(1);
  if (strippedValue.startsWith(".")) strippedValue = "0" + strippedValue;
  if (strippedValue.split(".")[1]?.length > decimals) {
    strippedValue = strippedValue.slice(0, strippedValue.length - 1);
  }

  if (strippedValue !== "0." && !Number(strippedValue)) return "0";
  return strippedValue;
};

export const isAdmin = (role: UserRoles) => role === UserRoles.ADMIN;

export const isSupervisor = (role: UserRoles) => {
  return [
    UserRoles.HK_MANAGER,
    UserRoles.ADMIN,
    UserRoles.HOTEL_MANAGER,
  ].includes(role);
};

export const isTechnicalManager = (role: UserRoles) => {
  return [
    UserRoles.TD_MANAGER,
    UserRoles.ADMIN,
    UserRoles.HOTEL_MANAGER,
  ].includes(role);
};

export const isFrontofficeManager = (role: UserRoles) => {
  return [UserRoles.ADMIN, UserRoles.HOTEL_MANAGER].includes(role);
};

export const totalHKDayReportDifference = (report: DayReportTotal) => {
  // Total sold + empty rooms but sold + not cleaned yesterday - sold but no clean needed - total cleaned
  return (
    report.sold +
    report.carriedOverFromYesterday +
    report.dayUse -
    report.noShow -
    report.carriedOver -
    (report.orange + report.green + report.doNotDisturb + report.nonGreenSkip) -
    report.cleaned
  );
};

export const getLanguage = () => {
  const language = localStorage.getItem("language");
  switch (language) {
    case "en":
      return enUS;
    case "de":
      return de;
    default:
      return nl;
  }
};
