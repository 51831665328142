import React from "react";
import withAuth from "@helpers/withAuth";
import Layout from "@components/Layout";
import RateTypesScreen from "@screens/RateTypes";

const RateTypesPage: React.FC = () => {
  return (
    <Layout active="settings/day-reports/rate-types">
      <RateTypesScreen />
    </Layout>
  );
};

export default withAuth(RateTypesPage);
