import { FC, useEffect, useState } from "react";
import QrList from "./QrList";
import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { User, UserRoles } from "@store/user/types";
import { selectCurrentProperty } from "@store/property/propertySelector";
import { selectHKMembers, selectUserEntities } from "@store/user/userSelectors";
import useAppSelector from "@hooks/useAppSelector";
import Breadcrumb from "@components/base/breadcrumb";
import SearchInput from "@components/base/search-input";
import Button from "@components/base/button";
import theme from "@styles/theme";
import { jsPDF } from "jspdf";
import Notification from "@components/base/notification";
import useAppDispatch from "@hooks/useAppDispatch";
import { fetchUsers } from "@store/user/userThunk";
import { useTranslation } from "react-i18next";
import { NavLabels } from "@components/Layout";

const AppLoginScreen: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>("");
  const [notificationType, showNotificationType] = useState<string>("");
  const [teamMembers, setTeamMembers] = useState<User[]>([]);
  const [qrRefs, setQrRefs] = useState<NodeListOf<HTMLCanvasElement>>();
  const property = useSelector(selectCurrentProperty);
  const memberEntities = useAppSelector(({ user }) => selectUserEntities(user));
  const members = useAppSelector(selectHKMembers);

  useEffect(() => {
    const sortedAndFilteredMembers = members
      .filter((member) => {
        if (member.profile.name == null) return false;

        return member.profile.name
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      })
      .sort((a: User, b: User) => {
        return a.profile?.name?.localeCompare(b.profile?.name);
      });

    setTeamMembers(sortedAndFilteredMembers);
  }, [search, members]);

  const getQrRefs = (isLast: boolean) => {
    if (!isLast) return;
    const canvas: NodeListOf<HTMLCanvasElement> =
      document.querySelectorAll(".qr > canvas");
    setQrRefs(canvas);
  };

  useEffect(() => {
    dispatch(fetchUsers());
    const notificationTimer = setTimeout(() => showNotificationType(""), 5000);
    return () => {
      clearTimeout(notificationTimer);
    };
  }, []);

  const downloadPdf = async () => {
    const pdf = new jsPDF();
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();

    const imageWidth = width / 4;
    const x = width / 2;
    const y = height / 2 - imageWidth;

    qrRefs &&
      qrRefs.forEach((ref, index) => {
        const dataUrl = ref.toDataURL("image/png");

        if (index !== 0) pdf.addPage();
        pdf
          .setFontSize(15)
          .text(memberEntities?.[ref.id]?.profile.name ?? "", x, y - 10, {
            align: "center",
          });
        pdf
          .setFontSize(10)
          .text(t(`roleNames.${UserRoles.HK_MEMBER}`), x, y - 5, {
            align: "center",
          });
        pdf.addImage(
          dataUrl,
          "png",
          x - imageWidth * 0.5,
          y,
          imageWidth,
          imageWidth,
        );
      });

    try {
      await pdf.save(`${property?.code}-QR-codes.pdf`, { returnPromise: true });
      showNotificationType("positive");
    } catch (error) {
      showNotificationType("negative");
    }
  };

  return (
    <>
      <Breadcrumb labels={[NavLabels.AppLogin]} />
      <section data-test-id="hk-login" css={styles.section}>
        <div css={styles.criteria}>
          <div>
            <SearchInput
              onChange={setSearch}
              value={search}
              placeholder={t("search.name")}
              label={`${t("search.title")}:`}
            />
          </div>
          <Button onClick={downloadPdf} kind="primary">
            {t("common.export", { value: ".pdf" })}
          </Button>
        </div>
        {notificationType !== "" && (
          <div
            data-test-id="notification-wrapper"
            css={styles.notificationWrapper}>
            {notificationType === "positive" && (
              <Notification
                kind="positive"
                content={t("actions.qrCode.success")}
              />
            )}

            {notificationType === "negative" && (
              <Notification
                kind="negative"
                content={t("actions.qrCode.failed")}
              />
            )}
          </div>
        )}
        <div css={styles.box}>
          <ul css={styles.list}>
            {teamMembers?.length > 0 &&
              teamMembers.map((teamMember) => (
                <QrList
                  key={teamMember.id}
                  teamMember={teamMember}
                  getQrRefs={getQrRefs}
                  isLast={
                    teamMembers[teamMembers.length - 1].id === teamMember.id
                  }
                />
              ))}
          </ul>
        </div>
      </section>
    </>
  );
};

const styles = {
  section: css({
    backgroundColor: theme.colors.backgroundPrimary,
  }),
  criteria: css({
    position: "relative",
    padding: theme.sizing.scale1600,
    backgroundColor: theme.colors.white,
    display: "flex",
    flexDirection: "row",
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  notificationWrapper: css({
    position: "absolute",
    top: 238,
    right: 20,
  }),
  box: css({
    overflowY: "scroll",
    height: "calc( 100vh - 265px)" /* subtracts combined heading heights */,
  }),
  list: css({
    margin: "0px 20px 30px 20px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "left",
    alignContent: "flex-start",
  }),
};

export default AppLoginScreen;
