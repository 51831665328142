import { Translation } from "./index";
import { RoomStates } from "@store/space/types";
import { NavLabels, SubNavLabels } from "@components/Layout";
import { IssueState } from "@store/issue/types";
import { UserRoles } from "@store/user/types";

const en: Translation = {
  common: {
    all: "All",
    notApplicable: "N / A",
    cancel: "Cancel",
    delete: "Delete",
    submit: "Submit",
    back: "Go back",
    filter: "Filter",
    logout: "Logout",
    loading: "Loading...",
    space: "Room",
    spaces: "Rooms",
    issue: "Task",
    issues: "Tasks",
    photos: "Photos",
    area: "Area",
    areas: "Areas",
    property: "Property",
    roomType: "Room type",
    customSpace: "Custom room",
    transactions: "Data",
    room: "Room",
    action: "Action",
    date: "Date",
    export: "Export {{value}}",
    save: "Save",
    total: "Total",
    change: "Change",
    name: "Name",
    giveName: "Enter a name",
    floor: "Floor",
    youSure: "Are you sure?",
    unknown: "Unknown",
    noData: "No data available",
    add: "Add",
    tryAgain: "Try again",
    remark: "Remark",
    resetFilter: "Reset filter",
    general: "General",
    rooms: "Rooms",
    optional: "Optional",
    view: "View",
    priority: "Priority",
    tasks: "Periodic tasks",
    saveChanges: "Save changes",
  },
  layout: {
    refresh: "Refresh",
    refreshMessage: 'Press "Refresh" for the most up-to-date data.',
    fold: "Fold",
    menu: {
      [NavLabels.Dashboard]: "Dashboard",
      [NavLabels.Overview]: "Rooms",
      [NavLabels.AppLogin]: "Log in",
      [NavLabels.DayReports]: "Week overview",
      [NavLabels.TdOverview]: "Technical Service",
      [NavLabels.GsOverview]: "General Service",
      [NavLabels.TeamSettings]: "Manage team",
      [NavLabels.AreaSettings]: "Manage sections",
      [NavLabels.CustomRoomSettings]: "Manage rooms",
      [NavLabels.TaskSettings]: "Manage tasks",
      [NavLabels.DayReportSettings]: "Manage day closure",
      [NavLabels.ActionsLog]: "Room Actions",
      [SubNavLabels.Settings]: "Settings",
      [SubNavLabels.None]: "None",
      [NavLabels.DayOverview]: "Day report",
      [NavLabels.ExtraTasks]: "Extra tasks",
      [NavLabels.TypeGroups]: "Rate type groups",
    },
  },
  dayreport: {
    endOfDay: "End of day",
    weekOverview: "Week overview",
    extra: "Extra tasks",
    deletedExtra:
      "This extra has been deleted. You can still view the value but you can no longer change it.",
    addRemark: "Add a remark",
    writeRemark: "Write your remark here",
    noRemarks: "No remarks have been added yet.",
    noChanges: "No changes have been made yet.",
    dayView: "Day view",
    saveAndApprove: "Save and approve",
    approve: "Approve",
    approval: "Approval",
    unsavedChanges: "You have unsaved changes. Are you sure you want to leave?",
    difference: "Difference",
    sold: {
      title: "Sold",
      description:
        "all rooms that we receive from PMS as sold, this is collected every night at 03:00 from the previous day.",
    },
    carriedOverFromYesterday: {
      title: "Carried over from yesterday",
      description: "all rooms that were not cleaned from yesterday.",
    },
    dayuse: {
      title: "Day use",
      description:
        "all rooms that we receive from PMS as day use, this is collected every night at 03:00 from the previous day.",
    },
    carriedOver: {
      title: "Carried over to today",
      description:
        "rooms that are still dirty but left yesterday (and not today).",
    },
    totalDescription:
      "the sold, day use and carried over rooms added together.",
    green: {
      title: "Green Choice",
      description:
        "rooms where the guest (via email) or reception (via ServiceV4) has indicated that no cleaning is required (green choice).",
    },
    orange: {
      title: "Orange Choice",
      description:
        "rooms where the guest (via email) or reception (via ServiceV4) has indicated that only towels or a bag (orange choice) are required + the rooms that have been set to 'bag hung' via the HKTD app.",
    },
    nonGreenSkip: {
      title: "cleaning skipped",
      description:
        "rooms where the guest without green choice chooses not to clean the room.",
    },
    doNotDisturb: {
      title: "Do not disturb",
      description:
        "the rooms that have been set to 'do not disturb' via the HKTD app.",
    },
    cleaned: {
      title: "To clean",
      description:
        "these are all sold, day use and carried over rooms added together where the exceptions (green, orange, do not disturb) have been removed.",
    },
    totalCleaned: {
      title: "Total cleaned",
      description:
        "these are the rooms that have been set to clean via the HKTD app.",
    },
    note: {
      title: "Note",
      description:
        "certain numbers can be adjusted manually by the HKS or FOM, the adjusted numbers will then be included in the calculation.",
    },
    calculate: "How is this calculated",
    noData: "No data available. This data will be retrieved at a later time.",
    noShow: "No show",
    clean: "Cleaned",
    oldValue: "Old value",
    new_Value: "New value",
    whoMay: {
      send: "Who may send the day report",
      edit: "Who may edit the day report",
      create: "Who may create extra tasks",
      receive: "Who may receive the day report",
    },
    setupEmail: "Setup email addresses",
    rateTypeGroup: "Change rate type group",
    editRateType: "View or edit rate type",
    week: "Week",
    editWarning: "Manual changes have been applied",
    send: {
      title: "Are you sure you want to send the weekly review?",
      description: "This weekly review is sent to:",
      button: "Yes, send this week",
      self: "Send",
      export: "Export overview",
    },
    manage: "Managing the day report",
    popover: {
      green: "Green choice <strong>{{number}}</strong>",
      orange: "Orange choice <strong>{{number}}</strong>",
      nonGreenSkip: "Skipped cleaning <strong>{{number}}</strong>",
      doNotDisturb: "Do not disturb <strong>{{number}}</strong>",
    },
    exceptions: "Exceptions",
    enableEmail:
      "To take full advantage of the weekly overview (and the day end), this module must be turned on.",
    noEmail: "No email has been added, the weekly summary cannot be sent.",
    settings: {
      reportPermission: {
        fom: "Front office",
        fomHk: "Front office & Housekeeping Supervisor",
        hk: "Housekeeping Supervisor",
        gs: "General service Supervisor",
        hkGs: "Housekeeping & General service Supervisor",
        fomGs: "Front office & General service",
        fomHkGs: "Front office, Housekeeping & General service Supervisor",
      },
    },
  },
  hkStatistics: {
    tableContent: {
      arrivals: "Arrivals",
      atStart: "Start",
      carriedOver: "Carried over",
      carriedOverFromYesterday: "Carried over from yesterday",
      currently: "Currently",
      departures: "Departures",
      dirtyVacant: "All vacant/dirty rooms",
      notAvailable: "Statistics are not (yet) available",
      roomsCleaned: "Rooms cleaned",
      stayovers: "Stayovers",
      rateTypesCleaned: "Suites cleaned",
    },
    emptyMessage: "The statistics could not be retrieved.",
    atStart: "Start",
    currently: "Currently",
  },
  pmsNotification: {
    content: {
      bad: "The room statuses are not up-to-date due to a connection problem with PMS.",
      poor: "The room statuses may not be up-to-date due to a connection problem with PMS.",
    },
    heading: {
      bad: "Unable to connect to PMS for a longer time",
      poor: "Unable to connect to PMS",
    },
    specific: {
      amadeus:
        "Ask the front desk to create a case with Amadeus (hospitality.support@amadeus.com) with the subject: 'Hybrid Cloud Service is down for Hotel {{propertyName}}'",
    },
  },
  actions: {
    export: {
      failed: "Exporting {{value}} failed.",
    },
    assign: {
      failed: "{{value}} is not assigned.",
      success: "{{value}} assigned successfully.",
    },
    unassign: {
      failed: "Unassigning {{value}} failed.",
      success: "Unassigning {{value}} successful.",
      success_plural: "Unassigning {{value}} successful.",
    },
    cleaning: {
      start: {
        failed: "Starting cleaning failed",
        success: "Starting cleaning successful",
      },
      stop: {
        failed: "Stopping cleaning failed",
        success: "Stopping cleaning successful",
      },
      finish: {
        failed: "Cannot set room to clean",
        success: "Room set to clean successfully",
      },
    },
    inspectionFinish: {
      failed: "Inspecting room failed",
      success: "Room inspection successful",
    },
    issueUpdate: {
      failed: "Task status update failed",
      success: "Task status update successful",
    },
    add: {
      failed: "Adding {{value}} failed.",
      success: "Adding {{value}} successful.",
      success_plural: "Adding {{value}} successful.",
      userFailed:
        "Note: it is not possible to use the same email address for multiple users/hotels.",
    },
    default: {
      failed: "Failed!",
      success: "{{value}} update successful",
    },
    update: {
      failed: "Updating {{value}} failed.",
      success: "{{value}} update successful",
      success_plural: "{{value}} update successful",
    },
    delete: {
      failed: "Deleting {{value}} failed.",
      success: "{{value}} delete successful",
      success_plural: "{{value}} delete successful",
    },
    approved: {
      failed: "Approving {{value}} failed.",
      success: "{{value}} approved successfully",
    },
    send: {
      failed: "Sending {{value}} failed.",
      success: "{{value}} send successful",
    },
    refresh: {
      failed: "Refreshing {{value}} failed.",
      success: "{{value}} refreshed successfully",
    },
    switch: {
      failed: "Switching {{value}} failed.",
      languageFailed: "Could not switch notification language.",
      success: "{{value}} switched!",
    },
    qrCode: {
      failed: "Exporting the QR-codes failed.",
      success: "The QR codes have been successfully downloaded.",
    },
    fetch: {
      failed:
        "The information cannot be retrieved at the moment.\n  Check your internet and try again.\n\n If the problem persists, try logging in again.",
    },
  },
  hk: {
    description: "Description: {{description}}",
    leavers: "Leavers",
    stayers: "Stayers",
    roomState: {
      [RoomStates.OUT_OF_INVENTORY]: "Out of inventory",
      [RoomStates.OUT_OF_ORDER]: "Out of order",
      cleanTowels: "towels cleaned",
      dirtyTowels: "towels still dirty",
      dirtyAndInspected: "dirty and inspected",
      cleanAndInspected: "clean and inspected",
      clean: "clean",
      dirty: "dirty",
      extra: {
        vacantToday: "vacant since today",
        leaving: "leaving",
        occupiedToday: "occupied since today",
        staying: "occupied | {{current}}/{{total}}",
        vacant_zero: "vacant since today",
        vacant_one: "vacant | {{count}} day",
        vacant_other: "vacant | {{count}} days",
        orange: "orange choice",
        green: "skip cleaning (green choice)",
        nonGreenSkip: "skip cleaning",
        doNotDisturb: "do not disturb",
      },
    },
    amountExtra: "{{amount}} extra tasks",
    filters: {
      roomState: "Room state (+ extras)",
      arrivals: "Arrivals (+ extras)",
      cleaningState: "Cleaning state",
      tasks: "Tasks",
      user: "User",
      roomsWithExtra: "Rooms with extra tasks",
      roomsWithTasks: "Rooms with tasks",
      occupiedToday: "Occupied since today",
      rooms: {
        dirty: "Dirty rooms",
        clean: "Clean rooms",
      },
    },
    selectedRooms: "selected rooms: {{count}} ",
    assignRoom: "Assign room",
    showQrCode: "Hover your mouse over this area for the QR code",
  },
  user: {
    sessionExpired: "Your session has expired. Please log in again.",
    self: "User",
    role: "Role",
    switch: 'Switch "{{value}}"',
  },
  tasks: {
    delete: {
      title: "Delete {{value}}",
      description:
        "Are you sure you want to delete {{value}}? Deletion cannot be undone. Deletion will take effect from the next working day.",
    },
    number: "Task {{task}}",
    add: "Add task",
    assign: "Assign",
    assignTask: "Assign task",
    assignTasks: "Assign tasks",
    unassignTasks: "Unassign tasks",
    unassignUser: "Unassign user",
    all: "+ Add an extra task",
    empty: "No periodic tasks created yet.",
    labelPlaceholder: "Cleaning",
    edit: "Edit task",
    remove: "Remove task",
  },
  actionLog: {
    actions: {
      space: {
        clean: {
          start: "cleaning started",
          stop: "cleaning stopped",
          finish: "cleaning finished",
          skip: "cleaning skipped",
        },
        assign: {
          add: "cleaner assigned",
          remove: "cleaner unassigned",
        },
        inspect: {
          finish: "inspection finished",
        },
      },
      issue: {
        work: {
          start: "task started",
          stop: "task stopped",
          finish: "task resolved",
          wait: "task in order",
        },
        report: {
          create: "new task reported",
          attach: "attachment added",
        },
        assign: {
          add: "task assigned",
          remove: "task unassigned",
        },
      },
    },
  },
  search: {
    title: "Search",
    rooms: "Search a room",
    noResults: "No {{object}} found for this search query",
    name: "Search by name",
    employee: "Search an employee",
    notFound:
      "Unfortunately, no {{search}} found for the current search query and/or filters.",
    floor: "Select a floor",
    role: "Select a role",
    task: "Search a task",
    roomOrHouseKeeper: "Search a room or housekeeper",
    location: "Search a location",
    taskPersonRoom: "Search a task, person or room",
    rateType: "Search a rate type",
    function: "Select a function",
  },
  area: {
    name: "Section name",
    numberOfRooms: "Number of rooms",
    assignedRooms: "Assigned rooms",
    noRoomsAssigned: "No rooms assigned yet",
    emptyMessage:
      "No sections found. Please create a section to assign rooms to.",
    delete: {
      action: "Remove from section",
      description:
        "You are about to remove assigned rooms from a section. \nThis action cannot be undone.",
      area: "You are about to remove area '{{count}}'. \nThis action cannot be undone.",
    },
    assignSection_zero: "Assign to section",
    assignSection: "Assign to section ({{count}})",
    select: "Select a section",
    count: "Number of rooms: {{count}}",
    toAssign: "Assign a section",
    remove: "Remove section",
    update: "Update section",
    add: "Add section",
    charLeft_one: "{{count}} character left",
    charLeft_other: "{{count}} characters left",
    charLeft_zero: "No characters left",
  },
  counts: {
    room_one: "{{count}} Room",
    room_other: "{{count}} Rooms",
  },
  customroom: {
    create: {
      title: "Create public space",
      submit: "Create room",
      add: "Add room",
      nameRequired: "The public space must have a name",
      nameInUse: "This name is already in use.",
      groupRequired: "Groupname is required",
    },
    changeTitle: "Change room",
    floor: "Floor {{floor}}",
    tooltip: "Tip: a short title works best!",
  },
  extra: {
    description: "description of extra",
    hourAndTask: "Extra tasks and hours",
    newTask: "New task",
    typeName: "Enter a name",
    noExtra: "No extra tasks have been added yet.",
    newTaskAction: "Click to view or edit extras",
    self: "Extras",
  },
  login: {
    alreadyadded: "The email address {{email}} has already been added.",
    invalid: "The email address {{email}} is not valid.",
    enterNew: "Enter a new email address",
    self: "E-mail address",
    password: "Password",
    loginError: "Incorrect email address or password",
    forgotPassword: "Forgot password?",
  },
  issue: {
    typeTaskName: "Enter a task name",
    typeReporterName: "Enter a reporter name",
    createdBy: "Created by",
    reportedBy: "Reported by",
    createdAt: "Created at",
    addRemark: "Add a remark to this task",
    priority: "Does this task have priority?",
    change: "Change task",
    create: "Create task",
    addNewTask: "Create new task",
    newTask: "New task",
    states: {
      title: "Statusses",
      [IssueState.OPEN]: "Open",
      [IssueState.IN_PROGRESS]: "In progress",
      [IssueState.ON_HOLD]: "On hold",
      [IssueState.ORDERED]: "Ordered",
      [IssueState.DONE]: "Done",
      all: "All",
    },
    createDate: "Create date",
    taskName: "Task name",
    status: "Status",
    assignee: "Assignee",
    changeLog: "Change log",
    changeDetails: "Change details",
    delete: {
      title: "Delete task",
      action: "Delete task",
      description:
        "You are about to delete task {{label}}. \nThis action cannot be undone.",
    },
    inactive: {
      title: "Set task to inactive",
      action: "Why do you want to set this task to inactive?",
      placeholder: "Add a reason",
      label: "Reason",
    },
    edit: "Change task",
    view: "View task details",
    addImage: "Add image",
    export: "Export tasks",
    newIssueModal: {
      createdIssuesTitle: "Other task for this space",
      columns: {
        date: "Date",
        task: "Task",
        status: "Status",
      },
    },
  },
  rateType: {
    create: "New ratetype",
    update: "Update ratetype",
    delete: "Delete ratetype",
    groupName: "Groupname",
    groupDescription: "Enter a groupname",
    defaultGroup:
      "You cannot delete the default group. You may assign the room types to another group.",
    roomType: "Room type",
    belongsTo: "Belongs to",
    deleteDescription:
      "The deleted room types will be restored under the group 'Rooms' or 'Suites'",
    deleteConfirmation:
      "You are about to delete rate type {{name}}. \nThis action cannot be undone.",
    groups: "rateType groups",
    group: "Group",
    groupedRatesInfo: "Changes will only take effect from next Monday.",
    countTypes: "Amount of room types",
  },
  segment: {
    ["Groepen"]: "Groups",
    ["Alle kamertypes"]: "All room types",
    all: "All",
  },
  team: {
    addMember: "Add team member",
    detail: "Details",
    removeMember: "Remove team member",
    inputName: "Enter a name",
    inputEmail: "Enter an email address",
    inputEmailInfo:
      "By entering an email address, the email will receive an invitation to create an account.",
    deleteConfirmation:
      "You are about to delete {{value}}. \nThis action cannot be undone.",
  },
  roleNames: {
    [UserRoles.ADMIN]: "Admin",
    [UserRoles.HOTEL_MANAGER]: "Hotel manager",
    [UserRoles.HK_MANAGER]: "Housekeeping manager",
    [UserRoles.HK_MEMBER]: "Housekeeper",
    [UserRoles.TD_MANAGER]: "TS manager",
    [UserRoles.TD_MEMBER]: "TS employee",
    [UserRoles.TD_CREATOR]: "TS creator",
    [UserRoles.GS_MANAGER]: "GS manager",
    [UserRoles.GS_MEMBER]: "GS employee",
    [UserRoles.NONE]: "None",
  },
};

export default en;
