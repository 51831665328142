import { FC, useEffect } from "react";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import CustomRoomTable from "./components/CustomRoomsTable";
import CustomSpacePopOver from "./components/CustomSpacePopOver";
import { Block } from "baseui/block";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { newCustomSpace } from "@store/space/types";
import { createCustomSpace, fetchSpaces } from "@store/space/spaceThunk";
import Button from "@components/base/button";
import useAppDispatch from "@hooks/useAppDispatch";
import Breadcrumb from "@components/base/breadcrumb";
import { useTranslation } from "react-i18next";
import { NavLabels, SubNavLabels } from "@components/Layout";

const CustomRooms: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSubmit = (customSpace: newCustomSpace) => {
    dispatch(
      createCustomSpace({
        label: customSpace.label,
        floor: customSpace.floor,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchSpaces());
  }, [dispatch]);

  return (
    <>
      <Breadcrumb
        labels={[SubNavLabels.Settings, NavLabels.CustomRoomSettings]}
      />
      <section css={styles.section}>
        <Block css={styles.modalWrapper}>
          <StatefulPopover
            content={({ close }) => (
              <CustomSpacePopOver
                title={t("customroom.create.title")}
                submitTitle={t("customroom.create.submit")}
                onCancel={close}
                handleSubmit={(space: newCustomSpace) => {
                  handleSubmit(space);
                  close();
                }}
                type="create"
              />
            )}
            placement={PLACEMENT.bottomRight}
            focusLock
            returnFocus
            renderAll
            autoFocus>
            <div>
              <Button>{t("customroom.create.add")}</Button>
            </div>
          </StatefulPopover>
        </Block>
        <CustomRoomTable />
      </section>
    </>
  );
};

const styles = {
  section: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: theme.sizing.scale1600,
    overflowY: "scroll",
  }),
  modalWrapper: css({
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.sizing.scale800,
  }),
};

export default CustomRooms;
