import { FC, useState } from "react";
import PopUpWrapper from "@components/PopUpWrapper";
import { css } from "@emotion/react";
import { selectCurrentArea } from "@store/area/areaSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { createArea, updateArea } from "@store/area/areaThunk";
import Button from "@components/base/button";
import { overrideButtonStyles } from "@components/base/button/overrides";
import Input from "@components/base/input";
import useAppDispatch from "@hooks/useAppDispatch";
import { hidePopUp } from "@store/ui/uiSlice";
import { useTranslation } from "react-i18next";

const AreaNameForm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const area = useAppSelector(selectCurrentArea);
  const [name, setName] = useState<string>(area?.label ?? "");
  const [charactersRemaining, setCharactersRemaining] = useState<number>(20);

  const canSubmit = area?.label === name || !name?.length;

  const onNameChange = (text: string) => {
    const charsRemaining = 20 - text.length;
    if (charsRemaining < 0) return;
    setCharactersRemaining(charsRemaining);
    setName(text);
  };

  const onSubmitName = () => {
    if (!name.length) return;

    if (area === undefined) {
      dispatch(createArea({ label: name }));
    } else {
      dispatch(updateArea({ label: name }, area.id));
    }
    dispatch(hidePopUp());
  };

  return (
    <PopUpWrapper>
      <div css={styles.wrapper}>
        <Input
          autoFocus
          value={name}
          placeholder={t("area.name")}
          onChangeText={(text) => onNameChange(text)}
        />
        <p css={styles.text}>
          {t("area.charLeft", { count: charactersRemaining })}
        </p>

        <Button
          onClick={onSubmitName}
          disabled={canSubmit}
          overrides={overrideButtonStyles({
            BaseButton: {
              alignSelf: "flex-end",
              marginTop: "16px",
            },
          })}>
          {t("common.save")}
        </Button>
      </div>
    </PopUpWrapper>
  );
};

const styles = {
  text: css({ alignSelf: "flex-end" }),
  wrapper: css({
    display: "flex",
    flexDirection: "column",
  }),
};

export default AreaNameForm;
