import React from "react";
import withAuth from "@helpers/withAuth";
import Layout from "@components/Layout";
import WeekOverviewScreen from "@screens/WeekOverview";

const DayReportDetailPage: React.FC = () => {
  return (
    <Layout active="day-reports/week">
      <WeekOverviewScreen />
    </Layout>
  );
};

export default withAuth(DayReportDetailPage);
