import { FC } from "react";
import { css } from "@emotion/react";
import TextareaInput from "@components/base/text-area";
import Button from "@components/base/button";
import {
  generateButtonColors,
  overrideButtonStyles,
} from "@components/base/button/overrides";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";

interface CreateEndOfDayRemarkProps {
  submitRemark: () => void;
  setRemark: (remark: string) => void;
  remark?: string;
  disabled?: boolean;
}

const CreateEndOfDayRemark: FC<CreateEndOfDayRemarkProps> = ({
  submitRemark,
  remark,
  setRemark,
}) => {
  const { t } = useTranslation();
  return (
    <div css={styles.container}>
      <h2>{t("common.remark")}</h2>
      <p css={styles.title}>{t("dayreport.addRemark")}</p>

      <TextareaInput
        value={remark ?? ""}
        placeholder={t("dayreport.writeRemark")}
        onChangeText={setRemark}
        useFullHeight={true}
      />

      <div css={styles.buttonContainer}>
        <Button
          onClick={submitRemark}
          disabled={!remark}
          kind="secondary"
          overrides={overrideButtonStyles({
            BaseButton: ({ $theme, $disabled }) => ({
              ":hover": {
                backgroundColor: "transparant",
                color: $theme.colors.accent200,
                borderColor: $theme.colors.accent200,
              },
              ...generateButtonColors({
                $disabled,
                color: $theme.colors.accent,
              }),
            }),
          })}>
          {t("dayreport.addRemark")}
        </Button>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: "flex",
    flex: "1",
    flexDirection: "column",
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.mono200}`,
    borderBottom: 0,
    padding: `${theme.sizing.scale1600} ${theme.sizing.scale800}`,
  }),
  buttonContainer: css({
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
  }),
  title: css({
    alignSelf: "flex-start",
    maxWidth: "500px",
    lineHeight: "30px",
    marginBottom: theme.sizing.scale400,
    fontWeight: 600,
  }),
};

export default CreateEndOfDayRemark;
