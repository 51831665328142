import { FC } from "react";
import { Spinner } from "baseui/spinner";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

const SpinnerLoader: FC = () => {
  const { t } = useTranslation();
  return (
    <div css={styles.loader}>
      <Spinner size={150} color="green" />
      <h3 style={{ padding: "16px" }}>{t("common.loading")}</h3>
    </div>
  );
};

const styles = {
  loader: css({
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  }),
};

export default SpinnerLoader;
