import { Selector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "..";
import { rateTypeAdapter } from "./rateTypeSlice";
import { RoomCode } from "./types";

const selectSelf = (state: RootState) => state.rateType;

export const selectRoomCodeEntities: Selector<
  RootState,
  Record<string, RoomCode>
> = createSelector(selectSelf, (rateType) => {
  return Object.values(rateType.entities).reduce(
    (result: Record<string, RoomCode>, entity) => {
      entity?.roomCodes.forEach((roomCode) => {
        result[roomCode] = {
          roomCode,
          rateType: { id: entity.id, name: entity.name },
        };
      });
      return result;
    },
    {},
  );
});

export const selectAllRoomCodes: Selector<RootState, RoomCode[]> =
  createSelector(selectSelf, (rateType) => {
    return Object.values(rateType.entities)
      .reduce((result: RoomCode[], entity) => {
        entity?.roomCodes.forEach((roomCode) => {
          result.push({
            roomCode,
            rateType: { id: entity.id, name: entity.name },
          });
        });
        return result;
      }, [])
      .sort((a, b) => a.rateType.name.localeCompare(b.rateType.name));
  });

export const { selectById: selectRateTypeById, selectAll: selectAllRateTypes } =
  rateTypeAdapter.getSelectors();
