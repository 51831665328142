import { EntityId } from "@reduxjs/toolkit";
import { FC } from "react";
import { selectVacantBySpaceId } from "@store/space/spaceSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { useTranslation } from "react-i18next";

type StayLeaveTableProps = {
  ids: EntityId[];
};

const StayLeaveTable: FC<StayLeaveTableProps> = ({ ids }) => {
  const { t } = useTranslation();
  const StayLeaveTotal = useAppSelector((state) =>
    selectVacantBySpaceId(state, ids),
  );
  return (
    <>
      <ul>
        <li>
          <span>{StayLeaveTotal.leavers}</span> {t("hk.leavers")}
        </li>
        <li>
          <span>{StayLeaveTotal.stayers}</span> {t("hk.stayers")}
        </li>
      </ul>
      <style jsx>{`
        ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        li {
          flex-shrink: 0;
          padding: 4px 0;
          background-color: transparent;
        }
        li:first-of-type {
          border-bottom: 1px solid #d8d8d8;
        }
        li span {
          font-weight: 700;
        }
      `}</style>
    </>
  );
};

export { StayLeaveTable };
