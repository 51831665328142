import { FormEvent, useState } from "react";
import theme from "@styles/theme";
import { KIND } from "baseui/button";
import { Filter } from "baseui/icon";
import DetectOutsideClick from "@components/DetectOutsideClick";
import useAppSelector from "@hooks/useAppSelector";
import { selectAllRoomTypes } from "@store/space/spaceSelectors";
import useAppDispatch from "@hooks/useAppDispatch";
import { setRoomFilter } from "@store/ui/uiSlice";
import { RoomSpaceFilterTypes } from "@store/space/types";
import Button from "@components/base/button";
import { useTranslation } from "react-i18next";

const FilterDropdown = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const roomTypeOptions = useAppSelector(selectAllRoomTypes);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const handleChange = (e: FormEvent<HTMLFormElement | HTMLInputElement>) => {
    const { id, checked } = e.target as HTMLFormElement;
    dispatch(
      setRoomFilter({
        filterType:
          e.type === RoomSpaceFilterTypes.RESET
            ? RoomSpaceFilterTypes.RESET
            : (id as RoomSpaceFilterTypes),
        value: checked,
      }),
    );
  };
  const filterOptions = [
    {
      label: t("dayreport.green.title"),
      value: RoomSpaceFilterTypes.GREEN_CHOICE,
    },
    {
      label: t("dayreport.orange.title"),
      value: RoomSpaceFilterTypes.ORANGE_CHOICE,
    },
    {
      label: t("dayreport.nonGreenSkip.title"),
      value: RoomSpaceFilterTypes.NON_GREEN_SKIP,
    },
    {
      label: t("hk.filters.arrivals"),
      value: RoomSpaceFilterTypes.ARRIVALS,
    },
    {
      label: t("hk.leavers"),
      value: RoomSpaceFilterTypes.DEPARTURES,
    },
    {
      label: t("hk.stayers"),
      value: RoomSpaceFilterTypes.STAYOVERS,
    },
    {
      label: t("hk.filters.roomsWithExtra"),
      value: RoomSpaceFilterTypes.WITH_EXTRAS,
    },
    {
      label: t("hk.filters.roomsWithTasks"),
      value: RoomSpaceFilterTypes.WITH_TASKS,
    },
    {
      label: t("hk.filters.occupiedToday"),
      value: RoomSpaceFilterTypes.OCCUPIED_TODAY,
    },
    {
      label: t("hk.filters.rooms.dirty"),
      value: RoomSpaceFilterTypes.ROOM_DIRTY,
    },
    {
      label: t("hk.filters.rooms.clean"),
      value: RoomSpaceFilterTypes.ROOM_CLEAN,
    },
  ];
  const handleRoomChange = (e: FormEvent<HTMLInputElement>) => {
    const { id } = e.target as HTMLFormElement;
    dispatch(
      setRoomFilter({
        filterType: RoomSpaceFilterTypes.ROOM_CODE,
        value: id,
      }),
    );
  };

  return (
    <>
      <div className="container">
        <Button
          startEnhancer={<Filter size={20} />}
          kind={KIND.tertiary}
          overrides={{
            BaseButton: {
              style: {
                height: "44px",
                padding: "14px 56px",
                borderRadius: "4px",
                backgroundColor: "transparent",
                color: theme.colors.primaryA,
                border: `2px solid ${theme.colors.primaryA}`,
              },
            },
          }}
          onClick={() => setShowDropdown(!showDropdown)}>
          {t("common.filter")}
        </Button>
        {showDropdown ? (
          <DetectOutsideClick onClick={() => setShowDropdown(false)} />
        ) : null}
        <form
          className={`${showDropdown ? "show" : ""} dropDown`}
          onReset={handleChange}>
          <div className="dropDownHeader">
            <h2 className="heading">{t("common.filter")}</h2>
            <button className="reset" type="reset">
              {t("common.resetFilter")}
            </button>
          </div>
          <div className="filterGroup">
            <h3 className="heading">{t("common.general")}</h3>
            {filterOptions.map(({ label, value }) => (
              <div key={value} className="filterItem">
                <input id={value} type="checkbox" onClick={handleChange} />
                <label htmlFor={value}>{label}</label>
              </div>
            ))}
          </div>
          <div className="filterGroup">
            <h3 className="heading">{t("common.roomType")}</h3>
            {roomTypeOptions.map((roomType) => (
              <div className="filterItem" key={roomType}>
                <input
                  id={roomType}
                  type="checkbox"
                  onClick={handleRoomChange}
                />
                <label htmlFor={roomType}>{roomType}</label>
              </div>
            ))}
          </div>
        </form>
      </div>
      <style jsx>{`
                .dropDown {
                    display: none;
                    width: 250px;
                    max-height: max(30vh, 300px);
                    margin-top: 4px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    position: absolute;
                    padding: 0 14px 8px;
                    background-color: #fff;
                    border-radius: 4px;
                    box-shadow: 0px 0px 10px rgb(0 0 0 / 14%);
                    z-index: 20;
                    box-sizing: border-box;
                }
                .dropDown.show {
                    display: block;
                }
                .dropDownHeader {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-right: 0;
                    position: sticky;
                    top: 0;
                    background-color: #fff;
                    padding-top: 8px;
                }
                .dropDownHeader > .heading {
                    font-size: 18px !important;
                    font-weight: 300;
                }
                .dropDownHeader > .reset {
                    padding: 0;
                    margin-right: 0;
                    text-decoration: underline;
                    border: 0;
                    background-color: transparent;
                    color: ${theme.colors.linkText}
                }
                .filterGroup {
                    width: 100%;
                    margin-bottom: 2px;
                    border-bottom: 2px solid ${theme.colors.mono200};
                }
                .filterGroup:last-child {
                    margin-bottom: 0;
                    border-bottom: 0;
                }
                .filterGroup > .heading {
                    margin-bottom: 4px;
                    font-size: 16px !important;
                    font-family: 'Source Sans Pro', sans-serif !important;
                    color: ${theme.colors.mono500}
                }
                .filterItem {
                    display: flex;
                    align-items: center;
                    margin-bottom: 14px;
                }
                .filterItem > input[type="checkbox"]onChange={handleChange}  {
                    margin: 0 10px 0 0;
                }
                .filterItem:last-child {
                    margin-bottom: 18px;
                }
            `}</style>
    </>
  );
};
export { FilterDropdown };
