import { FC, useEffect, useState } from "react";
import AuditRow from "./AuditRow";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { selectCurrentUser } from "@store/user/userSelectors";
import { Audit } from "@store/dayReport/types";
import useAppSelector from "@hooks/useAppSelector";
import { useTranslation } from "react-i18next";

const AuditsOverview: FC<{
  audits: Audit[];
  removeRemark: (id: string) => void;
}> = ({ audits, removeRemark }) => {
  const { t } = useTranslation();
  const user = useAppSelector(selectCurrentUser);
  const [remarks, setRemarks] = useState<Audit[]>([]);

  useEffect(() => {
    setRemarks(audits.filter((audit) => audit.remark));
  }, [audits]);

  return (
    <div css={styles.container}>
      {remarks.length > 0 ? (
        remarks.map((audit) => {
          return (
            <AuditRow
              key={audit.id}
              audit={audit}
              isFromCurrentUser={audit?.userId === user?.id}
              removeRemark={removeRemark}
            />
          );
        })
      ) : (
        <div css={styles.row}>
          <p css={styles.empty}>{t("dayreport.noRemarks")}</p>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: css({
    display: "flex",
    flex: 1,
    backgroundColor: theme.colors.white,
    borderTop: `1px solid ${theme.colors.mono200}`,
    borderRight: `1px solid ${theme.colors.mono200}`,
    marginBottom: theme.sizing.scale400,
    flexDirection: "column",
  }),
  row: css({
    display: "flex",
    flex: 1,
  }),
  empty: css({
    padding: theme.sizing.scale800,
    width: "100%",
    verticalAlign: "center",
    textAlign: "center",
    borderLeft: `1px solid ${theme.colors.mono200}`,
  }),
};

export default AuditsOverview;
