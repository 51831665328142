import { AppThunk } from "..";
import api from "../../services/api";
import { Task } from "../../types/common";
import { showNotification } from "../ui/uiThunk";
import { CreateTaskParams, UpdateTaskParams } from "./types";
import l from "@locale";
import { batch } from "react-redux";
import { addTask, removeTask, setTask, setTasks } from "./taskSlice";

export const fetchTasks = (): AppThunk => async (dispatch) => {
  try {
    const response = await api.Tasks.getAll();
    dispatch(setTasks(response.data));
  } catch (error) {
    /* empty */
  }
};
export const fetchTask =
  (id: Task["id"]): AppThunk =>
  async (dispatch) => {
    try {
      const response = await api.Tasks.getById(id);
      dispatch(setTask(response));
    } catch (error) {
      /* empty */
    }
  };

export const createTask =
  (task: CreateTaskParams): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.issue");
    try {
      const response = await api.Tasks.create(task);

      batch(() => {
        dispatch(addTask(response));
        dispatch(
          showNotification({
            type: "success",
            message: l.t("actions.add.success", { value }),
          }),
        );
      });
    } catch (error) {
      dispatch(
        showNotification({
          type: "error",
          message: l.t("actions.add.failed", { value }),
        }),
      );
    }
  };

export const updateTask =
  (taskId: Task["id"], task: UpdateTaskParams): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.issue");

    try {
      const response = await api.Tasks.update(taskId, task);
      batch(() => {
        dispatch(setTask(response));

        dispatch(
          showNotification({
            type: "success",
            message: l.t("actions.update.success", { value }),
          }),
        );
      });
    } catch (error) {
      dispatch(
        showNotification({
          type: "error",
          message: l.t("actions.update.failed", { value }),
        }),
      );
    }
  };

export const deleteTask =
  (taskId: Task["id"]): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.issue");
    try {
      await api.Tasks.delete(taskId);
      dispatch(removeTask(taskId));
      dispatch(
        showNotification({
          type: "success",
          message: l.t("actions.delete.success", { value }),
        }),
      );
    } catch (error) {
      dispatch(
        showNotification({
          type: "error",
          message: l.t("actions.delete.failed", { value }),
        }),
      );
    }
  };
