import React from "react";
import Sort from "@components/Sort";
import { css } from "@emotion/react";
import { EntityId } from "@reduxjs/toolkit";
import { useState } from "react";
import { useSelector } from "react-redux";
import CustomSpacePopOver from "./CustomSpacePopOver";
import TableRow from "./tableElements/TableRow";
import {
  selectCustomSpaceById,
  selectCustomSpaceIdsBySorting,
} from "@store/space/spaceSelectors";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import {
  CustomSpace,
  SpacesSortingKeys,
  newCustomSpace,
} from "@store/space/types";
import { setCustomSpaceSorting } from "@store/ui/uiSlice";
import { deleteSpace, updateCustomSpace } from "@store/space/spaceThunk";
import Table from "@components/base/table";
import { overrideTableStyles } from "@components/base/table/overrides";
import Button from "@components/base/button";
import Modal from "@components/base/modal";
import { useTranslation } from "react-i18next";

const CustomRoomTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [updateModalState, setUpdateModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [customSpaceId, setCustomSpaceId] = useState<EntityId>();
  const selectedSpace = useAppSelector((root) =>
    selectCustomSpaceById(root, customSpaceId as EntityId),
  );
  const { sorting, ids: sortedSpaceIds } = useSelector(
    selectCustomSpaceIdsBySorting,
  );
  const onSortChange = (key: string) => {
    dispatch(
      setCustomSpaceSorting({
        key: key as SpacesSortingKeys,
      }),
    );
  };
  const handleContentChange = (contentId: EntityId) => {
    setCustomSpaceId(contentId);
    setUpdateModalState((prev) => !prev);
  };
  const handleContentDelete = (contentId: EntityId) => {
    setCustomSpaceId(contentId);
    setDeleteModalState((prev) => !prev);
  };
  const onUpdateCustomSpace = (customSpace: newCustomSpace) => {
    dispatch(
      updateCustomSpace(
        selectedSpace as CustomSpace,
        customSpace.label,
        customSpace.floor,
      ),
    );
    setUpdateModalState(false);
  };
  const onDeleteCustomSpace = () => {
    dispatch(deleteSpace(customSpaceId as EntityId));
    setDeleteModalState(false);
  };

  const rows = sortedSpaceIds.map((customId) =>
    TableRow({ customId, handleContentChange, handleContentDelete }),
  );
  return (
    <>
      <Table
        data-test-id="customRoomsTable"
        columns={[
          <Sort sort={sorting} id="name" onChange={onSortChange}>
            {t("common.name")}
          </Sort>,
          <Sort sort={sorting} id="floor" onChange={onSortChange}>
            {t("common.floor")}
          </Sort>,
          t("common.action"),
        ]}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error - it requires the data to be a [][] but the data is a [] and the table breaks otherwise
        data={rows?.length > 1 ? rows : []}
        emptyMessage={t("search.noResults", {
          object: t("common.space").toLowerCase(),
        })}
        overrides={{
          ...overrideTableStyles({
            TableHeadCell: {
              borderRightWidth: "0px",
              ":nth-child(3)": {
                textAlign: "center",
                width: "100px",
              },
            },
            TableBodyCell: {
              borderRightWidth: "0px",
              ":nth-child(3)": {
                cursor: "default",
              },
            },
          }),
        }}
      />
      <Modal
        setShowModal={() => setDeleteModalState((prev) => !prev)}
        closeable={true}
        isOpen={deleteModalState}
        onClose={() => setDeleteModalState(false)}
        title={t("common.youSure")}>
        <div>
          <p css={styles.bodyText}>
            {t("tasks.delete.description", { value: selectedSpace?.label })}
          </p>
          <div css={styles.wrapper}>
            <a
              onClick={() => setDeleteModalState(false)}
              css={styles.cancelButton}>
              {t("common.cancel")}
            </a>
            <Button css={styles.addRoomButton} onClick={onDeleteCustomSpace}>
              {t("common.submit")}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        setShowModal={() => setUpdateModalState((prev) => !prev)}
        closeable={true}
        isOpen={updateModalState}
        onClose={() => setUpdateModalState(false)}
        title={t("customroom.changeTitle")}>
        <CustomSpacePopOver
          space={selectedSpace}
          submitTitle={t("common.submit")}
          onCancel={() => setUpdateModalState(false)}
          handleSubmit={onUpdateCustomSpace}
          type="update"
        />
      </Modal>
    </>
  );
};
const styles = {
  modalHeader: css({
    padding: "5px 0 10px 0 ",
    fontSize: "1.2rem",
  }),
  bodyText: css({
    padding: "15px 0",
    fontSize: "85%",
  }),
  wrapper: css({
    paddingTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    userSelect: "none",
    cursor: "default",
  }),
  addRoomButton: css({
    position: "relative",
    border: "1px solid #fff",
    backgroundColor: "#F7933B",
    borderRadius: "3px",
    padding: "10px",
    color: "#fff",
    ":hover": {
      border: "1px solid #fff",
      backgroundColor: "#F7933B",
      color: "#fff",
    },
  }),
  cancelButton: css({
    backgroundColor: "#fff",
    color: "#2BB9F5",
    width: "fit-content",
    borderBottom: "1px solid #2BB9F5",
    ":hover": {
      color: "#2BB9F5",
      backgroundColor: "#fff",
    },
  }),
};
export default CustomRoomTable;
