import { FC, useState } from "react";
import withAuth from "@helpers/withAuth";
import Layout from "@components/Layout";
import AdditionalTasksScreen from "@screens/EndOfDayExtras";
import Modal from "@components/base/modal";
import useAppSelector from "@hooks/useAppSelector";
import { selectAllReportExtras } from "@store/reportExtra/reportExtraSelector";
import { ReportExtra } from "@store/reportExtra/types";
import { deleteReportExtra } from "@store/reportExtra/reportExtraThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import { useTranslation } from "react-i18next";

const AdditionalTasksPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tasks: ReportExtra[] = useAppSelector(selectAllReportExtras);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [taskToDelete, setTaskToDelete] = useState<ReportExtra | null>(null);

  const deleteTask = () => {
    dispatch(deleteReportExtra(taskToDelete!.id));
    setDeleteModalOpen(false);
    setTaskToDelete(null);
  };

  return (
    <>
      <Layout active="settings/day-reports/extra-tasks">
        <AdditionalTasksScreen
          tasks={tasks}
          taskToDelete={setTaskToDelete}
          deleteTaskModal={setDeleteModalOpen}
        />
      </Layout>
      <Modal
        title={t("tasks.delete.title", { value: taskToDelete?.label })}
        isOpen={deleteModalOpen && taskToDelete !== null}
        setShowModal={setDeleteModalOpen}
        buttonText={t("common.delete")}
        buttonAction={deleteTask}>
        <p css={{ maxWidth: "500px" }}>
          {t("tasks.delete.description", { value: taskToDelete?.label })}
        </p>
      </Modal>
    </>
  );
};

export default withAuth(AdditionalTasksPage);
