import { createSelector, Selector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { DayReport, DayReportsMeta } from "./types";

const selectSelf = (state: RootState) => state.dayReport;

export const selectDayReportMeta: Selector<RootState, DayReportsMeta> =
  createSelector(selectSelf, ({ meta }) => meta);

export const selectCurrentDayReport: Selector<
  RootState,
  DayReport | undefined
> = createSelector(selectSelf, ({ current }) => current);

export const selectWeekOverview: Selector<
  RootState,
  { [id: string]: DayReport }
> = createSelector(selectSelf, ({ week }) => week);

export const selectDayReportByDate: Selector<RootState, DayReport | undefined> =
  createSelector(selectSelf, ({ current }) => current);

export const selectDayReportLoading: Selector<RootState, boolean> =
  createSelector(selectSelf, ({ loading }) => loading);

export const selectDayReportError: Selector<RootState, boolean> =
  createSelector(selectSelf, ({ error }) => error);
