import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { PaginationMeta } from "../../types/common";
import { TransactionPayload } from "./types";

export const transactionAdapter = createEntityAdapter<TransactionPayload>();

export interface transactionState extends EntityState<TransactionPayload> {
  selectedId?: EntityId;
  meta?: PaginationMeta;
}

const initialState: transactionState = {
  ...transactionAdapter.getInitialState(),
  selectedId: undefined,
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setTransactions: transactionAdapter.setAll,
    setTransactionMeta(state, { payload }: PayloadAction<PaginationMeta>) {
      state.meta = payload;
    },
  },
});

export const { setTransactionMeta, setTransactions } = transactionSlice.actions;

export default transactionSlice;
