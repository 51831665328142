import { AppThunk, RootState } from "..";
import { addError, addSuccess, setNotification } from "./uiSlice";
import { AllActionTypes, Notification } from "./types";
import { batch } from "react-redux";

let timer: NodeJS.Timeout | undefined = undefined;

export const showNotification =
  (notification: Notification, action?: AllActionTypes): AppThunk =>
  (dispatch, getState: () => RootState) => {
    if (getState().ui.notification && notification.noOverride) return;

    batch(() => {
      if (action && notification.type === "success") {
        dispatch(addSuccess(action));
      }
      if (action && notification.type === "error") {
        dispatch(addError(action));
      }
      dispatch(setNotification(notification));
    });

    let time = 3000;

    // Every 180 extra characters should add 1 second to the timer
    time += Math.floor(notification.message.length / 180) * 1000;

    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setNotification(undefined));
    }, time);
  };
