import { FC, useCallback, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { isAfter } from "date-fns";
import IssueTable from "./components/IssueTable";
import IssueTopRow from "./components/IssueTopRow";
import { debounce } from "lodash";
import { selectMemberEntities } from "@store/user/userSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { selectAllIssuesByType } from "@store/issue/issueSelectors";
import { selectSpaceEntities } from "@store/space/spaceSelectors";
import {
  Issue,
  IssueState,
  IssueType,
  STATUS_OPTIONS,
} from "@store/issue/types";
import { getIssuesByPage } from "@store/issue/issueThunk";
import { fetchUsers } from "@store/user/userThunk";
import { fetchSpaces } from "@store/space/spaceThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import Breadcrumb from "@components/base/breadcrumb";
import { NavLabels } from "@components/Layout";

export type SortKeys =
  | "priority"
  | "createdAt"
  | "label"
  | "location"
  | "state"
  | "assignee";

export interface IssueTableSort {
  key: SortKeys;
  asc: boolean;
}

interface IssueOverviewScreenProps {
  type: IssueType;
}

function isIssueState(value: string): value is IssueState {
  return Object.values(IssueState).includes(value as IssueState);
}

const IssueOverviewScreen: FC<IssueOverviewScreenProps> = ({ type }) => {
  const dispatch = useAppDispatch();
  const members = useAppSelector((state) => selectMemberEntities(state, type));
  const issues = useAppSelector((state) => selectAllIssuesByType(state, type));
  const spaces = useAppSelector(({ space }) => selectSpaceEntities(space));
  const [sort, setSort] = useState<IssueTableSort>({
    key: "createdAt",
    asc: false,
  });
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<IssueState | undefined>(IssueState.OPEN);
  const [filteredIssues, setFilteredIssues] = useState<Issue[]>();
  const [inputSearch, setSearch] = useState("");
  const delayedQuery = useRef(
    debounce(
      (page: number, query: string, status: IssueState | undefined) =>
        onPaginationChange(page, query, status),
      750,
    ),
  ).current;
  const assigns = issues.reduce((result: { [key: string]: number }, value) => {
    const userId = value.users?.[0]?.id;

    if (result[userId]) {
      result[userId] += 1;
      return result;
    }
    result[userId] = 1;
    return result;
  }, {});

  const onFilterChange = (filter: string) => {
    if (isIssueState(filter)) return setFilter(filter);
    setFilter(undefined);
  };

  const onSearchChange = (text: string) => {
    const trimText = text.trim();
    setSearch(trimText);
    delayedQuery(1, text, filter);
  };

  const mergeSpaceToIssue = useCallback(() => {
    const filtered = filter
      ? issues.filter((issue) => issue.state === filter)
      : issues;

    return filtered
      .sort((a: Issue, b: Issue) => {
        if (!sort) return 0;

        const [x, y] = sort.asc ? [a, b] : [b, a];

        const stateMap: { [key: string]: string } = {};
        switch (sort.key) {
          case "assignee":
            return (
              members[x.users?.[0]?.id]?.profile?.initials ?? ""
            ).localeCompare(members[y.users?.[0]?.id]?.profile?.initials ?? "");
          case "location":
            return (spaces[x.spaceId]?.label ?? "").localeCompare(
              spaces[y.spaceId]?.label ?? "",
            );
          case "priority":
            return x.priority ? -1 : 1;
          case "createdAt":
            return isAfter(
              new Date(x.createdAt ?? 0),
              new Date(y.createdAt ?? 0),
            )
              ? 1
              : -1;
          case "state":
            for (const state of STATUS_OPTIONS) {
              stateMap[state.id] = state.label;
            }

            return (stateMap[x.state] ?? "").localeCompare(
              stateMap[y.state] ?? "",
            );
          default:
            return x[sort.key]?.localeCompare(y[sort.key]);
        }
      })
      .map((issue: Issue) => ({
        ...issue,
        space: spaces[issue?.spaceId],
      }));
  }, [filter, issues, members, sort, spaces]);

  const onSortChange = (key: string) => {
    if (sort?.key === key) {
      setSort({ key, asc: !sort.asc });
      return;
    }
    setSort({ key: key as SortKeys, asc: true });
  };

  const onPaginationChange = useCallback(
    async (nextPage = 1, search = inputSearch, state = filter) => {
      setLoading(true);
      try {
        await dispatch(
          getIssuesByPage({
            page: nextPage,
            state,
            filter: search,
            type,
          }),
        );
      } catch (e) {
        // do nothing
      }
      setLoading(false);
    },
    [dispatch, filter, inputSearch, type],
  );

  useEffect(() => {
    onPaginationChange(1);
  }, [filter, onPaginationChange]);

  useEffect(() => {
    if (issues.length > 0) setFilteredIssues(mergeSpaceToIssue);
    else setFilteredIssues([]);
  }, [filter, issues, mergeSpaceToIssue, sort]);

  useEffect(() => {
    dispatch(getIssuesByPage({ page: 1, state: IssueState.OPEN, type }));
    dispatch(fetchSpaces());
    dispatch(fetchUsers());
  }, [dispatch, type]);

  return (
    <>
      <Breadcrumb
        labels={
          type === IssueType.GENERAL
            ? [NavLabels.GsOverview]
            : [NavLabels.TdOverview]
        }
      />
      <section data-test-id="td-section" css={styles.section}>
        <IssueTopRow
          search={inputSearch}
          onSearchChange={onSearchChange}
          state={filter}
          onStateChange={onFilterChange}
          onPaginationChange={onPaginationChange}
          type={type}
        />
        <IssueTable
          issues={filteredIssues}
          sort={sort}
          onSortChange={onSortChange}
          assigns={assigns}
          isLoading={loading}
          updateIssueList={onPaginationChange}
          type={type}
        />
      </section>
    </>
  );
};

const styles = {
  section: css({
    display: "flex",
    padding: theme.sizing.scale1600,
    flex: 1,
    flexDirection: "column",
    overflow: "hidden",
  }),
};

export default IssueOverviewScreen;
