import { AppThunk } from "..";
import { RateType, UpdateRateTypeParams } from "./types";
import services from "@services/api";
import l from "@locale";
import { showNotification } from "../ui/uiThunk";
import { batch } from "react-redux";
import { setRateTypes } from "./rateTypeSlice";

export const fetchRateTypes = (): AppThunk => async (dispatch) => {
  try {
    const { data } = await services.RateTypes.getAll();
    dispatch(setRateTypes(data));
  } catch (error) {
    /* empty */
  }
};

export const createRateType =
  (rateType: UpdateRateTypeParams): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.roomType");

    try {
      await services.RateTypes.create(rateType);

      batch(() => {
        dispatch(
          showNotification({
            message: l.t("actions.add.success", { value }),
            type: "success",
          }),
        );
        dispatch(fetchRateTypes());
      });
    } catch (error) {
      dispatch(
        showNotification({
          message: l.t("actions.add.failed", { value }),
          type: "error",
        }),
      );
    }
  };

export const updateRateType =
  (id: RateType["id"], rateType: UpdateRateTypeParams): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.roomType");
    try {
      await services.RateTypes.update(id, rateType);
      batch(() => {
        dispatch(
          showNotification({
            message: l.t("actions.update.success", { value }),
            type: "success",
          }),
        );
        dispatch(fetchRateTypes());
      });
    } catch (error) {
      dispatch(
        showNotification({
          message: l.t("actions.update.failed", { value }),
          type: "error",
        }),
      );
    }
  };

export const deleteRateType =
  (id: RateType["id"]): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.roomType");
    try {
      await services.RateTypes.delete(id);
      batch(() => {
        dispatch(
          showNotification({
            message: l.t("actions.delete.success", { value }),
            type: "success",
          }),
        );
        dispatch(fetchRateTypes());
      });
    } catch (error) {
      dispatch(
        showNotification({
          message: l.t("actions.delete.success", { value }),
          type: "error",
        }),
      );
    }
  };
