import { FC } from "react";
import useAppDispatch from "@hooks/useAppDispatch";
import { selectRoomToggles } from "@store/ui/uiSelectors";
import { selectRoomSpaceIdsByFilters } from "@store/space/spaceSelectors";
import { ToggleMap } from "@store/ui/types";
import { resetRoomToggles, setRoomToggles } from "@store/ui/uiSlice";
import Checkbox from "@components/base/checkbox";
import useAppSelector from "@hooks/useAppSelector";

const CheckboxColumn: FC = () => {
  const dispatch = useAppDispatch();

  const roomToggles = useAppSelector(selectRoomToggles);
  const filteredIds = useAppSelector(selectRoomSpaceIdsByFilters);
  const toggledIds = Object.keys(roomToggles) as Array<keyof ToggleMap>;

  // check if all currently filtered rows are already toggled
  const allCurrentRowsAreToggled =
    filteredIds.length === toggledIds.length &&
    filteredIds.every((e) => toggledIds.includes(e));

  const toggleAllSpaces = () => {
    if (allCurrentRowsAreToggled) {
      dispatch(resetRoomToggles());
      return;
    }
    const toggleSet: ToggleMap = {};
    filteredIds.forEach((spaceId) => {
      toggleSet[spaceId] = 1;
    });
    dispatch(setRoomToggles(toggleSet));
  };

  return (
    <Checkbox checked={allCurrentRowsAreToggled} onChange={toggleAllSpaces} />
  );
};

export default CheckboxColumn;
