import { FC, ReactNode } from "react";
import DetectOutsideClick from "./DetectOutsideClick";

interface PopUpProps {
  active: boolean;
  toggleActive: () => void;
  children: ReactNode;
  width: string;
  leftOffset: string;
  topOffset: string;
  arrowLeftOffset: string;
  contentPadding?: string;
  showCloseIcon?: boolean;
}

const PopUp: FC<PopUpProps> = ({
  active,
  toggleActive,
  children,
  width,
  leftOffset,
  topOffset,
  arrowLeftOffset,
  contentPadding = "20px",
  showCloseIcon = true,
}) => {
  const closeClick = () => {
    if (!active) return;
    toggleActive();
  };

  if (!active) return null;
  return (
    <div>
      <DetectOutsideClick onClick={closeClick} />
      <div className="popUpContainer">
        {showCloseIcon && (
          <div className="close" onClick={closeClick}>
            <img src="/icons/x.svg" alt="close" />
          </div>
        )}
        <div className="content">{children}</div>
      </div>
      <style jsx>{`
        .popUpContainer {
          position: absolute;
          margin-left: ${leftOffset};
          margin-top: ${topOffset};
          width: ${width};
          padding-top: ${showCloseIcon ? "10px" : "0px"};
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
          background: #ffffff;
          border: 1px solid #efefef;
          box-sizing: border-box;
          border-radius: 4px;
          z-index: 20;
        }
        .popUpContainer:after,
        .popUpContainer:before {
          bottom: 100%;
          left: ${arrowLeftOffset};
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        .popUpContainer:after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #fff;
          border-width: 10px;
          margin-left: -10px;
        }
        .popUpContainer:before {
          border-color: rgba(162, 162, 162, 0);
          border-bottom-color: #efefef;
          border-width: 11px;
          margin-left: -11px;
        }
        .overlay {
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 10;
        }
        .close {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
        .content {
          padding: ${contentPadding};
        }
      `}</style>
    </div>
  );
};

export default PopUp;
