import { FC, ReactNode, useEffect, useRef, useState } from "react";
import DetectOutsideClick from "./DetectOutsideClick";
import useAppSelector from "@hooks/useAppSelector";
import { hidePopUp } from "@store/ui/uiSlice";
import useAppDispatch from "@hooks/useAppDispatch";

interface PopUpWrapperProps {
  children: ReactNode;
}

const PopUpWrapper: FC<PopUpWrapperProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const popUp = useAppSelector(({ ui }) => ui.web.popUp);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const x = popUp?.pos.x;
  const y = popUp?.pos.y;

  const onClose = () => {
    dispatch(hidePopUp());
  };

  useEffect(() => {
    if (!containerRef.current) return;
    setContainerWidth(containerRef.current.clientWidth);
  }, [containerWidth]);

  if (!popUp || x === undefined) return null;

  return (
    <div>
      <DetectOutsideClick onClick={onClose} />
      <div
        className="container"
        style={{ top: y, left: x - containerWidth }}
        ref={containerRef}>
        <div className="content">{children}</div>
        <style jsx>{`
          .container {
            position: fixed;
            z-index: 100;
          }
          .content {
            display: flex;
            flex: 1;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
            background: #ffffff;
            border: 1px solid #efefef;
            padding: 16px;
            border-radius: 4px;
          }
        `}</style>
      </div>
    </div>
  );
};

export default PopUpWrapper;
