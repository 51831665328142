import React, { FC, useEffect, useState } from "react";
import AreaNameForm from "./components/AreaNameForm";
import AreasTable from "./components/AreasTable";
import AssignTable from "./components/AssignTable";
import EditArea from "./components/EditArea";
import theme from "@styles/theme";
import { css } from "@emotion/react";
import { selectCurrentArea } from "@store/area/areaSelectors";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import { setSelectedArea } from "@store/area/areaSlice";
import { hidePopUp, showPopUp } from "@store/ui/uiSlice";
import { deleteArea } from "@store/area/areaThunk";
import Breadcrumb from "@components/base/breadcrumb";
import Button from "@components/base/button";
import Modal from "@components/base/modal";
import { fetchSpaces } from "@store/space/spaceThunk";
import { useTranslation } from "react-i18next";
import { NavLabels, SubNavLabels } from "@components/Layout";
import { selectCurrentRole } from "@store/user/userSelectors";
import { isFrontofficeManager } from "@helpers";

const AreasScreen: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const area = useAppSelector(selectCurrentArea);
  const popUp = useAppSelector(({ ui }) => ui.web.popUp);
  const [showRemoveWindow, setShowRemoveWindow] = useState(false);

  const role = useAppSelector(selectCurrentRole);
  const canEdit = isFrontofficeManager(role);

  const onShowNameForm = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    dispatch(setSelectedArea(undefined));
    dispatch(
      showPopUp({
        type: "areas/areaForm",
        pos: { x: event.nativeEvent.pageX, y: event.nativeEvent.pageY },
      }),
    );
  };

  const onShowRemoveWindow = () => {
    dispatch(hidePopUp());
    setShowRemoveWindow(true);
  };

  const onRemoveSection = () => {
    if (area === undefined) return;
    dispatch(deleteArea(area.id));
    setShowRemoveWindow(false);
  };

  useEffect(() => {
    dispatch(fetchSpaces());
  }, [dispatch]);

  return (
    <>
      <Breadcrumb labels={[SubNavLabels.Settings, NavLabels.AreaSettings]} />
      <section data-test-id="secties-beheren" css={styles.section}>
        <div>
          <div css={styles.createRow}>
            <h4>{t("common.area")}</h4>
            {canEdit && (
              <Button onClick={onShowNameForm}>{t("area.add")}</Button>
            )}
          </div>

          <AreasTable />
        </div>

        {canEdit && <AssignTable />}

        {popUp?.type === "areas/editArea" && (
          <EditArea onShowRemoveWindow={onShowRemoveWindow} />
        )}
        {popUp?.type === "areas/areaForm" && <AreaNameForm />}

        <Modal
          title={t("common.youSure")}
          buttonText={t("area.delete.action")}
          buttonAction={onRemoveSection}
          isOpen={showRemoveWindow}
          setShowModal={setShowRemoveWindow}>
          {t("area.delete.area", {
            item: area?.label,
          })}
        </Modal>
      </section>
    </>
  );
};

const styles = {
  section: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: theme.sizing.scale1600,
    overflow: "scroll",
  }),
  createRow: css({
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.sizing.scale800,
  }),
};

export default AreasScreen;
