import { RootState } from "..";
import { createSelector, EntityId, Selector } from "@reduxjs/toolkit";

import { PopUp, RequiredDataState, ToggleMap } from "./types";
import { RoomSpaceFilters } from "../space/types";

const selectSelf = (state: RootState): RootState["ui"] => state.ui;

export const selectPopup: Selector<RootState, PopUp | undefined> =
  createSelector(
    (state: RootState) => state.ui.web.popUp,
    (popUp) => popUp,
  );

export const selectRoomToggles: Selector<RootState, ToggleMap> = createSelector(
  (state: RootState) => state.ui.roomToggles,
  (roomToggles) => roomToggles,
);

export const makeSelectRoomToggleById: () => Selector<
  RootState,
  boolean,
  [EntityId]
> = () =>
  createSelector(
    (state: RootState) => state.ui.roomToggles,
    (_: RootState, spaceId: EntityId) => spaceId,
    (roomToggles, spaceId: EntityId) => spaceId in roomToggles,
  );

export const selectRoomFilters: Selector<RootState, RoomSpaceFilters> =
  createSelector(
    (state: RootState) => state.ui.roomFilters,
    (roomFilters) => roomFilters,
  );

export const selectSidebarView: Selector<RootState, boolean> = createSelector(
  selectSelf,
  ({ sidebarView }) => sidebarView,
);

export const selectRequiredDataState: Selector<
  RootState,
  RequiredDataState | undefined
> = createSelector(selectSelf, ({ requiredDataState }) => requiredDataState);
