import theme from "@styles/theme";
import { FC, ReactNode } from "react";

interface TooltipProps {
  children: ReactNode;
  color?: string;
}

const Tooltip: FC<TooltipProps> = ({
  children,
  color = theme.colors.white,
}) => (
  <>
    <div className="container">{children}</div>
    <style jsx>
      {`
        .container {
          position: absolute;
          border-radius: 4px;
          box-sizing: border-box;
          display: block;
          box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
          background: ${color};
          border: 1px solid ${color ?? " #a2a2a2"};
        }
        .container:after,
        .container:before {
          bottom: 100%;
          left: 20px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        .container:after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: ${color ?? "white"} ;
          border-width: 10px;
          margin-left: -10px;
        }
        .container:before {
          border-color: rgba(162, 162, 162, 0);
          background-color: ${color ?? " #a2a2a2"}
          border-bottom-color:${color ?? " #a2a2a2"}
          border-width: 11px;
          margin-left: -11px;
        }
      `}
    </style>
  </>
);

export default Tooltip;
