import React, { FC } from "react";
import { css } from "@emotion/react";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { Block } from "baseui/block";
import { EntityId } from "@reduxjs/toolkit";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useTranslation } from "react-i18next";

interface RoomProps {
  customId: EntityId;
  handleChange: (value: EntityId) => void;
  handleDelete: (value: EntityId) => void;
}

const FloorRow: FC<RoomProps> = ({ customId, handleChange, handleDelete }) => {
  const { t } = useTranslation();
  return (
    <>
      <div css={styles.wrapper}>
        <StatefulPopover
          css={styles.label}
          content={({ close }) => (
            <Block
              overrides={{
                Block: {
                  style: {
                    width: "fit-content",
                    padding: "16px",
                    textAlign: "center",
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                    fontSize: "16px",
                    cursor: "pointer",
                  },
                },
              }}>
              <div css={styles.wrapper}>
                <a
                  css={[styles.editButton, styles.defaultButton]}
                  onClick={() => {
                    handleChange(customId);
                    close();
                  }}>
                  {t("common.change")}
                </a>
                <a
                  css={[styles.deleteButton, styles.defaultButton]}
                  onClick={() => {
                    handleDelete(customId);
                    close();
                  }}>
                  {t("common.delete")}
                </a>
              </div>
            </Block>
          )}
          placement={PLACEMENT.bottomRight}
          focusLock
          returnFocus
          renderAll
          showArrow>
          <a css={styles.dots}>
            <BiDotsVerticalRounded />
          </a>
        </StatefulPopover>
      </div>
    </>
  );
};

const styles = {
  label: css({
    fontWeight: "bold",
  }),
  dots: css({
    cursor: "pointer",
    fontSize: "25px",
    color: "#000",
    "&:hover": {
      color: "#000",
    },
  }),
  wrapper: css({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    userSelect: "none",
    cursor: "default ",
  }),
  editButton: css({
    paddingBottom: "5px",
    color: "#000",
    ":hover": {
      color: "#000",
      cursor: "pointer",
    },
  }),
  deleteButton: css({
    paddingTop: "5px",
    color: "#ff0000",
    ":hover": {
      color: "#ff0000",
      cursor: "pointer",
    },
  }),
  defaultButton: css({
    width: "100%",
    height: "24px",
    fontWeight: "600",
    ":not(:last-child)": {
      borderBottom: "2px solid #EFEFEF",
    },
  }),
};

const ActionRow = React.memo(FloorRow);

export default ActionRow;
