import React from "react";
import withAuth from "../helpers/withAuth";
import Layout from "../components/Layout";
import StatsScreen from "../screens/Statistics";

const IndexPage: React.FC = () => {
  return (
    <Layout active="dashboard">
      <StatsScreen />
    </Layout>
  );
};

export default withAuth(IndexPage);
