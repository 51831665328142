import { Attachment } from "../../../types/common";
import React from "react";

interface PreviewProps {
  attachment: Partial<Attachment>;
  onRemoveImage: (attachment: Partial<Attachment>) => void;
}

const Preview: React.FC<PreviewProps> = ({ attachment, onRemoveImage }) => {
  return (
    <div className="image-container">
      <img src={attachment.url} className="image" />
      <div
        onClick={() => onRemoveImage(attachment)}
        className="close-container">
        <img src="/icons/x.svg" className="close" />
      </div>
      <style jsx>{`
        .image {
          position: absolute;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
        }
        .image-container {
          position: relative;
          flex-basis: 48%;
          height: 100px;
          overflow: hidden;
          margin-bottom: 8px;
        }
        .close-container {
          width: 22px;
          height: 22px;
          position: absolute;
          top: 4px;
          right: 4px;
          background-color: #ffffff;
          border-radius: 10px;
          cursor: pointer;
          transition: all 200ms ease-out;
        }
        .close-container:hover {
          transform: scale(1.1);
          box-shadow: 0 1px 3px #777;
        }
        .close {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          height: 20px;
        }
      `}</style>
    </div>
  );
};

export default Preview;
