import React from "react";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";
import nl from "@locale/nl";
import { Translation } from "@locale";

interface SegmentControlProps {
  values: string[];
  selectedValue: string;
  onPress: (value: string) => void;
  filterTitle?: boolean;
}

const SegmentControl: React.FC<SegmentControlProps> = (props) => {
  const { t } = useTranslation();
  const isSegmentProp = (value: string) => value in nl.segment;

  return (
    <div css={styles.spacing}>
      {props.filterTitle && <span>{t("common.filter")}:</span>}
      <ul css={styles.segmentWrapper}>
        {props.values.map((value) => {
          return (
            <li
              key={value}
              onClick={() => props.onPress(value)}
              css={[
                styles.item,
                value === props.selectedValue && styles.active,
              ]}>
              {isSegmentProp(value)
                ? t(`segment.${value as keyof Translation["segment"]}`)
                : value}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const styles = {
  spacing: css({
    margin: "5px",
  }),
  active: css({
    borderBottom: `2px solid ${theme.colors.primaryA}`,
    fontWeight: 700,
  }),
  segmentWrapper: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  }),
  item: css({
    margin: "0 15px 0 0",
    padding: "5px 0",
    cursor: "pointer",
  }),
};

export default SegmentControl;
