import { FC, useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import theme from "@styles/theme";
import { DayReportTotal } from "@store/dayReport/types";
import { totalHKDayReportDifference } from "@helpers";
import Popover from "@components/base/popover";
import { useTranslation } from "react-i18next";

interface TotalDifferenceTableProps {
  totals: DayReportTotal;
  isDayTotal: boolean;
}

const TotalDifferenceTable: FC<TotalDifferenceTableProps> = ({
  totals,
  isDayTotal,
}) => {
  const { t } = useTranslation();
  const [detailView, setDetailView] = useState(false);
  const totalUsed =
    totals.sold + totals.dayUse + totals.carriedOverFromYesterday;
  const subTotalDayUse = totalUsed - totals.noShow;
  const totalDayUse = subTotalDayUse - totals.carriedOver;
  const totalToBeCleaned =
    totalDayUse -
    (totals.green + totals.orange + totals.doNotDisturb + totals.nonGreenSkip);
  const totalCleanedDifference = totalHKDayReportDifference(totals);
  const inRange = [-1, 0, 1].includes(totalCleanedDifference);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="totals-overview">
            <h4>
              {t("dayreport.difference")}:
              {!detailView && (
                <span
                  className={isDayTotal ? (inRange ? "green" : "red") : "-"}>
                  {isDayTotal ? totalCleanedDifference : "-"}
                </span>
              )}
            </h4>
            <div className="cell">
              <Popover
                content={
                  <section className="popover">
                    <div className="popover-item">
                      <span className="popover-item-header">
                        {t("dayreport.sold.title")}:
                      </span>
                      {t("dayreport.sold.description")}
                    </div>
                    <div className="popover-item">
                      <span className="popover-item-header">
                        {t("dayreport.carriedOverFromYesterday.title")}:
                      </span>
                      {t("dayreport.carriedOverFromYesterday.description")}
                    </div>
                    <div className="popover-item">
                      <span className="popover-item-header">
                        {t("dayreport.dayuse.title")}:
                      </span>
                      {t("dayreport.dayuse.description")}
                    </div>
                    <div className="popover-item">
                      <span className="popover-item-header">
                        {t("dayreport.carriedOver.title")}:
                      </span>
                      {t("dayreport.carriedOver.description")}
                    </div>
                    <div className="popover-item">
                      <span className="popover-item-header">
                        {t("common.total")}:
                      </span>
                      {t("dayreport.totalDescription")}
                    </div>
                    <div className="popover-item">
                      <span className="popover-item-header">
                        {t("dayreport.green.title")}:
                      </span>
                      {t("dayreport.green.description")}
                    </div>
                    <div className="popover-item">
                      <span className="popover-item-header">
                        {t("dayreport.orange.title")}:
                      </span>
                      {t("dayreport.orange.description")}
                    </div>
                    <div className="popover-item">
                      <span className="popover-item-header">
                        {t("dayreport.nonGreenSkip.title")}:
                      </span>
                      {t("dayreport.nonGreenSkip.description")}
                    </div>
                    <div className="popover-item">
                      <span className="popover-item-header">
                        {t("dayreport.doNotDisturb.title")}:
                      </span>
                      {t("dayreport.doNotDisturb.description")}
                    </div>
                    <div className="popover-item">
                      <span className="popover-item-header">
                        {t("dayreport.cleaned.title")}:
                      </span>
                      {t("dayreport.cleaned.description")}
                    </div>
                    <div className="popover-item">
                      <span className="popover-item-header">
                        {t("dayreport.totalCleaned.title")}:
                      </span>
                      {t("dayreport.totalCleaned.description")}
                    </div>
                    <div>
                      <div className="popover-warning">
                        <span className="popover-warning-header">
                          {t("dayreport.note.title")}:
                        </span>
                        {t("dayreport.note.description")}
                      </div>
                    </div>
                  </section>
                }
                actionElement={<a id="clikker">{t("dayreport.calculate")}</a>}
                placement="bottomLeft"
              />

              <div
                className="dropdown-icon"
                onClick={() => setDetailView((prev) => !prev)}>
                {detailView ? <BiChevronUp /> : <BiChevronDown />}
              </div>
            </div>
          </div>
          {detailView && (
            <div className="totals-overview-table">
              {!isDayTotal ? (
                <p className="no-data">{t("dayreport.noData")}</p>
              ) : (
                <table>
                  <tbody>
                    <tr>
                      <td>{t("dayreport.sold.title")}</td>
                      <td>{totals.sold}</td>
                    </tr>
                    <tr>
                      <td>{t("dayreport.carriedOverFromYesterday.title")}</td>
                      <td>{totals.carriedOverFromYesterday}</td>
                    </tr>
                    <tr>
                      <td>{t("dayreport.dayuse.title")}</td>
                      <td>{totals.dayUse}</td>
                    </tr>
                    <tr className="sub-total">
                      <td></td>
                      <td>{totalUsed}</td>
                    </tr>
                    <tr>
                      <td>{t("dayreport.noShow")}</td>
                      <td>{totals.noShow}</td>
                    </tr>
                    <tr className="min-total">
                      <td></td>
                      <td>{subTotalDayUse}</td>
                    </tr>
                    <tr>
                      <td>{t("dayreport.carriedOver.title")}</td>
                      <td>{totals.carriedOver}</td>
                    </tr>
                    <tr className="min-total">
                      <td></td>
                      <td>{totalDayUse}</td>
                    </tr>
                    <tr>
                      <td>{t("dayreport.green.title")}</td>
                      <td>{totals.green}</td>
                    </tr>
                    <tr>
                      <td>{t("dayreport.orange.title")}</td>
                      <td>{totals.orange}</td>
                    </tr>
                    <tr>
                      <td>{t("dayreport.nonGreenSkip.title")}</td>
                      <td>{totals.nonGreenSkip}</td>
                    </tr>
                    <tr>
                      <td>{t("dayreport.doNotDisturb.title")}</td>
                      <td>{totals.doNotDisturb}</td>
                    </tr>
                    <tr className="min-total">
                      <td>{t("dayreport.cleaned.title")}</td>
                      <td>{totalToBeCleaned}</td>
                    </tr>
                    <tr>
                      <td>{t("dayreport.totalCleaned.title")}</td>
                      <td className="total-cleaned">{totals.cleaned}</td>
                    </tr>
                    <tr className="total">
                      <td>{t("dayreport.difference")}</td>
                      <td className={inRange ? "green" : "red"}>
                        {totalCleanedDifference}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      </div>
      <style jsx>
        {`
          .container {
            background-color: ${theme.colors.white};
            border: 1px solid ${theme.colors.mono200};
            width: max(875px, 45vw);
            margin-bottom: ${theme.sizing.scale800};
          }
          .row {
            padding: ${theme.sizing.scale800};
            display: flex;
            flex-direction: column;
          }
          h4 span {
            padding-left: ${theme.sizing.scale400};
            font-weight: 600;
            font-size: 1.25rem;
          }
          .totals-overview {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
          .dropdown-icon {
            font-size: 2.5rem;
            user-select: none;
            cursor: pointer;
          }
          .cell {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: ${theme.sizing.scale800};
          }
          table {
            width: 250px;
            user-select: none;
            cursor: arrow;
          }
          tr td {
            padding-bottom: ${theme.sizing.scale400};
          }
          tr td:last-child {
            text-align: right;
            padding-right: 5px;
          }
          .sub-total,
          .min-total,
          .total {
            padding-top: ${theme.sizing.scale400};
            border-top: 2px solid ${theme.colors.contentInverseSecondary};
          }
          .sub-total td:first-child,
          .min-total td:first-child,
          .total td:first-child {
            padding-top: ${theme.sizing.scale200};
          }
          .sub-total td:last-child,
          .min-total td:last-child,
          .total-cleaned {
            font-size: 1.1rem;
            font-weight: 600;
          }
          .sub-total::after {
            content: "+";
            font-size: 1.15rem;
          }
          .min-total::after,
          .total::after {
            content: "-";
            font-size: 1.35rem;
          }
          .sub-total::after,
          .min-total::after,
          .total::after {
            display: block;
            position: relative;
            font-weight: 600;
            top: -22px;
          }
          .total td {
            font-weight: 600;
          }
          .green {
            color: ${theme.colors.positive};
          }
          .red {
            color: ${theme.colors.negative};
          }
          .totals-overview-table {
            border-top: 2px solid ${theme.colors.contentInverseSecondary};
            padding-top: ${theme.sizing.scale800};
          }
          .no-data {
            padding: ${theme.sizing.scale800};
            font-style: italic;
          }
          .popover {
            max-width: 500px;
            padding: ${theme.sizing.scale1000};
            border-radius: 5px;
            background-color: ${theme.colors.white};
            border: 1px solid ${theme.colors.contentInverseSecondary};
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            font-size: 0.9rem;
          }
          .popover-item {
            width: 500px;
            padding-bottom: ${theme.sizing.scale600};
            word-break: break-word;
          }
          .popover-item-header,
          .popover-warning-header {
            padding-right: ${theme.sizing.scale200};
            font-weight: 600;
            white-space: nowrap;
          }
          .popover-warning {
            font-style: italic;
          }
        `}
      </style>
    </>
  );
};
export default TotalDifferenceTable;
