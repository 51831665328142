import { FC, useState } from "react";
import RoomRow from "@components/RoomRow";
import SelectOptions from "@components/SelectOptions";
import PopUp from "@components/PopUp";
import { css } from "@emotion/react";
import { RoomSpace } from "@store/space/types";
import { selectRoomSpaceEntities } from "@store/space/spaceSelectors";
import { selectAllAreas } from "@store/area/areaSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { assignArea } from "@store/space/spaceThunk";
import theme from "@styles/theme";
import useAppDispatch from "@hooks/useAppDispatch";
import Button from "@components/base/button";
import { useTranslation } from "react-i18next";

interface AssignFormProps {
  setShowAssignForm: (showAssignForm: boolean) => void;
  setToggleAll: (toggleAll: boolean) => void;
  selectedSpaces: { [key: string]: number };
  setSelectedSpaces: (selectedSpaces: { [key: string]: number }) => void;
  toggleSpace: (spaceId: RoomSpace["id"]) => void;
  selectedSpacesCount: number;
}

const AssignForm: FC<AssignFormProps> = ({
  setShowAssignForm,
  setToggleAll,
  selectedSpaces,
  toggleSpace,
  setSelectedSpaces,
  selectedSpacesCount,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const areas = useAppSelector(({ area }) =>
    selectAllAreas(area).reduce((result: { [key: string]: string }, x) => {
      result[x.id] = x.label;
      return result;
    }, {}),
  );
  const spaces = useAppSelector(selectRoomSpaceEntities);
  const [selectedArea, setSelectedArea] = useState<string>("");

  const hideAssignForm = () => {
    setShowAssignForm(false);
  };

  const onAssignToArea = () => {
    dispatch(assignArea(Object.keys(selectedSpaces), selectedArea));
    hideAssignForm();
    setToggleAll(false);
    setSelectedSpaces({});
  };

  return (
    <PopUp
      active={true}
      toggleActive={hideAssignForm}
      width="375px"
      arrowLeftOffset="328px"
      leftOffset="-172px"
      topOffset="11px">
      <h3>{t("area.assignSection_zero")}</h3>
      <div css={styles.space}>
        <strong>{t("common.area")}</strong>
      </div>
      <SelectOptions
        width={335}
        optionsWithLabel={areas}
        value={selectedArea}
        placeholder={t("area.select")}
        onChange={setSelectedArea}
      />
      <br />
      <br />
      <h5>{t("area.count", { count: selectedSpacesCount })}</h5>
      <ul css={styles.selectedRooms}>
        {Object.keys(selectedSpaces)
          .sort()
          .map((key) => {
            const space = spaces[key];
            if (space === undefined) return null;
            return (
              <RoomRow
                selectedRooms={space}
                toggleSpace={toggleSpace}
                key={key}
              />
            );
          })}
      </ul>

      <div css={styles.roomCount}>
        <p>{t("common.total")}</p>
        <h5>{t("counts.room", { count: selectedSpacesCount })}</h5>
      </div>
      <div css={styles.rightAlign}>
        <Button onClick={onAssignToArea} disabled={!selectedArea}>
          {t("common.save")}
        </Button>
      </div>
    </PopUp>
  );
};

const styles = {
  space: css({
    marginBottom: theme.sizing.scale500,
  }),
  rightAlign: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: theme.colors.mono400,
  }),
  roomCount: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  }),
  selectedRooms: css({
    margin: `${theme.sizing.scale100} 0 ${theme.sizing.scale500} 0`,
    height: "auto",
    maxHeight: "215px",
    overflowY: "scroll",
    listStyle: "none",
  }),
};

export default AssignForm;
