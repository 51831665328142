import { FC } from "react";
import { css } from "@emotion/react";
import { API_ROOT } from "@services/api";

const StagingIndicator: FC = () => {
  return (
    <>
      {API_ROOT.includes("staging") && (
        <h4 css={style.indicator}>DEVELOPER MODE | STAGING</h4>
      )}
    </>
  );
};

const style = {
  indicator: css({
    marginLeft: "10px",
    padding: "4px 8px",
    color: "#fff",
    backgroundColor: "#cf3a03",
    borderRadius: 8,
    border: "2px solid #cf3a03",
  }),
};

export default StagingIndicator;
