import { createSelector, Selector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Task } from "../../types/common";
import { taskAdapter } from "./taskSlice";

const selectSelf = (state: RootState) => state.task;

export const selectCurrentTask: Selector<RootState, Task | undefined> =
  createSelector(selectSelf, ({ entities, selectedId }) => {
    if (selectedId) return entities[selectedId];
    return undefined;
  });

export const {
  selectAll: selectAllTasks,
  selectById: selectTaskById,
  selectEntities: selectTaskEntities,
  selectIds: selectAllTaskIds,
} = taskAdapter.getSelectors();
