import React from "react";
import Label from "./Label";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import { EntityId } from "@reduxjs/toolkit";
import { css } from "@emotion/react";
import { Issue, WorkLog } from "@store/issue/types";
import useAppSelector from "@hooks/useAppSelector";
import { selectCurrentUserId, selectUserById } from "@store/user/userSelectors";
import Button from "@components/base/button";
import { Attachment } from "../../../types/common";
import theme from "@styles/theme";
import { selectSpaceById } from "@store/space/spaceSelectors";
import { ImCheckmark } from "react-icons/im";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { getLanguage } from "@helpers";

interface IssueDetailsProps {
  issue: Issue;
  onEditPressed: () => void;
}

const IssueDetails: React.FC<IssueDetailsProps> = ({
  issue: {
    attachments,
    description,
    spaceId,
    label,
    priority,
    state,
    workLogs,
    createdAt,
    reportedBy,
    authorId,
  },
  onEditPressed,
}) => {
  const { t } = useTranslation();
  const space = useAppSelector(({ space }) => selectSpaceById(space, spaceId));

  const filterWorkLogs = ({ nextState, prevState }: WorkLog) =>
    !isEmpty(nextState) && !isEmpty(prevState);

  const user = useAppSelector(({ user }) =>
    selectUserById(user, authorId as EntityId),
  );

  const filteredLogs = workLogs.filter(filterWorkLogs);
  return (
    <>
      <div css={styles.container}>
        <div css={styles.pannelsContainer}>
          <div css={styles.panel}>
            <Label label={t("common.property")} value={space?.label} />
            <Label label={t("common.issue")} value={label} />
            <Label label={t("common.remark")} value={description} />
            {user !== undefined && (
              <Label label={t("issue.createdBy")} value={user.profile.name} />
            )}
            {reportedBy !== undefined && (
              <Label label={t("issue.reportedBy")} value={reportedBy} />
            )}
            {createdAt !== undefined && (
              <Label
                label={t("issue.createdAt")}
                value={format(new Date(createdAt), "HH:mm, dd-MM-yyyy", {
                  locale: getLanguage(),
                })}
              />
            )}
            {priority && (
              <div css={styles.priority}>
                <ImCheckmark css={styles.check} />
                <span>{t("issue.priority")}</span>
              </div>
            )}
          </div>
          <div css={styles.divider} />
          <div data-test-id="button-wrapper" css={styles.panel}>
            <Attachments attachments={attachments} />
            <Label
              label={t("issue.states.title")}
              value={t(`issue.states.${state}`)}
            />
            {filteredLogs.length > 0 && (
              <div css={styles.logContainer}>
                <span>{t("issue.changeLog")}</span>
                {filteredLogs.map((workLog, index) => (
                  <WorkLogRow key={index} workLog={workLog} />
                ))}
              </div>
            )}
            <Button kind="tertiary" onClick={onEditPressed}>
              {t("issue.changeDetails")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

interface AttachmentsProps {
  attachments?: Attachment[];
}

const Attachments: React.FC<AttachmentsProps> = ({ attachments }) => {
  return (
    <>
      {!attachments?.length ? (
        <div css={[styles.noAttachments, styles.carouselContainer]}>
          <h6 color="rgba(0, 0, 0, 0.4)">Nog geen foto</h6>
        </div>
      ) : attachments.length === 1 ? (
        <div css={styles.carouselContainer}>
          <img css={styles.image} src={attachments[0].url} />
        </div>
      ) : (
        <div css={styles.carouselContainer}>
          <Carousel
            showThumbs={false}
            width={327}
            showStatus={false}
            renderArrowNext={(
              clickHandler: (
                event: React.MouseEvent<HTMLDivElement, MouseEvent>,
              ) => void,
              hasNext,
            ) => (
              <div
                onClick={hasNext ? clickHandler : undefined}
                css={styles.arrow}
                style={{
                  right: theme.sizing.scale800,
                  opacity: hasNext ? 1 : 0.5,
                }}>
                <FiChevronRight css={styles.chevron} />
              </div>
            )}
            renderArrowPrev={(
              clickHandler: (
                event: React.MouseEvent<HTMLDivElement, MouseEvent>,
              ) => void,
              hasNext,
            ) => (
              <div
                onClick={hasNext ? clickHandler : undefined}
                css={styles.arrow}
                style={{
                  left: theme.sizing.scale800,
                  opacity: hasNext ? 1 : 0.5,
                }}>
                <FiChevronLeft css={styles.chevron} />
              </div>
            )}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                return (
                  <li
                    css={[styles.indicator, styles.selected]}
                    aria-label={`Selected: ${label} ${index + 1}`}
                    title={`Selected: ${label} ${index + 1}`}
                  />
                );
              }
              return (
                <li
                  css={styles.indicator}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label} ${index + 1}`}
                  aria-label={`${label} ${index + 1}`}
                />
              );
            }}>
            {attachments.map((attachment) => (
              <img key={attachment.id} src={attachment.url} />
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

interface WorkLogRowProps {
  workLog: WorkLog;
}

const WorkLogRow: React.FC<WorkLogRowProps> = ({
  workLog: { userId, remark, createdAt, prevState, nextState },
}) => {
  const { t } = useTranslation();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const user = useAppSelector(({ user }) => selectUserById(user, userId));

  const formattedDate = format(
    new Date(createdAt),
    "EEEEEE dd MMMM yyyy - HH:mm",
    {
      locale: getLanguage(),
    },
  );

  return (
    <>
      <div css={styles.logRow}>
        <div css={styles.initials}>
          <span css={styles.initialsLabel}>{user?.profile.initials}</span>
        </div>
        <div>
          <div css={styles.status}>
            <span css={styles.label}>{t(`issue.states.${prevState}`)}</span>

            <span>{"  >  "}</span>

            <span css={styles.label}>{t(`issue.states.${nextState}`)}</span>
          </div>
          {remark && (
            <span style={{ marginBottom: theme.sizing.scale200 }}>
              '{remark}'
            </span>
          )}
          <div css={styles.timestamp}>
            <span>{`${formattedDate} door ${
              user?.id === currentUserId ? "mij" : user?.profile.name
            }`}</span>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  initials: css({
    display: "flex",
    backgroundColor: theme.colors.mono200,
    height: 42,
    width: 42,
    borderRadius: theme.borders.radius600,
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.sizing.scale400,
  }),
  logRow: css({
    display: "flex",
    marginTop: theme.sizing.scale200,
    marginBottom: theme.sizing.scale800,
    alignItems: "center",
  }),
  status: css({
    marginBottom: theme.sizing.scale200,
  }),
  timestamp: css({
    display: "flex",
    alignItems: "center",
  }),
  initialsLabel: css({
    color: theme.colors.white,
  }),
  label: css({
    borderRadius: theme.borders.radius400,
    padding: `${theme.sizing.scale200} ${theme.sizing.scale400}`,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.mono200,
  }),
  carouselContainer: css({
    marginBottom: theme.sizing.scale800,
  }),
  noAttachments: css({
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    height: 48,
    border: "1px dashed rgba(0, 0, 0, 0.4)",
  }),
  image: css({
    width: 327,
  }),
  chevron: css({
    width: 24,
    height: 24,
    cursor: "pointer",
  }),
  indicator: css({
    background: theme.colors.white,
    width: 8,
    height: 8,
    verticalAlign: "middle",
    borderRadius: theme.borders.radius200,
    display: "inline-block",
    margin: `0 ${theme.sizing.scale200}`,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
  }),
  selected: css({
    width: 12,
    height: 12,
    borderRadius: theme.borders.radius300,
  }),
  container: css({
    backgroundColor: theme.colors.white,
    width: 732,
  }),

  pannelsContainer: css({
    display: "flex",
    marginTop: theme.sizing.scale1600,
  }),
  divider: css({
    display: "flex",
    width: 1,
    backgroundColor: theme.colors.mono200,
    margin: `0 ${theme.sizing.scale800}`,
  }),
  panel: css({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flex: 1,
  }),
  check: css({
    marginRight: theme.sizing.scale400,
    fontSize: 24,
    color: theme.colors.positive,
  }),
  priority: css({
    display: "flex",
    alignItems: "center",
  }),
  logContainer: css({
    marginBottom: theme.sizing.scale1600,
  }),
  arrow: css({
    display: "flex",
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 36,
    height: 36,
    borderRadius: theme.borders.radius500,
    backgroundColor: theme.colors.white,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  }),
};

export default IssueDetails;
