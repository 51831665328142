import { createSelector, Selector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { PaginationMeta } from "../../types/common";
import { issueSelectors } from "./issueSlice";
import { Issue, IssueType } from "./types";

const selectSelf = (state: RootState) => state.issue;
export const { selectById: selectIssueById } = issueSelectors;

export const selectCurrentIssue: Selector<RootState, Issue | undefined> =
  createSelector(selectSelf, ({ entities, selectedId }) => {
    if (selectedId) return entities[selectedId];
    return undefined;
  });

export const selectPaginationMeta: Selector<RootState, PaginationMeta> =
  createSelector(
    selectSelf,
    ({ meta }) =>
      meta ?? { count: 0, last: 1, next: null, prev: null, page: 1, pages: 1 },
  );

export const selectAllIssuesByType: Selector<RootState, Issue[], [IssueType]> =
  createSelector(
    selectSelf,
    (_: RootState, type: IssueType) => type,
    ({ entities }, type) => {
      const issues = Object.values(entities);
      return issues.filter((issue) => issue?.typeOf === type) as Issue[];
    },
  );
