import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
  Update,
} from "@reduxjs/toolkit";
import {
  DeleteAttachmentPayload,
  Issue,
  IssueState as IssueStates,
  TransactionPayload,
} from "./types";
import {
  IssueTransaction,
  IssueTransactionTypes,
} from "../../types/transaction";
import { Attachment, PaginationMeta } from "../../types/common";

export interface IssueState extends EntityState<Issue> {
  selectedId?: EntityId;
  meta?: PaginationMeta;
}

const issueAdapter = createEntityAdapter<Issue>();

const initialState: IssueState = {
  selectedId: undefined,
  meta: undefined,
  ...issueAdapter.getInitialState(),
};

const issueChangesByWorkTransaction = (
  transaction: IssueTransaction,
  prevIssue: Issue,
): Update<Issue>["changes"] => {
  const userId = transaction.userId;
  const workLogs = [...prevIssue.workLogs];

  switch (transaction.name) {
    case IssueTransactionTypes.ASSIGN: {
      if (userId !== undefined)
        return {
          users: [{ id: userId }],
        };
      return { users: [] };
    }
    case IssueTransactionTypes.UNASSIGN: {
      const users = prevIssue.users.filter(
        (user) => user.id !== transaction.userId,
      );
      return {
        users,
      };
    }
    case IssueTransactionTypes.START_WORK: {
      const users = [...prevIssue.users];
      if (userId !== undefined) {
        workLogs.unshift({
          userId,
          remark: transaction.meta?.remark,
          prevState: prevIssue.state,
          nextState: IssueStates.IN_PROGRESS,
          createdAt: transaction.createdAt,
          attachments: [],
        });
        if (!users.some((user) => user.id === userId))
          users.push({ id: userId });
      }
      return {
        state: stateByTransactionType[transaction.name],
        users,
        workLogs,
      };
    }
    case IssueTransactionTypes.WAIT_WORK:
    case IssueTransactionTypes.STOP_WORK: {
      const nextState = transaction.meta?.nextState;

      let state = stateByTransactionType[transaction.name];
      if (userId !== undefined) {
        state = nextState ?? IssueStates.OPEN;
        workLogs.unshift({
          userId,
          remark: transaction.meta?.remark,
          prevState: prevIssue.state,
          nextState: state,
          createdAt: transaction.createdAt,
          attachments: [],
        });
      }
      return {
        state,
        workLogs,
      };
    }
    case IssueTransactionTypes.FINISH_WORK: {
      if (userId !== undefined)
        workLogs.unshift({
          userId,
          remark: transaction.meta?.remark,
          prevState: prevIssue.state,
          nextState: IssueStates.DONE,
          createdAt: transaction.createdAt,
          attachments: [],
        });
      return {
        state: stateByTransactionType[transaction.name],
        workLogs,
      };
    }
    default:
      return {};
  }
};

const stateByTransactionType: { [id: string]: IssueStates } = {
  [IssueTransactionTypes.START_WORK]: IssueStates.IN_PROGRESS,
  [IssueTransactionTypes.STOP_WORK]: IssueStates.OPEN,
  [IssueTransactionTypes.FINISH_WORK]: IssueStates.DONE,
};

const issueSlice = createSlice({
  name: "issues",
  initialState,
  reducers: {
    setIssues: issueAdapter.setAll,
    setIssue: issueAdapter.upsertOne,
    addIssue: issueAdapter.addOne,
    removeIssue: issueAdapter.removeOne,
    setSelectedIssueId(
      state,
      { payload }: PayloadAction<Issue["id"] | undefined>,
    ) {
      state.selectedId = payload;
    },

    setPaginationMeta(state, { payload }: PayloadAction<PaginationMeta>) {
      state.meta = payload;
    },

    deleteAttachment: (
      state,
      { payload }: PayloadAction<DeleteAttachmentPayload>,
    ) => {
      const issue = state.entities[payload.issueId];
      if (issue === undefined) return;

      issue.attachments = issue.attachments.filter(
        (a) => a.id != payload.attachment.id,
      );
    },

    commitAttachments: (
      state,
      {
        payload,
      }: PayloadAction<{
        issueId: Issue["id"];
        attachments: Attachment[];
      }>,
    ) => {
      const { issueId, attachments } = payload;
      const issue = state.entities[issueId];
      if (issue === undefined) return;
      if (issue.attachments === undefined) {
        issue.attachments = attachments;
        return;
      }
      issue.attachments = issue.attachments.concat(attachments);
    },

    commitIssueTransaction: (
      state,
      { payload }: PayloadAction<TransactionPayload[]>,
    ) => {
      const updatedIssues: Update<Issue>[] = [];

      payload.forEach(({ transaction, prevIssue }) => {
        const update: Update<Issue> = { id: transaction.issueId, changes: {} };
        update.changes = issueChangesByWorkTransaction(transaction, prevIssue);
        updatedIssues.push(update);
      });
      issueAdapter.updateMany(state, updatedIssues);
    },
  },
});

export const {
  setIssue,
  setIssues,
  setSelectedIssueId,
  addIssue,
  commitIssueTransaction,
  removeIssue,
  commitAttachments,
  deleteAttachment,
  setPaginationMeta,
} = issueSlice.actions;

export const issueSelectors = issueAdapter.getSelectors();

export default issueSlice;
