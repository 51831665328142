import { FC } from "react";
import { css } from "@emotion/react";
import theme from "@styles/theme";

interface LabelProps {
  label: string;
  value?: string;
}

const Label: FC<LabelProps> = ({ label, value }) => {
  return (
    <div css={styles.container}>
      <div>
        <strong>{label}</strong>
      </div>
      <div>{value ?? "-"}</div>
    </div>
  );
};

const styles = {
  container: css({
    marginBottom: theme.sizing.scale800,
  }),
};

export default Label;
