import { FC } from "react";
import Assignee from "@components/Assignee";
import { format } from "date-fns";
import Table from "@components/base/table";
import theme from "@styles/theme";
import { Audit, Total } from "@store/dayReport/types";
import { ReportExtra } from "@store/reportExtra/types";
import l from "@locale";
import { useTranslation } from "react-i18next";
import { getLanguage } from "@helpers";
interface ActivityLogProps {
  audits: Audit[];
}

interface choiceNames extends Total {
  extras: ReportExtra;
}

const choiceNameType: Record<keyof choiceNames, string> = {
  green: l.t("dayreport.green.title"),
  orange: l.t("dayreport.orange.title"),
  cleaned: l.t("dayreport.clean"),
  doNotDisturb: l.t("dayreport.doNotDisturb.title"),
  carriedOver: l.t("dayreport.carriedOver.title"),
  nonGreenSkip: l.t("dayreport.nonGreenSkip.title"),
  carriedOverFromYesterday: l.t("dayreport.carriedOverFromYesterday.title"),
  extras: l.t("dayreport.extra"),
};

const ActivityLog: FC<ActivityLogProps> = ({ audits }) => {
  const { t } = useTranslation();
  const generateActivityRows = () => {
    return audits
      .map(({ userId, rateName, changes, createdAt }) => {
        return Object.keys(changes).map((key) => {
          let [oldValue, newValue]: number[] = [];
          if (key === "extras") {
            const oldExtras = changes["extras"]![0];
            const newExtras = changes["extras"]![1];
            const allExtras = Object.keys(changes["extras"]![1]);

            [oldValue, newValue] = allExtras.reduce((acc, extra) => {
              const oldExtra =
                oldExtras[extra] === undefined ? 0 : oldExtras[extra];
              const newExtra = newExtras[extra];
              if (oldExtra !== newExtra) {
                acc.push(oldExtra, newExtra);
              }
              return acc;
            }, [] as number[]);
          } else {
            [oldValue, newValue] = changes[
              key as keyof choiceNames
            ]! as number[];
          }
          return [
            <Assignee memberId={userId} hover={true} />,
            format(new Date(createdAt), "EEEE d-M-yy, HH:mm", {
              locale: getLanguage(),
            }),
            rateName ?? t("common.notApplicable"),
            choiceNameType[key as keyof choiceNames],
            oldValue,
            newValue,
          ];
        });
      })
      .flat();
  };
  const dataRows = generateActivityRows();
  return (
    <>
      <div className="container">
        <h2>Activiteiten log</h2>
        <Table
          columns={[
            t("user.self"),
            t("common.date"),
            t("common.roomType"),
            t("common.action"),
            t("dayreport.oldValue"),
            t("dayreport.new_Value"),
          ]}
          data={audits.length > 0 ? dataRows : []}
          emptyMessage={t("dayreport.noChanges")}
        />
      </div>
      <style jsx>
        {`
          .container {
            background-color: ${theme.colors.white};
            border: 1px solid ${theme.colors.mono200};
            padding: ${theme.sizing.scale800};
            margin-bottom: ${theme.sizing.scale800};
          }
          h2 {
            margin-bottom: ${theme.sizing.scale800};
          }
          .row{
            padding: ${(theme.sizing.scale800, theme.sizing.scale400)};
          },
        `}
      </style>
    </>
  );
};

export default ActivityLog;
