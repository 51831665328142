import { memo, useMemo } from "react";
import { css } from "@emotion/react";
import { EntityId } from "@reduxjs/toolkit";
import useAppSelector from "@hooks/useAppSelector";
import { makeSelectCustomSpaceById } from "@store/space/spaceSelectors";
import { useTranslation } from "react-i18next";

interface RoomProps {
  customId: EntityId;
}

const FloorRow = memo(({ customId }: RoomProps) => {
  const { t } = useTranslation();
  const space = useMemo(makeSelectCustomSpaceById, [customId]);
  const { floor } = useAppSelector((state) => space(state, customId));
  return (
    <div>
      <span css={styles.label}>
        {t("customroom.floor", {
          floor: !!floor ? floor : t("common.unknown").toLowerCase(),
        })}
      </span>
    </div>
  );
});

const styles = {
  label: css({
    fontWeight: 400,
  }),
};

export default FloorRow;
