import { newCustomSpace, Space, UpdateSpaceParams } from "../store/space/types";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestTransformer,
  AxiosResponse,
  AxiosResponseTransformer,
} from "axios";
import helpers, { Auth } from "./helpers";
import humps from "humps";
import { Issue, UpdateIssueParams } from "../store/issue/types";
import {
  CreateUserParams,
  UpdateUserParams,
  User,
  UserRoles,
} from "../store/user/types";
import { CreateTaskParams, UpdateTaskParams } from "../store/task/types";
import { Area, Attachment, Task, Tokens } from "../types/common";
import { Transactions } from "../types/transaction";
import { Property, UpdatePropertyParams } from "../store/property/types";
import {
  Audit,
  DayReport,
  DayReportQueryParams,
  DayReportsMeta,
  UpdateDayReportParams,
} from "../store/dayReport/types";
import packageJson from "../../package.json";
import { RateType, UpdateRateTypeParams } from "../store/rateType/types";
import store from "../store";
import { signOut } from "../store/user/userThunk";
import l from "@locale";
import { ExportParams, PaginationParams, PaginationResponse } from "./types";
import {
  ReportExtra,
  UpdateReportExtraParams,
} from "../store/reportExtra/types";
import { TransactionPageParams } from "../store/transaction/types";
import { Honeybadger } from "@honeybadger-io/react";

type AxiosErrorWithResponse = AxiosError<{
  status: number;
  error: string;
  code: string;
}>;

export const API_ROOT =
  import.meta.env.VITE_PUBLIC_API ??
  "https://api-staging.valkdigitalservices.nl";

const headers = {
  "X-Application": `hktd-web-${packageJson.version}`,
};
const uuidV4Regex = new RegExp(
  /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
);
export const client = axios.create({
  baseURL: `${API_ROOT}/v1/hktd`,
  // Awaiting pagination on backend side
  timeout: 10000,
  headers,
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    (data) =>
      humps.camelizeKeys(data, (key, convert) => {
        return uuidV4Regex.test(key) ? key : convert(key);
      }),
  ],
  transformRequest: [
    (data) => humps.decamelizeKeys(data),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
});

export const getToken = () => {
  const auth = Auth.get();
  if (!auth) return false;
  return auth.token;
};

export const tokenHeader = (token: string | false) => {
  if (!token) return {};
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Accept-Language": l.language,
    },
  };
};

const logoutInvalidUser = (message: string) => {
  Honeybadger.notify("Logout invalid user", message);
  store.dispatch(signOut(true));
  window.location.reload();
};

type RequestConfigWithRetry = AxiosRequestConfig;
type AxiosErrorWithRetry = AxiosErrorWithResponse & {
  config: RequestConfigWithRetry;
};

client.interceptors.response.use(
  (response) => response,
  async (error: AxiosErrorWithRetry) => {
    const { config, response } = error;
    const originalRequest: AxiosRequestConfig = config;
    const retrying: boolean =
      "refresh_token" in JSON.parse(config.data ?? "{}");

    if (response?.data?.code === "hktd.invalid_auth") {
      console.log("User is unauthorized, logging out");
      return logoutInvalidUser("Unauthorized");
    }
    if (response?.data?.code !== "invalid_token") return Promise.reject(error);

    if (retrying) {
      Honeybadger.notify("Refresh Token fail", JSON.stringify(error));
      logoutInvalidUser("Refresh token fail");
      return;
    }
    const tokenResult = await refreshTokenPromise();
    if (!tokenResult) return;
    const {
      data: { token, refreshToken },
    } = tokenResult;
    Auth.update(token, refreshToken);

    originalRequest.headers = originalRequest.headers ?? {};
    originalRequest.headers["Authorization"] = `Bearer ${token}`;

    return axios.request(originalRequest);
  },
);

let refreshTokenStarted = false;
let tokenPromise: Promise<
  AxiosResponse<{ token: string; refreshToken: string }>
> | null = null;
const refreshTokenPromise = async () => {
  if (!refreshTokenStarted) {
    refreshTokenStarted = true;
    const auth = Auth.get();
    tokenPromise = client.post("/sessions", {
      refresh_token: auth?.refreshToken,
    });
    tokenPromise.finally(() => {
      tokenPromise = null;
      refreshTokenStarted = false;
    });
  }

  return tokenPromise;
};

const handleErrors = async (e: AxiosError<{ error: string }>) => {
  let message = "error";
  if (e.response && e.response.data && e.response.data.error) {
    message = e.response.data.error;
  }
  throw message;
};

export const requests = {
  del: async (url: string, data?: object) => {
    try {
      const token = await getToken();
      const res = await client.delete(url, {
        data,
        ...tokenHeader(token),
      });
      return res.data;
    } catch (er) {
      return handleErrors(er as AxiosErrorWithResponse);
    }
  },
  get: async (url: string, rawParams?: object) => {
    try {
      const params = rawParams ? humps.decamelizeKeys(rawParams) : {};
      const token = await getToken();
      const res = await client.get(url, { ...tokenHeader(token), params });
      return res.data;
    } catch (er) {
      return handleErrors(er as AxiosErrorWithResponse);
    }
  },
  publicGet: async (url: string) => {
    try {
      const res = await client.get(url, {
        headers: {
          // "Accept-Language": l.language,
          "Cache-Control": "no-cache",
        },
      });
      return res.data;
    } catch (er) {
      return handleErrors(er as AxiosErrorWithResponse);
    }
  },
  put: async (url: string, body?: object) => {
    try {
      const token = await getToken();
      const res = await client.put(url, body, tokenHeader(token));
      return res.data;
    } catch (er) {
      return handleErrors(er as AxiosErrorWithResponse);
    }
  },
  post: async (url: string, body: object) => {
    try {
      const token = await getToken();
      const res = await client.post(url, body, tokenHeader(token));
      return res.data;
    } catch (er) {
      return handleErrors(er as AxiosErrorWithResponse);
    }
  },
  rawPost: async (url: string, body: object) => {
    const token = await getToken();
    return client.post(url, body, tokenHeader(token));
  },
  publicPost: async (url: string, body: object) => {
    try {
      const res = await client.post(url, body, {
        headers: { "Accept-Language": l.language },
        baseURL: API_ROOT,
      });
      return res.data;
    } catch (er) {
      return handleErrors(er as AxiosErrorWithResponse);
    }
  },
};

class ApiService {
  client: typeof requests;
  Transactions = {
    getAll: async (): Promise<AxiosResponse<Transactions>> => {
      return this.client.get("/transactions");
    },
    getByPage: async (
      params: TransactionPageParams,
    ): Promise<PaginationResponse<Transactions>> => {
      return this.client.get("/transactions", params);
    },
    create: async (batch: Transactions) =>
      this.client.post("/transactions", { batch }),
  };
  Users = {
    getAll: async (): Promise<AxiosResponse<User[]>> => {
      return this.client.get("/users");
    },
    getById: async (id: User["id"]): Promise<User> => {
      return this.client.get(`/users/${id}`);
    },
    create: async (params: CreateUserParams): Promise<User> => {
      return this.client.post("/users", params);
    },
    update: async (id: User["id"], params: UpdateUserParams): Promise<User> => {
      return this.client.put(`/users/${id}`, params);
    },
    delete: async (id: User["id"]): Promise<AxiosResponse> => {
      return this.client.del(`/users/${id}`);
    },
    switchRole: async (role: UserRoles) => {
      const response: Tokens = await this.client.put("/sessions/current", {
        role,
      });
      helpers.Auth.update(response.token, response.refreshToken);
      return true;
    },
    resetPassword: async (email: string) =>
      this.client.post("/users/password_reset", { email }),
  };
  Areas = {
    getAll: async (): Promise<AxiosResponse<Area[]>> => {
      return this.client.get("/areas");
    },
    create: async (params: Omit<Area, "id">): Promise<Area> => {
      return this.client.post("/areas", params);
    },
    update: async (id: Area["id"], params: Partial<Area>): Promise<Area> => {
      return this.client.put(`/areas/${id}`, params);
    },
    delete: async (id: Area["id"]): Promise<AxiosResponse> => {
      return this.client.del(`/areas/${id}`);
    },
  };
  Attachment = {
    add: async (): Promise<{ presignedUrl: string; id: string }> =>
      this.client.post("/attachments", {}),
    delete: async (id: Attachment["id"]) =>
      this.client.del(`/attachments/${id}`),
  };
  Tasks = {
    getAll: async (): Promise<AxiosResponse<Task[]>> => {
      return this.client.get("/tasks");
    },
    getById: async (id: Task["id"]): Promise<Task> => {
      return this.client.get(`/tasks/${id}`);
    },
    create: async (params: CreateTaskParams): Promise<Task> => {
      return this.client.post("/tasks", params);
    },
    update: async (id: Task["id"], params: UpdateTaskParams): Promise<Task> => {
      return this.client.put(`/tasks/${id}`, params);
    },
    delete: async (id: Task["id"]) => {
      return this.client.del(`/tasks/${id}`);
    },
  };
  Auth = {
    signInWithEmail: async (username: string, password: string) => {
      try {
        const response: Tokens = await this.client.post("/sessions", {
          email: username,
          password,
          grant: "credentials",
        });
        helpers.Auth.update(response.token, response.refreshToken);
        return true;
      } catch (error) {
        return false;
      }
    },
    signInWithQr: async (userId: User["id"]) => {
      try {
        const response: Tokens = await this.client.post("/sessions", {
          id: userId,
          grant: "credentials",
        });
        helpers.Auth.update(response.token, response.refreshToken);
        return true;
      } catch (error) {
        return false;
      }
    },
  };
  Issues = {
    delete: async (issueId: Issue["id"]) => {
      return this.client.del(`/issues/${issueId}`);
    },
    update: async (issueId: Issue["id"], updatedParams: UpdateIssueParams) => {
      return this.client.put(`/issues/${issueId}`, updatedParams);
    },
    getPage: async (
      params: PaginationParams,
    ): Promise<PaginationResponse<Issue[]>> => {
      return this.client.get("/issues", params);
    },
    getById: async (id: Issue["id"]): Promise<Issue> => {
      return this.client.get(`/issues/${id}`);
    },
    getCsv: async (params: ExportParams): Promise<BlobPart> => {
      return this.client.get("/issues", { ...params, csv: true });
    },
    getActive: async (params: {
      spaceId: Issue["spaceId"];
      type: Issue["typeOf"];
    }): Promise<PaginationResponse<Issue[]>> => {
      return this.client.get(`/issues`, {
        ...params,
        active: true,
      });
    },
  };
  Property = {
    getById: async (id: Property["id"]): Promise<Property> => {
      return this.client.get(`/properties/${id}`);
    },
    getAll: async (): Promise<AxiosResponse<Property[]>> => {
      return this.client.get(`/properties`);
    },
    update: async (
      id: Property["id"],
      params: UpdatePropertyParams,
    ): Promise<Property> => {
      return this.client.put(`/properties/${id}`, params);
    },
    switch: async (propertyId: Property["id"]) => {
      const response: Tokens = await this.client.put("/sessions/current", {
        propertyId,
      });
      helpers.Auth.update(response.token, response.refreshToken);
      return true;
    },
  };
  Stats = {
    getStats: async () => {},
  };
  Spaces = {
    getAll: async (): Promise<AxiosResponse<Space[]>> => {
      return this.client.get("/spaces");
    },
    getById: async (id: Space["id"]): Promise<Space> => {
      return this.client.get(`/spaces/${id}`);
    },
    createCustomSpace: async (newSpace: newCustomSpace) => {
      return this.client.post("/spaces", newSpace);
    },
    update: async (
      id: Space["id"],
      params: UpdateSpaceParams,
    ): Promise<Space> => {
      return this.client.put(`/spaces/${id}`, params);
    },
    delete: async (id: Space["id"]): Promise<AxiosResponse> => {
      return this.client.del(`/spaces/${id}`);
    },
  };
  DayReports = {
    getPage: async (
      params: DayReportQueryParams & PaginationParams,
    ): Promise<PaginationResponse<DayReport[]> & { meta: DayReportsMeta }> => {
      return this.client.get("/day_reports", { ...params });
    },
    getCsv: async (params: DayReportQueryParams): Promise<BlobPart> =>
      this.client.get("/day_reports", { ...params, csv: true }),
    getById: async (id: DayReport["id"]): Promise<DayReport> => {
      return this.client.get(`/day_reports/${id}`);
    },
    getByDate: async (date: string): Promise<DayReport> => {
      return this.client.get(`/day_reports/date:${date}`);
    },
    update: async (
      id: DayReport["id"],
      params: UpdateDayReportParams,
    ): Promise<DayReport> => {
      return this.client.put(`/day_reports/${id}`, params);
    },
    deleteRemark: async (
      id: DayReport["id"],
      auditId: Audit["id"],
    ): Promise<AxiosResponse> => {
      return this.client.del(`/day_reports/${id}/remarks/${auditId}`);
    },
    approve: async (id: DayReport["id"]): Promise<DayReport> => {
      return this.client.put(`/day_reports/${id}/approve`);
    },
    deliver: async (date: string): Promise<AxiosResponse> => {
      return this.client.put(`/day_reports/${date}/deliver`);
    },
  };

  RateTypes = {
    getAll: async (): Promise<AxiosResponse<RateType[]>> => {
      return this.client.get("/rate_types");
    },
    create: async (params: UpdateRateTypeParams) => {
      return this.client.post("/rate_types", params);
    },
    update: async (
      id: RateType["id"],
      params: Partial<UpdateRateTypeParams>,
    ) => {
      return this.client.put(`/rate_types/${id}`, params);
    },
    delete: async (id: RateType["id"]) => {
      return this.client.del(`/rate_types/${id}`);
    },
  };

  ReportExtras = {
    getAll: async (): Promise<AxiosResponse<ReportExtra[]>> => {
      return this.client.get("/report_extras");
    },
    create: async (params: UpdateReportExtraParams) => {
      return this.client.post("/report_extras", params);
    },
    delete: async (id: ReportExtra["id"]) => {
      return this.client.del(`/report_extras/${id}`);
    },
  };

  constructor() {
    this.client = requests;
  }
}

export default new ApiService();
