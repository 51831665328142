import React, { useEffect, useRef, useState } from "react";
import { EmailsInput } from "./components";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { css } from "@emotion/react";
import { Option } from "baseui/select";
import RolebasedSegment from "./components/RolebasedSegment";
import { UserRoles } from "@store/user/types";
import { selectCurrentProperty } from "@store/property/propertySelector";
import { fetchProperty, updateProperty } from "@store/property/propertyThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import { ReportingSettings } from "@store/property/types";
import theme from "@styles/theme";
import Breadcrumb from "@components/base/breadcrumb";
import Checkbox, { CheckboxProps } from "@components/base/checkbox";
import Button from "@components/base/button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavLabels, SubNavLabels } from "@components/Layout";
import i18next from "i18next";

const PERMISSION_OPTIONS: ReportingSettingsOption[] = [
  {
    label: i18next.t("dayreport.settings.reportPermission.fom"),
    value: [UserRoles.HOTEL_MANAGER],
  },
  {
    label: i18next.t("dayreport.settings.reportPermission.hk"),
    value: [UserRoles.HK_MANAGER],
  },
  {
    label: i18next.t("dayreport.settings.reportPermission.fomHk"),
    value: [UserRoles.HK_MANAGER, UserRoles.HOTEL_MANAGER],
  },
];

const GS_MANAGER_OPTIONS: ReportingSettingsOption[] = [
  ...PERMISSION_OPTIONS,
  {
    label: i18next.t("dayreport.settings.reportPermission.fomHkGs"),
    value: [
      UserRoles.HK_MANAGER,
      UserRoles.HOTEL_MANAGER,
      UserRoles.GS_MANAGER,
    ],
  },
  {
    label: i18next.t("dayreport.settings.reportPermission.hkGs"),
    value: [UserRoles.HK_MANAGER, UserRoles.GS_MANAGER],
  },
  {
    label: i18next.t("dayreport.settings.reportPermission.fomGs"),
    value: [UserRoles.HOTEL_MANAGER, UserRoles.GS_MANAGER],
  },
  {
    label: i18next.t("dayreport.settings.reportPermission.gs"),
    value: [UserRoles.GS_MANAGER],
  },
].sort((a, b) => a.value.length - b.value.length);

export interface ReportingSettingsOption extends Option {
  label: string;
  value: UserRoles[];
}

interface ReportingSettingsOptions {
  send?: ReportingSettingsOption;
  update?: ReportingSettingsOption;
  reportExtras?: ReportingSettingsOption;
}

const EndOfDaySettingsScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentProperty = useSelector(selectCurrentProperty);
  const storedSettings = currentProperty!.settings.reporting;
  const hasStoredSettings = useRef<boolean>(false);
  const [settings, setSettings] = useState<ReportingSettings>(storedSettings);

  const [selectedOptions, setSelectedOptions] =
    useState<ReportingSettingsOptions>();
  const canSubmit = !isEqual(settings, storedSettings);

  const goToRoomRates = () => {
    navigate("/settings/day-reports/rate-types");
  };
  const goToExtraTasks = () => {
    navigate("/settings/day-reports/extra-tasks");
  };
  useEffect(() => {
    if (!currentProperty) return;
    dispatch(fetchProperty(currentProperty.id));
  }, []);

  const onPermissionChange = (
    key: keyof ReportingSettingsOptions,
    selectedOption: ReportingSettingsOption,
  ) => {
    const newSelectedOptions = {
      ...selectedOptions,
      [key]: selectedOption,
    };
    setSelectedOptions(newSelectedOptions);
    setSettings({
      ...settings,
      permission: {
        ...settings.permission,
        [key]: selectedOption.value,
      },
    });
  };
  const setEmails = (email: string[]) => {
    setSettings({ ...settings, email });
  };
  const findSelectedOption = (storedSettings: UserRoles[]) => {
    if (!storedSettings || !storedSettings.length) return undefined;
    return PERMISSION_OPTIONS.find((option) =>
      isEqual([...option.value].sort(), [...storedSettings].sort()),
    );
  };

  useEffect(() => {
    if (hasStoredSettings.current && !!selectedOptions) return;
    setSelectedOptions({
      update: findSelectedOption(storedSettings.permission.update),
      send: findSelectedOption(storedSettings.permission.send),
      reportExtras: findSelectedOption(storedSettings.permission.reportExtras),
    });
    hasStoredSettings.current = true;
  }, [storedSettings]);

  const onSubmit = () => {
    if (!currentProperty) return;
    dispatch(
      updateProperty(currentProperty, {
        reporting: settings,
      }),
    );
  };

  return (
    <>
      <Breadcrumb
        labels={[SubNavLabels.Settings, NavLabels.DayReportSettings]}
      />
      <section data-test-id="dagafsluiting" css={styles.section}>
        <div css={[styles.row, styles.enableRow]}>
          <div css={[styles.row]}>
            <h1 style={{ marginRight: theme.sizing.scale800 }}>
              {t("dayreport.endOfDay")}
            </h1>
            <Checkbox
              checked={settings?.active}
              onChange={({ target }) => {
                setSettings({
                  ...settings,
                  active: (target as CheckboxProps).checked || false,
                });
              }}
              checkmarkType="toggle"
            />
          </div>
          <Button onClick={onSubmit} disabled={!canSubmit}>
            {t("common.save")}
          </Button>
        </div>
        <div className={!settings?.active ? "inactive" : ""}>
          <h3 css={styles.title}>Bevoegdheid</h3>
          <RolebasedSegment
            PERMISSION_OPTIONS={PERMISSION_OPTIONS}
            selectedOption={selectedOptions?.send}
            onPermissionChange={(o) => onPermissionChange("send", o)}
            title={t("dayreport.whoMay.send")}
          />
          <RolebasedSegment
            selectedOption={selectedOptions?.update}
            PERMISSION_OPTIONS={PERMISSION_OPTIONS}
            onPermissionChange={(o) => onPermissionChange("update", o)}
            title={t("dayreport.whoMay.edit")}
          />
          <RolebasedSegment
            selectedOption={selectedOptions?.reportExtras}
            PERMISSION_OPTIONS={GS_MANAGER_OPTIONS}
            onPermissionChange={(o) => onPermissionChange("reportExtras", o)}
            title={t("dayreport.whoMay.create")}
          />
          <div css={styles.setting}>
            <h3>{t("dayreport.setupEmail")}</h3>
            <EmailsInput
              label={t("dayreport.whoMay.receive")}
              setEmails={setEmails}
              emails={settings.email}
            />
          </div>

          <div>
            <div>
              <h3>{t("extra.hourAndTask")}</h3>
              <a onClick={goToExtraTasks}>{t("extra.newTaskAction")}</a>
            </div>
          </div>
          <div css={styles.setting}>
            <h3>{t("dayreport.rateTypeGroup")}</h3>
            <a data-test-id="grouprates-link" onClick={goToRoomRates}>
              {t("dayreport.editRateType")}
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

const styles = {
  section: css({
    overflow: "scroll",
    padding: theme.sizing.scale1600,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    paddingBottom: "90px",
  }),

  row: css({
    display: "flex",
    alignItems: "center",
  }),
  setting: css({
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.sizing.scale1600,
    marginTop: theme.sizing.scale1600,
  }),
  inactive: css({
    opacity: "0.2",
    cursor: "not-allowed",
  }),
  enableRow: css({
    width: "100%",
    justifyContent: "space-between",
    marginBottom: theme.sizing.scale800,
  }),
  subtitle: css({
    fontWeight: "bold",
    marginBottom: theme.sizing.scale800,
  }),
  a: css({
    marginTop: theme.sizing.scale400,
  }),
  sectionButton: css({
    alignSelf: "flex-end",
  }),
  title: css({
    paddingBottom: theme.sizing.scale100,
    marginRight: "8px",
  }),
};

export default EndOfDaySettingsScreen;
