import {
  NegativeInspectionTransaction,
  PositiveInspectionTransaction,
  SpaceTransaction,
  SpaceTransactionTypes,
} from "../../types/transaction";
import { Assignee, NewInspection, Room, Space } from "./types";
import {
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit";
import { isRoomSpace } from "@helpers";

const spaceAdapter = createEntityAdapter<Space>();

export interface SpaceState {
  entities: Dictionary<Space>;
  ids: EntityId[];
  selectedId?: EntityId;
}

export interface CustomState {
  entities: Dictionary<Space>;
  ids: EntityId[];
  selectedId?: EntityId;
}

const initialState: SpaceState = {
  selectedId: undefined,
  ...spaceAdapter.getInitialState(),
};

const spaceSlice = createSlice({
  name: "spaces",
  initialState,
  reducers: {
    setSpaces: spaceAdapter.setAll,
    setSpace: spaceAdapter.upsertOne,
    removeSpace: spaceAdapter.removeOne,
    setSelectedSpace(
      state,
      { payload }: PayloadAction<Space["id"] | undefined>,
    ) {
      state.selectedId = payload;
    },

    commitInspectionTransaction: (
      state,
      {
        payload: { transaction, newInspection },
      }: PayloadAction<{
        transaction:
          | PositiveInspectionTransaction
          | NegativeInspectionTransaction;
        newInspection: NewInspection;
      }>,
    ) => {
      const space = state.entities[transaction.spaceId];
      if (!isRoomSpace(space)) return;

      space.room.inspected = true;
      space.room.clean = transaction.meta.result;

      // remove lastInspection if exist's
      if (newInspection.remark === undefined) {
        space.room.lastInspection = undefined;
        return;
      }

      space.room.lastInspection = {
        ...newInspection,
        remark: newInspection.remark,
      };
    },

    commitSpaceTransaction: (
      state,
      { payload }: PayloadAction<SpaceTransaction[]>,
    ) => {
      payload.forEach((action) => {
        const space = state.entities[action.spaceId];
        if (!isRoomSpace(space)) return;
        state.entities[action.spaceId] = {
          ...space,
          room: roomStateByTransaction(space.room as Room, action),
        };
      });
    },
  },
});

export const {
  setSpace,
  setSpaces,
  removeSpace,
  commitSpaceTransaction,
  commitInspectionTransaction,
  setSelectedSpace,
} = spaceSlice.actions;

export const spaceSelectors = spaceAdapter.getSelectors();

const assignTask = (room: Room, action: SpaceTransaction): Room => {
  let newTasks = room?.tasks ?? [];
  if (action.name === SpaceTransactionTypes.ASSIGN_SPACE && action.taskId) {
    // Check if it's not already added (needed for socket + local changes on web)
    if (!newTasks.some((task) => task.id === action.taskId)) {
      newTasks.push({ id: action.taskId });
    }
  } else {
    newTasks = newTasks.filter((task) => task.id !== action.taskId);
  }
  return {
    ...room,
    tasks: newTasks,
  };
};

const assignUser = (room: Room, action: SpaceTransaction): Room => {
  let newAssignments: Assignee[] = [];
  if (action.name === SpaceTransactionTypes.ASSIGN_SPACE) {
    newAssignments.push({
      id: action.userId!,
      active: false,
    });
  } else {
    newAssignments = newAssignments.filter((ass) => ass.id !== action.userId);
  }
  return {
    ...room,
    users: newAssignments,
  };
};

const roomStateByTransaction = (room: Room, action: SpaceTransaction): Room => {
  switch (action.name) {
    // Clean space
    case SpaceTransactionTypes.START_CLEANING:
    case SpaceTransactionTypes.STOP_CLEANING:
    case SpaceTransactionTypes.FINISH_CLEANING:
      return {
        ...room,
        clean: action.name === SpaceTransactionTypes.FINISH_CLEANING,
        users: room?.users.map(({ id, active }) =>
          id === action.userId
            ? {
                active: action.name === SpaceTransactionTypes.START_CLEANING,
                id,
              }
            : { active, id },
        ),
      };
    case SpaceTransactionTypes.SKIP_CLEANING:
      const doNotDisturb = action.meta?.doNotDisturb;
      if (doNotDisturb !== undefined) {
        return {
          ...room,
          doNotDisturb,
          users: room?.users.map(({ id }) => ({ active: false, id })),
        };
      }
      const freshTowels = action.meta?.freshTowels;
      if (freshTowels !== undefined) {
        return {
          ...room,
          freshTowels,
          users: room?.users.map(({ id }) => ({ active: false, id })),
        };
      }
      return room;

    // Assign space
    case SpaceTransactionTypes.ASSIGN_SPACE:
    case SpaceTransactionTypes.UNASSIGN_SPACE:
      if (action.userId) {
        return assignUser(room, action);
      }
      if (action.taskId) {
        return assignTask(room, action);
      }
      return room;

    default:
      return room;
  }
};

export default spaceSlice;
