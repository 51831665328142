import { FC, MouseEvent } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { setSelectedArea } from "@store/area/areaSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import { showPopUp } from "@store/ui/uiSlice";
import { Area } from "../../../types/common";

interface ActionsProps {
  area: Area;
}

const Actions: FC<ActionsProps> = ({ area }) => {
  const dispatch = useAppDispatch();

  const onShowActions = (event: MouseEvent) => {
    dispatch(setSelectedArea(area.id));
    dispatch(
      showPopUp({
        type: "areas/editArea",
        pos: { x: event.clientX, y: event.clientY },
      }),
    );
  };

  return (
    <>
      <div className="dots" onClick={onShowActions}>
        <BiDotsVerticalRounded />
      </div>
      <style jsx>{`
        .dots {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 25px;
        }
      `}</style>
    </>
  );
};

export default Actions;
