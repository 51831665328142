import React from "react";
import classNames from "classnames";
import theme from "@styles/theme";
import getIcon from "./SidebarIcon";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { switchNavigationView } from "@store/ui/uiSlice";
import { selectSidebarView } from "@store/ui/uiSelectors";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import { useTranslation } from "react-i18next";
import { Translation } from "@locale";

interface SidebarLinkProps {
  active: boolean;
  href: string;
  label: keyof Translation["layout"]["menu"];
}

const SidebarLink: React.FC<SidebarLinkProps> = ({ active, href, label }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const visible = useAppSelector(selectSidebarView);

  const handleSideBar = () => {
    dispatch(switchNavigationView(true));
  };

  const onClick = () => {
    navigate(href);
  };

  return (
    <div onClick={onClick}>
      <li
        data-test-id="sidebar-link"
        onClick={handleSideBar}
        css={style.menuItem}>
        {getIcon({ label, active })}
        <a
          className={` ${classNames({ active })} ${
            visible ? "smallView" : ""
          }`}>
          {t(`layout.menu.${label}`)}
        </a>
        <style jsx>{`
          a {
            white-space: nowrap;
            color: ${theme.colors.white};
            line-height: 1;
            opacity: 1;
            transition: opacity 200ms ease-in-out 50ms;
          }
          a.active {
            color: #ffa500;
          }
          li {
            display: flex;
            align-items: center;
            user-select: none;
            font-weight: normal;
            font-size: 16px;
            line-height: 25px;
            margin: 32px 0;
            cursor: pointer;
          }
          .smallView {
            opacity: 0;
          }
        `}</style>
      </li>
    </div>
  );
};

const style = {
  menuItem: css({
    ":hover svg": {
      fill: theme.colors.accent,
    },
    ":hover a": {
      color: theme.colors.accent,
    },
  }),
};
export default SidebarLink;
