import React, { useState } from "react";
import Input from "@components/base/input";
import Button from "@components/base/button";
import {
  generateButtonColors,
  overrideButtonStyles,
} from "@components/base/button/overrides";
import { useTranslation } from "react-i18next";

interface EmailsInputProps {
  active?: boolean;
  label?: string;
  emails: string[];
  setEmails: (emails: string[]) => void;
}

const EmailsInput: React.FC<EmailsInputProps> = ({
  active = true,
  label,
  emails = [],
  setEmails,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);

  const addEmail = () => {
    const trimmedValue = value.trim();
    if (value && isValid(trimmedValue)) {
      setEmails([...emails, trimmedValue]);
      setValue("");
    }
  };

  const onInputChange = (text: string) => {
    setValue(text);
    setError(undefined);
  };

  const handleDelete = (item: string) => {
    setEmails(emails.filter((email) => email !== item));
  };

  const isValid = (email: string) => {
    let error = undefined;

    if (isInList(email)) {
      error = t("login.alreadyadded", { email });
    }

    if (!isEmail(email)) {
      error = t("login.invalid", { email });
    }

    if (error) {
      setError(error);
      return false;
    }
    return true;
  };

  const isInList = (email: string) => emails?.includes(email);
  const isEmail = (email: string) =>
    /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);

  return (
    <div className={`container ${!active && "inactive"}`}>
      <div className="row">
        <Input
          label={label}
          value={value}
          disabled={!active}
          onChangeText={(text) => onInputChange(text)}
          placeholder={t("login.enterNew")}
          containerStyle={{ width: "325px" }}
        />
        <Button
          onClick={addEmail}
          disabled={!active || !!error}
          kind="secondary"
          overrides={overrideButtonStyles({
            BaseButton: ({ $theme, $disabled }) => ({
              padding: "10px",
              ":hover": {
                backgroundColor: "transparant",
                color: $theme.colors.accent200,
                borderColor: $theme.colors.accent200,
              },
              ...generateButtonColors({
                $disabled,
                color: $theme.colors.accent,
              }),
            }),
          })}>
          {t("common.add")}
        </Button>
      </div>

      <div className="tags">
        {emails?.map((item) => (
          <div className="tag-item" key={item}>
            {item}
            <button
              type="button"
              className="button"
              onClick={() => handleDelete(item)}>
              &times;
            </button>
          </div>
        ))}
      </div>

      <style jsx>
        {`
          .container {
            max-width: 600px;
          }
          .container.inactive {
            cursor: not-allowed;
          }
          .container > p {
            margin-bottom: 4px;
          }
          .row {
            display: flex;
            align-items: flex-end;
            margin: 8px 0;
          }
          .tags {
            display: flex;
            flex-wrap: wrap;
          }
          .tag-item {
            background-color: #d4d5d6;
            font-size: 14px;
            border-radius: 30px;
            height: 30px;
            padding: 0 8px 0 1rem;
            display: inline-flex;
            align-items: center;
            align-self: flex-start;
            margin: 0 0.3rem 0.3rem 0;
          }
          .tag-item > .button {
            background-color: white;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            border: none;
            cursor: pointer;
            font: inherit;
            margin-left: 10px;
            font-weight: bold;
            padding: 0;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        `}
      </style>
    </div>
  );
};

export default EmailsInput;
