import { AppThunk } from "..";
import services from "../../services/api";
import { Area } from "../../types/common";
import { CreateAreaParams, UpdateAreaParams } from "./types";
import l from "@locale";
import { batch } from "react-redux";
import { showNotification } from "../ui/uiThunk";
import { addArea, removeArea, setAreas, upsertArea } from "./areaSlice";

export const fetchAreas = (): AppThunk => async (dispatch) => {
  try {
    const response = await services.Areas.getAll();

    dispatch(setAreas(response.data));
  } catch (error) {}
};

export const createArea =
  (area: CreateAreaParams): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.area");

    try {
      const response = await services.Areas.create(area);

      batch(() => {
        dispatch(
          showNotification({
            message: l.t("actions.add.success", { value }),
            type: "success",
          }),
        );
        dispatch(addArea(response));
      });
    } catch (error) {
      dispatch(
        showNotification({
          message: l.t("actions.add.failed", { value }),
          type: "error",
        }),
      );
    }
  };

export const deleteArea =
  (areaId: Area["id"]): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.area");

    try {
      await services.Areas.delete(areaId);
      batch(() => {
        dispatch(
          showNotification({
            message: l.t("actions.add.success", { value }),
            type: "success",
          }),
        );
        dispatch(removeArea(areaId));
      });
    } catch (error) {
      dispatch(
        showNotification({
          message: l.t("actions.add.failed", { value }),
          type: "error",
        }),
      );
    }
  };

export const updateArea =
  (updatedParams: UpdateAreaParams, areaId: Area["id"]): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.area");

    try {
      const response = await services.Areas.update(areaId, updatedParams);
      batch(() => {
        dispatch(
          showNotification({
            message: l.t("actions.add.success", { value }),
            type: "success",
          }),
        );
        dispatch(upsertArea(response));
      });
    } catch (error) {
      dispatch(
        showNotification({
          message: l.t("actions.update.failed", { value }),
          type: "error",
        }),
      );
    }
  };
