import React, { useMemo } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { makeSelectRoomToggleById } from "@store/ui/uiSelectors";
import { setRoomToggle } from "@store/ui/uiSlice";
import Checkbox from "@components/base/checkbox";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";

interface Props {
  spaceId: EntityId;
}

const RoomCheckbox: React.FC<Props> = ({ spaceId }) => {
  const dispatch = useAppDispatch();
  const roomToggleById = useMemo(makeSelectRoomToggleById, [spaceId]);
  const isSelected = useAppSelector((state) => roomToggleById(state, spaceId));
  const onCheck = () => {
    dispatch(setRoomToggle(spaceId));
  };

  return <Checkbox checked={isSelected} onChange={onCheck} />;
};

export default RoomCheckbox;
