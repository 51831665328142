import React, { useEffect, useState } from "react";
import ResizableTextArea from "@components/ResizableTextArea";
import SuggestionsInput, { Suggestion } from "@components/SuggestionsInput";
import theme from "@styles/theme";
import { Task } from "../../../types/common";
import { useTranslation } from "react-i18next";

interface SelectTasksProps {
  tasks?: { [key: string]: Task };
  suggestions: Suggestion[];
  editTasks: (id: Task["id"]) => void;
  updateTask: (task: Task) => void;
  setSelectedTask: (id: Task["id"] | null) => void;
  selectedTask?: Task;
  setIsValid: (valid: boolean) => void;
}

const SelectTasks: React.FC<SelectTasksProps> = ({
  tasks = {},
  suggestions,
  editTasks,
  updateTask,
  setSelectedTask,
  setIsValid,
  selectedTask,
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [tabs, setTabs] = useState<(Task["id"] | null)[]>([null]);
  const amountOfTabs = tabs.length;
  const [input, setInput] = useState("");

  useEffect(() => {
    setIsValid(!tabs.includes(null));
  }, [tabs, selectedTask]);

  const addTask = (id: Task["id"]) => {
    if (selectedTask) {
      editTasks(selectedTask.id);
    }
    editTasks(id);
    setSelectedTask(id);
    const newTabs = [...tabs];
    newTabs[selectedTab] = id;
    setTabs(newTabs);
  };

  const tabPressed = (index?: number) => {
    if (index === undefined) return;
    const id = tabs[index];
    if (id) {
      setSelectedTask(tabs[index]);
      tasks[id] && setInput(tasks[id].label);
    } else {
      setInput("");
      setSelectedTask(null);
    }
    setSelectedTab(index);
  };

  const removeTab = (index: number) => {
    const id = tabs[index];

    //removes the tasks from selected tasks
    if (id) {
      editTasks(id);
    }
    if (index > 0) {
      tabPressed(index - 1);
    } else {
      tabPressed(index);
    }
    const newTabs = tabs.filter((_tab, i) => i != index);
    setTabs(newTabs);
  };

  const updateRemarks = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (selectedTask === undefined) return;
    updateTask({ ...selectedTask, description: value });
  };

  const addTab = () => {
    setTabs([...tabs, null]);
    tabPressed(tabs.length);
  };

  return (
    <div className="selectContainer">
      <div className="tabsContainer">
        {amountOfTabs > 1 &&
          tabs.map((k, i) => {
            if (i != selectedTab) {
              return (
                <div
                  key={i}
                  className={`tab inactive ${
                    tabs[i] != null ? "assigned" : ""
                  }`}
                  onClick={() => tabPressed(i)}>
                  <span>{i + 1}</span>
                </div>
              );
            }
            return (
              <div key={k} className="tab active">
                <span>{t("tasks.number", { task: i + 1 })}</span>
                <img
                  className="closeTab"
                  width={16}
                  height={16}
                  onClick={() => removeTab(i)}
                  src="/icons/icon-remove.svg"
                />
              </div>
            );
          })}
        {amountOfTabs == 1 && (
          <div className="tab active">
            <span>{t("tasks.number", { task: 1 })}</span>
          </div>
        )}
        {amountOfTabs <= 1 ? (
          <span className="add" onClick={addTab}>
            {t("tasks.add")}
          </span>
        ) : amountOfTabs <= 6 ? (
          <div className="addButton" onClick={addTab}>
            <p className="plus" color="#fff">
              +
            </p>
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="details">
        <SuggestionsInput
          suggestions={suggestions}
          placeholder={t("search.task")}
          label={t("common.issue")}
          input={input}
          setInput={setInput}
          onSetSuggestion={addTask}
        />
        {selectedTask && (
          <ResizableTextArea
            value={selectedTask?.description ?? ""}
            onChange={updateRemarks}
            maxRows={12}
            label={t("common.remark")}
          />
        )}
      </div>
      <style jsx>
        {`
          .selectContainer {
            display: flex;
            position: relative;
            background-color: #ffffff;
            flex-direction: column;
            align-items: flex-start;
            align-self: center;
            border-radius: 4px;
            margin: auto;
            width: 300px;
            padding: 0 0 40px 0;
          }
          .tabsContainer {
            display: flex;
            box-sizing: border-box;
            width: 100%;
            flex-direction: row;
            align-items: center;
          }
          .tab {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 3px 10px;
            z-index: 800;
            margin-right: 5px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            cursor: pointer;
          }
          .active {
            border: solid ${theme.colors.mono200};
            border-width: 1px 1px 0 1px;
            margin-bottom: -1.7px;
            padding: 5px 5px;
            background: white;
          }
          .inactive {
            background-color: ${theme.colors.mono200};
            margin-bottom: -3px;
          }
          .assigned {
            background-color: ${theme.colors.lightGreen};
          }
          span {
            cursor: pointer;
            color: ${theme.colors.dark};
          }
          .add {
            color: ${theme.colors.orange};
            padding-left: 5px;
          }
          .addButton {
            display: flex;
            align-self: center;
            border-radius: 8px;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            background-color: ${theme.colors.orange};
          }

          p.plus {
            color: #fff;
            padding-bottom: 2px;
          }

          .details {
            display: flex;
            padding: 20px 20px 0 20px;
            flex-direction: column;
            border: 1px solid ${theme.colors.mono200};
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
          }

          input {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 20px;
            outline: none;
            border: none;
            border-bottom: solid 1px ${theme.colors.lightGray};
            margin-bottom: 20px;
            resize: none;
          }

          .closeTab {
            margin-left: 5px;
          }
        `}
      </style>
    </div>
  );
};

export default SelectTasks;
