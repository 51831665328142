import { FC } from "react";
import { css } from "@emotion/react";
import theme from "@/styles/theme";
import { Notification as BaseNotification, KIND } from "baseui/notification";

export interface NotificationProps {
  content?: string;
  title?: string;
  kind?: notificationTypes;
  fixed?: boolean;
  fullWidth?: boolean;
}

export type notificationTypes = "positive" | "negative" | "info" | "warning";

const Notification: FC<NotificationProps> = (props) => {
  const { fullWidth, content, kind = KIND.info, fixed } = props;
  let color;
  let backgroundColor;

  switch (kind) {
    case "info":
      color = theme.colors.primaryA;
      backgroundColor = theme.colors.primary300;
      break;
    case "positive":
      color = theme.colors.positive50;
      backgroundColor = theme.colors.positive300;
      break;
    case "negative":
      color = theme.colors.negative50;
      backgroundColor = theme.colors.negative300;
      break;
    case "warning":
      color = theme.colors.mono1000;
      backgroundColor = theme.colors.warning200;
      break;
    default:
      color = theme.colors.primaryA;
      backgroundColor = theme.colors.primary300;
  }

  return (
    <div css={fixed ? styles.fixedWrapper : ""}>
      <BaseNotification
        overrides={{
          Body: { style: { width: fullWidth ? "auto" : "", backgroundColor } },
          InnerContainer: { style: { color } },
        }}
        kind={kind}>
        {content}
      </BaseNotification>
    </div>
  );
};

const styles = {
  fixedWrapper: css({
    position: "fixed",
    right: "10px",
    top: "64px",
  }),
};

export default Notification;
