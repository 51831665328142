import { css } from "@emotion/react";
import { Value } from "baseui/select";
import { useState } from "react";
import * as yup from "yup";
import theme from "@styles/theme";
import { CustomSpace } from "@store/space/types";
import { showNotification } from "@store/ui/uiThunk";
import { Form } from "@helpers";
import Button from "@components/base/button";
import {
  selectAllCustomSpaces,
  selectAllRoomSpaces,
} from "@store/space/spaceSelectors";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import Select from "@components/base/select";
import Input from "@components/base/input";
import { useTranslation } from "react-i18next";
import l from "@locale";
interface PopOverProps {
  title?: string;
  space?: CustomSpace;
  submitTitle: string;
  handleSubmit: (space: any) => void;
  onCancel?: () => void;
  type: "create" | "update";
}

interface FormData {
  name: string;
  floor: number;
}

const schema = yup.object().shape({
  name: yup.string().trim().required(l.t("customroom.create.nameRequired")),
  floor: yup.number().notRequired(),
});
const CustomSpacePopOver = ({
  title,
  space,
  submitTitle,
  handleSubmit,
  onCancel,
  type,
}: PopOverProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [floor, setFloor] = useState<Value>(
    space?.floor
      ? [
          {
            id: space?.floor,
            label: t("customroom.floor", { floor: space?.floor }),
          },
        ]
      : [],
  );
  const [form, setForm] = useState<FormData>({
    name: space?.label || "",
    floor: space?.floor || 0,
  });
  const floors = new Set(
    useAppSelector(selectAllRoomSpaces).map((space) => space.floor),
  );
  const customSpaceLabels = useAppSelector(selectAllCustomSpaces).map(
    (space) => space.label,
  );

  const dispatchNotification = (message: string) => {
    dispatch(
      showNotification(
        {
          type: "error",
          message: message,
        },
        "add:customSpace",
      ),
    );
  };
  const handleSubmitClick = async () => {
    const error = await Form.validate(schema, form);
    if (type != "update" && customSpaceLabels.includes(form.name)) {
      dispatchNotification(t("customroom.create.nameInUse"));
      return;
    }
    if (error === null) {
      if (form.floor > 0)
        handleSubmit({
          label: form.name,
          floor: form.floor,
        });
      else {
        handleSubmit({
          label: form.name,
        });
      }
      return;
    }
    dispatchNotification(Object.values(error)[0]);
  };
  const handleCancel = () => {
    setForm({
      name: "",
      floor: 0,
    });
    if (onCancel) onCancel();
  };
  return (
    <>
      <div className={`modal ${title && "spacing"}`}>
        {title && (
          <h4 css={type === "update" && styles.modalHeader}>{title}</h4>
        )}

        <Input
          autoFocus
          label={t("common.name")}
          placeholder={t("common.giveName")}
          containerStyle={{ width: "420px" }}
          value={form.name}
          onChangeText={(text) => text && setForm({ ...form, name: text })}
          required={true}
        />
        <span css={styles.helper}>{t("customroom.tooltip")}</span>
        <label>{t("common.floor")}</label>
        <Select
          options={Array.from(floors).map((floor) => ({
            label: t("customroom.floor", { floor }),
            id: floor,
          }))}
          placeholder={t("search.floor")}
          onChange={({ value }) => {
            setFloor(value);
            setForm({ ...form, floor: (value[0]?.id as number) || 0 });
          }}
          value={floor}
          overrides={{
            ControlContainer: {
              style: {
                backgroundColor: theme.colors.white,
                border: `1px solid ${theme.colors.contentInverseSecondary}`,
              },
            },
            Popover: {
              props: {
                placement: "bottom",
                overrides: {
                  Body: {
                    style: {
                      maxHeight: "475px",
                      zIndex: 99999,
                    },
                  },
                },
              },
            },
            Dropdown: {
              style: {
                backgroundColor: theme.colors.white,
              },
            },
          }}
        />
        <div className="buttonContainer">
          <a
            css={styles.cancelButton}
            onClick={() => {
              close();
              handleCancel();
            }}>
            {t("common.cancel")}
          </a>
          <Button
            onClick={() => {
              handleSubmitClick();
              close();
            }}>
            {submitTitle}
          </Button>
        </div>
      </div>
      <style jsx>{`
        .modal {
          background-color: #fff;
          display: flex;
          postion: relative;
          flex-direction: column;
          align-items: flex-start;
          width: auto;
          min-width: 350px;
        }
        .spacing {
          padding: 20px;
        }
        .modal form {
          display: flex;
          flex-direction: column;
        }
        span {
          font-size: 0.65rem;
          padding-left: 2px;
          font-weight: 400;
        }
        .modal h4 {
          margin: 10px 0;
        }
        .modal label {
          padding-top: 10px;
          padding-left: 2px;
          margin-bottom: 2px;
          font-size: 0.9rem;
        }
        .buttonContainer {
          margin-top: 20px;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      `}</style>
    </>
  );
};
const styles = {
  modal: css({
    display: "flex",
    borderRadius: "0",
  }),
  helper: css({
    paddingTop: "3px",
  }),
  modalHeader: css({
    fontSize: "1.5rem",
  }),
  cancelButton: css({
    marginLeft: "12px",
    borderBottom: "1px solid #2BB9F5",
  }),
};
export default CustomSpacePopOver;
