import { FC } from "react";
import theme from "@styles/theme";
import { useDispatch } from "react-redux";
import { User } from "@store/user/types";
import { getMostImportantRole } from "@helpers";
import { setSelectedUser } from "@store/user/userSlice";
import { FiChevronRight } from "react-icons/fi";
import { useTranslation } from "react-i18next";

interface MemberRowProps {
  member: User;
  active: boolean;
}

const MemberRow: FC<MemberRowProps> = ({ member, active }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    id,
    profile: { initials, name },
    roles,
  } = member;

  const role = getMostImportantRole(roles);

  const onSelect = () => {
    dispatch(setSelectedUser(id));
  };

  return (
    <div className={active ? "active item" : "item"} onClick={onSelect}>
      <div className="details">
        <div className="initials">
          <span>{initials}</span>
        </div>
        <div className="nameColumn">
          <div className="name">{name}</div>
          <div className="code">
            {role ? t(`roleNames.${role}`) : t("common.unknown")}
          </div>
        </div>
      </div>

      {active && <FiChevronRight />}
      <style jsx>{`
        div.item {
          padding: 8px 16px;
          height: 44px;
          min-height: 44px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
        }
        .details {
          display: flex;
          flex-direction: row;
        }
        .active {
          background: ${theme.colors.turquoise};
        }
        div.nameColumn {
          flex-direction: column;
        }
        .name {
          font-size: 16px;
          font-weight: 600;
          color: ${theme.colors.dark};
        }
        .code {
          font-size: 14px;
          line-height: 25px;
          color: ${theme.colors.dark};
        }
        .initials {
          width: 44px;
          height: 44px;
          min-width: 44px;
          border-radius: 44px;
          background-color: ${theme.colors.primary};
          text-align: center;
          margin-right: 8px;
        }
        .initials > span {
          font-weight: 800;
          font-size: 16px;
          color: white;
          line-height: 42px;
        }
        span.active {
          border-bottom: 3px solid ${theme.colors.dark};
        }
        span.inactive {
          border-bottom: 3px solid #fff;
        }
      `}</style>
    </div>
  );
};

export default MemberRow;
