import { FC } from "react";
import { Table as BaseTable, TableProps } from "baseui/table-semantic";
import { tableOverrides } from "@components/base/table/overrides";

const Table: FC<TableProps> = (props) => {
  return (
    <BaseTable
      divider="vertical"
      {...props}
      overrides={{ ...tableOverrides, ...props.overrides }}
    />
  );
};

export default Table;
