import React from "react";

interface FileDialogProps {
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileDialog = React.forwardRef<HTMLInputElement, FileDialogProps>(
  ({ onInputChange }, ref) => {
    return (
      <input
        ref={ref}
        type="file"
        style={{ display: "none" }}
        accept="image/*"
        multiple={true}
        onChange={onInputChange}
      />
    );
  },
);

export default FileDialog;
