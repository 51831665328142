import { memo, useMemo } from "react";
import { css } from "@emotion/react";
import { EntityId } from "@reduxjs/toolkit";
import { makeSelectCustomSpaceById } from "@store/space/spaceSelectors";
import useAppSelector from "@hooks/useAppSelector";

interface RoomProps {
  customId: EntityId;
}

const NameRow = memo(({ customId }: RoomProps) => {
  const space = useMemo(makeSelectCustomSpaceById, [customId]);
  const { label } = useAppSelector((state) => space(state, customId));
  return (
    <div>
      <span css={styles.label}>{label}</span>
    </div>
  );
});

const styles = {
  label: css({
    fontWeight: 400,
  }),
};
export default NameRow;
