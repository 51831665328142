import React, { useState } from "react";
import { css } from "@emotion/react";
import { selectAllRoomCodes } from "@store/rateType/rateTypeSelectors";
import useAppSelector from "@hooks/useAppSelector";
import SearchInput from "@components/base/search-input";
import Table from "@components/base/table";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";

interface AllRatesProps {}

const AllRates: React.FC<AllRatesProps> = () => {
  const { t } = useTranslation();
  const roomCodes = useAppSelector(selectAllRoomCodes);
  const [search, setSearch] = useState("");

  const filteredRoomCodes = roomCodes.filter((item) => {
    return item.roomCode.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <>
      <div data-test-id="kamertypes-search" css={styles.controls}>
        <h3>{t("rateType.roomType")}</h3>
        <SearchInput
          placeholder={t("search.rateType")}
          value={search}
          onChange={setSearch}
          containerStyle={{ justifyContent: "flex-end" }}
        />
      </div>
      <Table
        columns={[t("rateType.roomType"), t("rateType.belongsTo")]}
        data={filteredRoomCodes.map((item) => {
          return [item.roomCode, item.rateType.name];
        })}
      />
    </>
  );
};

const styles = {
  controls: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: `${theme.sizing.scale800} 0`,
  }),
};

export default AllRates;
