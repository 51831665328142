import { batch } from "react-redux";
import { AppThunk } from "..";
import services from "../../services/api";
import { showNotification } from "../ui/uiThunk";
import { setReportExtras } from "./reportExtraSlice";
import { UpdateReportExtraParams } from "./types";
import l from "@locale";
import { EntityId } from "@reduxjs/toolkit";

export const fetchReportExtras = (): AppThunk => async (dispatch) => {
  try {
    const { data } = await services.ReportExtras.getAll();
    dispatch(setReportExtras(data));
  } catch (error) {
    /* empty */
  }
};

export const createReportExtra =
  (params: UpdateReportExtraParams): AppThunk =>
  async (dispatch) => {
    try {
      await services.ReportExtras.create(params);
      dispatch(fetchReportExtras());
    } catch (error) {
      batch(() => {
        dispatch(
          showNotification({
            type: "error",
            message: l.t("actions.add.failed", {
              value: l.t("common.photos").toLowerCase(),
            }),
          }),
        );
      });
    }
  };

export const deleteReportExtra =
  (id: EntityId): AppThunk =>
  async (dispatch) => {
    try {
      await services.ReportExtras.delete(id);
      dispatch(fetchReportExtras());
    } catch (error) {
      batch(() => {
        dispatch(
          showNotification({
            type: "error",
            message: l.t("actions.delete.failed", {
              value: l.t("common.photos").toLowerCase(),
            }),
          }),
        );
      });
    }
  };
