import { jwtDecode } from "jwt-decode";
import { User, UserRoles } from "../store/user/types";
import { Property } from "../store/property/types";
import api from "./api";
import { Attachment } from "../types/common";

// Helpers for the app/web
export const Auth = {
  get() {
    const storedTokens = localStorage.getItem("tokens");
    if (!storedTokens) return null;
    const tokens = JSON.parse(storedTokens);
    const decodedToken = jwtDecode<{
      role: UserRoles;
      pry: Property["id"];
      sub: User["id"];
    }>(tokens.token);

    const { pry, role, sub } = decodedToken;
    return {
      token: tokens.token,
      refreshToken: tokens.refresh_token,
      property: pry,
      role,
      currentUserId: sub,
    };
  },
  update(token: string, refreshToken: string) {
    return localStorage.setItem(
      "tokens",
      JSON.stringify({
        token,
        refresh_token: refreshToken,
      }),
    );
  },
  reset() {
    return localStorage.removeItem("tokens");
  },
};

const getBlob = async (fileUri: string) => {
  const resp = await fetch(fileUri);
  const imageBody = await resp.blob();
  return imageBody;
};

const uploadToS3 = async (presignedUrl: string, url: string) => {
  //If file selected then create FormData
  const imageBody = await getBlob(url);

  await fetch(presignedUrl, {
    method: "PUT",
    body: imageBody,
    headers: {
      "Content-Type": "image/jpeg",
    },
  });
};

export const createAttachments = async (attachments: Partial<Attachment>[]) => {
  const newAttachments: Attachment[] = [];
  await Promise.all(
    attachments.map(async (attachment) => {
      if (attachment.url === undefined) return;
      const res = await api.Attachment.add();
      const id = res.id;
      const url = res.presignedUrl;

      newAttachments.push({ id, url: attachment.url });

      await uploadToS3(url, attachment.url);
    }),
  );
  return newAttachments;
};

export default { Auth, createAttachments };
