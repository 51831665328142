import { ButtonOverrides } from "baseui/button";
import theme from "@styles/theme";
import { overrideStyles, OverrideStyles } from "@components/base/helpers";

export const buttonOverrides: ButtonOverrides = {
  BaseButton: {
    style: ({ $theme, $kind, $disabled }) => ({
      whiteSpace: "nowrap",
      fontFamily: "SourceSansPro-Bold!important",
      borderLeftWidth: "1px",
      borderRightWidth: "1px",
      borderTopWidth: "1px",
      borderBottomWidth: "1px",
      borderLeftStyle: "solid",
      borderTopStyle: "solid",
      borderRightStyle: "solid",
      borderBottomStyle: "solid",
      borderTopLeftRadius: theme.borders.radius200,
      borderTopRightRadius: theme.borders.radius200,
      borderBottomLeftRadius: theme.borders.radius200,
      borderBottomRightRadius: theme.borders.radius200,
      opacity: $disabled ? 0.4 : 1,
      ...defaultButtonColors({
        $disabled,
        $theme,
        $kind,
      }),
    }),
  },
};

export const overrideButtonStyles = (
  styles?: OverrideStyles<ButtonOverrides>,
) => {
  return overrideStyles<ButtonOverrides>({
    overrides: buttonOverrides,
    styles,
  });
};

export const generateButtonColors = ({
  $disabled,
  color,
  backgroundColor,
}: {
  $disabled: any;
  color?: string;
  backgroundColor?: string;
}) => {
  if ($disabled) {
    if (color !== undefined) color += "!important";
    if (backgroundColor !== undefined) backgroundColor += "!important";
  }

  return {
    ...(backgroundColor !== undefined && { backgroundColor }),
    ...(color !== undefined && {
      borderLeftColor: color,
      borderTopColor: color,
      borderRightColor: color,
      borderBottomColor: color,
      color,
    }),
  };
};

export const defaultButtonColors = ({
  $theme,
  $kind,
  $disabled,
}: {
  $theme: any;
  $kind: any;
  $disabled: any;
}) => {
  let color: string = $theme.colors.white;
  let backgroundColor: string = $theme.colors.accent;

  if ($kind === "secondary") {
    color = $theme.colors.accent;
    backgroundColor = "transparent";
  }

  if ($kind === "tertiary") {
    backgroundColor = $theme.colors.primaryA;
    color = $theme.colors.white;
  }

  return generateButtonColors({
    $disabled,
    color,
    backgroundColor,
  });
};
