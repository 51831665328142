import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import NewIssueScreen from "./NewIssueScreen";
import { EntityId } from "@reduxjs/toolkit";
import { selectCurrentUserId } from "@store/user/userSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { Issue, IssueState, IssueType } from "@store/issue/types";
import { selectAllSpaces } from "@store/space/spaceSelectors";
import { selectIssueById } from "@store/issue/issueSelectors";
import { isCustomSpace, isRoomSpace } from "@helpers";
import { createIssue, updateIssue } from "@store/issue/issueThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import { useTranslation } from "react-i18next";

interface NewIssueContainerProps {
  onClose: () => void;
  selectedIssueId?: string;
  type: IssueType;
}

const NewIssueContainer: FC<NewIssueContainerProps> = ({
  onClose,
  selectedIssueId,
  type = IssueType.TECHNICAL,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentUserId);

  const [issue, setIssue] = useState<Omit<Issue, "id">>({
    spaceId: "",
    label: "",
    authorId: currentUserId,
    reportedBy: "",
    createdAt: new Date().toISOString(),
    priority: false,
    description: "",
    attachments: [],
    state: IssueState.OPEN,
    users: [],
    workLogs: [],
    typeOf: IssueType.TECHNICAL,
  });
  const spaces = useAppSelector(({ space }) => selectAllSpaces(space));

  let selectedIssue: Issue | undefined = undefined;

  if (selectedIssueId)
    selectedIssue = useAppSelector((state) =>
      selectIssueById(state.issue, selectedIssueId),
    );

  const [hasChanges, setHasChanges] = useState(!selectedIssueId);

  const generateSections = () => {
    const roomSpaces: { value: EntityId; label: string }[] = [];
    const customSpaces: { value: EntityId; label: string }[] = [];
    spaces.forEach((space) => {
      if (isCustomSpace(space))
        customSpaces.push({ value: space.id, label: space.label });
      if (isRoomSpace(space))
        roomSpaces.push({ value: space.id, label: space.label });
    });
    return [
      {
        title: t("common.rooms"),
        data: customSpaces,
      },
      {
        title: t("common.spaces"),
        data: roomSpaces,
      },
    ];
  };
  const sections = useMemo(generateSections, [spaces]);

  const canSubmit =
    issue.spaceId !== undefined &&
    issue.spaceId !== "" &&
    issue.label !== undefined &&
    issue.label?.length > 1 &&
    issue.reportedBy !== undefined &&
    issue.reportedBy?.length > 1 &&
    hasChanges;

  const onSubmit = () => {
    const { attachments, ...newIssue } = issue;
    if (selectedIssue) {
      dispatch(updateIssue(selectedIssue, newIssue, attachments));
    } else {
      dispatch(
        createIssue(
          { ...newIssue, attachments: [], typeOf: type },
          attachments,
        ),
      );
    }
    onClose();
  };

  const prefillForm = useCallback(() => {
    if (!selectedIssue) return;
    setIssue(selectedIssue);
  }, [selectedIssue]);

  const checkFormChanges = useCallback(() => {
    if (!selectedIssue) return;
    setHasChanges(!isEqual(selectedIssue, issue));
  }, [selectedIssue, issue]);

  useEffect(() => {
    prefillForm();
  }, [prefillForm]);

  useEffect(() => {
    checkFormChanges();
  }, [checkFormChanges, issue]);
  return (
    <NewIssueScreen
      sections={sections}
      issue={issue}
      setIssue={setIssue}
      onSubmit={onSubmit}
      canSubmit={canSubmit}
      isInEditMode={!!selectedIssueId}
      type={type}
    />
  );
};

export default NewIssueContainer;
