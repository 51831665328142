import { StatefulPopover } from "baseui/popover";

import { IoLanguage } from "react-icons/io5";
import { FC, useEffect, useState } from "react";
import { OnChangeParams, Option, Select } from "baseui/select";
import { Button } from "baseui/button";

const languageOptions: Option[] = [
  { label: "Nederlands", id: "nl" },
  { label: "English", id: "en" },
  { label: "Deutsch", id: "de" },
];

const LanguageSwitch: FC = () => {
  const [language, setLanguage] = useState<Option>(languageOptions[0]);

  const onChangeLanguage = (params: OnChangeParams) => {
    const selectedLanguage = params.value[0].id?.toString();
    const storageLanguage = localStorage.getItem("language");
    if (!selectedLanguage || storageLanguage === selectedLanguage) return;

    localStorage.setItem("language", selectedLanguage);
    window.location.reload();
  };

  useEffect(() => {
    const storageLanguage = localStorage.getItem("language");
    const language = languageOptions.find(
      (lang) => lang.id === storageLanguage,
    );
    setLanguage(language || languageOptions[0]);
  }, []);

  return (
    <StatefulPopover
      content={
        <Select
          startOpen
          value={[language]}
          onChange={onChangeLanguage}
          options={Object.values(languageOptions)}
          multi={false}
          clearable={false}
          labelKey="label"
          valueKey="id"
          searchable={false}
          placeholder="Select language"
          overrides={{
            ValueContainer: {
              style: {
                display: "none",
              },
            },
            IconsContainer: {
              style: {
                display: "none",
              },
            },
            Dropdown: {
              style: {
                width: "200px",
                marginLeft: "-32px",
              },
            },
          }}
        />
      }>
      <Button
        overrides={{
          BaseButton: {
            style: {
              backgroundColor: "transparent",
              color: "black",
              border: "none",
              cursor: "pointer",
            },
          },
        }}
        css={{
          ":hover": {
            backgroundColor: "transparent",
          },
        }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            border: "1px solid #E2E2E2",
            padding: "4px",
          }}>
          <IoLanguage />
          {String(language.label).substring(0, 3).toUpperCase()}
        </div>
      </Button>
    </StatefulPopover>
  );
};

export default LanguageSwitch;
