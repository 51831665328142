import { FC, ReactNode } from "react";

interface DetectOutsideClickProps {
  children?: ReactNode;
  onClick: () => void;
  backgroundColor?: string;
}

const DetectOutsideClick: FC<DetectOutsideClickProps> = ({
  children,
  onClick,
  backgroundColor,
}) => {
  const handleClickOutside = () => {
    onClick();
  };

  return (
    <div className="overlay" onClick={handleClickOutside}>
      {children}
      <style jsx>{`
        .overlay {
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 10;
          background: ${backgroundColor};
        }
      `}</style>
    </div>
  );
};

export default DetectOutsideClick;
