import { createSelector, Selector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { propertyAdapter } from "./propertySlice";
import { Property } from "./types";

const selectState = (state: RootState) => state;

export const selectCurrentProperty: Selector<RootState, Property | undefined> =
  createSelector(selectState, ({ user: { selectedPropertyId }, property }) => {
    if (selectedPropertyId) {
      return property.entities[selectedPropertyId!];
    }
    return undefined;
  });

export const { selectAll: selectAllProperties } =
  propertyAdapter.getSelectors();
