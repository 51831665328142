import React from "react";
import withAuth from "@helpers/withAuth";
import Layout from "@components/Layout";
import AppLoginScreen from "@screens/AppLogin";

const AppLoginContainer: React.FC = () => {
  return (
    <Layout active="app-login">
      <AppLoginScreen />
    </Layout>
  );
};

export default withAuth(AppLoginContainer);
