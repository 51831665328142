import React, { useEffect, useState } from "react";
import Sort from "@components/Sort";
import { BiDotsVerticalRounded } from "react-icons/bi";
import useAppSelector from "@hooks/useAppSelector";
import { selectAllTasks } from "@store/task/taskSelectors";
import useAppDispatch from "@hooks/useAppDispatch";
import { Task } from "../../../types/common";
import { setSelectedTask } from "@store/task/taskSlice";
import { showPopUp } from "@store/ui/uiSlice";
import Table from "@components/base/table";
import { overrideTableStyles } from "@components/base/table/overrides";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

interface TasksTableProps {
  search: string;
}

const TasksTable: React.FC<TasksTableProps> = ({ search }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tasks = useAppSelector(({ task }) => selectAllTasks(task));
  const [sort, setSort] = useState({ key: "name", asc: true });
  const [filteredTasks, setFilteredTasks] = useState<Task[]>();

  const onChange = () => {
    setSort({ key: "name", asc: !sort.asc });
  };

  const sortTasks = (p1: Task, p2: Task) => {
    const name1 = p1.label;
    const name2 = p2.label;
    if (name1 === name2) return 0;
    if (name1 < name2) {
      return sort.asc ? -1 : 1;
    } else {
      return sort.asc ? 1 : -1;
    }
  };

  const filterTasks = (task: Task) => {
    return task.label.toLowerCase().includes(search.toLowerCase());
  };

  const generateRows = () => {
    return (
      filteredTasks?.map((task) => {
        const onShowPopUp = (
          event: React.MouseEvent<HTMLImageElement, MouseEvent>,
        ) => {
          dispatch(setSelectedTask(task.id));
          dispatch(
            showPopUp({
              type: "tasks/editTask",
              pos: { x: event.clientX, y: event.clientY },
            }),
          );
        };

        return [
          task.label,
          <div
            onClick={onShowPopUp}
            css={css({
              display: "flex",
              flex: 1,
              fontSize: "25px",
              justifyContent: "center",
              ":hover": {
                cursor: "pointer",
              },
            })}>
            <BiDotsVerticalRounded />
          </div>,
        ];
      }) ?? []
    );
  };

  useEffect(() => {
    if (!tasks.length) return;
    setFilteredTasks(tasks.sort(sortTasks).filter(filterTasks));
  }, [search, sort, tasks]);

  return (
    <Table
      columns={[
        <Sort id="name" sort={sort} onChange={onChange}>
          {t("common.issue")}
        </Sort>,
        "",
      ]}
      data={generateRows()}
      emptyMessage={
        search?.length && tasks?.length
          ? t("search.notFound", { search: t("common.tasks").toLowerCase() })
          : t("tasks.empty")
      }
      overrides={overrideTableStyles({
        TableHeadCell: {
          ":last-child": {
            width: "50px",
          },
        },
      })}
    />
  );
};

export default TasksTable;
