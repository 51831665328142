import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import Notification from "../../components/Notification";
import SidebarLink from "./SidebarLink";
import SubLinksWrapper from "./SubLinksWrapper";
import SubSidebarLink from "./SubSidebarLink";
import SelectOptions from "../SelectOptions";
import { css } from "@emotion/react";
import { useIdleTimer } from "react-idle-timer";
import SpinnerLoader from "@components/SpinnerLoader";
import StagingIndicator from "@components/StagingIndicator";
import { selectCurrentRole } from "@store/user/userSelectors";
import { RootState } from "@store";
import {
  selectAllProperties,
  selectCurrentProperty,
} from "@store/property/propertySelector";
import { signOut, switchProperty } from "@store/user/userThunk";
import { selectSidebarView } from "@store/ui/uiSelectors";
import { UserRoles } from "@store/user/types";
import Modal from "@components/base/modal";
import PmsSyncStateNotification from "@components/PmsSyncStateNotification";
import Button from "@components/base/button";
import { switchNavigationView } from "@store/ui/uiSlice";
import theme from "@styles/theme";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "@components/LanguageSwitch";

export enum SubNavLabels {
  None = "None",
  Settings = "settings",
}
export enum NavLabels {
  Dashboard = "dashboard",
  Overview = "overview",
  AppLogin = "app-login",
  DayReports = "day-reports-overview",
  TdOverview = "td-overview",
  GsOverview = "gs-overview",
  ActionsLog = "actions-log",
  AreaSettings = "area-settings",
  TeamSettings = "team-settings",
  CustomRoomSettings = "custom-room-settings",
  TaskSettings = "task-settings",
  DayReportSettings = "day-report-settings",
  DayOverview = "day-overview",
  ExtraTasks = "extra-tasks",
  TypeGroups = "rate-type-groups",
}

interface LayoutProps {
  children: JSX.Element;
  active: string;
}
const Layout: React.FC<LayoutProps> = ({ children, active }) => {
  const { t } = useTranslation();
  const [subNavigation, setSubNavigation] = useState<SubNavLabels>(
    SubNavLabels.None,
  );

  const iconView = useSelector(selectSidebarView);

  const [showRefreshModal, setShowRefreshModal] = useState(false);

  const dispatch = useAppDispatch();
  const role = useAppSelector(selectCurrentRole);
  const userPropertyIds = useAppSelector(
    ({ user }: RootState) => user.current?.properties,
  );
  const properties = useAppSelector(({ property }) =>
    selectAllProperties(property),
  );
  const currentProperty = useAppSelector(selectCurrentProperty);
  const notification = useAppSelector(({ ui }) => ui.notification);

  const reloadPage = () => {
    window.location.reload();
  };

  const onUpdateProperty = (propertyId: string) =>
    dispatch(switchProperty(propertyId));
  const onSignOut = async () => {
    dispatch(signOut());
  };

  const onToggleSidebar = () => {
    dispatch(switchNavigationView(!iconView));
  };

  useIdleTimer({
    timeout: 1000 * 60 * 120,
    debounce: 500,
    onIdle: () => setShowRefreshModal(true),
  });

  if (!currentProperty || !role || !userPropertyIds || !properties)
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
        }}>
        <SpinnerLoader />
      </div>
    );

  const hasMultipleProperties = properties.length > 1;
  const propertyAdminRole = [UserRoles.HOTEL_MANAGER, UserRoles.ADMIN].includes(
    role,
  );
  const isPropertyTD = UserRoles.TD_MANAGER === role || propertyAdminRole;
  const isPropertyGS = UserRoles.GS_MANAGER === role || propertyAdminRole;
  const isPropertyHKManager =
    UserRoles.HK_MANAGER === role || propertyAdminRole;

  return (
    <>
      <div className="wrapper">
        <div className="header">
          <div className="heading">
            <div className="property-heading">
              <h1 className="vex">
                Valk Exclusief -{" "}
                {!hasMultipleProperties && currentProperty.code}
              </h1>
              {hasMultipleProperties && (
                <SelectOptions
                  width={200}
                  onChange={onUpdateProperty}
                  optionsWithLabel={Object.fromEntries(
                    properties.map((p) => [p?.id, p?.name]),
                  )}
                  value={`${currentProperty.id}`}
                />
              )}
              <StagingIndicator />
              <LanguageSwitch />
            </div>
            <div className="row">
              <a onClick={onSignOut}>{t("common.logout")}</a>
            </div>
          </div>
        </div>
        {window?.location?.hostname === "hktd.valkdigital.nl" ? (
          <PmsSyncStateNotification />
        ) : null}
        <div className="container">
          <Modal
            setShowModal={setShowRefreshModal}
            closeable={true}
            isOpen={showRefreshModal}
            onClose={reloadPage}
            title="De data is verouderd">
            <p css={style.refreshText}>{t("layout.refreshMessage")}</p>
            <div className="button-wrapper">
              <Button onClick={reloadPage}>{t("layout.refresh")}</Button>
            </div>
          </Modal>
          <div className={`side ${iconView ? "smaller" : ""}`}>
            <div className="sidebar-header">
              <Button
                data-test-id="side-bar-button"
                onClick={onToggleSidebar}
                css={style.sideMenuButton}>
                {iconView ? (
                  <RiMenuUnfoldLine css={style.toggle} />
                ) : (
                  <RiMenuFoldLine css={style.toggle} />
                )}
                <a
                  className={`${iconView ? "smallView" : ""}`}
                  css={style.buttonText}>
                  {t("layout.fold")}
                </a>
              </Button>
            </div>
            <ul>
              {isPropertyHKManager && (
                <SidebarLink
                  href="/"
                  label={NavLabels.Dashboard}
                  active={active === "dashboard"}
                />
              )}
              <SidebarLink
                href="/overview"
                label={NavLabels.Overview}
                active={active === "overview"}
              />

              {isPropertyHKManager && (
                <SidebarLink
                  href="/app-login"
                  label={NavLabels.AppLogin}
                  active={active === "app-login"}
                />
              )}

              {(isPropertyHKManager || isPropertyGS) && (
                <SidebarLink
                  href={"/day-reports/week/"}
                  label={NavLabels.DayReports}
                  active={active === "day-reports/week"}
                />
              )}
              {(isPropertyTD || isPropertyHKManager) && (
                <SidebarLink
                  href="/td-overview"
                  label={NavLabels.TdOverview}
                  active={active === "td-overview"}
                />
              )}
              {(isPropertyGS || isPropertyHKManager) && (
                <SidebarLink
                  href="/gs-overview"
                  label={NavLabels.GsOverview}
                  active={active === "gs-overview"}
                />
              )}
              <SubLinksWrapper
                label={SubNavLabels.Settings}
                active={[
                  "settings/areas",
                  "settings/team",
                  "settings/tasks",
                  "settings/custom-rooms",
                  "settings/day-reports",
                ].includes(active)}
                subNavigation={subNavigation}
                setSubNavigation={setSubNavigation}>
                <SubSidebarLink
                  href="/settings/team"
                  label={NavLabels.TeamSettings}
                  active={active === "settings/team"}
                />

                <SubSidebarLink
                  href="/settings/areas"
                  label={NavLabels.AreaSettings}
                  active={active === "settings/areas"}
                />
                {isPropertyTD && (
                  <SubSidebarLink
                    href="/settings/custom-rooms"
                    label={NavLabels.CustomRoomSettings}
                    active={active === "settings/custom-rooms"}
                  />
                )}
                {isPropertyHKManager && (
                  <SubSidebarLink
                    href="/settings/tasks"
                    label={NavLabels.TaskSettings}
                    active={active === "settings/tasks"}
                  />
                )}
                {propertyAdminRole && (
                  <SubSidebarLink
                    href="/settings/day-reports"
                    label={NavLabels.DayReportSettings}
                    active={active === "settings/day-reports"}
                  />
                )}
              </SubLinksWrapper>
              {isPropertyHKManager && (
                <SidebarLink
                  href="/actions-log"
                  label={NavLabels.ActionsLog}
                  active={active === "actions-log"}
                />
              )}
            </ul>
          </div>
          {notification && <Notification {...notification} />}
          <div className="content">{children}</div>
        </div>
      </div>
      <style jsx>{`
        .loader {
          height: 100vh;
          width: 100vw;
        }
        .wrapper {
          display: flex;
          height: 100vh;
          overflow: hidden;
          flex-direction: column;
        }
        .property-heading {
          align-items: center;
          display: flex;
        }
        .vex {
          margin-right: 10px;
          white-space: nowrap;
        }
        .property {
          margin-left: 10px;
        }
        .row {
          display: flex;
          align-items: center;
          flex-direction: row;
        }
        .header {
          display: flex;
          flex-direction: row;
          background-color: ${theme.colors.white};
        }
        .heading {
          height: 60px;
          margin-left: 16px;
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: space-between;
        }
        h1 {
          font-size: 24px;
          font-weight: 800;
          letter-spacing: normal;
          color: ${theme.colors.black};
        }

        .button-wrapper {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }

        .header a {
          cursor: pointer;
          font-size: 16px;
          margin: 0 30px;
          color: ${theme.colors.primaryA};
        }
        .side {
          overflow-x: hidden;
          width: 244px;
          transition: width 250ms ease-in-out;
          background-color: ${theme.colors.primary500};
          height: 100%;
        }
        .side ul {
          margin: 12px 0 32px 16px;
        }
        .sidebar-header {
          display: flex;
          width: 100%;
          padding-top: 16px;
        }
        /* Tablet optimizations */
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
          .side {
            min-width: 180px;
            width: 180px;
          }
        }
        .smaller {
          width: 60px;
        }
        .container {
          display: flex;
          flex-direction: row;
          flex-grow: 1;
          min-height: 0;
          height: 0;
        }
        .content {
          display: flex;
          background-color: ${theme.colors.backgroundPrimary};
          flex-direction: column;
          flex: 1;
          max-height: 100vh;
        }
        a {
          color: ${theme.colors.white};
          line-height: 1;
          line-clamp: 1;
        }
        li {
          display: flex;
          align-items: center;
          user-select: none;
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          margin: 32px 0;
          cursor: pointer;
        }
        .smallView {
          display: none;
          visibility: hidden;
          opacity: 0;
        }
      `}</style>
    </>
  );
};
const style = {
  refreshText: css({
    marginBottom: theme.sizing.scale800,
  }),
  toggle: css({
    marginRight: "16px",
    minWidth: "25px",
    height: "25px",
    fill: theme.colors.white,
  }),
  sideMenuButton: css({
    display: "flex",
    justifyContent: "center",
    paddingLeft: "25px",
    margin: "10px auto 0px",
    maxHeight: "50px",
    minWidth: "50px",
    width: "80%",
  }),
  buttonText: css({
    paddingLeft: "-16px",
  }),
};

export default Layout;
