import { AppThunk } from "..";
import api from "../../services/api";
import { Property, UpdatePropertyParams } from "./types";
import l from "@locale";
import { batch } from "react-redux";
import { showNotification } from "../ui/uiThunk";
import { setProperties, setProperty } from "./propertySlice";

export const fetchProperty =
  (id: Property["id"]): AppThunk =>
  async (dispatch) => {
    try {
      const response = await api.Property.getById(id);

      dispatch(setProperty(response));
    } catch (error) {
      /* empty */
    }
  };

export const fetchProperties = (): AppThunk => async (dispatch) => {
  try {
    const response = await api.Property.getAll();
    dispatch(setProperties(response.data));
  } catch (error) {
    /* empty */
  }
};

export const updateProperty =
  (oldProperty: Property, params: UpdatePropertyParams): AppThunk =>
  async (dispatch) => {
    const value = l.t("common.property");

    try {
      await api.Property.update(oldProperty.id, params);
      batch(() => {
        dispatch(
          setProperty({
            ...oldProperty,
            settings: { ...oldProperty.settings, ...params },
          }),
        );

        dispatch(
          showNotification({
            type: "success",
            message: l.t("actions.update.success", { value }),
          }),
        );
      });
    } catch (error) {
      dispatch(
        showNotification({
          type: "error",
          message: l.t("actions.update.failed", { value }),
        }),
      );
    }
  };
