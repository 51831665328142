import React, { ReactNode, useState } from "react";
import { Popover, PLACEMENT, TRIGGER_TYPE } from "baseui/popover";
import { OnChangeParams, Option } from "baseui/select";
import { css } from "@emotion/react";
import { User } from "@store/user/types";
import { AssigneeCounts } from "@store/space/types";
import useAppSelector from "@hooks/useAppSelector";
import { selectUserById } from "@store/user/userSelectors";
import Select from "@components/base/select";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";

interface AssignMemberProps {
  children: ReactNode;
  memberId?: User["id"];
  assigns: AssigneeCounts;
  onSetMember: (params: { assign: boolean; userId: User["id"] }) => void;
  members: User[];
}

const AssignMember: React.FC<AssignMemberProps> = ({
  memberId,
  assigns,
  children,
  onSetMember,
  members,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const member = useAppSelector(({ user }) => {
    if (!memberId) return undefined;
    return selectUserById(user, memberId);
  });

  const options = members.map((member) => ({
    id: member.id,
    label: member.profile.name,
  }));
  const selectedOption = member
    ? [{ id: member.id, label: member.profile.name }]
    : [];

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onSelectMember = (params: OnChangeParams) => {
    setIsOpen(false);
    if (params.type === "clear") {
      onSetMember({ assign: false, userId: member?.id ?? "" });
      return;
    }
    onSetMember({ assign: true, userId: params.option?.id ?? "" });
  };

  const getLabel = ({ option }: { option?: Option }) => {
    const member = members.find((m) => m.id === option?.id);
    const nr = assigns[option?.id ?? ""] ?? 0;

    return (
      <div css={styles.row}>
        <span css={styles.initials}>{member?.profile.initials}</span>
        <span css={styles.name}>{member?.profile.name}</span>
        <span css={styles.spaces}>{`- ${t("counts.room", { count: nr })}`}</span>
      </div>
    );
  };

  return (
    <Popover
      isOpen={isOpen}
      onClick={onOpen}
      triggerType={TRIGGER_TYPE.click}
      placement={PLACEMENT.bottomLeft}
      content={
        <Select
          value={selectedOption}
          options={options}
          props={{
            labelKey: "label",
            valueKey: "id",
            type: "select",
            autoFocus: true,
            placeholder: t("search.employee"),
            getOptionLabel: getLabel,
            maxDropdownHeight: "40vh",
            onClose: onClose,
          }}
          onChange={onSelectMember}
          overrides={{
            Root: { style: { width: "280px", height: "60px" } },
            ControlContainer: {
              style: {
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopWidth: "1px",
                borderRightWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderBottomColor: theme.colors.mono300,
                borderTopColor: theme.colors.mono300,
                borderLeftColor: theme.colors.mono300,
                borderRightColor: theme.colors.mono300,
                borderTopRightRadius: theme.borders.radius200,
                borderTopLeftRadius: theme.borders.radius200,
                borderBottomRightRadius: theme.borders.radius200,
                borderBottomLeftRadius: theme.borders.radius200,
                backgroundColor: theme.colors.white,
              },
            },
            Popover: {
              style: { marginTop: "0", marginBottom: "0" },
            },
            Dropdown: {
              style: {
                paddingTop: "0",
                paddingBottom: "0",
              },
            },
          }}
        />
      }
      accessibilityType="menu"
      overrides={{
        Arrow: { style: { backgroundColor: theme.colors.mono400 } },
      }}>
      <div>{children}</div>
    </Popover>
  );
};

const styles = {
  row: css({
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  }),
  name: css({
    color: theme.colors.mono800,
  }),
  initials: css({
    margin: `0 ${theme.sizing.scale500}`,
    display: "flex",
    fontWeight: 600,
    backgroundColor: theme.colors.mono400,
    color: theme.colors.white,
    borderRadius: theme.borders.radius500,
    width: "34px",
    height: "34px",
    justifyContent: "center",
    alignItems: "center",
  }),
  spaces: css({
    marginLeft: theme.sizing.scale100,
  }),
};

export default AssignMember;
