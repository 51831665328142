import { createSelector, Dictionary, Selector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getEditableRoles, getMostImportantRole } from "@helpers";
import { Property } from "../property/types";
import { User, UserRoles } from "./types";
import { userSelectors } from "./userSlice";
import { IssueType } from "@store/issue/types";

export const {
  selectAll: selectAllUsers,
  selectEntities: selectUserEntities,
  selectById: selectUserById,
} = userSelectors;

const selectSelf = (state: RootState) => state.user;

export const selectCurrentUser: Selector<RootState, User | undefined> =
  createSelector(selectSelf, (state) => state.current);

export const selectCurrentUserId: Selector<RootState, string | undefined> =
  createSelector(selectSelf, (state) =>
    state.current ? state.current.id.toString() : undefined,
  );
export const selectCurrentRole: Selector<RootState, UserRoles> = createSelector(
  selectSelf,
  (state) => state.selectedRole,
);

export const selectCurrentPropertyId: Selector<RootState, Property["id"] | undefined> =
  createSelector(selectSelf, (state) => state.selectedPropertyId);

export const selectSelectedUserId: Selector<RootState, User["id"] | undefined> =
  createSelector(selectSelf, (data) => data.selectedId);
export const selectSelectedUser: Selector<RootState, User | undefined> =
  createSelector(selectSelf, (state) => {
    const id = state.selectedId;
    if (id) {
      return selectUserById(state, id);
    }
    return undefined;
  });

export const selectEditableUsers: Selector<RootState, User[]> = createSelector(
  (state: RootState) => state,
  (state) => {
    const editableRoles = selectEditableRoles(state);

    const { ids, entities } = selectSelf(state);
    return ids.reduce((users: User[], id) => {
      const user = entities[id];
      if (user === undefined) return users;
      const mostImportantRole = getMostImportantRole(user.roles);
      if (editableRoles.includes(mostImportantRole)) users.push(user);
      return users;
    }, []);
  },
);
export const selectEditableRoles: Selector<RootState, User["roles"]> =
  createSelector(selectSelf, (state) => {
    const role = state.selectedRole;
    return getEditableRoles(role);
  });

export const selectHKMembers: Selector<RootState, User[]> = createSelector(
  selectSelf,
  ({ entities, ids }) => {
    const hkMembers: User[] = [];
    ids.forEach((id) => {
      const user = entities[id];
      if (
        user === undefined ||
        !user?.roles.includes(UserRoles.HK_MEMBER) ||
        user?.roles.length > 1
      )
        return;
      hkMembers.push(user);
    });

    return hkMembers;
  },
);
export const selectHKMemberEntities: Selector<
  RootState,
  Dictionary<User>
> = createSelector(selectSelf, ({ entities, ids }) => {
  const hkMembers: Dictionary<User> = {};
  ids.forEach((id) => {
    const user = entities[id];
    if (
      user === undefined ||
      !user?.roles.includes(UserRoles.HK_MEMBER) ||
      user?.roles.length > 1
    )
      return;
    hkMembers[user.id] = user;
  });

  return hkMembers;
});

export const selectMembers: Selector<RootState, User[], [IssueType]> =
  createSelector(
    selectSelf,
    (_: RootState, type: IssueType) => type,
    ({ entities, ids }, type) => {
      const members: User[] = [];
      ids.forEach((id) => {
        const user = entities[id];
        if (user === undefined || user.roles.length > 1) return;

        if (
          (isGeneralUser(user.roles) && type === IssueType.GENERAL) ||
          (isTechnicalUser(user.roles) && type === IssueType.TECHNICAL)
        )
          members.push(user);
      });

      return members;
    },
  );

export const selectMemberEntities: Selector<
  RootState,
  Dictionary<User>,
  [IssueType]
> = createSelector(
  selectSelf,
  (_: RootState, type: IssueType) => type,
  ({ entities, ids }, type) => {
    const members: Dictionary<User> = {};
    ids.forEach((id) => {
      const user = entities[id];
      if (user === undefined) return;

      if (
        (isGeneralUser(user.roles) && type === IssueType.GENERAL) ||
        (isTechnicalUser(user.roles) && type === IssueType.TECHNICAL)
      )
        members[user.id] = user;
    });

    return members;
  },
);

const isGeneralUser = (roles: User["roles"]) =>
  roles.includes(UserRoles.GS_MANAGER) || roles.includes(UserRoles.GS_MEMBER);
const isTechnicalUser = (roles: User["roles"]) =>
  roles.includes(UserRoles.TD_MANAGER) || roles.includes(UserRoles.TD_MEMBER);
