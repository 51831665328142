import React from "react";
import {
  Modal as BaseModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalOverrides,
  ModalProps as BaseModalProps,
} from "baseui/modal";
import { StyledLink } from "baseui/link";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import Button from "@components/base/button";
import { useTranslation } from "react-i18next";

export interface ModalProps extends BaseModalProps {
  title?: string;
  buttonText?: string;
  buttonAction?: () => any;
  setShowModal: (show: boolean) => any;
  center?: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { t } = useTranslation();
  const {
    title,
    buttonText,
    buttonAction,
    setShowModal,
    center,
    children,
    ...baseProps
  } = props;
  return (
    <BaseModal
      {...baseProps}
      overrides={{ ...baseProps.overrides, ...modalOverrides }}
      closeable={true}
      onClose={() => setShowModal(false)}
      animate={true}
      size="default"
      role="dialog">
      {title && (
        <ModalHeader
          data-baseweb="modal-header"
          css={[styles.modalHeader, center ? styles.center : ""]}>
          {title}
        </ModalHeader>
      )}
      <ModalBody css={[styles.modalBody, center ? styles.center : ""]}>
        {children}
      </ModalBody>

      {buttonAction && (
        <ModalFooter css={styles.modalFooter}>
          <StyledLink css={styles.link} onClick={() => setShowModal(false)}>
            {t("common.cancel")}
          </StyledLink>

          <Button kind="primary" onClick={() => buttonAction()}>
            {buttonText}
          </Button>
        </ModalFooter>
      )}
    </BaseModal>
  );
};

export const modalOverrides: ModalOverrides = {
  Root: {
    style: () => ({
      display: "flex",
      zIndex: 999,
    }),
  },
  Close: {
    style: () => ({
      width: "40px",
      height: "40px",
    }),
    props: {
      Svg: {
        style: () => ({
          width: 40,
          height: 40,
        }),
      },
    },
  },
  Dialog: {
    style: ({ $theme }) => ({
      background: theme.colors.white,
      width: "auto",
      minWidth: "450px",
      maxHeight: "90vh",
      display: "flex",
      padding: $theme.sizing.scale1000,
      flexDirection: "column",
      overflowY: "auto",
    }),
  },
};

const styles = {
  modalHeader: css({
    fontSize: 28,
    fontFamily: "SourceSansPro-Bold",
    marginTop: 16,
  }),
  modalBody: css({
    fontSize: 15,
  }),
  modalFooter: css({
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  }),
  link: css({
    fontSize: 16,
    display: "flex",
    alignItems: "center",
  }),
  center: css({
    textAlign: "center",
  }),
};

export default Modal;
