import Notification from "@components/Notification";
import LoginScreen from "../screens/Login";
import useAppSelector from "@hooks/useAppSelector";

const LoginPage: React.FC = () => {
  const notification = useAppSelector(({ ui }) => ui.notification);

  return (
    <>
      <LoginScreen />
      {notification && <Notification {...notification} />}
    </>
  );
};

export default LoginPage;
