import React from "react";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { User } from "@store/user/types";
import useAppSelector from "@hooks/useAppSelector";
import { selectUserById } from "@store/user/userSelectors";

interface AssigneeProps {
  memberId?: User["id"];
  isActive?: boolean;
  hover?: boolean;
}

const Assignee: React.FC<AssigneeProps> = ({
  memberId,
  isActive,
  hover = false,
}) => {
  const member = useAppSelector(({ user }) => {
    if (!memberId) return undefined;
    return selectUserById(user, memberId);
  });

  return (
    <div css={styles.container}>
      {member !== undefined ? (
        <div css={[styles.initials, isActive ? styles.active : ""]}>
          <a
            css={[styles.profile, hover && styles.showName]}
            data-title={hover ? member.profile.name : null}>
            {member.profile.initials}
          </a>
        </div>
      ) : (
        <img css={styles.image} src="/icons/icon-assign.svg" />
      )}
    </div>
  );
};

const styles = {
  container: css({
    display: "flex",
    flex: 1,
    justifyContent: "center",
    cursor: "pointer",
  }),
  initials: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    margin: " 0 auto",
    width: "32px",
    height: "32px",
    borderRadius: theme.borders.radius500,
    backgroundColor: theme.colors.mono600,
  }),
  active: css({
    backgroundColor: theme.colors.primaryA,
  }),
  profile: css({
    fontWeight: 800,
    fontSize: "16px",
    color: theme.colors.white,
    lineHeight: "16px",
    position: "relative",
    ":hover": {
      color: theme.colors.white,
    },
  }),
  showName: css({
    ":hover::after": {
      opacity: 1,
      transition: "all 0.1s ease 0.5s",
      visibility: "visible",
    },
    ":after": {
      content: "attr(data-title)",
      color: "#000000",
      backgroundColor: "#fff",
      fontWeight: 400,
      fontSize: "0.8rem",
      position: "absolute",
      padding: "5px 8px",
      whiteSpace: "nowrap",
      opacity: 0,
      transform: "translate(10%, -25%)",
      borderRadius: "5px",
      border: `1px solid ${theme.colors.gray}`,
      zIndex: 15,
      visibility: "hidden",
    },
  }),
  image: css({
    alignSelf: "center",
    width: "32px",
    height: "32px",
    textAlign: "center",
  }),
};

export default Assignee;
