import i18next from "i18next";
import en from "./en";
import nl from "./nl";
import de from "./de";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: en,
  },
  nl: {
    translation: nl,
  },
  de: {
    translation: de,
  },
};

i18next.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: "nl",
  lng: localStorage.getItem("language") ?? "nl",
  resources,
});

export default i18next;
export type Translation = typeof nl;
