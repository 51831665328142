import { EntityId } from "@reduxjs/toolkit";
import { Property } from "../property/types";

export enum UserRoles {
  ADMIN = "admin",
  HOTEL_MANAGER = "manager",

  //   HK
  HK_MEMBER = "hk_member",
  HK_MANAGER = "hk_manager",

  //   TD
  TD_MEMBER = "td_member",
  TD_MANAGER = "td_manager",
  TD_CREATOR = "td_creator",

  // GS
  GS_MEMBER = "gs_member",
  GS_MANAGER = "gs_manager",

  NONE = "initialized",
}

export interface User {
  id: EntityId;
  email: string;
  teamId?: string;
  profile: {
    name: string;
    initials?: string;
    language?: string;
  };
  properties: Property["id"][];
  roles: UserRoles[];
}

export interface Users {
  [id: string]: User;
}

export type UpdateUserParams = Partial<
  Pick<User, "email" | "properties" | "roles" | "profile">
>;

export interface CreateUserParams
  extends Omit<User, "id" | "email"> {
  email?: string;
}
