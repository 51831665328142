import { EntityId } from "@reduxjs/toolkit";
import api from "../../services/api";
import { IssueType } from "./types";
import { format } from "date-fns";
import i18next from "i18next";

export const fetchAllIssues = async (type = IssueType.TECHNICAL) => {
  let response = await api.Issues.getPage({ page: 1, type });
  const issuesArr = response.data;

  while (response.meta.next !== null) {
    response = await api.Issues.getPage({
      page: response.meta.next,
      type,
    });
    issuesArr.push(...response.data);
  }
  return issuesArr;
};

export const getFormattedActiveIssues = async (
  spaceId: EntityId,
  type: IssueType,
) => {
  const response = await api.Issues.getActive({ spaceId, type });

  return response.data.reduce((acc, issue) => {
    if (!issue.createdAt) return acc;
    acc.push([
      format(issue.createdAt, "yyyy-MM-dd"),
      issue.label,
      i18next.t(`issue.states.${issue.state}`),
    ]);
    return acc;
  }, [] as string[][]);
};
