import React from "react";
import { css } from "@emotion/react";
import { Option } from "baseui/select";
import { ReportingSettingsOption } from "../index";
import Select from "@components/base/select";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";

interface RolebasedSegmentProps {
  onPermissionChange: (option: ReportingSettingsOption) => void;
  title: string;
  selectedOption: ReportingSettingsOption | undefined;
  PERMISSION_OPTIONS: Option[];
}

const RolebasedSegment: React.FC<RolebasedSegmentProps> = ({
  onPermissionChange,
  title,
  selectedOption,
  PERMISSION_OPTIONS,
}) => {
  const { t } = useTranslation();
  return (
    <div css={styles.setting}>
      <h5>{title}</h5>
      <Select
        options={PERMISSION_OPTIONS}
        value={selectedOption ? [selectedOption] : []}
        onChange={(param) => onPermissionChange(param.option)}
        props={{
          placeholder: t("search.role"),
          labelKey: "label",
          valueKey: "value",
          clearable: false,
          searchable: false,
        }}
        containerStyle={{ width: "325px" }}
      />
    </div>
  );
};

export default RolebasedSegment;

const styles = {
  setting: css({
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.sizing.scale600,
  }),
};
