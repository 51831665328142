import { EntityId } from "@reduxjs/toolkit";
import { Attachment } from "../../types/common";
import { IssueState } from "../issue/types";
import { User } from "../user/types";

export enum SpaceTransactionTypes {
  // Cleaning actions
  START_CLEANING = "space:clean:start",
  STOP_CLEANING = "space:clean:stop",
  FINISH_CLEANING = "space:clean:finish",
  SKIP_CLEANING = "space:clean:skip",

  // Assign actions
  ASSIGN_SPACE = "space:assign:add",
  UNASSIGN_SPACE = "space:assign:remove",

  // Inspect actions
  FINISH_INSPECTION = "space:inspect:finish",
}

export enum IssueTransactionTypes {
  // Cleaning actions
  START_WORK = "issue:work:start",
  STOP_WORK = "issue:work:stop",
  FINISH_WORK = "issue:work:finish",
  WAIT_WORK = "issue:work:wait",

  CREATE = "issue:report:create",
  ASSIGN = "issue:assign:add",
  UNASSIGN = "issue:assign:remove",
}

export enum AttachmentsTransactionTypes {
  ADD_ATTACHMENT = "issue:report:attach",
}

export type AllTransactionNames =
  | IssueTransactionTypes
  | AttachmentsTransactionTypes
  | SpaceTransactionTypes;

export interface Transaction {
  name:
    | IssueTransactionTypes
    | SpaceTransactionTypes
    | AttachmentsTransactionTypes;
  userId?: EntityId;
  createdAt: string;
}

export interface SpaceTransaction extends Transaction {
  spaceId: EntityId;
  taskId?: EntityId;
  authorId?: User["id"];
  meta?: {
    freshTowels?: boolean;
    doNotDisturb?: boolean;
    remark?: string;
    attachmentIds?: Attachment["id"][];
    result?: boolean;
    tags?: string[];
  };
}

interface InspectionTransaction {
  spaceId: EntityId;
  name: SpaceTransactionTypes.FINISH_INSPECTION;
  userId: User["id"];
  createdAt: string;
}

export interface PositiveInspectionTransaction extends InspectionTransaction {
  meta: {
    remark?: string;
    result: boolean;
  };
}

export interface NegativeInspectionTransaction extends InspectionTransaction {
  meta: {
    remark: string;
    attachmentIds?: Attachment["id"][];
    result: boolean;
    tags?: string[];
  };
}

export interface IssueTransaction extends Transaction {
  issueId: EntityId;
  spaceId?: EntityId;
  userId?: EntityId;
  authorId?: User["id"];
  meta?: {
    label?: string;
    remark?: string;
    reportedBy?: string;
    priority?: boolean;
    nextState?: IssueState;
    attachments?: Attachment[];
  };
}

export interface AttachmentsTransaction
  extends Omit<Transaction, "spaceId" | "createdAt"> {
  name: AttachmentsTransactionTypes;
  issueId: EntityId;
  createdAt?: string;
  meta: {
    attachmentIds: string[];
  };
}

export type Transactions = (
  | SpaceTransaction
  | IssueTransaction
  | AttachmentsTransaction
)[];

export type TransactionPayload =
  | SpaceTransaction
  | IssueTransaction
  | AttachmentsTransaction;

export interface TransactionPageParams {
  page: number;
  spaceLabel?: string;
}
