import { FC } from "react";
import { useDispatch } from "react-redux";
import PopUpWrapper from "@components/PopUpWrapper";
import theme from "@styles/theme";
import useAppSelector from "@hooks/useAppSelector";
import { selectCurrentArea } from "@store/area/areaSelectors";
import { showPopUp } from "@store/ui/uiSlice";
import { useTranslation } from "react-i18next";

interface EditAreaProps {
  onShowRemoveWindow: () => void;
}

const EditArea: FC<EditAreaProps> = ({ onShowRemoveWindow }) => {
  const { t } = useTranslation();
  const area = useAppSelector(selectCurrentArea);
  const pos = useAppSelector(({ ui }) => ui.web.popUp?.pos);
  const dispatch = useDispatch();

  const onShowNameForm = () => {
    if (!pos) return;
    dispatch(
      showPopUp({
        type: "areas/areaForm",
        pos,
      }),
    );
  };

  if (area === undefined) return null;
  return (
    <PopUpWrapper>
      <div>
        <div className="edit" onClick={onShowNameForm}>
          {t("area.update")}
        </div>
        <hr />
        <div className="edit red" onClick={onShowRemoveWindow}>
          {t("area.remove")}
        </div>
      </div>
      <style jsx>{`
        .edit {
          text-align: center;
          width: 200px;
          padding-bottom: 5px;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          cursor: pointer;
        }
        .red {
          padding: 5px 0 0 0;
          color: ${theme.colors.red};
        }
      `}</style>
    </PopUpWrapper>
  );
};

export default EditArea;
