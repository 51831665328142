import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import issueSlice from "./issue/issueSlice";
import propertySlice from "./property/propertySlice";
import spaceSlice from "./space/spaceSlice";
import userSlice from "./user/userSlice";
import uiSlice from "./ui/uiSlice";
import dayReportSlice from "./dayReport/dayReportSlice";
import taskSlice from "./task/taskSlice";
import areaSlice from "./area/areaSlice";
import rateTypeSlice from "./rateType/rateTypeSlice";
import reportExtraSlice from "./reportExtra/reportExtraSlice";
import transactionSlice from "./transaction/transactionSlice";

export const rootReducer = combineReducers({
  issue: issueSlice.reducer,
  property: propertySlice.reducer,
  space: spaceSlice.reducer,
  user: userSlice.reducer,
  task: taskSlice.reducer,
  area: areaSlice.reducer,
  ui: uiSlice.reducer,
  dayReport: dayReportSlice.reducer,
  rateType: rateTypeSlice.reducer,
  reportExtra: reportExtraSlice.reducer,
  transaction: transactionSlice.reducer,
});

const store = configureStore({ reducer: rootReducer });

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
export type AppDispatch = typeof store.dispatch;

export default store;
