import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Value } from "baseui/select";
import { UserRoles } from "@store/user/types";
import {
  selectCurrentPropertyId,
  selectCurrentRole,
  selectSelectedUser,
} from "@store/user/userSelectors";
import useAppDispatch from "@hooks/useAppDispatch";
import { Form, getMostImportantRole } from "@helpers";
import { createUser, deleteUser, updateUser } from "@store/user/userThunk";
import Select from "@components/base/select";
import Input from "@components/base/input";
import Notification from "@components/base/notification";
import { css } from "@emotion/react";
import Modal from "@components/base/modal";
import Button from "@components/base/button";
import {
  generateButtonColors,
  overrideButtonStyles,
} from "@components/base/button/overrides";
import { useTranslation } from "react-i18next";

interface MemberFormProps {
  roleOptions: {
    label: string;
    extraLabel?: string;
    value: UserRoles | undefined;
    leftComponent?: JSX.Element | null | boolean;
  }[];
}

const userSchema = yup.object().shape({
  name: yup.string().required(),
  role: yup.string().required(),
  email: yup.string().when("role", {
    is: (role: FormData["role"]) => role && role !== UserRoles.HK_MEMBER,
    then: (schema) => schema.email().required(),
  }),
});

interface FormData {
  name: string;
  email?: string;
  password?: string;
  role?: UserRoles;
}

interface FormValidation {
  isValid: boolean;
  errors: Partial<FormData>;
}

const MemberForm: React.FC<MemberFormProps> = ({
  roleOptions,
}: MemberFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedUser = useSelector(selectSelectedUser);
  const propertyId = useSelector(selectCurrentPropertyId);
  const currentRole = useSelector(selectCurrentRole);

  const [form, setForm] = useState<FormData>({
    name: "",
    email: undefined,
    role: undefined,
  });
  const [value, setValue] = useState<Value>([]);
  const [validation, setValidation] = useState<FormValidation>({
    isValid: false,
    errors: {},
  });
  const [showModal, setShowModal] = useState(false);

  const selectedUserId = selectedUser?.id;
  const isInEditMode = !!selectedUser;
  const canEdit = useMemo(
    () =>
      roleOptions.find((option) => option.value === form.role) !== undefined,
    [form.role],
  );
  const hasChanges = useMemo(() => {
    if (!selectedUser) return true;
    const {
      email,
      profile: { name },
      roles,
    } = selectedUser;
    return !isEqual(
      { email, name, role: getMostImportantRole(roles) },
      { ...form, role: form.role },
    );
  }, [form]);
  const canSubmit = hasChanges && canEdit && validation.isValid;
  const isHkMember = form?.role === UserRoles.HK_MEMBER;

  const validate = async () => {
    const result = await Form.validate(userSchema, form);
    if (result === null) {
      setValidation({ isValid: true, errors: {} });
    } else {
      setValidation({ isValid: false, errors: result });
    }
  };

  const onShowDeleteModal = () => {
    setShowModal(true);
  };

  const onDeleteMember = () => {
    if (!selectedUserId) return;
    dispatch(deleteUser(selectedUserId));
    setShowModal(false);
  };

  const onSubmit = () => {
    if (!canSubmit) return;
    const { email, role, name } = form;

    if (isInEditMode) {
      dispatch(
        updateUser(
          {
            email,
            roles: role && [role],
            properties: propertyId ? [propertyId] : [],
            profile: { name },
          },
          selectedUserId!,
        ),
      );
    } else {
      if (propertyId !== undefined && role !== undefined)
        dispatch(
          createUser({
            email,
            roles: [role],
            properties: [propertyId],
            profile: { name },
          }),
        );
    }
  };

  const setInitialRole = () => {
    switch (currentRole) {
      case UserRoles.ADMIN:
      case UserRoles.HOTEL_MANAGER:
      case UserRoles.HK_MANAGER:
        return UserRoles.HK_MEMBER;
      case UserRoles.TD_MANAGER:
        return UserRoles.TD_MEMBER;
      default:
        return undefined;
    }
  };

  const prefillForm = () => {
    if (selectedUser === undefined) {
      setForm({ role: setInitialRole(), email: undefined, name: "" });
      setValue([]);
      return;
    }

    const {
      email,
      profile: { name },
      roles,
    } = selectedUser;
    const mostImportantRole = getMostImportantRole(roles);

    setValue([
      mostImportantRole && {
        label: t(`roleNames.${mostImportantRole}`),
        value: mostImportantRole,
      },
    ] as Value);

    setForm({
      name,
      email: roles.includes(UserRoles.HK_MEMBER) ? undefined : email,
      role: mostImportantRole || setInitialRole(),
    });
  };

  useEffect(() => {
    prefillForm();
  }, [selectedUser]);

  useEffect(() => {
    validate();
  }, [form]);

  return (
    <div css={styles.container}>
      <div css={styles.form}>
        <h3 css={styles.title}>
          {selectedUserId ? t("team.detail") : t("team.addMember")}
        </h3>
        <Select
          value={value}
          options={roleOptions}
          onChange={(param) => {
            setForm({
              ...form,
              role: param.option?.value,
            });
            setValue(param.value);
          }}
          containerStyle={{
            width: "325px",
          }}
          props={{
            placeholder: t("search.function"),
            labelKey: "label",
            valueKey: "value",
            clearable: false,
            searchable: false,
          }}
        />
        <Input
          autoFocus
          label={t("common.name")}
          value={form.name}
          onChangeText={(text) => setForm({ ...form, name: text })}
          containerStyle={{
            marginTop: "10px",
            width: "100%",
          }}
          placeholder={t("team.inputName")}
        />
        {!isHkMember && (
          <>
            <Input
              label={t("login.self")}
              value={form.email}
              onChangeText={(text) => setForm({ ...form, email: text.trim() })}
              containerStyle={{
                marginBottom: "20px 0",
                marginTop: "10px",
                width: "325px",
              }}
              placeholder={t("team.inputEmail")}
            />
            {!selectedUserId && (
              <Notification content={t("team.inputEmailInfo")} kind="info" />
            )}
          </>
        )}

        <Button
          onClick={onSubmit}
          disabled={!canSubmit}
          overrides={overrideButtonStyles({
            BaseButton: { marginTop: "32px" },
          })}>
          {isInEditMode ? t("common.saveChanges") : t("common.save")}
        </Button>
      </div>
      {selectedUserId && (
        <Button
          onClick={onShowDeleteModal}
          kind="secondary"
          overrides={overrideButtonStyles({
            BaseButton: ({ $theme, $disabled }) => ({
              margin: "24px",
              position: "absolute",
              bottom: 0,
              right: 0,
              ...generateButtonColors({
                $disabled,
                color: $theme.colors.negative,
              }),
            }),
          })}
          disabled={!canEdit}>
          {t("team.removeMember")}
        </Button>
      )}

      <Modal
        title={t("common.youSure")}
        buttonText={t("team.removeMember")}
        buttonAction={onDeleteMember}
        isOpen={showModal}
        setShowModal={setShowModal}>
        {t("team.deleteConfirmation", { value: selectedUser?.profile.name })}
      </Modal>
    </div>
  );
};

const styles = {
  container: css({
    flex: 1,
    justifyContent: "space-between",
    marginLeft: 16,
    marginTop: 24,
  }),
  form: css({
    width: 324,
  }),
  input: css({
    margin: "20px 0",
  }),
  title: css({
    paddingBottom: "20px",
  }),
};

export default MemberForm;
