import { createSelector, Selector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { PaginationMeta } from "../../types/common";
import { transactionAdapter } from "./transactionSlice";

const selectSelf = (state: RootState) => state.transaction;

export const selectTransactionMeta: Selector<RootState, PaginationMeta> =
  createSelector(
    selectSelf,
    ({ meta }) =>
      meta ?? { count: 0, last: 1, next: null, prev: null, page: 1, pages: 1 },
  );

export const { selectAll: selectAllTransactions } =
  transactionAdapter.getSelectors();
