import { StatefulPopover } from "baseui/popover";
import { ParagraphMedium } from "baseui/typography";
import { FC } from "react";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { IssueActionTypes } from "@store/issue/types";
import { useTranslation } from "react-i18next";

interface MoreActionsProps {
  onMoreClick: (action: IssueActionTypes) => void;
}

const MoreActions: FC<MoreActionsProps> = ({ onMoreClick }) => {
  const { t } = useTranslation();
  return (
    <StatefulPopover
      showArrow
      placement="bottomRight"
      triggerType="click"
      data-test-id="popover"
      dismissOnEsc
      dismissOnClickOutside
      content={({ close }) => (
        <div css={styles.popUp}>
          <ParagraphMedium
            data-test-id="bekijken"
            css={styles.edit}
            onClick={() => {
              onMoreClick("view");
              close();
            }}>
            {t("common.view")}
          </ParagraphMedium>
          <div css={styles.line} />
          <ParagraphMedium
            data-test-id="edit"
            css={styles.edit}
            onClick={() => {
              onMoreClick("edit");
              close();
            }}>
            {t("common.change")}
          </ParagraphMedium>
          <div css={styles.line} />
          <ParagraphMedium
            data-test-id="remove"
            css={styles.destructive}
            onClick={() => {
              onMoreClick("remove");
              close();
            }}>
            {t("common.delete")}
          </ParagraphMedium>
        </div>
      )}
      accessibilityType="menu"
      overrides={{
        Arrow: { style: { backgroundColor: theme.colors.white } },
      }}>
      <div css={styles.container}>
        <BiDotsVerticalRounded />
      </div>
    </StatefulPopover>
  );
};

const styles = {
  container: css({
    display: "flex",
    flex: 1,
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "25px",
  }),
  popUp: css({
    padding: theme.sizing.scale800,
    backgroundColor: theme.colors.white,
    borderRadius: theme.borders.radius200,
  }),
  line: css({
    border: `1px solid ${theme.colors.mono200}`,
    padding: 0,
    margin: 0,
  }),
  edit: css({
    padding: `${theme.sizing.scale400} 0`,
    textAlign: "center",
    cursor: "pointer",
  }),
  destructive: css({
    padding: `${theme.sizing.scale400} 0`,
    textAlign: "center",
    cursor: "pointer",
    color: theme.colors.negative,
  }),
};

export default MoreActions;
