import { StatefulPopover } from "baseui/popover";
import { ParagraphSmall } from "baseui/typography";
import React, { useMemo } from "react";
import TaskRow from "./TaskRow";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { makeSelectRoomSpaceById } from "@store/space/spaceSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { RoomSpace } from "@store/space/types";

interface PeriodicTasksProps {
  spaceId: RoomSpace["id"];
}

const PeriodicTasks: React.FC<PeriodicTasksProps> = ({ spaceId }) => {
  const selectSpaceById = useMemo(makeSelectRoomSpaceById, [spaceId]);
  const space = useAppSelector((state) => selectSpaceById(state, spaceId));

  const tasks = space.room?.tasks ?? [];

  if (!tasks?.length) {
    return null;
  }
  return (
    <div css={styles.container}>
      <StatefulPopover
        showArrow
        placement="bottomRight"
        triggerType="click"
        content={
          <div css={styles.commentsContainer}>
            {tasks.map((task: any) => {
              return (
                <TaskRow key={task.id} taskId={task.id} spaceId={spaceId} />
              );
            })}
          </div>
        }
        accessibilityType="menu"
        overrides={{
          Arrow: { style: { backgroundColor: theme.colors.white } },
        }}>
        <ParagraphSmall
          color={theme.colors.white}
          css={{
            padding: `${theme.sizing.scale200} ${theme.sizing.scale600}`,
            backgroundColor: theme.colors.primaryA,
            cursor: "pointer",
            borderRadius: theme.borders.radius300,
            textAlign: "center",
          }}>
          {tasks.length}
        </ParagraphSmall>
      </StatefulPopover>
    </div>
  );
};

const styles = {
  container: css({
    display: "flex",
    flex: 0,
    justifyContent: "center",
  }),
  commentsContainer: {
    padding: theme.sizing.scale800,
    backgroundColor: theme.colors.white,
    borderRadius: theme.borders.radius200,
  },
};

export default PeriodicTasks;
