import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { DayReportReportExtra } from "../reportExtra/types";
import { DayReport, DayReportsMeta } from "./types";

export interface DayReportState extends EntityState<DayReport> {
  meta: DayReportsMeta;
  week: { [id: string]: DayReport };
  current: DayReport | undefined;
  reportExtras: DayReportReportExtra;
  loading: boolean;
  error: boolean;
}

export const dayReportAdapter = createEntityAdapter<DayReport>();

const initialState: DayReportState = {
  loading: false,
  error: false,
  current: undefined,
  meta: {
    totals: {
      noShow: 0,
      cleaned: 0,
      carriedOver: 0,
      carriedOverFromYesterday: 0,
      doNotDisturb: 0,
      dayUse: 0,
      green: 0,
      orange: 0,
      nonGreenSkip: 0,
      sold: 0,
    },
    perRate: {},
  },
  week: {},
  reportExtras: {},
  ...dayReportAdapter.getInitialState(),
};

const dayReportSlice = createSlice({
  name: "dayReport",
  initialState,
  reducers: {
    setDayReports(
      state,
      { payload }: PayloadAction<{ data: DayReport[]; meta: DayReportsMeta }>,
    ) {
      dayReportAdapter.setAll(state, payload.data);
      state.meta = payload.meta;
    },

    setWeek(
      state,
      { payload }: PayloadAction<{ data: DayReport[]; meta: DayReportsMeta }>,
    ) {
      state.meta = payload.meta;
      state.week = payload.data.reduce((allReports, dayReport) => {
        return { ...allReports, [dayReport.id]: dayReport };
      }, {});
    },

    setDayReport(state, action: PayloadAction<DayReport>) {
      state.current = action.payload;
      state.error = false;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setError(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
  },
});

export const { setDayReports, setDayReport, setWeek, setLoading, setError } =
  dayReportSlice.actions;

export default dayReportSlice;
