import React from "react";
import theme from "@styles/theme";
import { useSelector } from "react-redux";
import { format, isBefore } from "date-fns";
import Remarks from "./Audits";
import EditsWarning from "./EditsWarning";
import { DefaultRateTypes } from "../../RateTypeForm";
import { css } from "@emotion/react";
import { getLanguage, totalHKDayReportDifference } from "@helpers";
import {
  selectDayReportMeta,
  selectWeekOverview,
} from "@store/dayReport/dayReportSelector";
import { Link } from "react-router-dom";
import { overrideTableStyles } from "@components/base/table/overrides";
import Table from "@components/base/table";
import { ImCheckmark } from "react-icons/im";
import { Trans, useTranslation } from "react-i18next";

type tableTotalsPerDayRate = {
  sold: number[];
  carriedOver: number[];
  carriedOverFromYesterday: number[];
  dayUse: number[];
  orange: number[];
  green: number[];
  nonGreenSkip: number[];
  doNotDisturb: number[];
  cleaned: number[];
  noShow: number[];
};

const defaultRateCount = {
  cleaned: 0,
  green: 0,
  orange: 0,
  nonGreenSkip: 0,
  doNotDisturb: 0,
};

const WeekOverviewTable: React.FC = () => {
  const { t } = useTranslation();
  const totals = useSelector(selectDayReportMeta)?.totals;
  const dayReports = useSelector(selectWeekOverview);
  const dayReportsArray = !!dayReports ? Object.values(dayReports) : [];
  const rateNames: string[] = [];

  const totalsPerDay: tableTotalsPerDayRate = dayReportsArray.reduce(
    (acc: tableTotalsPerDayRate, report) => {
      return {
        sold: [...acc.sold, report.totals.sold],
        carriedOver: [...acc.carriedOver, report.totals.carriedOver],
        carriedOverFromYesterday: [
          ...acc.carriedOverFromYesterday,
          report.totals.carriedOverFromYesterday,
        ],
        dayUse: [...acc.dayUse, report.totals.dayUse],
        orange: [...acc.orange, report.totals.orange],
        green: [...acc.green, report.totals.green],
        nonGreenSkip: [...acc.nonGreenSkip, report.totals.nonGreenSkip],
        doNotDisturb: [...acc.doNotDisturb, report.totals.doNotDisturb],
        cleaned: [...acc.cleaned, report.totals.cleaned],
        noShow: [...acc.noShow, report.totals.noShow],
      };
    },
    {
      sold: [],
      carriedOver: [],
      carriedOverFromYesterday: [],
      dayUse: [],
      orange: [],
      nonGreenSkip: [],
      green: [],
      doNotDisturb: [],
      cleaned: [],
      noShow: [],
    } as tableTotalsPerDayRate,
  );

  const generateDayColumns = () => {
    return dayReportsArray.map((report) => {
      const day = format(new Date(report.date), "eeeeee d MMM", {
        locale: getLanguage(),
      });

      if (isBefore(new Date(report.date), new Date())) {
        return (
          <Link to={`/day-reports?date=${report.date}`}>
            <a
              data-test-id="day-reports-link"
              style={{ color: theme.colors.white }}>
              <strong css={styles.bold}>{day}</strong>
            </a>
          </Link>
        );
      }
      return day;
    });
  };

  const generateApprovalRows = () => {
    return [
      [
        <strong css={styles.bold}>{t("dayreport.approval")}</strong>,
        "HKS",
        ...dayReportsArray.map((report) => {
          return !!report.approvals.hkManager ? (
            <ImCheckmark
              style={{
                width: 16,
                height: 16,
                stroke: theme.colors.positive,
                fill: theme.colors.positive,
              }}
            />
          ) : null;
        }),
      ],
      [
        null,
        "FOM",
        ...dayReportsArray.map((report) => {
          if (!!report.approvals.manager) {
            return (
              <ImCheckmark
                style={{
                  width: 16,
                  height: 16,
                  stroke: theme.colors.positive,
                  fill: theme.colors.positive,
                }}
              />
            );
          }
          return null;
        }),
      ],
    ];
  };

  const generateCountRows = () => {
    dayReportsArray.forEach((report) => {
      report.perRate !== undefined &&
        Object.keys(report.perRate).forEach((rateName) => {
          if (rateName === undefined) return;
          if (!rateNames.includes(rateName)) {
            rateNames.push(rateName);
          }
        });
    });
    return rateNames.sort().flatMap((rateType) => {
      const data = dayReportsArray.map((report) => {
        return {
          count: report.perRate[rateType] ?? defaultRateCount,
          hasEdits: report.audits.some(
            ({ rateName, changes }) =>
              rateName === rateType ||
              (rateType === DefaultRateTypes.ROOMS &&
                !!changes.cleaned?.length) ||
              (rateType === DefaultRateTypes.SUITES &&
                !!changes.cleaned?.length),
          ),
        };
      });
      return [
        [
          <strong css={styles.bold}>{rateType}</strong>,
          t("dayreport.clean"),

          ...data.map(({ count, hasEdits }) => {
            if (hasEdits) {
              return <EditsWarning cleaned={count.cleaned} />;
            }
            return count.cleaned ?? 0;
          }),
          data.reduce((acc, { count }) => {
            return acc + (count.cleaned ?? 0);
          }, 0),
        ],
        [
          null,
          t("dayreport.exceptions"),
          ...data.map(({ count }) => {
            return (
              count.orange +
                count.green +
                count.doNotDisturb +
                count.nonGreenSkip || 0
            );
          }),
          data.reduce((acc, { count }) => {
            return (
              acc +
              (count.orange ?? 0) +
              (count.green ?? 0) +
              (count.nonGreenSkip ?? 0) +
              (count.doNotDisturb ?? 0)
            );
          }, 0),
        ],
      ];
    });
  };
  const geneRateTotalCountRows = () => {
    let totalToBeCleaned = 0;
    return [
      [
        <p css={styles.bold}>Totalen</p>,
        t("dayreport.sold.title"),
        ...totalsPerDay.sold,
        totalsPerDay.sold.reduce((a, b) => a + b, 0),
      ],
      [
        null,
        t("dayreport.carriedOverFromYesterday.title"),
        ...totalsPerDay.carriedOverFromYesterday,
        totalsPerDay.carriedOverFromYesterday.reduce((a, b) => a + b, 0),
      ],
      [
        null,
        t("dayreport.dayuse.title"),
        ...totalsPerDay.dayUse,
        totalsPerDay.dayUse.reduce((a, b) => a + b, 0),
      ],
      [
        null,
        t("dayreport.noShow"),
        ...totalsPerDay.noShow,
        totalsPerDay.noShow.reduce((a, b) => a + b, 0),
      ],
      [
        null,
        t("dayreport.carriedOver.title"),
        ...totalsPerDay.carriedOver,
        totals.carriedOver,
      ],
      [
        null,
        t("dayreport.exceptions"),
        ...totalsPerDay.orange.map((acc, perRateIndex) => {
          return (
            <>
              <div css={styles.exceptionCell}>
                {acc +
                  totalsPerDay.green[perRateIndex] +
                  totalsPerDay.doNotDisturb[perRateIndex] +
                  totalsPerDay.nonGreenSkip[perRateIndex]}
                <ul css={styles.popOver}>
                  <li css={styles.listingException}>
                    <Trans
                      i18nKey="dayreport.popover.green"
                      values={{ number: totalsPerDay.green[perRateIndex] }}
                    />
                  </li>
                  <li css={styles.listingException}>
                    <Trans
                      i18nKey="dayreport.popover.orange"
                      values={{ number: totalsPerDay.orange[perRateIndex] }}
                    />
                  </li>
                  <li css={styles.listingException}>
                    <Trans
                      i18nKey="dayreport.popover.nonGreenSkip"
                      values={{
                        number: totalsPerDay.nonGreenSkip[perRateIndex],
                      }}
                    />
                  </li>
                  <li css={styles.listingException}>
                    <Trans
                      i18nKey="dayreport.popover.doNotDisturb"
                      values={{
                        number: totalsPerDay.doNotDisturb[perRateIndex],
                      }}
                    />
                  </li>
                </ul>
              </div>
            </>
          );
        }),
        totals.orange +
          totals.green +
          totals.doNotDisturb +
          totals.nonGreenSkip,
      ],
      [
        null,
        t("dayreport.cleaned.title"),
        ...totalsPerDay.sold.map((acc, perDayIndex) => {
          const total =
            acc +
            totalsPerDay.dayUse[perDayIndex] +
            totalsPerDay.carriedOverFromYesterday[perDayIndex] -
            totalsPerDay.carriedOver[perDayIndex] -
            totalsPerDay.noShow[perDayIndex] -
            (totalsPerDay.orange[perDayIndex] +
              totalsPerDay.green[perDayIndex] +
              totalsPerDay.doNotDisturb[perDayIndex] +
              totalsPerDay.nonGreenSkip[perDayIndex]);
          totalToBeCleaned += total;
          return total;
        }),
        totalToBeCleaned,
      ],
      [
        null,
        <strong>{t("dayreport.clean")}</strong>,
        ...totalsPerDay.cleaned.map((clean) => {
          return <strong css={styles.bold}>{clean}</strong>;
        }),
        <strong>{totals.cleaned}</strong>,
      ],
      [
        null,
        t("dayreport.difference"),
        ...dayReportsArray.map((report) => {
          const difference = totalHKDayReportDifference(report?.totals);
          return (
            <span
              css={[-1, 0, 1].includes(difference) ? styles.green : styles.red}>
              {difference}
            </span>
          );
        }),
        <span
          css={
            [-1, 0, 1].includes(totalToBeCleaned - totals.cleaned)
              ? styles.green
              : styles.red
          }>
          {totalToBeCleaned - totals.cleaned}
        </span>,
      ],
    ];
  };

  const generateAuditRow = () => {
    return [
      [
        <strong css={styles.bold}>{t("common.remark")}</strong>,
        null,
        ...dayReportsArray.map((report) => {
          return <Remarks key={report.id} report={report} />;
        }),
      ],
    ];
  };

  return (
    <Table
      columns={[null, null, ...generateDayColumns(), t("common.total")]}
      data={[
        ...generateApprovalRows(),
        ...generateAuditRow(),
        ...geneRateTotalCountRows(),
        ...generateCountRows(),
      ]}
      overrides={overrideTableStyles({
        Root: {
          style: {
            overflow: "auto",
          },
        },
        TableBodyRow: {
          style: {
            whiteSpace: "nowrap",
          },
          ":nth-child(n)": {
            backgroundColor: `${theme.colors.white}`,
          },
          ":nth-child(12n + 3)": {
            backgroundColor: `${theme.colors.mono200} `,
          },
          ":nth-child(12n + 4)": {
            backgroundColor: `${theme.colors.mono200} `,
          },
          ":nth-child(2)": {
            backgroundColor: `${theme.colors.mono200}`,
          },
          ":nth-child(3)": {
            backgroundColor: `${theme.colors.white} `,
          },
          ":nth-child(4)": {
            backgroundColor: `${theme.colors.mono200}`,
            borderTop: `2px solid ${theme.colors.contentInverseSecondary}`,
          },
          ":nth-child(5)": {
            backgroundColor: `${theme.colors.mono200} `,
          },
          ":nth-child(6)": {
            backgroundColor: `${theme.colors.mono200}`,
          },
          ":nth-child(7)": {
            backgroundColor: `${theme.colors.mono200}`,
          },
          ":nth-child(8)": {
            backgroundColor: `${theme.colors.mono200}`,
          },
          ":nth-child(9)": {
            backgroundColor: `${theme.colors.mono200}`,
          },
          ":nth-child(10)": {
            borderTop: `1px solid ${theme.colors.contentInverseSecondary}`,
          },
          ":nth-child(12)": {
            borderTop: `1px solid ${theme.colors.contentInverseSecondary}`,
            backgroundColor: `${theme.colors.mono200}`,
            borderBottom: `2px solid ${theme.colors.contentInverseSecondary}`,
          },
        },
      })}
    />
  );
};

const styles = {
  bold: css({
    fontWeight: 600,
  }),
  subTotal: css({
    borderTop: `2px solid ${theme.colors.contentInverseSecondary}`,
  }),
  clearCell: css({
    borderTop: "none !important",
    backgroundColor: "orange !important",
  }),
  green: css({
    color: `${theme.colors.positive}`,
    fontWeight: 600,
  }),
  red: css({
    color: `${theme.colors.negative}`,
    fontWeight: 600,
  }),
  exceptionCell: css({
    ":hover": {
      cursor: "pointer",
    },
    ":hover ul": {
      opacity: 1,
      transition: "all 0.1s ease 0.1s",
      visibility: "visible",
      zIndex: 15,
    },
  }),
  popOver: css({
    color: "#000000",
    backgroundColor: "#fff",
    fontWeight: 400,
    fontSize: "0.8rem",
    position: "absolute",
    padding: "5px 8px",
    whiteSpace: "nowrap",
    opacity: 0,
    transform: "translate(0%, 15%)",
    borderRadius: "5px",
    border: `1px solid ${theme.colors.gray}`,
    visibility: "visible",
    zIndex: -15,
  }),
  listingException: css({
    gap: "5px",
    display: "flex",
    justifyContent: "space-between",
  }),
};
export default WeekOverviewTable;
