import { css } from "@emotion/react";
import React from "react";
import PopUpWrapper from "@components/PopUpWrapper";
import theme from "@styles/theme";
import useAppSelector from "@hooks/useAppSelector";
import useAppDispatch from "@hooks/useAppDispatch";
import { showPopUp } from "@store/ui/uiSlice";
import { useTranslation } from "react-i18next";

interface EditTaskProps {
  onShowDeleteWindow: () => void;
}

const EditTask: React.FC<EditTaskProps> = ({ onShowDeleteWindow }) => {
  const { t } = useTranslation();
  const pos = useAppSelector(({ ui }) => ui.web.popUp?.pos);
  const dispatch = useAppDispatch();

  const onEdit = () => {
    dispatch(showPopUp({ type: "tasks/taskForm", pos: pos! }));
  };

  const onDelete = () => {
    onShowDeleteWindow();
  };

  return (
    <PopUpWrapper>
      <div>
        <div css={styles.task} onClick={onEdit}>
          {t("tasks.edit")}
        </div>
        <hr />
        <div css={[styles.task, styles.onDelete]} onClick={onDelete}>
          {t("tasks.remove")}
        </div>
      </div>
    </PopUpWrapper>
  );
};

const styles = {
  task: css({
    width: "160px",
    paddingBottom: theme.sizing.scale200,
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "20px",
    textAlign: "center",
    cursor: "pointer",
  }),
  onDelete: css({
    padding: `${theme.sizing.scale200} 0 0 0`,
    color: theme.colors.red,
  }),
};

export default EditTask;
