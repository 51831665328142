import { TableOverrides } from "baseui/table-semantic";
import { overrideStyles, OverrideStyles } from "@components/base/helpers";

export const tableOverrides: TableOverrides = {
  TableHead: {
    style: ({ $theme }) => ({
      backgroundColor: $theme.colors.primary400,
    }),
  },
  TableHeadCell: {
    style: ({ $theme }) => ({
      backgroundColor: $theme.colors.primary400,
      paddingTop: $theme.sizing.scale200,
      paddingRight: $theme.sizing.scale400,
      paddingLeft: $theme.sizing.scale400,
      paddingBottom: $theme.sizing.scale200,
      color: $theme.colors.white,
    }),
  },
  TableBodyRow: {
    style: ({ $theme }) => ({
      display: "table-row !important",
      backgroundColor: $theme.colors.white,
      ":nth-child(odd)": {
        backgroundColor: $theme.colors.white,
      },
      ":nth-child(odd):hover": {
        backgroundColor: $theme.colors.mono300,
      },
      ":nth-child(even)": {
        backgroundColor: $theme.colors.mono200,
      },
      ":nth-child(even):hover": {
        backgroundColor: $theme.colors.mono300,
      },
    }),
  },
  TableBodyCell: {
    style: ({ $theme }) => ({
      display: "table-cell !important",
      color: $theme.colors.black,
      verticalAlign: "middle",
      paddingTop: $theme.sizing.scale400,
      paddingLeft: $theme.sizing.scale400,
      paddingRight: $theme.sizing.scale400,
      paddingBottom: $theme.sizing.scale400,
    }),
  },
};

export const overrideTableStyles = (
  styles?: OverrideStyles<TableOverrides>,
) => {
  return overrideStyles<TableOverrides>({
    overrides: tableOverrides,
    styles,
  });
};
