import React, { useEffect, useState } from "react";
import AllRates from "./components/AllRates";
import GroupedRates from "./components/GroupedRates";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { fetchRateTypes } from "@store/rateType/rateTypeThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import SegmentControl from "@components/base/segment-control";
import Breadcrumb from "@components/base/breadcrumb";
import { NavLabels, SubNavLabels } from "@components/Layout";

enum Segments {
  GROUPED_RATES = "Groepen",
  ALL_RATES = "Alle kamertypes",
}

interface RateTypesProps {}

const RateTypes: React.FC<RateTypesProps> = () => {
  const dispatch = useAppDispatch();
  const [segment, setSegment] = useState<string>(Segments.GROUPED_RATES);

  useEffect(() => {
    dispatch(fetchRateTypes());
  }, []);

  return (
    <>
      <Breadcrumb
        labels={[
          SubNavLabels.Settings,
          NavLabels.DayReportSettings,
          NavLabels.TypeGroups,
        ]}
      />
      <section css={styles.section}>
        <div data-test-id="segment-control">
          <SegmentControl
            values={[Segments.GROUPED_RATES, Segments.ALL_RATES]}
            selectedValue={segment}
            onPress={setSegment}
          />
        </div>
        {segment === Segments.GROUPED_RATES && <GroupedRates />}
        {segment === Segments.ALL_RATES && <AllRates />}
      </section>
    </>
  );
};

const styles = {
  section: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: theme.sizing.scale1600,
    overflow: "scroll",
  }),
};

export default RateTypes;
