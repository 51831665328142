import { StatefulPopover } from "baseui/popover";
import { ParagraphSmall } from "baseui/typography";
import React from "react";
import { css } from "@emotion/react";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";

interface EditsWarningProps {
  cleaned?: number | null;
}

const EditsWarning: React.FC<EditsWarningProps> = ({ cleaned }) => {
  const { t } = useTranslation();
  return (
    <div css={styles.container}>
      <StatefulPopover
        showArrow
        placement="bottomRight"
        triggerType="hover"
        content={
          <ParagraphSmall
            css={{
              padding: theme.sizing.scale800,
              backgroundColor: theme.colors.white,
              borderRadius: theme.borders.radius200,
            }}>
            {t("dayreport.editWarning")}
          </ParagraphSmall>
        }
        accessibilityType="menu"
        overrides={{
          Arrow: { style: { backgroundColor: theme.colors.white } },
        }}>
        <div css={styles.row}>
          <ParagraphSmall>{cleaned}</ParagraphSmall>
          <img
            src="/icons/icon-warning.svg"
            css={{ marginLeft: theme.sizing.scale400 }}
          />
        </div>
      </StatefulPopover>
    </div>
  );
};

const styles = {
  container: css({
    display: "flex",
    flex: 0,
  }),
  row: css({
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
  }),
};

export default EditsWarning;
