import React, { FC, useState, useEffect } from "react";
import DetectOutsideClick from "./DetectOutsideClick";
import theme from "@styles/theme";

interface SelectOptionsProps {
  options?: string[];
  optionsWithLabel?: { [key: string]: string };
  width: number;
  onChange: (option: string) => void;
  value: string;
  placeholder?: string;
  label?: string;
  active?: boolean;
  style?: React.CSSProperties;
  selectorStyle?: React.CSSProperties;
}

const SelectOptions: FC<SelectOptionsProps> = ({
  options,
  optionsWithLabel,
  width,
  onChange,
  value,
  placeholder,
  active = true,
  label,
  style,
  selectorStyle,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [nonSelectedOptions, setNonSelectedOptions] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("");

  const onDropDownClick = (clickedOption: string) => {
    if (clickedOption === selectedOption) return;
    setSelectedOption(clickedOption);
    setShowOptions(false);
    onChange(clickedOption);
  };

  const toggleShowOptions = () => {
    setShowOptions(!showOptions && active);
  };

  const handleOutsideClick = () => {
    setShowOptions(false);
  };

  useEffect(() => {
    if (options) {
      setNonSelectedOptions(options.filter((option) => option !== value));
    } else if (optionsWithLabel) {
      setNonSelectedOptions(
        Object.keys(optionsWithLabel).filter((option) => option !== value),
      );
    }
  }, [value, options, optionsWithLabel]);

  return (
    <div className={!active ? "inactive not-allowed" : ""} style={style}>
      {label && <p>{label}</p>}
      <div
        data-test-id="selector"
        className={value === "" ? "selector placeholder" : "selector"}
        onClick={toggleShowOptions}
        style={selectorStyle}>
        {value === ""
          ? placeholder
          : options
            ? value
            : optionsWithLabel![value]}
        <img className="sortIcon" src="/icons/icon-sort-dark.svg" />
      </div>

      {showOptions && (
        <div>
          <DetectOutsideClick onClick={handleOutsideClick} />
          <ul className="dropDown">
            {nonSelectedOptions.map((option) => {
              return (
                <li
                  data-test-id="options"
                  key={option}
                  onClick={() => onDropDownClick(option)}>
                  {options ? option : optionsWithLabel![option]}
                </li>
              );
            })}
          </ul>
        </div>
      )}

      <style jsx>{`
        .container {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
        }
        .inactive {
          opacity: 0.2;
        }
        .not-allowed {
          cursor: not-allowed;
        }
        p {
          margin-bottom: 8px;
        }
        .selector {
          display: flex;
          justify-content: space-between;
          width: ${width - 14}px;
          height: auto;
          padding: 8px 0 8px 16px;
          font-size: 16px;
          font-weight: bold;
          line-height: 21px;
          background: ${theme.colors.mono100};
          border-radius: 8px;
          outline: none;
          cursor: ${active ? "pointer" : "not-allowed"};
        }
        .placeholder {
          color: ${theme.colors.mono600};
        }
        .sortIcon {
          margin-right: 10px;
        }
        ul.dropDown {
          position: absolute;
          margin-top: 4px;
          background-color: white;
          width: ${width}px;
          height: auto;
          max-height: 300px;
          overflow-y: scroll;
          border-radius: 4px;
          box-sizing: border-box;
          z-index: 10;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
        }
        ul.dropDown > li {
          font-size: 15px;
          padding: 14px 0 14px 14px;
          border-bottom: 2px solid #efefef;
          box-sizing: border-box;
          cursor: pointer;
        }
        ul.dropDown > li:last-child {
          border-bottom: none;
        }
        ul.dropDown > li:hover {
          background-color: #efefef;
        }

        /* Tablet optimizations */
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
          p,
          .selector {
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};

export default SelectOptions;
