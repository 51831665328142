import { Attachment } from "../../types/common";
import { IssueTransaction } from "../../types/transaction";
import { Space } from "../space/types";
import { User } from "../user/types";

export enum IssueState {
  OPEN = "open",
  IN_PROGRESS = "in_progress",
  ON_HOLD = "on_hold",
  DONE = "done",
  ORDERED = "ordered",
}

export enum IssueType {
  TECHNICAL = "technical",
  GENERAL = "general",
}

export interface WorkLog {
  userId: User["id"];
  remark?: string;
  attachments?: Attachment[];
  createdAt: string;
  prevState: IssueState;
  nextState: IssueState;
}

export interface Issue {
  attachments: Attachment[];
  authorId?: string;
  reportedBy: string;
  createdAt?: string;
  description?: string;
  id: string;
  label: string;
  priority: boolean;
  spaceId: Space["id"];
  state: IssueState;
  users: { id: User["id"] }[];
  workLogs: WorkLog[];
  typeOf: IssueType;
}

export enum IssueFilterTypes {
  SEARCH_TEXT = "search_text",
  STATUS_TAB = "status_tab",
}

export type IssueFilters = {
  [IssueFilterTypes.SEARCH_TEXT]?: string;
  [IssueFilterTypes.STATUS_TAB]?: IssueState;
};

export type UpdateIssueParams = Partial<
  Pick<
    Issue,
    "label" | "spaceId" | "description" | "priority" | "reportedBy" | "state"
  >
>;

export interface TransactionPayload {
  transaction: IssueTransaction;
  prevIssue: Issue;
}

export interface DeleteAttachmentPayload {
  attachment: Attachment;
  issueId: Issue["id"];
}

export type IssueActionTypes =
  | "view"
  | "edit"
  | "remove"
  | "setToInactive"
  | false;

export const STATUS_OPTIONS = [
  { label: "Open", id: IssueState.OPEN },
  { label: "Actief", id: IssueState.IN_PROGRESS },
  { label: "Voltooid", id: IssueState.DONE },
  { label: "Inactief", id: IssueState.ON_HOLD },
  { label: "In Bestelling", id: IssueState.ORDERED },
];
