import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";
import packageJson from "../package.json";

const HONEYBADGER_API_KEY = import.meta.env.VITE_HONEYBADGER_API_KEY;

const config = {
  apiKey: HONEYBADGER_API_KEY,
  environment: "production",
  revision: packageJson.version,
};

const honeybadger = Honeybadger.configure(config);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <App />
    </HoneybadgerErrorBoundary>
  </React.StrictMode>,
);
