import React from "react";
import TableRow from "./TableRow";
import CheckboxColumn from "./CheckboxColumn";
import Sort from "@components/Sort";
import useAppSelector from "@hooks/useAppSelector";
import { selectRoomSpaceIdsBySorting } from "@store/space/spaceSelectors";
import { setRoomSorting } from "@store/ui/uiSlice";
import { RoomSpaceSortingKeys } from "@store/space/types";
import Table from "@components/base/table";
import useAppDispatch from "@hooks/useAppDispatch";
import { overrideTableStyles } from "@components/base/table/overrides";
import { useTranslation } from "react-i18next";
import { selectCurrentRole } from "@store/user/userSelectors";
import { isSupervisor } from "@helpers";

const RoomsTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { sorting, ids: sortedSpaceIds } = useAppSelector(
    selectRoomSpaceIdsBySorting,
  );
  const currentRole = useAppSelector(selectCurrentRole);
  const canEdit = isSupervisor(currentRole);
  const rows = sortedSpaceIds.map(
    (spaceId) => TableRow({ spaceId, canEdit }) as any,
  );

  const onSortChange = (key: string) => {
    dispatch(
      setRoomSorting({
        key: key as RoomSpaceSortingKeys,
      }),
    );
  };

  return (
    <Table
      data-test-id="roomsTable"
      columns={[
        canEdit && <CheckboxColumn />,
        canEdit && (
          <Sort sort={sorting} onChange={onSortChange} id="action">
            {t("common.action")}
          </Sort>
        ),
        <Sort sort={sorting} onChange={onSortChange} id="room">
          {t("common.space")}
        </Sort>,
        <Sort sort={sorting} onChange={onSortChange} id="roomStatus">
          {t("hk.filters.roomState")}
        </Sort>,
        <Sort sort={sorting} onChange={onSortChange} id="arrivals">
          {t("hk.filters.arrivals")}
        </Sort>,
        <Sort sort={sorting} onChange={onSortChange} id="cleaningStatus">
          {t("hk.filters.cleaningState")}
        </Sort>,
        canEdit && (
          <Sort sort={sorting} onChange={onSortChange} id="tasks">
            {t("hk.filters.tasks")}
          </Sort>
        ),
        canEdit && t("hk.filters.user"),
      ].filter(Boolean)}
      data={rows}
      emptyMessage={t("search.notFound", {
        search: t("common.space").toLowerCase(),
      })}
      overrides={{
        ...overrideTableStyles({
          TableHeadCell: {
            borderRightWidth: "0px !important",
            ":nth-child(3)": {
              paddingTop: "0px",
              paddingBottom: "0px",
              paddingLeft: "1.5px",
              paddingRight: "1.5px",
            },
          },
          TableBodyCell: {
            borderRightWidth: "0px !important",
            ":first-child": {
              alignSelf: "center",
              verticalAlign: "middle",
            },
            ":nth-child(2)": {
              paddingLeft: "0px",
            },
            ":nth-child(3)": {
              position: "relative",
              paddingTop: "0px",
              paddingBottom: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
              borderRightWidth: "0px",
            },
          },
        }),
      }}
    />
  );
};

export default RoomsTable;
