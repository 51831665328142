import { FC, useState } from "react";
import { IssueTableSort } from "../index";
import theme from "@styles/theme";
import Sort from "@components/Sort";
import { format } from "date-fns";
import IssueActions from "./IssueActions";
import MoreActions from "./MoreActions";
import { OnChangeParams } from "baseui/select";
import { isEqual } from "lodash";
import Assignee from "@components/Assignee";
import RoomStatusIndicator from "@components/RoomStatusIndicator";
import {
  Issue,
  IssueActionTypes,
  IssueState,
  IssueType,
  STATUS_OPTIONS,
} from "@store/issue/types";
import {
  selectCurrentUserId,
  selectUserEntities,
} from "@store/user/userSelectors";
import useAppSelector from "@hooks/useAppSelector";
import { selectSpaceEntities } from "@store/space/spaceSelectors";
import Table from "@components/base/table";
import { setSelectedIssueId } from "@store/issue/issueSlice";
import { setSelectedUser } from "@store/user/userSlice";
import { setSelectedSpace } from "@store/space/spaceSlice";
import {
  IssueTransaction,
  IssueTransactionTypes,
} from "@store/transaction/types";
import { handleIssueTransaction, updateIssue } from "@store/issue/issueThunk";
import Select from "@components/base/select";
import { overrideTableStyles } from "@components/base/table/overrides";
import useAppDispatch from "@hooks/useAppDispatch";
import { css } from "@emotion/react";
import { EntityId } from "@reduxjs/toolkit";
import AssignIssueMember from "./AssignIssueMember";
import { useTranslation } from "react-i18next";
import { getLanguage } from "@helpers";

const OPEN_STATUS_OPTIONS = STATUS_OPTIONS.filter(
  (option) => !isEqual(option, { label: "Inactief", id: IssueState.ON_HOLD }),
);

interface IssueTableProps {
  issues?: Issue[];
  sort: IssueTableSort;
  onSortChange: (sort: string) => void;
  assigns: Record<string, number>;
  isLoading?: boolean;
  updateIssueList: () => void;
  type: IssueType;
}

const IssueTable: FC<IssueTableProps> = ({
  issues,
  sort,
  onSortChange,
  assigns,
  isLoading,
  updateIssueList,
  type,
}) => {
  const { t } = useTranslation();
  const locale = getLanguage();
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const members = useAppSelector(({ user }) => selectUserEntities(user));
  const spaces = useAppSelector(({ space }) => selectSpaceEntities(space));
  const [actionType, setActionType] = useState<IssueActionTypes>(false);
  const [showModal, setShowModal] = useState(false);

  const onMoreClick = (
    action: IssueActionTypes,
    id: string,
    userId: EntityId,
    spaceId?: EntityId,
  ) => {
    setShowModal(true);
    setActionType(action);
    dispatch(setSelectedIssueId(id));
    if (spaceId) dispatch(setSelectedSpace(spaceId));
    if (userId) dispatch(setSelectedUser(userId));
  };

  const onStatusChange = async (params: OnChangeParams, issue: Issue) => {
    const state = params.option?.id as IssueState;
    if (state === IssueState.ON_HOLD) {
      setShowModal(true);
      setActionType("setToInactive");
      dispatch(setSelectedIssueId(issue.id));
      return;
    }

    const states = {
      [IssueState.OPEN]: IssueTransactionTypes.STOP_WORK,
      [IssueState.IN_PROGRESS]: IssueTransactionTypes.START_WORK,
      [IssueState.ORDERED]: IssueTransactionTypes.WAIT_WORK,
      [IssueState.DONE]: IssueTransactionTypes.FINISH_WORK,
    };

    const transaction: IssueTransaction = {
      issueId: issue.id,
      name: states[state],
      spaceId: issue.spaceId,
      createdAt: format(new Date(), "EEEEEE dd MMMM yyyy - HH:mm", {
        locale,
      }),
      userId: currentUserId,
      meta: {
        nextState: state,
      },
    };
    dispatch(handleIssueTransaction([{ transaction, prevIssue: issue }]));
    dispatch(updateIssue(issue, { state }));
    updateIssueList();
  };
  return (
    <>
      <Table
        isLoading={isLoading}
        loadingMessage={t("common.loading")}
        columns={[
          <Sort sort={sort} onChange={onSortChange} id="priority">
            {t("common.priority")}
          </Sort>,
          <Sort sort={sort} onChange={onSortChange} id="createdAt">
            {t("issue.createDate")}
          </Sort>,
          <Sort sort={sort} onChange={onSortChange} id="label">
            {t("issue.taskName")}
          </Sort>,
          "",
          <Sort sort={sort} onChange={onSortChange} id="location">
            {t("common.property")}
          </Sort>,
          <Sort sort={sort} onChange={onSortChange} id="state">
            {t("issue.status")}
          </Sort>,
          <Sort sort={sort} onChange={onSortChange} id="assignee">
            {t("issue.assignee")}
          </Sort>,
          "Meer",
        ]}
        data={
          issues?.map((issue) => {
            const { priority, createdAt, label, spaceId, state, users, id } =
              issue;
            const userId = users?.[0]?.id;
            const member = members?.[userId];
            const space = spaces?.[spaceId];
            const selectedOption = STATUS_OPTIONS.find(
              (option) => option.id === state,
            );

            return [
              priority && <img src="/icons/prio.svg" />,
              createdAt
                ? format(new Date(createdAt), "dd-MM-yyyy", {
                    locale,
                  })
                : "-",
              <a
                data-test-id={`view-more-${label?.replaceAll(" ", "-")}`}
                onClick={() => onMoreClick("view", id, userId, space?.id)}
                css={css({
                  textDecoration: "underline",
                  cursor: "pointer",
                })}>
                {label}
              </a>,
              <RoomStatusIndicator spaceId={spaceId} />,
              space?.label,
              <div
                data-test-id={`status-select-${label?.replaceAll(" ", "-")}`}>
                <Select
                  value={selectedOption ? [selectedOption] : []}
                  options={
                    state === IssueState.OPEN
                      ? OPEN_STATUS_OPTIONS
                      : STATUS_OPTIONS
                  }
                  onChange={(params) => onStatusChange(params, issue)}
                  containerStyle={{
                    borderRadius: "8px",
                    padding: "4px",
                    fontWeight: "bold",
                  }}
                  props={{
                    labelKey: "label",
                    valueKey: "id",
                    clearable: false,
                    backspaceRemoves: false,
                    searchable: false,
                    size: "compact",
                  }}
                />
              </div>,
              <AssignIssueMember
                memberId={member?.id}
                issue={issue}
                assigns={assigns}
                type={type}>
                <Assignee memberId={member?.id} />
              </AssignIssueMember>,
              <MoreActions
                onMoreClick={(action) =>
                  onMoreClick(action, id, userId, space?.id)
                }
              />,
            ];
          }) ?? []
        }
        emptyMessage={t("search.notFound", {
          search: t("common.issues").toLowerCase(),
        })}
        overrides={overrideTableStyles({
          TableHeadCell: {
            ":nth-child(4)": {
              paddingTop: "0px",
              paddingBottom: "0px",
              paddingLeft: "1.5px",
              paddingRight: "1.5px",
              borderRightWidth: "0px",
            },
          },
          TableBodyCell: {
            ":nth-child(1)": {
              width: "0px",
            },
            ":nth-child(4)": {
              position: "relative",
              paddingTop: "0px",
              paddingBottom: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
              borderRightWidth: "0px",
            },
            ":nth-child(7)": {
              width: "0px",
            },
            ":nth-child(8)": {
              width: "0px",
            },
          },
          TableBodyRow: {
            border: `1px solid ${theme.colors.mono400}`,
            ":last-child": {
              border: 0,
            },
            ":nth-child(odd)": {
              backgroundColor: theme.colors.white,
            },
            ":nth-child(odd):hover": {
              backgroundColor: theme.colors.mono100,
            },
            ":nth-child(even)": {
              backgroundColor: theme.colors.white,
            },
            ":nth-child(even):hover": {
              backgroundColor: theme.colors.mono100,
            },
          },
        })}
      />
      <IssueActions
        actionType={actionType}
        setActionType={setActionType}
        showModal={showModal}
        setShowModal={setShowModal}
        type={type}
      />
    </>
  );
};

export default IssueTable;
