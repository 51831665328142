import { useEffect } from "react";
import FetchingRequiredDataErrorScreen from "@screens/FetchingRequiredDataErrorScreen";
import SpinnerLoader from "@components/SpinnerLoader";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { selectCurrentPropertyId } from "@store/user/userSelectors";
import { selectCurrentProperty } from "@store/property/propertySelector";
import { selectRequiredDataState } from "@store/ui/uiSelectors";
import useAppDispatch from "@hooks/useAppDispatch";
import { fetchRequiredData } from "@store/user/userThunk";
import { fetchProperty } from "@store/property/propertyThunk";
import useAppSelector from "@hooks/useAppSelector";

const withAuth = (WrappedComponent: any) => {
  return (props: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // Using property id to determine auth state, since this is tied to token
    const selectedPropertyId = useAppSelector(selectCurrentPropertyId);
    const selectedProperty = useAppSelector(selectCurrentProperty);
    const requiredDataState = useAppSelector(selectRequiredDataState);

    useEffect(() => {
      if (localStorage.getItem("tokens") == null) {
        return navigate("/login");
      } else if (!selectedPropertyId) {
        dispatch(fetchRequiredData());
        return;
      }
      if (!selectedProperty || selectedPropertyId !== selectedProperty.id)
        dispatch(fetchProperty(selectedPropertyId));
    }, [selectedPropertyId]);

    if (requiredDataState === "error")
      return <FetchingRequiredDataErrorScreen />;
    if (!selectedPropertyId || requiredDataState === "loading")
      return (
        <div css={style.loader}>
          <SpinnerLoader />
        </div>
      );
    return <WrappedComponent {...props} />;
  };
};

const style = {
  loader: css({
    height: "100vh",
    width: "100vw",
  }),
};

export default withAuth;
