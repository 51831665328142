import { css } from "@emotion/react";
import React from "react";
import useAppSelector from "@hooks/useAppSelector";
import { selectAllRateTypes } from "@store/rateType/rateTypeSelectors";
import { setSelectedRateType } from "@store/rateType/rateTypeSlice";
import Table from "@components/base/table";
import Notification from "@components/base/notification";
import useAppDispatch from "@hooks/useAppDispatch";
import Button from "@components/base/button";
import theme from "@styles/theme";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GroupedRates: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const rateTypes = useAppSelector(({ rateType }) =>
    selectAllRateTypes(rateType),
  );

  const onNewGroupPress = () => {
    dispatch(setSelectedRateType(undefined));
    navigate("/settings/day-reports/rate-type-form");
  };

  return (
    <>
      <div data-test-id="grouped-rates" css={styles.controls}>
        <h3>{t("rateType.groups")}</h3>
        <Button onClick={onNewGroupPress}>{t("rateType.create")}</Button>
      </div>
      <Notification content={t("rateType.groupedRatesInfo")} kind="info" />
      <Table
        columns={[t("rateType.group"), t("rateType.countTypes")]}
        data={rateTypes.map((rateType) => {
          const onPress = () => {
            dispatch(setSelectedRateType(rateType.id));
            navigate("/settings/day-reports/rate-type-form");
          };

          return [
            <a onClick={onPress}>{rateType.name}</a>,
            rateType.roomCodes.length,
          ];
        })}
      />
    </>
  );
};

const styles = {
  controls: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: `${theme.sizing.scale800} 0`,
  }),
};

export default GroupedRates;
