import React from "react";
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from "baseui/button";
import { buttonOverrides } from "./overrides";

const Button: React.FC<BaseButtonProps> = (props) => {
  return (
    <BaseButton
      {...props}
      overrides={{ ...buttonOverrides, ...props.overrides }}
      css={styles.button}
    />
  );
};

export default Button;

const styles = {
  button: {
    ":hover": {
      opacity: 0.7,
    },
  },
};
