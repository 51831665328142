import React from "react";
import withAuth from "@helpers/withAuth";
import Layout from "@components/Layout";
import EndOfDayScreen from "@screens/EndOfDay";

const DayReportsPage: React.FC = () => {
  return (
    <Layout active="day-reports">
      <EndOfDayScreen />
    </Layout>
  );
};

export default withAuth(DayReportsPage);
